import { HandleBlockSeekType, LocalScriptBlockType } from "../..";
import { IconButton, Tooltip } from "@mui/material";
import { PlayArrowOutlined } from "@mui/icons-material";
import { memo, useMemo, useRef } from "react";
import { useBlockSynthesis } from "@/features/synthesis";
import { PlayButtonBase } from "@/components/elements";
import { useTypedSelector, useAppDispatch } from "@/config/configureAppStore";
import { setRenderCircuitBreaker } from "@/reducers/slices/projectUtilitySlice/projectUtilitySlice";
import { doesLocalBlockNeedsRender } from "../../utils";

interface RenderPlayButtonProps {
  isPlaying: boolean;
  block: LocalScriptBlockType;
  audioSrc?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onClick: (isPlay: boolean) => void | undefined;
  handleBlockSeek: HandleBlockSeekType;
  buffering: boolean;
}

function RenderPlayButton({
  isPlaying,
  disabled = false,
  block,
  audioSrc,
  onClick,
  handleBlockSeek,
  readOnly,
  buffering
}: RenderPlayButtonProps) {
  const { chunkAndSynthesizeBlocks } = useBlockSynthesis();
  const dispatch = useAppDispatch();

  const renderingBlocks = useTypedSelector(
    (state) => state.projectUtility.renderingBlocks
  );
  const currentPlayingBlockId = useTypedSelector(
    (state) => state.projectUtility.currentPlayingBlockId
  );
  const currentPlayingBlockIdRef = useRef(currentPlayingBlockId);
  currentPlayingBlockIdRef.current = currentPlayingBlockId;

  const saveInProgress = useTypedSelector(
    (state) => state.currentScriptProject.saveInProgress
  );

  if (!disabled) {
    if (!block && !audioSrc) {
      throw new Error(
        "You must provide either a block or an audioSrc to RenderPlayButton"
      );
    } else if (block && audioSrc) {
      throw new Error(
        "You must provide either a block or an audioSrc to RenderPlayButton, not both"
      );
    }
  }

  const isRendering = useMemo(() => {
    return renderingBlocks.includes(block.blockId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderingBlocks]);

  function handleBlockRender() {
    // do render stuff
    if (block) {
      dispatch(setRenderCircuitBreaker({ renderCircuitBreaker: false }));
      handleBlockSeek(block);
      chunkAndSynthesizeBlocks({
        blockIdList: [block.blockId],
        onError: () => console.log("RENDER_PLAY_BUTTON", "Block render failed"),
        onFinish: () => {
          onClick(true);
        }
      });
    }
  }

  function handlePlayButtonClick() {
    onClick(isPlaying ? false : true);
  }

  return (
    <>
      {buffering || isRendering ? (
        <PlayButtonBase disabled buffering />
      ) : doesLocalBlockNeedsRender(block) && !isPlaying ? (
        <Tooltip
          title={
            saveInProgress
              ? "Save in progress"
              : isRendering
              ? "Rendering"
              : "Render"
          }
        >
          <span>
            <IconButton
              // if save is in progress then disable the render button
              disabled={readOnly || disabled || saveInProgress}
              onClick={handleBlockRender}
              size="small"
            >
              <PlayArrowOutlined />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <PlayButtonBase
          disabled={disabled}
          isPlaying={isPlaying}
          onClick={handlePlayButtonClick}
        />
      )}
    </>
  );
}

export default memo(RenderPlayButton);
