import React from "react";
import { useAuth } from "@/lib/firebase/firebaseHooks";
import { Navigate, useLocation } from "react-router-dom";

interface RequiresAuthProps {
  children: React.ReactNode;
}

function RequiresAuth({ children }: RequiresAuthProps): React.ReactNode {
  const authUser = useAuth();
  const location = useLocation();

  if (!authUser) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  return children;
}

export default RequiresAuth;
