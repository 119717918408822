import { TBaseSourceFile } from "@/types/project";

export enum BoundingBoxStatus {
  IDLE = "IDLE",
  SELECTED = "SELECTED",
  DISCARDED = "DISCARDED"
}

export interface BoundingBox {
  topLeft: {
    x: number;
    y: number;
  };
  bottomRight: {
    x: number;
    y: number;
  };
}

export interface Clip {
  boundingBoxes: BoundingBox[] | null;
  startFrame: number;
  endFrame: number;
  thumbnail: string;
  selected: boolean;
  selectedFaceIdx: number;
  dummy?: boolean;
}

export type TuningFile = Pick<
  TBaseSourceFile,
  "fileName" | "sourceFileId" | "uploadedAt" | "duration"
> & {
  projectName: string;
  downloadUrl: string;
  faceMarkClips: {
    clips: FaceMarkClip[];
    videoFPS: number;
    inputVideoResolution: [number, number];
  };
};

export interface FaceMarkClip {
  startFrameIdx: number;
  endFrameIdx: number;
  selectedFaceIdx: number;
  thumbnailDownloadUrl?: string;
  faceBoundingBoxes: BoundingBox[] | null;
}
