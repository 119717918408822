import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { IFileData } from "@/features/clientProjectDetails";
import {
  AudiotrackRounded,
  FileCopyRounded,
  SearchRounded,
  VideocamRounded
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  autocompleteClasses,
  outlinedInputClasses
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  MEDIA_TYPE,
  MEDIA_TYPE_EXTN,
  PAGE_TYPE,
  SEE_ALL_RESULTS,
  SEE_ALL_RESULTS_OPTION
} from "../../constant";
import { resetAllFilters } from "@/reducers/slices/currentClientProjectSlice/currentClientProjectSlice";

const EMPTY_ARRAY: IFileData[] = [];

interface IOptions {
  label: string;
  id?: string;
  type: string;
}

export const SearchBar = (): React.ReactNode => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  const allProjects = useTypedSelector((state) => state.clientHome.allProjects);
  const workspaces = useTypedSelector((state) => state.user.linkedWorkspaces);
  const projectFiles = useTypedSelector((state) =>
    projectId
      ? state.currentClientProject?.[projectId]?.sourceFileResponseList
      : EMPTY_ARRAY
  );

  const [searchValue, setSearchValue] = useState("");

  const currentPage = useMemo((): PAGE_TYPE => {
    if (location.pathname === "/home" || location.pathname === "/search") {
      return "HOME";
    } else if (location.pathname.startsWith("/project/")) {
      return "PROJECT_DETAIL";
    }
    return "NA";
  }, [location?.pathname]);

  const resetProjectFilter = () => {
    if (!projectId) {
      return;
    }
    dispatch(
      resetAllFilters({
        projectId
      })
    );
  };

  const options: IOptions[] = useMemo(() => {
    if (currentPage === "HOME") {
      const projectList = Object.values(allProjects).map((project) => ({
        label: project.projectName || "Untitled project",
        id: project.projectId,
        type: "PROJECT"
      }));
      return projectList || [];
    } else if (currentPage === "PROJECT_DETAIL") {
      const fileList = projectFiles?.map((file) => ({
        label: file.fileName || file.originalFileName,
        id: file.sourceFileId,
        type: file.contentType?.startsWith(MEDIA_TYPE_EXTN.VIDEO)
          ? MEDIA_TYPE.VIDEO
          : MEDIA_TYPE.AUDIO
      }));

      return fileList || [];
    }
    return [];
  }, [allProjects, projectFiles, currentPage]);

  const navigateToSearch = (option: IOptions) => {
    if (option.type === "PROJECT") {
      navigate(`/project/${option.id}`);
    } else {
      navigate(`?fileId=${option.id}&search=${option.label}`);
      resetProjectFilter();
    }
  };

  const handleSeeAllResults = () => {
    if (currentPage === "HOME") {
      navigate(`/search?q=${searchValue}`);
    } else if (currentPage === "PROJECT_DETAIL") {
      navigate(`?search=${searchValue}`);
      resetProjectFilter();
    }
  };

  if (!workspaces?.length) {
    return null;
  }

  return (
    <Autocomplete
      disablePortal
      id="search-bar"
      options={options}
      popupIcon={null}
      slotProps={{
        paper: {
          sx: {
            borderRadius: 2
          },
          elevation: 1
        }
      }}
      sx={{
        flexGrow: 1,
        minWidth: 300,
        maxWidth: 436,
        border: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: 2,
        [`& .${outlinedInputClasses.root}`]: {
          py: 0,
          px: "12px",
          pr: "12px",
          "&:focus-visible": {
            outline: 0
          },
          [`& .${autocompleteClasses.input}`]: {
            padding: "6px 8px",
            cursor: "pointer",
            "&:focus-visible": {
              outline: 0
            }
          }
        }
      }}
      ListboxProps={{
        sx: {
          p: 0.5,
          [`& .${autocompleteClasses.option}`]: {
            p: 1,
            borderRadius: 1
          }
        }
      }}
      renderInput={(params) => {
        return (
          <>
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchRounded />,
                endAdornment: params.InputProps.endAdornment,
                sx: {
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px" /* 150% */,
                  letterSpacing: "0.15px",
                  color: "text.primary",
                  height: 46,
                  minHeight: 46,
                  m: 0,
                  cursor: "pointer",
                  "&:focus-visible": {
                    outline: 0
                  },
                  [`&.${outlinedInputClasses.focused}`]: {
                    backgroundColor: "background.default",
                    borderRadius: 2
                  },
                  [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                    {
                      borderWidth: 0,
                      outline: 0
                    }
                }
              }}
              placeholder={
                currentPage === "PROJECT_DETAIL"
                  ? "Search Files"
                  : "Search Projects"
              }
              sx={{
                backgroundColor: "transparent",
                p: 0,
                m: 0,
                borderRadius: 2,
                cursor: "pointer",
                "&:focus-visible": {
                  outline: 0
                },
                "& fieldset": {
                  borderWidth: 0,
                  cursor: "pointer",
                  "&:focus-visible": {
                    borderWidth: 0,
                    outline: 0
                  }
                },
                "&:hover": {
                  backgroundColor: "action.hover"
                }
              }}
              color="secondary"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </>
        );
      }}
      renderOption={(props, option) =>
        option.id === SEE_ALL_RESULTS ? (
          <>
            <Divider sx={{ my: 0.5 }} />
            <ListItem {...props} sx={{ borderRadius: 2, p: 0 }}>
              <Button
                fullWidth
                color="secondary"
                variant="text"
                sx={{
                  justifyContent: "flex-start",
                  p: 1,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.17px",
                  "&:hover": {
                    backgroundColor: "transparent"
                  }
                }}
                onClick={(e) => {
                  handleSeeAllResults();
                  if (props.onClick) {
                    props.onClick(e as any);
                  }
                }}
              >
                See All Results
              </Button>
            </ListItem>
          </>
        ) : (
          <ListItem
            {...props}
            onClick={(e) => {
              navigateToSearch(option);
              if (props.onClick) {
                props.onClick(e);
              }
            }}
            sx={{ borderRadius: 2 }}
          >
            <ListItemAvatar
              sx={{
                bgcolor: "background.default",
                color: "secondary.focusVisible",
                p: 1.5,
                minWidth: 32,
                minHeight: 32,
                display: "inline-flex",
                borderRadius: 6
              }}
            >
              {option.type === "PROJECT" ? (
                <FileCopyRounded sx={{ height: 20, width: 20 }} />
              ) : option.type === MEDIA_TYPE.VIDEO ? (
                <VideocamRounded sx={{ height: 20, width: 20 }} />
              ) : (
                <AudiotrackRounded sx={{ height: 20, width: 20 }} />
              )}
            </ListItemAvatar>
            <ListItemText
              sx={{
                ml: 2,
                textOverflow: "ellipsis",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.17px",
                maxWidth: "100%",
                overflow: "hidden"
              }}
              disableTypography
            >
              {option.label}
            </ListItemText>
          </ListItem>
        )
      }
      filterOptions={(options, state) => {
        const _searchTerm = state.inputValue?.toLowerCase();
        const temp = options.filter((option) =>
          option.label?.toLowerCase()?.includes(_searchTerm)
        );
        const isSeeAllReq = _searchTerm?.length && temp?.length > 5;
        const returnValue = [...temp.slice(0, 4)];
        if (isSeeAllReq) {
          returnValue.push(SEE_ALL_RESULTS_OPTION);
        }
        return returnValue;
      }}
      onInputChange={(_, __, reason) => {
        if (reason === "clear") {
          if (currentPage === "PROJECT_DETAIL") {
            navigate(location.pathname);
          }
        }
      }}
      onChange={(_, option, reason) => {
        if (reason === "selectOption" && option) {
          if (option.id !== SEE_ALL_RESULTS) {
            navigateToSearch(option);
          } else {
            handleSeeAllResults();
          }
        }
      }}
      blurOnSelect={true}
      noOptionsText={
        searchValue?.length
          ? currentPage === "PROJECT_DETAIL"
            ? `No file found with the name "${searchValue}"`
            : `No project found with the name "${searchValue}"`
          : `You can search your ${
              currentPage === "PROJECT_DETAIL" ? "files" : "projects"
            } here`
      }
    />
  );
};
