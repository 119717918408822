import { generateId } from "./misc";

export enum POLL_STATUS {
  FAILED = "FAILED",
  STOP_POLL = "STOP_POLL"
}

export type PollFunction<T> = (
  signal?: AbortSignal,
  ...args: any[]
) => Promise<T>;

export type getPollerArguments<T> = {
  id?: string;
  fn: PollFunction<T>;
  validate: (args: T) => boolean;
  interval: number;
};

let instance: PollFactory | null = null;

/**
 * Singleton Class
 */
class PollFactory {
  pollers: {
    [key: string]: {
      abortController: AbortController;
    };
  };

  constructor() {
    if (instance) {
      throw new Error("You can only create one instance!");
    } else {
      instance = this as PollFactory;
    }

    this.pollers = {};
  }

  /**
   * @returns : Boolean, whether factory has active pollers or not
   */
  isFactoryActive() {
    return !!Object.keys(this.pollers)?.length;
  }

  /**
   * @param id : Used for tracking the poller returned
   * @param fn : Function that needs to be polled, should always return a promise and should always resolve it with statuses in pollFactory
   * @param validate: Function validates the @fn response and stops/continues the poller
   * @param interval: Interval for poller. Starts only after the poller function promise resolve
   */
  getPoller<T>({
    id = generateId("POLL"),
    fn,
    validate,
    interval = 3000
  }: getPollerArguments<T>) {
    if (this.pollers[id]) {
      console.error(`PollFactory::Poller with ID ${id} already exists.`);
      return;
    }

    const poller = async () => {
      const abortController = new AbortController();
      const { signal } = abortController;
      this.pollers[id] = { abortController }; // save abort controller
      const executePoll = async (
        resolve: (value: T | PromiseLike<T>) => void,
        reject: (reason: unknown) => void
      ) => {
        try {
          if (!this.pollers[id]) return; // check before starting
          const result = await fn(signal);
          if (!this.pollers[id]) return; // check after promise resolves

          if (!validate(result)) {
            setTimeout(executePoll, interval, resolve, reject);
          }
        } catch (e: any) {
          if (!this.pollers[id]) return;

          if (e?.code === "ERR_NETWORK") {
            setTimeout(executePoll, interval, resolve, reject);
          }
        }
      };

      return new Promise<T>(executePoll);
    };

    poller();
    return id;
  }

  cancelPoller(id: string) {
    if (!this.pollers[id]) {
      console.error(`PollFactory::Poller with ID ${id} does not exist.`);
      return;
    }
    const { abortController } = this.pollers[id];
    abortController.abort();
    delete this.pollers[id];
  }

  removeAllPollers() {
    for (const [, poll] of Object.entries(this.pollers)) {
      poll?.abortController?.abort();
    }

    this.pollers = {};
  }
}

const pollFactory = Object.freeze(new PollFactory());

export default pollFactory;

// Usage
// Create a poller with ID "myPoller"
// const myPoller = pollFactory.getPoller({id:"myPoller", fn, validate, interval}); | returns promise |

// Cancel the poller with ID "myPoller"
// pollFactory.cancelPoller("myPoller");
