import { STATUS } from "@/constants/status";
import { TMember } from "@/features/user";
import { userThunks } from "@/reducers/thunks/user";
import { createSlice } from "@reduxjs/toolkit";

interface UserManagementSlice {
  status: STATUS;
  users: TMember<string | null>[];
}

const initialState: UserManagementSlice = {
  status: STATUS.IDLE,
  users: []
};

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userThunks.fetchInternalUsers.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(userThunks.fetchInternalUsers.rejected, (state) => {
        state.status = STATUS.ERROR;
      })
      .addCase(userThunks.fetchInternalUsers.fulfilled, (state, action) => {
        state.status = STATUS.SUCCESS;
        state.users = action.payload.map((e) => ({
          ...e,
          displayName:
            e.displayName ?? `${e.firstName ?? ""} ${e.lastName ?? ""}`
        }));
      });
  }
});

export default userManagementSlice.reducer;
