import { Avatar, Chip, ChipOwnProps, chipClasses, styled } from "@mui/material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import React from "react";
import { TUser } from "@/features/user";

export interface AvatarChipProps extends ChipOwnProps {
  user?: TUser;
  avatarIcon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  [`& .${chipClasses.avatar}`]: {
    textTransform: "uppercase"
  },

  "& .MuiChip-avatarColorError": {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText
  },
  "& .MuiChip-avatarColorInfo": {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.info.contrastText
  },
  "& .MuiChip-avatarColorSuccess": {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.success.contrastText
  },
  "& .MuiChip-avatarColorWarning": {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.warning.contrastText
  }
}));

const AvatarChip = ({ user, label, avatarIcon, ...props }: AvatarChipProps) => {
  return (
    <StyledChip
      {...props}
      label={(user?.displayName?.trim() || user?.emailId) ?? label}
      avatar={
        <Avatar
          src={user?.profileImageUrl ?? undefined}
          alt={user?.displayName}
        >
          {user?.displayName
            ? user.displayName?.trim()?.[0]
            : avatarIcon ?? <PersonRoundedIcon />}
        </Avatar>
      }
    />
  );
};

export default AvatarChip;
