import { useMurfTheme } from "@/themes/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";

const MurfThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const { baseTheme } = useMurfTheme();

  return (
    <ThemeProvider theme={baseTheme}>
      <CssBaseline enableColorScheme />
      {children}
    </ThemeProvider>
  );
};

export default MurfThemeProvider;
