import { LocalScriptBlockType } from "@/features/blocks";
import { Speaker } from "@/features/scriptStudio/types";

export interface UpdateBlockPayload {
  blockId: string;
  block: Partial<LocalScriptBlockType>;
}

export interface RenderedBlock {
  id: string;
  text: string;
  renderVersion: number;
}

export interface BlocksMap {
  [key: string]: LocalScriptBlockType;
}

export enum PLAYER_MODE {
  ORIGINAL = "ORIGINAL",
  TRANSLATION = "TRANSLATION"
}

export interface UpdateSpeakerPayload {
  speaker: Speaker;
  needToSync?: boolean;
}
