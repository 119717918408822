import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { authenticationRoutes } from "@/features/authentication";
import { commonRoutes } from "./commonRoutes";
import { baseRoute } from "@/features/base";
import { internalRoute } from "@/features/internal/routes";
import AppLayout from "@/components/layout/AppLayout";
import { linkPagesRoutes } from "@/features/linkPages/routes";
import ErrorFallback from "@/components/elements/ErrorFallback/ErrorFallback";
import NotFound from "@/components/elements/NotFound";
import { wrapCreateBrowserRouter } from "@sentry/react";

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <AppLayout />,
    errorElement: <ErrorFallback />,
    children: [
      baseRoute,
      internalRoute,
      ...commonRoutes,
      ...linkPagesRoutes,
      authenticationRoutes
    ]
  },
  {
    path: "*",
    element: <NotFound />
  }
]);

function Routes() {
  return <RouterProvider router={router} />;
}

export default Routes;
