import { TuningFile } from "../types";
import { FACE_MARK_CLIPS } from "./faceMarkCutsDummy";

const FPS = 23.976023976023978;

// const THUMBNAIL_URLS = [
//   "https://murf.ai/public-assets/home/ai-voice-changer/murf-studio-quality-at-home.jpg",
//   "https://murf.ai/public-assets/home/ai-voice-changer/murf-no-fancy.jpg",
//   "https://murf.ai/public-assets/home/ai-voice-changer/murf-switch-voices.jpg",
//   "https://murf.ai/public-assets/home/ai-voice-changer/murf-mix-multiple-voices.jpg"
// ];

export const TUNING_FILE_DATA: TuningFile = {
  projectName: "dummy project name",
  sourceFileId: "SF2cglq0i4O6",
  fileName: "Oppenheimer _ New Trailer.mp4",
  uploadedAt: 1702984964296,
  duration: 186,
  downloadUrl:
    "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/multipart/processed/reducedQuality/ASSET_VID/MP2cglq0i3JS.mp4?response-cache-control=max-age%3D604800&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T000000Z&X-Amz-SignedHeaders=host&X-Amz-Expires=259200&X-Amz-Credential=AKIAVUFZ2XGAH2CKTPTO%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=7fe64e7e49a37803797940928dfa1a7659e18b27453453132153bf68dcf76ff2",
  faceMarkClips: {
    clips: FACE_MARK_CLIPS,
    videoFPS: FPS,
    inputVideoResolution: [720, 1280]
  }
};
