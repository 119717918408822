import { Autocomplete, AutocompleteProps, ChipTypeMap } from "@mui/material";

export const PopperComponent = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { disablePortal, anchorEl, open, role, ...other } = props;
  return <div {...other} />;
};

/**
 *  This is Autocomplete component customized to be used inside popover
 *  use styled(PopoverAutocomplete) to modify child element styles
 */

export const PopoverAutocomplete = <
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"]
>({
  ...props
}: Omit<
  AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  "open"
>) => {
  return <Autocomplete open {...props} PopperComponent={PopperComponent} />;
};
