import { FaceMarkClip } from "..";

export const FACE_MARK_CLIPS: FaceMarkClip[] = [
  {
    startFrameIdx: 0,
    endFrameIdx: 292,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/0.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=40d84eaa0992dbd0616d4c2b6243a22ec879c8a8f74ed7dc48c40138816d9df8",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 293,
    endFrameIdx: 316,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/293.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=5468adfaf631cf5bfc33850542930ccf52f454b931c7887fcf1f52c9e22d3c16",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 520,
          y: 40
        },
        bottomRight: {
          x: 976,
          y: 616
        }
      }
    ]
  },
  {
    startFrameIdx: 316,
    endFrameIdx: 331,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/316.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=56e6b0767e65909889c67d8d1c5b2c9884139c528f85a6e0b2b58cb15f2e157a",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 72,
          y: 92
        },
        bottomRight: {
          x: 456,
          y: 528
        }
      }
    ]
  },
  {
    startFrameIdx: 332,
    endFrameIdx: 332,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/332.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=7497c73f67442d2690fc2e0cc9aaf268255ed384edfbf70ac8fa6785178206be",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 333,
    endFrameIdx: 333,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/333.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=84fda35740c8efe7a016e5be4d98f45e9a67ebc344e1adccdb6a43caa2c748db",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 248,
          y: 196
        },
        bottomRight: {
          x: 468,
          y: 460
        }
      }
    ]
  },
  {
    startFrameIdx: 334,
    endFrameIdx: 350,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/334.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=5e7b8e08ece029aafdc18fbb0948143269b6d4a3c8b5a261bf68befb4d3bb54e",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 351,
    endFrameIdx: 353,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/351.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=54b2176f7cdc7648f70d5d8b1d4f3f4e93c98c99c69cafbda48185339be7f13e",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 404,
          y: 180
        },
        bottomRight: {
          x: 624,
          y: 516
        }
      }
    ]
  },
  {
    startFrameIdx: 354,
    endFrameIdx: 360,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/354.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=b506d42529749a6b3570e3792498fffb44825c96276ed983db18161e24274fa3",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 361,
    endFrameIdx: 362,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/361.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=9127b50e62bec589886e70dfc1bd088a772a6ff2939bc9cb3a838389c694d6e4",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 428,
          y: 160
        },
        bottomRight: {
          x: 632,
          y: 492
        }
      }
    ]
  },
  {
    startFrameIdx: 363,
    endFrameIdx: 596,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/363.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=0c4ddc6d62961b73e322372b0be7f2a2df078fa3fdeb56611f9f81d6aa5bb59e",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 597,
    endFrameIdx: 598,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/597.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=254350c4029cfabe9292de4fb5d8d43840c4293c9bd3463ad56329b58618393e",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 1156,
          y: 308
        },
        bottomRight: {
          x: 1256,
          y: 476
        }
      }
    ]
  },
  {
    startFrameIdx: 599,
    endFrameIdx: 601,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/599.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=e15a8fdddab62a517eaec0dd5691637a86f181c75fb1d6ece880c00f91357f11",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 602,
    endFrameIdx: 602,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/602.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=1b8ccf9752cdb727fa0b19cb9363c891c2e50c6e779ce8af2e5ee263d082c150",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 92,
          y: 236
        },
        bottomRight: {
          x: 160,
          y: 324
        }
      }
    ]
  },
  {
    startFrameIdx: 603,
    endFrameIdx: 603,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/603.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=796e397f014783d0f2ce0180994789a1fe22c1377a1f0012ee1dd61d117cb5eb",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 604,
    endFrameIdx: 608,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/604.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=2cd5a32f563b6880cab2f6e68b42db0d4ca5c128ee0eff882708ac92270c1bfd",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 1112,
          y: 304
        },
        bottomRight: {
          x: 1224,
          y: 456
        }
      },
      {
        topLeft: {
          x: 104,
          y: 240
        },
        bottomRight: {
          x: 184,
          y: 328
        }
      }
    ]
  },
  {
    startFrameIdx: 609,
    endFrameIdx: 609,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/609.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=fcb30f77a302fcccb4ff58d3373930f3308955a22fc3b211f6aae21e304a17be",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 610,
    endFrameIdx: 610,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/610.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=62aefed573247ee79768fa2582684285c94fe86a30c083170ca4d75754807620",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 1104,
          y: 312
        },
        bottomRight: {
          x: 1204,
          y: 452
        }
      },
      {
        topLeft: {
          x: 516,
          y: 264
        },
        bottomRight: {
          x: 616,
          y: 356
        }
      }
    ]
  },
  {
    startFrameIdx: 611,
    endFrameIdx: 611,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/611.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=e2241959657ff9435e7821e4430e57531445fb26d4d9af967e592c1c9e4a81e4",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 612,
    endFrameIdx: 612,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/612.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=43168ceda375eb432a0f1ada3af0c1d7721f8f64497ab42321d05406981326da",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 1108,
          y: 320
        },
        bottomRight: {
          x: 1204,
          y: 460
        }
      },
      {
        topLeft: {
          x: 136,
          y: 256
        },
        bottomRight: {
          x: 200,
          y: 332
        }
      },
      {
        topLeft: {
          x: 520,
          y: 256
        },
        bottomRight: {
          x: 616,
          y: 364
        }
      }
    ]
  },
  {
    startFrameIdx: 613,
    endFrameIdx: 613,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/613.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=935775628db899533b9c0a6dc6f7676e93d289def09ff28249355bcf8fa7061f",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 614,
    endFrameIdx: 614,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/614.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=11d0c2a97e0f49cdaaa4d2ec3c86c5af3ff8d420631ca0d91ddaedd13ed645c7",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 1100,
          y: 324
        },
        bottomRight: {
          x: 1200,
          y: 456
        }
      },
      {
        topLeft: {
          x: 140,
          y: 256
        },
        bottomRight: {
          x: 204,
          y: 332
        }
      },
      {
        topLeft: {
          x: 520,
          y: 260
        },
        bottomRight: {
          x: 616,
          y: 364
        }
      }
    ]
  },
  {
    startFrameIdx: 615,
    endFrameIdx: 615,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/615.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=16565de7faee2b0c73140280c07ac8e0b0096151d726add6494a401c1483998c",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 616,
    endFrameIdx: 620,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/616.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=115fa085e7ac0d3c8301b853cbf265c43354c6dc39bf53539c98f769a0ba15b7",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 1080,
          y: 300
        },
        bottomRight: {
          x: 1192,
          y: 444
        }
      },
      {
        topLeft: {
          x: 528,
          y: 240
        },
        bottomRight: {
          x: 648,
          y: 364
        }
      }
    ]
  },
  {
    startFrameIdx: 621,
    endFrameIdx: 621,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/621.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=7e1525f72efb3a8014320a5094e9be8ec3b03153c478cb8cf623955a8d37f4de",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 622,
    endFrameIdx: 622,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/622.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=56a4f7dfedbb3a5da1473bffec4325f4ec66dbf143958624d4b27c61888d4a57",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 1068,
          y: 288
        },
        bottomRight: {
          x: 1164,
          y: 404
        }
      }
    ]
  },
  {
    startFrameIdx: 623,
    endFrameIdx: 623,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/623.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=1417e35a486ab1972df2b4fb49d59ac55a2780f6b74b7899ca89da5c9ee6e4e3",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 624,
    endFrameIdx: 625,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/624.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=85c9de5fde285f5b0aedf19af5d720d4d7d9eb8704834b2c25dabd9532047dcf",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 568,
          y: 228
        },
        bottomRight: {
          x: 672,
          y: 348
        }
      },
      {
        topLeft: {
          x: 1052,
          y: 288
        },
        bottomRight: {
          x: 1156,
          y: 416
        }
      }
    ]
  },
  {
    startFrameIdx: 626,
    endFrameIdx: 626,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/626.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=7f0e04edc60f674e365d70adf28e448a6ad98eb0368811f6baf5e1e9fbc1454e",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 627,
    endFrameIdx: 627,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/627.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=67374861ecc747e5fcce3561707cd964518ad7314d9a2767514c75a259de6e3f",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 572,
          y: 228
        },
        bottomRight: {
          x: 672,
          y: 340
        }
      },
      {
        topLeft: {
          x: 1040,
          y: 296
        },
        bottomRight: {
          x: 1124,
          y: 412
        }
      },
      {
        topLeft: {
          x: 184,
          y: 240
        },
        bottomRight: {
          x: 272,
          y: 332
        }
      }
    ]
  },
  {
    startFrameIdx: 628,
    endFrameIdx: 628,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/628.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=aad38daf17327391ba444c6360ee2fcd39476864f2c8a2826705e0a82ad3c1c5",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 629,
    endFrameIdx: 629,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/629.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=e0b7440ebadd1d69032687125081e9b5dd544e3e5dc52902ef4242a66620e576",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 200,
          y: 244
        },
        bottomRight: {
          x: 288,
          y: 348
        }
      }
    ]
  },
  {
    startFrameIdx: 630,
    endFrameIdx: 630,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/630.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=039afc2cb867dd97ec75649fc2c32356e6d768ae9f7d0f8200f6edeb42a414ae",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 631,
    endFrameIdx: 631,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/631.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=77a33d6d07355b75a1bd257ba6655c016ac55948c7ef1aba4737ed46eb3fb363",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 556,
          y: 220
        },
        bottomRight: {
          x: 644,
          y: 328
        }
      },
      {
        topLeft: {
          x: 24,
          y: 400
        },
        bottomRight: {
          x: 120,
          y: 556
        }
      },
      {
        topLeft: {
          x: 212,
          y: 252
        },
        bottomRight: {
          x: 300,
          y: 352
        }
      },
      {
        topLeft: {
          x: 1168,
          y: 360
        },
        bottomRight: {
          x: 1272,
          y: 532
        }
      }
    ]
  },
  {
    startFrameIdx: 632,
    endFrameIdx: 632,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/632.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=fe91ecf47480e07537471510d76aaa9562f6e7ca94894d31c550d71356fdd4c3",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 633,
    endFrameIdx: 640,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/633.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=65ae4dbf97ef708c074e62b764fafa7e6e450f5a083d76a04df044ea1dcf8a3b",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 552,
          y: 228
        },
        bottomRight: {
          x: 652,
          y: 360
        }
      },
      {
        topLeft: {
          x: 232,
          y: 256
        },
        bottomRight: {
          x: 380,
          y: 376
        }
      },
      {
        topLeft: {
          x: 1072,
          y: 356
        },
        bottomRight: {
          x: 1244,
          y: 528
        }
      }
    ]
  },
  {
    startFrameIdx: 641,
    endFrameIdx: 641,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/641.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=1fe53d7acfd29a1cc832981bf3c519d3cb92f7417e18e7984ab0674cb321b1e6",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 642,
    endFrameIdx: 650,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/642.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=848acc4396877db10afaee3bfa7db226139a7309880a941791a0ebbb0792f5c2",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 1000,
          y: 364
        },
        bottomRight: {
          x: 1176,
          y: 528
        }
      },
      {
        topLeft: {
          x: 548,
          y: 236
        },
        bottomRight: {
          x: 656,
          y: 384
        }
      }
    ]
  },
  {
    startFrameIdx: 651,
    endFrameIdx: 651,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/651.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=4fc791bc201912347d3b27d7c015e0bb01b4ede0b879510dcd4a6ce721086932",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 652,
    endFrameIdx: 652,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/652.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=93e69ddf20c1c344ea335f5254b4e197987cf8ce732e183564f52953adadffef",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 564,
          y: 284
        },
        bottomRight: {
          x: 664,
          y: 392
        }
      },
      {
        topLeft: {
          x: 988,
          y: 388
        },
        bottomRight: {
          x: 1096,
          y: 532
        }
      },
      {
        topLeft: {
          x: 4,
          y: 280
        },
        bottomRight: {
          x: 92,
          y: 432
        }
      }
    ]
  },
  {
    startFrameIdx: 653,
    endFrameIdx: 653,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/653.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=d4605a63dce8d27fdca14282257549f84d646287105bce578e86ad65aa364466",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 654,
    endFrameIdx: 660,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/654.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=656199a2865678886f9cbe9bd8999bce7ef4d108a1be8f458827102c3f5c34b4",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 576,
          y: 292
        },
        bottomRight: {
          x: 708,
          y: 412
        }
      },
      {
        topLeft: {
          x: 4,
          y: 276
        },
        bottomRight: {
          x: 100,
          y: 428
        }
      },
      {
        topLeft: {
          x: 976,
          y: 372
        },
        bottomRight: {
          x: 1088,
          y: 532
        }
      },
      {
        topLeft: {
          x: 396,
          y: 280
        },
        bottomRight: {
          x: 488,
          y: 412
        }
      }
    ]
  },
  {
    startFrameIdx: 661,
    endFrameIdx: 661,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/661.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=750ac4ca2d9efe2f9895d51a4877890e7d4dbda3bae35e1e09c2de2e00e20cd8",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 662,
    endFrameIdx: 670,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/662.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=5b41a3e466f327a0bfbed81564c36276a52ffa135c399e5785b936f5a2ec11d9",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 604,
          y: 240
        },
        bottomRight: {
          x: 724,
          y: 400
        }
      },
      {
        topLeft: {
          x: 976,
          y: 324
        },
        bottomRight: {
          x: 1104,
          y: 504
        }
      },
      {
        topLeft: {
          x: 396,
          y: 268
        },
        bottomRight: {
          x: 512,
          y: 372
        }
      }
    ]
  },
  {
    startFrameIdx: 671,
    endFrameIdx: 709,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/671.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=93dac071a687638c2f605e792c8defbea519b68c863958f007e15f39c6be6bba",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 710,
    endFrameIdx: 710,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/710.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=3d987910f5d23c81a934afcc940744d8f67a45c1664ab4738eaec399b7e1d61a",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 352,
          y: 208
        },
        bottomRight: {
          x: 432,
          y: 324
        }
      }
    ]
  },
  {
    startFrameIdx: 711,
    endFrameIdx: 711,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/711.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=805cfe8423ebec744c49027728f37bf06266920f228ed2ccc68af318155df10e",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 712,
    endFrameIdx: 714,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/712.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=faa9ef0bd6bf0facf406a6f8611f5c5ca16f1316370253e862407a2e30666490",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 348,
          y: 204
        },
        bottomRight: {
          x: 440,
          y: 328
        }
      },
      {
        topLeft: {
          x: 1016,
          y: 236
        },
        bottomRight: {
          x: 1192,
          y: 520
        }
      },
      {
        topLeft: {
          x: 528,
          y: 204
        },
        bottomRight: {
          x: 656,
          y: 364
        }
      }
    ]
  },
  {
    startFrameIdx: 715,
    endFrameIdx: 715,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/715.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=ec250c376be0cfd80d396d81bb27eb33a0907fa427791e9bcb477113543e4a61",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 716,
    endFrameIdx: 733,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/716.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=e7561325c0e24b0ec8ea6c78e8bee8350fcb46fa49043da93ddc2841aefef38c",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 520,
          y: 200
        },
        bottomRight: {
          x: 680,
          y: 376
        }
      },
      {
        topLeft: {
          x: 188,
          y: 200
        },
        bottomRight: {
          x: 432,
          y: 340
        }
      },
      {
        topLeft: {
          x: 960,
          y: 240
        },
        bottomRight: {
          x: 1172,
          y: 528
        }
      },
      {
        topLeft: {
          x: 908,
          y: 168
        },
        bottomRight: {
          x: 1036,
          y: 364
        }
      }
    ]
  },
  {
    startFrameIdx: 734,
    endFrameIdx: 734,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/734.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=5689d5c24387cbab4feff2ee69a2b084c7fc20ff9acce10a2369925b05f80c18",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 735,
    endFrameIdx: 749,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/735.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=9db08b930642d188bdc53a2c332cb6778f6ef3bd819a6931adedf6edb097fa0f",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 124,
          y: 208
        },
        bottomRight: {
          x: 280,
          y: 360
        }
      },
      {
        topLeft: {
          x: 944,
          y: 180
        },
        bottomRight: {
          x: 1172,
          y: 532
        }
      },
      {
        topLeft: {
          x: 564,
          y: 216
        },
        bottomRight: {
          x: 740,
          y: 392
        }
      }
    ]
  },
  {
    startFrameIdx: 750,
    endFrameIdx: 750,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/750.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=fe2e5b98bae274cb072abf0bc3d0b2a6870939296e33777b6bb6784c876cf9c7",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 751,
    endFrameIdx: 752,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/751.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=8f980d7bd23763a901704799d6eeade16ad5ea54ce6ffdc14649d33902ca8b85",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 120,
          y: 192
        },
        bottomRight: {
          x: 236,
          y: 340
        }
      },
      {
        topLeft: {
          x: 1016,
          y: 216
        },
        bottomRight: {
          x: 1208,
          y: 504
        }
      },
      {
        topLeft: {
          x: 600,
          y: 216
        },
        bottomRight: {
          x: 744,
          y: 400
        }
      },
      {
        topLeft: {
          x: 932,
          y: 160
        },
        bottomRight: {
          x: 1060,
          y: 356
        }
      },
      {
        topLeft: {
          x: 968,
          y: 172
        },
        bottomRight: {
          x: 1128,
          y: 436
        }
      }
    ]
  },
  {
    startFrameIdx: 753,
    endFrameIdx: 753,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/753.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=b0656116607a59b726d54ef92560fb610b4579038582a77f7b3f7450369923dc",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 754,
    endFrameIdx: 769,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/754.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=51703f4c18a41ace886b780a7fba044364c3bd29850224d651b78ddf256d24fd",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 16,
          y: 180
        },
        bottomRight: {
          x: 236,
          y: 368
        }
      },
      {
        topLeft: {
          x: 1052,
          y: 180
        },
        bottomRight: {
          x: 1280,
          y: 492
        }
      },
      {
        topLeft: {
          x: 516,
          y: 208
        },
        bottomRight: {
          x: 740,
          y: 428
        }
      },
      {
        topLeft: {
          x: 936,
          y: 160
        },
        bottomRight: {
          x: 1100,
          y: 392
        }
      }
    ]
  },
  {
    startFrameIdx: 770,
    endFrameIdx: 839,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/770.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=932a237065a83d123f1cf777a31c94ce63346d1e2b868214013e140bf7e3f8f7",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 840,
    endFrameIdx: 864,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/840.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=3d800d5d8319aeb235445251d5d5f5e04a2de31f42604aefbc724058216f8dce",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 644,
          y: 84
        },
        bottomRight: {
          x: 944,
          y: 488
        }
      }
    ]
  },
  {
    startFrameIdx: 865,
    endFrameIdx: 949,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/865.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=e1e59dbb1beb11f1e719432a75ba155d4a41d950e06560def78cc1329fc3af26",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 950,
    endFrameIdx: 981,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/950.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=96730c58eb0a0c584eb6d984386919feff48506bf11a9edc4e705adf040bf67c",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 588,
          y: 200
        },
        bottomRight: {
          x: 816,
          y: 508
        }
      }
    ]
  },
  {
    startFrameIdx: 981,
    endFrameIdx: 984,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/981.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=00b06d1d553e294c3e691fb3c991c8d8f9c392dacb154eb342fde8ae5873029c",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 572,
          y: 180
        },
        bottomRight: {
          x: 664,
          y: 304
        }
      }
    ]
  },
  {
    startFrameIdx: 985,
    endFrameIdx: 985,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/985.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=501939e2ee62626e5e9fc19593f7a70228688ca2acb085f16e309dd8748c4bb0",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 986,
    endFrameIdx: 986,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/986.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=0848a200b6b891d27d4c6061ed9a2c896fdb0b9cf55865789f33392d36469003",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 600,
          y: 212
        },
        bottomRight: {
          x: 668,
          y: 312
        }
      }
    ]
  },
  {
    startFrameIdx: 987,
    endFrameIdx: 987,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/987.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=48d130f921d13cc7e7b6fe58b5b41f692870afd68f96a5ad65b824875b0f30f2",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 988,
    endFrameIdx: 1013,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/988.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=35ee8d0272152212506b6a2802c75b78f4d93b13b10e868612eacf4dd5ce5de9",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 564,
          y: 176
        },
        bottomRight: {
          x: 684,
          y: 316
        }
      }
    ]
  },
  {
    startFrameIdx: 1013,
    endFrameIdx: 1032,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1013.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=68beda77c73025cde3399ada4dc3ea8bc62224cc9af8cc3aacc6dbccbd4f92b4",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 604,
          y: 168
        },
        bottomRight: {
          x: 804,
          y: 428
        }
      }
    ]
  },
  {
    startFrameIdx: 1033,
    endFrameIdx: 1207,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1033.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=1c55cf251b2c19a22ef92869f3f349e1563a62778b5653d7cdc54c76b84ab29d",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1208,
    endFrameIdx: 1217,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1208.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=aaa833e0989ce7c769a9efec6e7ed2185126dfc9b5b4ed707c0590a329ee869c",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 504,
          y: 216
        },
        bottomRight: {
          x: 680,
          y: 400
        }
      }
    ]
  },
  {
    startFrameIdx: 1218,
    endFrameIdx: 1223,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1218.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=5df36ef82d782fc126920c519cf6c2659308b5cc1541b12f4cfb54be8b136e15",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1224,
    endFrameIdx: 1232,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1224.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=7b81b53a0ce94b519ef93448930efd19828f30ab7f5793bb6e5ed68c6e3343e1",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 420,
          y: 200
        },
        bottomRight: {
          x: 600,
          y: 412
        }
      },
      {
        topLeft: {
          x: 652,
          y: 152
        },
        bottomRight: {
          x: 760,
          y: 272
        }
      }
    ]
  },
  {
    startFrameIdx: 1233,
    endFrameIdx: 1234,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1233.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=ed95495b7f8b308ae06afda196cc66364801c4bb7e00e3ce677e10555cd131b3",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1235,
    endFrameIdx: 1235,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1235.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=ea03ef3c933bec36e94543c8e1da41c75ba85d2fc96123a8bffff72bb72efa17",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 444,
          y: 252
        },
        bottomRight: {
          x: 584,
          y: 440
        }
      },
      {
        topLeft: {
          x: 628,
          y: 184
        },
        bottomRight: {
          x: 708,
          y: 284
        }
      }
    ]
  },
  {
    startFrameIdx: 1236,
    endFrameIdx: 1236,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1236.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=c98adfedf9965c3d340db5981ed7d205c11f2b6d9d5536b4d80f55bd437fe564",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1237,
    endFrameIdx: 1241,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1237.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=d3fc9bd0f45ed41d1509a59b2328c387ae46783161409697ecd8d29a7293a5c0",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 604,
          y: 168
        },
        bottomRight: {
          x: 700,
          y: 292
        }
      }
    ]
  },
  {
    startFrameIdx: 1242,
    endFrameIdx: 1243,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1242.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=93a9861c72d0b5403e90f03c5546423e0c53752d378dff2ee12443f44c8ab00b",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1244,
    endFrameIdx: 1244,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1244.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=fd382dde55e0e526cebcb7794d3943aa2494ac6793f64870194a713ba5130c94",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 628,
          y: 184
        },
        bottomRight: {
          x: 708,
          y: 292
        }
      }
    ]
  },
  {
    startFrameIdx: 1245,
    endFrameIdx: 1245,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1245.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=c78d4fe5eb6fce4d901c009389f6e2eb2131c3d44ed4ebefb5d845f158132137",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1246,
    endFrameIdx: 1257,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1246.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=10b30c526cdc1bb9829115ce82264213bf453fc0e5f021ced4490801bd2dac22",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 640,
          y: 184
        },
        bottomRight: {
          x: 816,
          y: 316
        }
      }
    ]
  },
  {
    startFrameIdx: 1257,
    endFrameIdx: 1268,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1257.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=60fa616193e00e259a01b1c99502b68cf4ae58ebe660b60afd7b1c38128aab8e",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 524,
          y: 156
        },
        bottomRight: {
          x: 680,
          y: 300
        }
      }
    ]
  },
  {
    startFrameIdx: 1269,
    endFrameIdx: 1595,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1269.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=597031e6a162eb398621b332b1af80327742097d407724066ddfa223f2ede78d",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1596,
    endFrameIdx: 1642,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1596.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=19ac561b84951765461100fc9962a6db95435039ff82cc80885f34e921646be4",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 620,
          y: 196
        },
        bottomRight: {
          x: 856,
          y: 484
        }
      }
    ]
  },
  {
    startFrameIdx: 1642,
    endFrameIdx: 1653,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1642.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=1f754c1457c1b7cfb7e95bb1d887b4b29c769cdfe64cf458c9e0fc0adb57b841",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 448,
          y: 256
        },
        bottomRight: {
          x: 528,
          y: 360
        }
      }
    ]
  },
  {
    startFrameIdx: 1654,
    endFrameIdx: 1654,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1654.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=43328696d27f69907ef4f9edd01c9a0b697ba65f2f5b6a159c3e3608755e9e0b",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1655,
    endFrameIdx: 1684,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1655.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=a5f2e94c2efcf81dbd4034394f4013e781147234b92b401636ea00d2f025ebec",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 440,
          y: 256
        },
        bottomRight: {
          x: 524,
          y: 360
        }
      }
    ]
  },
  {
    startFrameIdx: 1685,
    endFrameIdx: 1883,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1685.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=f64763e8443351e4037320e93f1c75580bb103dc1b64780f147fde9cba3ea1f6",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1884,
    endFrameIdx: 1905,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1884.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=dbdcdd11bb871ad1b11233eb49c804e40a5955a9d5728e6e796c35d16c7b44a5",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 488,
          y: 236
        },
        bottomRight: {
          x: 724,
          y: 396
        }
      }
    ]
  },
  {
    startFrameIdx: 1906,
    endFrameIdx: 1906,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1906.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=e2d70b09476d210b9009cd5dc128dad3cb70697a9bf0bf428f0bb946d83bf799",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1907,
    endFrameIdx: 1910,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1907.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=7b39e57500837d53c4f46be02a8ecf9aab53ffc8f7346c9bdb43106ca281d480",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 852,
          y: 180
        },
        bottomRight: {
          x: 948,
          y: 296
        }
      },
      {
        topLeft: {
          x: 224,
          y: 184
        },
        bottomRight: {
          x: 296,
          y: 296
        }
      },
      {
        topLeft: {
          x: 604,
          y: 208
        },
        bottomRight: {
          x: 680,
          y: 308
        }
      }
    ]
  },
  {
    startFrameIdx: 1911,
    endFrameIdx: 1911,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1911.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=2e2821e08af9cd3a11feac6b84c76c9a6b9afdfa657b897a0d3ab336e935940d",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1912,
    endFrameIdx: 1912,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1912.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=2d76c9342c72c2c26265bf0f23c69c20395e7dc8a02e98bc1bc6285047b95025",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 844,
          y: 184
        },
        bottomRight: {
          x: 924,
          y: 296
        }
      },
      {
        topLeft: {
          x: 220,
          y: 184
        },
        bottomRight: {
          x: 288,
          y: 292
        }
      },
      {
        topLeft: {
          x: 608,
          y: 208
        },
        bottomRight: {
          x: 684,
          y: 308
        }
      }
    ]
  },
  {
    startFrameIdx: 1913,
    endFrameIdx: 1913,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1913.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=131ab4cd43406ddbd92fdaa5db231ed5d45b08747a0a7a2a03e408c0c1f057c7",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1914,
    endFrameIdx: 1916,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1914.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=6e9b182aac787b2f0def2375d2ee37675f0bba5eff2e16d80b1557edbcaa2abe",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 832,
          y: 180
        },
        bottomRight: {
          x: 916,
          y: 308
        }
      },
      {
        topLeft: {
          x: 208,
          y: 180
        },
        bottomRight: {
          x: 292,
          y: 296
        }
      }
    ]
  },
  {
    startFrameIdx: 1917,
    endFrameIdx: 1917,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1917.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=461da268000ec66abdf89483fd72a5cb042eb6855a1e4ab18b2f9f01b78416a9",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1918,
    endFrameIdx: 1921,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1918.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=00e36179b6b17e8bde9d1d7a4c0330fecd9d00ee7a8bd6249f43149ca00fd894",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 196,
          y: 172
        },
        bottomRight: {
          x: 284,
          y: 296
        }
      },
      {
        topLeft: {
          x: 824,
          y: 188
        },
        bottomRight: {
          x: 908,
          y: 312
        }
      },
      {
        topLeft: {
          x: 596,
          y: 204
        },
        bottomRight: {
          x: 676,
          y: 312
        }
      }
    ]
  },
  {
    startFrameIdx: 1922,
    endFrameIdx: 1922,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1922.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=0eb9eb49279f0e4e1cd83340a9256e483f85b99c0b182243a6a90fb693313629",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1923,
    endFrameIdx: 1923,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1923.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=89b143245b4a90591c953bf67d38c3251cadba1187af9dbc9995fdff0372ad14",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 192,
          y: 168
        },
        bottomRight: {
          x: 276,
          y: 296
        }
      },
      {
        topLeft: {
          x: 824,
          y: 188
        },
        bottomRight: {
          x: 904,
          y: 308
        }
      },
      {
        topLeft: {
          x: 588,
          y: 208
        },
        bottomRight: {
          x: 664,
          y: 312
        }
      }
    ]
  },
  {
    startFrameIdx: 1924,
    endFrameIdx: 1924,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1924.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=3adee960a409442d3ad3a54d66e7a7b02a797ccb8d202cb5d40b1869011e6bbc",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1925,
    endFrameIdx: 1925,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1925.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=a669b46c2aec031ec9b8e1029c0c60cbbc5f911c4487a2b4ca0e38316397b4ac",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 188,
          y: 168
        },
        bottomRight: {
          x: 272,
          y: 296
        }
      },
      {
        topLeft: {
          x: 824,
          y: 188
        },
        bottomRight: {
          x: 904,
          y: 304
        }
      },
      {
        topLeft: {
          x: 576,
          y: 212
        },
        bottomRight: {
          x: 656,
          y: 316
        }
      }
    ]
  },
  {
    startFrameIdx: 1926,
    endFrameIdx: 1982,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1926.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=e8b82113b28f3f7bf6c095b12993ccce1f8ff368614960658372a89fafd3dbac",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1983,
    endFrameIdx: 1984,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1983.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=9ae9665f4afcdc2824e3052cb1431ba5f8e79acbc6974930fedbde1aa7ba12b1",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 856,
          y: 164
        },
        bottomRight: {
          x: 988,
          y: 356
        }
      },
      {
        topLeft: {
          x: 612,
          y: 204
        },
        bottomRight: {
          x: 716,
          y: 344
        }
      },
      {
        topLeft: {
          x: 0,
          y: 132
        },
        bottomRight: {
          x: 104,
          y: 340
        }
      }
    ]
  },
  {
    startFrameIdx: 1985,
    endFrameIdx: 1985,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1985.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=b840acbb3dbc384d1d9bb4cccd3fd7f03ad468bd1dfbae13d9ae4df635c5801d",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 1986,
    endFrameIdx: 2032,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/1986.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=194e517b302145fb62cd280e7910b3a9b665741dfbd01aa90f87aaf3cb168106",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 556,
          y: 184
        },
        bottomRight: {
          x: 728,
          y: 356
        }
      },
      {
        topLeft: {
          x: 852,
          y: 92
        },
        bottomRight: {
          x: 1148,
          y: 400
        }
      }
    ]
  },
  {
    startFrameIdx: 2033,
    endFrameIdx: 2056,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2033.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=081ae3113fef8993d9a0269cc092a98ca8b9beb726291e1912d5233ed22112cf",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2057,
    endFrameIdx: 2083,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2057.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=53519f447ee35f7eec0e9dd41138ddb0d7662df71d776153621e810b3fd39c9e",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 544,
          y: 36
        },
        bottomRight: {
          x: 1252,
          y: 644
        }
      }
    ]
  },
  {
    startFrameIdx: 2084,
    endFrameIdx: 2113,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2084.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=5e5c89a7cb9c414dde62be9aec0553e86618caeee1a6c35dd1a340b4481a9df9",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2114,
    endFrameIdx: 2134,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2114.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=13724d88c34863abef1a626395835b40ed84d805fa2ebd58864c4ea90c43c5a0",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 848,
          y: 76
        },
        bottomRight: {
          x: 1104,
          y: 432
        }
      }
    ]
  },
  {
    startFrameIdx: 2135,
    endFrameIdx: 2194,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2135.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=830920753e9f423bdb828583a7431c38b757e5ce965ec8ca47019f3356d11ac2",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2195,
    endFrameIdx: 2200,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2195.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=2b5afc77e6ed6cbc25c79edc0b8593b756752b309282554d2b3c892fe74cea8c",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 444,
          y: 152
        },
        bottomRight: {
          x: 560,
          y: 296
        }
      },
      {
        topLeft: {
          x: 748,
          y: 252
        },
        bottomRight: {
          x: 832,
          y: 348
        }
      }
    ]
  },
  {
    startFrameIdx: 2201,
    endFrameIdx: 2201,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2201.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=a0b33396b79b85b7e32ef02b486c7c988b41c7ceed142db35ad5b2bd97062780",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2202,
    endFrameIdx: 2211,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2202.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=1c8478f56df0bc8c6626c910040c135c0d0c2ecda2bb0e747d4f1c5f6c37b856",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 440,
          y: 152
        },
        bottomRight: {
          x: 552,
          y: 292
        }
      }
    ]
  },
  {
    startFrameIdx: 2212,
    endFrameIdx: 2212,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2212.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=03b032c375ab3a1d4d8daf4cbe1ccb25d397090522e72fbb56bb11def3157e35",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2213,
    endFrameIdx: 2225,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2213.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=002af090c91d929f5f1c06319c38fb3091ddc0d7e9679072a979ae569c31445d",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 452,
          y: 152
        },
        bottomRight: {
          x: 556,
          y: 296
        }
      },
      {
        topLeft: {
          x: 720,
          y: 248
        },
        bottomRight: {
          x: 828,
          y: 356
        }
      }
    ]
  },
  {
    startFrameIdx: 2226,
    endFrameIdx: 2226,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2226.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=4fddfc4d1fd65fd24042b62750c6bf645b639e9fd0b545e271f61142cd27658a",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2227,
    endFrameIdx: 2231,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2227.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=910bc934d97906d40536e94815c11dc9b807730eb6adafc86be7389b9e6c191e",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 460,
          y: 156
        },
        bottomRight: {
          x: 552,
          y: 292
        }
      },
      {
        topLeft: {
          x: 724,
          y: 260
        },
        bottomRight: {
          x: 812,
          y: 356
        }
      }
    ]
  },
  {
    startFrameIdx: 2232,
    endFrameIdx: 2256,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2232.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=9374c134965819adabd37c72ceb66dc021dfdbb901458c200490f9cc0d38825f",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2257,
    endFrameIdx: 2287,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2257.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=33362ff70e3d4153cba2baf7cc28f151de51d4e204bcc712634ab6a90e245755",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 268,
          y: 120
        },
        bottomRight: {
          x: 704,
          y: 596
        }
      }
    ]
  },
  {
    startFrameIdx: 2288,
    endFrameIdx: 2306,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2288.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=8ed76693a0585920d885d3c5b10f09c1b19066d651f54cd6a18b2f7874b229b6",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2307,
    endFrameIdx: 2325,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2307.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=18beccf974418706c0e4a44d6f818d06c4ed1b3da525262b8e11ad244b7b7d2e",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 496,
          y: 160
        },
        bottomRight: {
          x: 748,
          y: 432
        }
      }
    ]
  },
  {
    startFrameIdx: 2326,
    endFrameIdx: 2362,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2326.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=b381b0cef7df168c5f42c75b80ce114074ab0cb54929f16f19d3e3c343799237",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2363,
    endFrameIdx: 2395,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2363.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=02b9bbbb01cc1cec7b1ff01171fadd22818c420a7d25b08ae21ccc8140ab8497",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 520,
          y: 164
        },
        bottomRight: {
          x: 720,
          y: 420
        }
      }
    ]
  },
  {
    startFrameIdx: 2395,
    endFrameIdx: 2426,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2395.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=72b49d4ef53f64ff9ce708a581545f1004840660bf696588cc6d7b4d65cec72f",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 468,
          y: 128
        },
        bottomRight: {
          x: 708,
          y: 440
        }
      }
    ]
  },
  {
    startFrameIdx: 2426,
    endFrameIdx: 2488,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2426.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=2502ff8fb0aa4f4265be508fcf3d4461f5bb622ba77141212e3252de1f7be015",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 592,
          y: 200
        },
        bottomRight: {
          x: 820,
          y: 480
        }
      }
    ]
  },
  {
    startFrameIdx: 2488,
    endFrameIdx: 2532,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2488.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=c8a1061dfbd19ad7c8a2f5026e00f347369be2e996e3df9ff45bbda3f963ad80",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 512,
          y: 128
        },
        bottomRight: {
          x: 736,
          y: 444
        }
      }
    ]
  },
  {
    startFrameIdx: 2532,
    endFrameIdx: 2574,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2532.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=044e82d70dcfea6274486d93cdc8387ab43314747da6ef82e4c4c68299d2827b",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 536,
          y: 200
        },
        bottomRight: {
          x: 752,
          y: 468
        }
      }
    ]
  },
  {
    startFrameIdx: 2575,
    endFrameIdx: 2598,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2575.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=5ed550738ecf7aa015fb0de4edc3d8cc823e267f59dbf6caf31a898ffd10d996",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2599,
    endFrameIdx: 2599,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2599.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=f64b37b8641d38b887fc988969f9408be10b23296e333fc42bc5fa9dc2282064",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 228,
          y: 132
        },
        bottomRight: {
          x: 540,
          y: 544
        }
      }
    ]
  },
  {
    startFrameIdx: 2600,
    endFrameIdx: 2603,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2600.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=ff3c41789b4b94fc6a403810ab8e1712d2f0a8676f461bc85417fa24e471288f",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2604,
    endFrameIdx: 2609,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2604.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=7c03494e44a6c014b1481930ca878fd5c238017ddf50018650551634bb58ef74",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 340,
          y: 52
        },
        bottomRight: {
          x: 708,
          y: 692
        }
      }
    ]
  },
  {
    startFrameIdx: 2610,
    endFrameIdx: 2770,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2610.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=ec13b0347e1f3f0a0f19b68ce77c255430d9facc2753de6a2529ee604a3defae",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2771,
    endFrameIdx: 2771,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2771.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=97c97b15f979cb8b6e05890e430a259c7b7908c9b5e4ec82d6de0606fc6c0c64",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 516,
          y: 248
        },
        bottomRight: {
          x: 584,
          y: 328
        }
      }
    ]
  },
  {
    startFrameIdx: 2772,
    endFrameIdx: 2774,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2772.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=bcf06e06ac4046bdf5903c61b26b5de5f5e020fb2fccffe0572133abfb99bc9e",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2775,
    endFrameIdx: 2775,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2775.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=b17ff57810827872e35e46a3c591e861ad571128e808451d726fe13981cdf332",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 536,
          y: 268
        },
        bottomRight: {
          x: 604,
          y: 348
        }
      }
    ]
  },
  {
    startFrameIdx: 2776,
    endFrameIdx: 2779,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2776.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=79b388c31ea671bd1c3ae305e2cc85c1cc553a6aed1ad3593b15939f781b9d63",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2780,
    endFrameIdx: 2794,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2780.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=e3784b57cef55ddf86e1d34a1b7fb2dbdecfe6d0af9ecdf82eaeca2afe818269",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 496,
          y: 236
        },
        bottomRight: {
          x: 616,
          y: 356
        }
      }
    ]
  },
  {
    startFrameIdx: 2794,
    endFrameIdx: 2814,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2794.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=67c347bc2ce21762ccdd52832312dbe39025fe1db6805bda137b77b8669edeb6",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 508,
          y: 136
        },
        bottomRight: {
          x: 796,
          y: 516
        }
      }
    ]
  },
  {
    startFrameIdx: 2815,
    endFrameIdx: 2916,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2815.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=c52cb79095b6d3e5b729c35ae56ebc25636109246557b2a5dd6677a82fb0239a",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2917,
    endFrameIdx: 2935,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2917.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=d3c0f79ac4b3f8d7f5d1619ce0cfe42b52bffe2003eccc7801eff3952b9197df",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 400,
          y: 176
        },
        bottomRight: {
          x: 508,
          y: 308
        }
      }
    ]
  },
  {
    startFrameIdx: 2936,
    endFrameIdx: 2946,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2936.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=d45ca3cea3d93775edb0f0c1257920d087011d2ee114f6fe242649c77fa56205",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2947,
    endFrameIdx: 2963,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2947.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=8c3c330ebf818dfe236ba1df9c6f596e5387d5762958e2e758fe74f8bc001b16",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 552,
          y: 204
        },
        bottomRight: {
          x: 664,
          y: 320
        }
      }
    ]
  },
  {
    startFrameIdx: 2964,
    endFrameIdx: 2968,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2964.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=bcb458257275690b9aed941db1742f716f95cddb878c2b7eb41154d4f01c7e6e",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2969,
    endFrameIdx: 2979,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2969.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=03d4e666baf80be9d8fd1ec472f904246b9ad4257d3bd7986920775c60d6083e",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 608,
          y: 200
        },
        bottomRight: {
          x: 716,
          y: 308
        }
      }
    ]
  },
  {
    startFrameIdx: 2980,
    endFrameIdx: 2981,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2980.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=c845b2f389e198380586ddad4b27c6fe574b47303011c255b95ec01dd1801e3b",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 2982,
    endFrameIdx: 3009,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/2982.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=df2887e418ff8536a981059dc572028f3265a181f3fd2e177dada406a620b47b",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 548,
          y: 200
        },
        bottomRight: {
          x: 700,
          y: 320
        }
      }
    ]
  },
  {
    startFrameIdx: 3009,
    endFrameIdx: 3031,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3009.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=6bc560430fcd50dbb0bbcf414137fbfed1e7280a37db6a1d0042aa28bcb759bc",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 668,
          y: 212
        },
        bottomRight: {
          x: 764,
          y: 332
        }
      }
    ]
  },
  {
    startFrameIdx: 3032,
    endFrameIdx: 3148,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3032.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=db88094ee0864a486ed97d5b254a83c7d8fe8a4d19ad23dd5be0c6cd40ab1abf",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3149,
    endFrameIdx: 3149,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3149.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=1de0842e64ab21a02e19a1920a7bb3d2b1dbd4eab2f8f520d7e9a9bacad23195",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 832,
          y: 204
        },
        bottomRight: {
          x: 916,
          y: 312
        }
      },
      {
        topLeft: {
          x: 328,
          y: 196
        },
        bottomRight: {
          x: 396,
          y: 292
        }
      }
    ]
  },
  {
    startFrameIdx: 3150,
    endFrameIdx: 3150,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3150.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=b7bbc35f7a348ddebbf700d0e15b67cf07f52dfacfa7aca898ff815ee57b8b5f",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3151,
    endFrameIdx: 3164,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3151.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=31cd449c87d7e662fd50678a2e59135f21be34db042a8212bfe72286b091af5d",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 832,
          y: 184
        },
        bottomRight: {
          x: 932,
          y: 308
        }
      }
    ]
  },
  {
    startFrameIdx: 3165,
    endFrameIdx: 3166,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3165.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=202bdb67604c8c8af6c3f897282e91ca3f4cd8a5d0e9c7307e6c8dba5ab8995d",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3167,
    endFrameIdx: 3168,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3167.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=3b867ae99ff5d7dff912eecb891034a14389df1fa73507ff2ff1b8402fc0b35e",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 860,
          y: 184
        },
        bottomRight: {
          x: 936,
          y: 288
        }
      }
    ]
  },
  {
    startFrameIdx: 3169,
    endFrameIdx: 3189,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3169.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=f6148c0e0b3b53b76288b45198cc3fc6310e38981ca7b6aa7d9d2ea39959c617",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3190,
    endFrameIdx: 3222,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3190.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=985a6b728dacac5ca56d064cc6f3e8dde9a12934c4b7c79dc5d8c57650a8cdf1",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 480,
          y: 172
        },
        bottomRight: {
          x: 724,
          y: 548
        }
      }
    ]
  },
  {
    startFrameIdx: 3223,
    endFrameIdx: 3223,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3223.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=fdb76d54f897acd2af00eef9940d867a52f1363fcdfb50b5f07fd36424509411",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3224,
    endFrameIdx: 3225,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3224.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=b615254dc6bee455f0b0b46f241bf854c83c1fe0ea103c73eca23deb85384d68",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 668,
          y: 480
        },
        bottomRight: {
          x: 744,
          y: 576
        }
      }
    ]
  },
  {
    startFrameIdx: 3226,
    endFrameIdx: 3226,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3226.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=46f2a4fde4d7c10dfd49bfde37efc73b4b84c973f672901a53db25b7bef9b5e2",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3227,
    endFrameIdx: 3227,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3227.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=8cb368e0d6f8f7fb34431be7a4a4b02c0513a8823f4061e747f1b026c8c14ca0",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 140,
          y: 516
        },
        bottomRight: {
          x: 244,
          y: 636
        }
      },
      {
        topLeft: {
          x: 664,
          y: 496
        },
        bottomRight: {
          x: 732,
          y: 588
        }
      }
    ]
  },
  {
    startFrameIdx: 3228,
    endFrameIdx: 3228,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3228.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=c46d39e6ba1ffebde1a254eb1875d04ce053273349af05ce24a3fec42c1fba92",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3229,
    endFrameIdx: 3230,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3229.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=775c0a6fe290496cdab64310dc59fd421f7b6889f09a3ebd913033db1305495b",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 140,
          y: 456
        },
        bottomRight: {
          x: 240,
          y: 600
        }
      },
      {
        topLeft: {
          x: 344,
          y: 536
        },
        bottomRight: {
          x: 524,
          y: 644
        }
      },
      {
        topLeft: {
          x: 640,
          y: 524
        },
        bottomRight: {
          x: 728,
          y: 636
        }
      }
    ]
  },
  {
    startFrameIdx: 3231,
    endFrameIdx: 3231,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3231.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=3d3d540e656a6c254ba28e7409ab05d394ce447fb93c0ef4c2767c4a6d93b467",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3232,
    endFrameIdx: 3232,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3232.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=47374dac936a317972434a756a0c6b696437a96597a6822d6b9a5aa8dea0fa3e",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 144,
          y: 424
        },
        bottomRight: {
          x: 240,
          y: 548
        }
      },
      {
        topLeft: {
          x: 444,
          y: 548
        },
        bottomRight: {
          x: 508,
          y: 632
        }
      }
    ]
  },
  {
    startFrameIdx: 3233,
    endFrameIdx: 3233,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3233.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=df7cfdc7a25f14b5137d1f213023e4b853b809a5e219c51070202bf04b5cc2e4",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3234,
    endFrameIdx: 3234,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3234.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=d1bf8aeccece83e62bc82f8ed9ae1dcace4af9dde330052d75ab6281125c4fba",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 144,
          y: 404
        },
        bottomRight: {
          x: 244,
          y: 528
        }
      },
      {
        topLeft: {
          x: 916,
          y: 332
        },
        bottomRight: {
          x: 980,
          y: 424
        }
      }
    ]
  },
  {
    startFrameIdx: 3235,
    endFrameIdx: 3235,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3235.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=4529fc6b0499fabd97356921cf9b57beee4dcebca1dfa55d3cebfc7eec781da6",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3236,
    endFrameIdx: 3236,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3236.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=071f944226097be3589c7f42b6086e925f8604a1de6085faa257b797f21790f8",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 152,
          y: 376
        },
        bottomRight: {
          x: 248,
          y: 500
        }
      }
    ]
  },
  {
    startFrameIdx: 3237,
    endFrameIdx: 3237,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3237.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=b914a6c1d9933e212d63417033f4a83afc152e18e9a7045dd76871d0d1982696",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3238,
    endFrameIdx: 3238,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3238.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=ab4fbc7a8935db32af7f05aa3ac83d9cd0d1d34af1f303ea3dbeb89abcae739e",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 152,
          y: 356
        },
        bottomRight: {
          x: 248,
          y: 480
        }
      },
      {
        topLeft: {
          x: 532,
          y: 544
        },
        bottomRight: {
          x: 620,
          y: 648
        }
      }
    ]
  },
  {
    startFrameIdx: 3239,
    endFrameIdx: 3239,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3239.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=7b4887c5638da596c65d24a54304215447ba184c96eb34d1f7a952b9037233ec",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3240,
    endFrameIdx: 3242,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3240.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=6b7a14bbae902b2f6d084d7caf11ef95103994dd0e1dc85d494c7d01fcf0f636",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 780,
          y: 268
        },
        bottomRight: {
          x: 860,
          y: 396
        }
      }
    ]
  },
  {
    startFrameIdx: 3243,
    endFrameIdx: 3243,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3243.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=0d32821630d5de14f9f4e695ef079f6a9546be922f54762094cadecd75919c8f",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3244,
    endFrameIdx: 3244,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3244.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=a83ef4c18b8b253121e3a49cd3a15217e6bfebc7e4c819da299a59ac325ebf74",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 760,
          y: 252
        },
        bottomRight: {
          x: 828,
          y: 352
        }
      },
      {
        topLeft: {
          x: 756,
          y: 480
        },
        bottomRight: {
          x: 876,
          y: 632
        }
      },
      {
        topLeft: {
          x: 1200,
          y: 488
        },
        bottomRight: {
          x: 1264,
          y: 580
        }
      }
    ]
  },
  {
    startFrameIdx: 3245,
    endFrameIdx: 3245,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3245.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=08182ff6c9dde2bd920021a0f48509e5452e933a924b26263131190a29f9e82e",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3246,
    endFrameIdx: 3246,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3246.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=3630308733c43744847a0d2c4ef0e7330a7f44d285e038c89e695643d78b9f9c",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 740,
          y: 368
        },
        bottomRight: {
          x: 860,
          y: 532
        }
      },
      {
        topLeft: {
          x: 740,
          y: 256
        },
        bottomRight: {
          x: 808,
          y: 352
        }
      },
      {
        topLeft: {
          x: 1184,
          y: 432
        },
        bottomRight: {
          x: 1252,
          y: 524
        }
      }
    ]
  },
  {
    startFrameIdx: 3247,
    endFrameIdx: 3247,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3247.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=43101438ae8ede8b6e94c3121223caad117b89520e0adaab764eb51ffee45147",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3248,
    endFrameIdx: 3248,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3248.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=50932871d88f3dffccdee465adccb71c1bd25e71a2289a2e69e9472d9eb60031",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 720,
          y: 284
        },
        bottomRight: {
          x: 856,
          y: 452
        }
      },
      {
        topLeft: {
          x: 1180,
          y: 372
        },
        bottomRight: {
          x: 1248,
          y: 460
        }
      }
    ]
  },
  {
    startFrameIdx: 3249,
    endFrameIdx: 3249,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3249.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=7b026d7d64fc7e654af93536496a725ec2a181c37e8c1fcd3ce0bcfd650081d5",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3250,
    endFrameIdx: 3254,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3250.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=2e829062e693a2bf19b776a8e2d034f609bf518e40af2e0d00fab4f813d106c9",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 676,
          y: 184
        },
        bottomRight: {
          x: 840,
          y: 404
        }
      }
    ]
  },
  {
    startFrameIdx: 3255,
    endFrameIdx: 3256,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3255.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=a3113733bb43dc74e5add4a3166386c2e151169e19b8b26823157e1c9e3a7c9b",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3257,
    endFrameIdx: 3261,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3257.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=6a3e7452d3072e162b473564df6d8c38630c98f0b22bfac1bb84a84eecdf308e",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 600,
          y: 188
        },
        bottomRight: {
          x: 772,
          y: 364
        }
      }
    ]
  },
  {
    startFrameIdx: 3262,
    endFrameIdx: 3262,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3262.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=fee0bf7a972b7b6cba0b4847f3f64b827b13b16049086e8ab3420562242dce59",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3263,
    endFrameIdx: 3263,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3263.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=518d2321d454f4396c745ca6683d0a74f6a600c62deb63a37b29d302bcb744bd",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 588,
          y: 188
        },
        bottomRight: {
          x: 728,
          y: 368
        }
      }
    ]
  },
  {
    startFrameIdx: 3264,
    endFrameIdx: 3285,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3264.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=747de4789c06fee1a9ecd52c1cdfaf28988f3d504a62435ba356a6ced0c0a549",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3286,
    endFrameIdx: 3312,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3286.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=f287919fcc478f603fc9e7f2c05ceb6db713f6d7665c66205251b43912a1945f",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 604,
          y: 204
        },
        bottomRight: {
          x: 780,
          y: 452
        }
      }
    ]
  },
  {
    startFrameIdx: 3313,
    endFrameIdx: 3390,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3313.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=7940eb7b15d881eb310eff775a2e8512b2f321a2b85e10c3e20dab05d5f95c79",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3391,
    endFrameIdx: 3409,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3391.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=b69c9f3935e2dcf701fcf20f9ffb49a04d4ba0c339683cb914683834b225fb60",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 604,
          y: 236
        },
        bottomRight: {
          x: 828,
          y: 508
        }
      }
    ]
  },
  {
    startFrameIdx: 3410,
    endFrameIdx: 3532,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3410.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=63baca5aef185b546432a93f41b1a9e9da790214caad63b59869e5b26fe4bf40",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3533,
    endFrameIdx: 3561,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3533.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=e9c51d8e9eeb60bd49bf86c790cf63e443e0e51c0f131c1704aa7ab8e48ecf56",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 504,
          y: 200
        },
        bottomRight: {
          x: 752,
          y: 500
        }
      }
    ]
  },
  {
    startFrameIdx: 3561,
    endFrameIdx: 3578,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3561.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=9ab020ef1d96b562e6fcf517e28b0d464b738c77b2f46ff901f74de35cedd99c",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 572,
          y: 244
        },
        bottomRight: {
          x: 856,
          y: 532
        }
      }
    ]
  },
  {
    startFrameIdx: 3579,
    endFrameIdx: 3671,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3579.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=84a5f163008dbcffdb00add262031b95faf708bf473a59f4454025b356d18d43",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3672,
    endFrameIdx: 3765,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3672.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=fb3316877a31c3dcee2c7dec910d97e0f2032cc105483f5a969704550fee710d",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 340,
          y: 0
        },
        bottomRight: {
          x: 1276,
          y: 704
        }
      }
    ]
  },
  {
    startFrameIdx: 3765,
    endFrameIdx: 3782,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3765.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=9072c848353f61db79424108877c59d277451fd1263d8ecd963b6d3badaff31a",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 460,
          y: 120
        },
        bottomRight: {
          x: 732,
          y: 488
        }
      }
    ]
  },
  {
    startFrameIdx: 3783,
    endFrameIdx: 3792,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3783.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=0e717f557165bbef0d2196b94f57707f05cfb0f05c150651a6378d5d50fc9ec3",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3793,
    endFrameIdx: 3834,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3793.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=0bbe6a520069f291821440f8b0067021bd08727e94e618f084d163e5f6195623",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 504,
          y: 196
        },
        bottomRight: {
          x: 676,
          y: 416
        }
      }
    ]
  },
  {
    startFrameIdx: 3835,
    endFrameIdx: 3835,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3835.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=e8e574e743c8ab0d686846617bc20e6d66c6492b990936d09c6aaab1d0bc48f4",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3836,
    endFrameIdx: 3853,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3836.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=15d5d7494971c41de251f30a38cfb0e8515e871bb45feb5af15badb2af54ff52",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 64,
          y: 220
        },
        bottomRight: {
          x: 208,
          y: 384
        }
      },
      {
        topLeft: {
          x: 424,
          y: 228
        },
        bottomRight: {
          x: 552,
          y: 380
        }
      },
      {
        topLeft: {
          x: 1120,
          y: 256
        },
        bottomRight: {
          x: 1248,
          y: 412
        }
      }
    ]
  },
  {
    startFrameIdx: 3854,
    endFrameIdx: 3870,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3854.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=5c2adbecc02d97103597eecab6651758924ce8690cf2551515e084332c96631e",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3871,
    endFrameIdx: 3888,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3871.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=462b02389d7d9cbf42854818a4f59c8cba1254dcf16bc3f9b135621bf3d614be",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 496,
          y: 244
        },
        bottomRight: {
          x: 708,
          y: 504
        }
      },
      {
        topLeft: {
          x: 312,
          y: 228
        },
        bottomRight: {
          x: 460,
          y: 420
        }
      }
    ]
  },
  {
    startFrameIdx: 3889,
    endFrameIdx: 3955,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3889.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=6ec11096d1adab24bc19fd4f3925d03cd49147cbf6e5cdb2a7be048afb435697",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3956,
    endFrameIdx: 3958,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3956.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=1c9771fa43becbfea46181d53bd783340278c0ee28d3af3c781928be0be4038a",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 284,
          y: 340
        },
        bottomRight: {
          x: 516,
          y: 620
        }
      },
      {
        topLeft: {
          x: 772,
          y: 208
        },
        bottomRight: {
          x: 964,
          y: 448
        }
      }
    ]
  },
  {
    startFrameIdx: 3959,
    endFrameIdx: 3959,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3959.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=90d63b8177a3011714efb4459fea2bf5e18de2364ba0bbf075620f96a91f4474",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3960,
    endFrameIdx: 3962,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3960.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=6e1dbf7fd1429d56531ea94fe0778a8cc75fcc879ff394dd34fd3104f02127a3",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 236,
          y: 340
        },
        bottomRight: {
          x: 448,
          y: 588
        }
      },
      {
        topLeft: {
          x: 720,
          y: 252
        },
        bottomRight: {
          x: 880,
          y: 508
        }
      }
    ]
  },
  {
    startFrameIdx: 3963,
    endFrameIdx: 3963,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3963.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=d0ec3bb45d633c9165ac1a6788a11ae1624ce89d91ccb8c799688267f36c8551",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3964,
    endFrameIdx: 3964,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3964.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=e7f84254826942d79c41fe93be028e7e6aad39b18eaf8537482b07b6bf592159",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 300,
          y: 468
        },
        bottomRight: {
          x: 516,
          y: 736
        }
      },
      {
        topLeft: {
          x: 688,
          y: 288
        },
        bottomRight: {
          x: 840,
          y: 516
        }
      }
    ]
  },
  {
    startFrameIdx: 3965,
    endFrameIdx: 3966,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3965.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=18ea1ae6d1f3386afd068e99cf775d3547590257622ca6a386c6e3bc1a6b6dce",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3967,
    endFrameIdx: 3969,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3967.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=fc8c6faba42ffa9b8de63e107a23e7bd63d5e55f716b971dbd0dc51107d32dd1",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 656,
          y: 228
        },
        bottomRight: {
          x: 844,
          y: 524
        }
      },
      {
        topLeft: {
          x: 236,
          y: 328
        },
        bottomRight: {
          x: 456,
          y: 608
        }
      }
    ]
  },
  {
    startFrameIdx: 3970,
    endFrameIdx: 3971,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3970.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=e51e6f10fd23b708d70ff4cd9fb675e059e98a33e3a7773ffdb3582ea38a1b3e",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3972,
    endFrameIdx: 3972,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3972.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=c747229954fc776b2963202dd2adfc7abb23ee0e604ce55a8ffce90585a12593",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 324,
          y: 324
        },
        bottomRight: {
          x: 508,
          y: 544
        }
      }
    ]
  },
  {
    startFrameIdx: 3973,
    endFrameIdx: 3973,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3973.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=4c0f2bce655c31e1ad44d4acd474c84238cceed1d324607e6a38122dbd618577",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3974,
    endFrameIdx: 3974,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3974.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=990f69bde8a9ee62d6e04f16c9aac388aab8e86e5667246af257ce7ba9292845",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 280,
          y: 276
        },
        bottomRight: {
          x: 480,
          y: 528
        }
      }
    ]
  },
  {
    startFrameIdx: 3975,
    endFrameIdx: 3992,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3975.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=ea3188110f6201c3f60aea96ea6324a7f8a64ce92adde1014c9838eda778c56a",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 3993,
    endFrameIdx: 4093,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/3993.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=c1afe8c8e07ba994d8b9e9e9c76130dc33717f5e3918e5305a9494ba398951e8",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 488,
          y: 100
        },
        bottomRight: {
          x: 804,
          y: 560
        }
      }
    ]
  },
  {
    startFrameIdx: 4094,
    endFrameIdx: 4095,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/4094.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=f5b3618370da86d816d9b30bbc761cadb27ae5b1b3e6e862c9939e3d2793e11f",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 4096,
    endFrameIdx: 4097,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/4096.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=d0f3bd7ea0a9890f02bc7bddc762ac0309b7178ba818620f787cd74878064ad9",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 516,
          y: 140
        },
        bottomRight: {
          x: 776,
          y: 524
        }
      }
    ]
  },
  {
    startFrameIdx: 4098,
    endFrameIdx: 4272,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/4098.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=ad250471ba25f0934dcbffdf6f898d1b67c2322b3e2d2d2e35a1613a1b95197d",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 4273,
    endFrameIdx: 4304,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/4273.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=82afa4c58cafb8056085d58699236cb0c2ef447dd700d37230c93a26f945efb8",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 472,
          y: 136
        },
        bottomRight: {
          x: 732,
          y: 484
        }
      }
    ]
  },
  {
    startFrameIdx: 4305,
    endFrameIdx: 4328,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/4305.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=0bc8e1ec24ec858180bac0cdebf4517328e1f17be0051ca7c54820044e5ce824",
    faceBoundingBoxes: null
  },
  {
    startFrameIdx: 4329,
    endFrameIdx: 4349,
    selectedFaceIdx: 0,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/4329.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=ad64c8e3bf2f82efe73b01c28101e6e46414d66549852f611479b80bb6823a64",
    faceBoundingBoxes: [
      {
        topLeft: {
          x: 204,
          y: 196
        },
        bottomRight: {
          x: 356,
          y: 376
        }
      },
      {
        topLeft: {
          x: 608,
          y: 200
        },
        bottomRight: {
          x: 784,
          y: 432
        }
      }
    ]
  },
  {
    startFrameIdx: 4350,
    endFrameIdx: 4474,
    selectedFaceIdx: -1,
    thumbnailDownloadUrl:
      "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W016993461879711JG/FACE_MARKS/THUMB/SF2cglq0i4O6/4350.jpeg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIGmBamOGxBs8qjFDJ7Pgb7VCyBAzqFrY3OfZtiWhP%2B1RAiEA%2B4FHFblQzpBAagVkAu4zhZv9mhTbS4yAG%2Bh4abIBBRAqnAQI7v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzODY5MzY3ODExODQiDJlJ7ntaWfb%2FuPaeKyrwA2GN35yuuY4ntrmvywDia5Cg%2FspRDMQydL0wQaSBob1Nr%2BYXEPpXRI3arqqnZCDGSClE92hwzNzhViul8JpE5Frvgeno671%2F5mVsaK6KoEMAuYUBw73n5lCWGQAMa3PEXH%2Brj9oRhlZpyuaC9MA1iAEuEKFrv4%2Btv0%2BwMdqd3i0PvhE%2Br5eG561nZfb4wnJx1EDboIfISs5pyWzTpuMYTdwI4gQ76I8JLMwi2Oev5hGkiO5qy5TSJAttN%2BQu2jlRTvQlhaXimeOjRPZ%2FpzkhPGjYrjE9VVnxGidfdVZKIrbCrJ5R2vQEh%2BNoksUue8rkdylb0ixmRc9X%2B2xkZGnt5WkZ3VA6GPvkJ50JaNvpSHKuoSxfPkC2%2BSXlteg6Tmul3JUoaI%2BZqv5SELEXYC81iS0au6%2FGaklTOywnenHcNSUNG%2BXU0V1z%2BVzEPPtx44Ki8hRLEKoX7ydlyhEjh3Mgj9ssgTCBI12B5f65hk9W7Qw09eLWvSy7UUzwS74CGgrc8n%2F6W9ZsVjaC0F%2F%2FU5%2FGKKBpV%2BnDl%2FYDutazyKApav13AvTVlQDWS6p%2B7NmxQI7QdxNtjAP9QfbFlrz4dFtEi8xxiAYfnNxcB7xnv76lBXLecctaa%2Bm552i%2B2O7v8T26gM874Cmut8JXMO8tUQG0iPow0MCLrAY6pgHriIfKm%2BKtktcsLgJwhFc5bn0tqaW1Iqp6HNOh1lRjg2c5d47pkumsIQW70IuoItla0EDeJOKz%2B6ZiwoF6xIB1qUMXpu8K5DfesXybLI9oifnc2heMX2uFanKATPV6pV52lgeklY4DyLT4xbmc43OcZ%2FprNYwlMlSUlNuzDO2fNeX3TYs2eH%2F3rD70%2BzpOSfZsNckurUJINGuMc9LeVxVRNUEC2Nff&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231220T132608Z&X-Amz-SignedHeaders=host&X-Amz-Expires=172800&X-Amz-Credential=ASIAVUFZ2XGAJBONF2FF%2F20231220%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=9a59b1fb4a8364beb5aab2d8ae90a4c16484ccbec9a941ae07fda39509ba64f4",
    faceBoundingBoxes: null
  }
];
