import { lazy } from "react";
import { RouteObject } from "react-router-dom";
const Tasks = lazy(() => import("./Tasks"));

export const taskRoutes: RouteObject[] = [
  {
    path: "tasks",
    element: <Tasks key="all-tasks" />
  },
  {
    path: "my-tasks",
    element: <Tasks myTask key="my-tasks" />
  }
];
