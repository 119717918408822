export const pauseRegex =
  /(\[pause ((\d+(\.\d+)?s)|(none)|(x-weak)|(weak)|(medium)|(strong)|(x-strong))\])/g;

export const pauseRegexWithNbsp =
  /(\[pause ((\d+(\.\d+)?s)|(none)|(x-weak)|(weak)|(medium)|(strong)|(x-strong))\][\s | &nbsp;])/g;

export const pauseRegexWithoutBrackets =
  /(pause ((\d+(\.\d+)?s)|(none)|(x-weak)|(weak)|(medium)|(strong)|(x-strong)))/g;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegex =
  /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[$&+,:;=?@#|'<>.^*()%!-]).{8,}$/g;

export const whiteSpaceRegex = /^\S*$/;

export const boundaryCharRegex = /[&/\\#+()=$~%.,!"*?<>{};]/g;

export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
