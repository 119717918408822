import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SelectedFilesWithMultiParts } from "@/features/createProject/api";

interface UppySliceState {
  [key: string]: {
    [key: string]: SelectedFilesWithMultiParts;
  };
}

export const uppySlice = createSlice({
  name: "uppy",
  initialState: {} as UppySliceState,
  reducers: {
    setFile: (state, action) => {
      const { projectId, fileId, fileData } = action.payload;
      if (!projectId) {
        return state;
      }
      state[projectId] = {
        ...state[projectId],
        [fileId]: fileData
      };
      return state;
    },
    updateFile: (state, action) => {
      const { projectId, fileId, updatedData } = action.payload;
      if (!projectId || !state[projectId]?.[fileId]) {
        return state;
      }
      state[projectId] = {
        ...state[projectId],
        [fileId]: {
          ...state[projectId]?.[fileId],
          ...updatedData
        }
      };
      return state;
    },
    setCurrentFilesData: (state, action) => {
      const { projectId, filesData } = action.payload;
      if (!projectId || !filesData) {
        return state;
      }
      state[projectId] = Object.assign({}, filesData);
      return state;
    },
    resetProjectFiles: (state, action) => {
      const { projectId } = action.payload;
      if (!projectId) {
        return state;
      }
      state[projectId] = {};
      return state;
    },
    removeFileFromUppy: (
      state,
      action: PayloadAction<{
        projectId: string;
        sourceFileId: string;
      }>
    ) => {
      const { projectId, sourceFileId } = action.payload;
      if (!projectId || !state[projectId]?.[sourceFileId]) {
        return state;
      }
      delete state[projectId][sourceFileId];
      return state;
    }
  }
});

export const {
  setFile,
  setCurrentFilesData,
  resetProjectFiles,
  updateFile,
  removeFileFromUppy
} = uppySlice.actions;

export default uppySlice.reducer;
