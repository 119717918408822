import { Box, Button, Stack, useTheme } from "@mui/material";
import DialogMessage from "../Dialog/DialogMessage";
import FourOFourSvg from "@/components/assets/FourOFour";

const NotFound = () => {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100%"
    >
      <DialogMessage
        icon={<FourOFourSvg color={theme.palette.secondary.light} />}
        message="Not found"
        description="We could not find what you were looking for"
      />
      <Box display="flex" justifyContent="center">
        <Button variant="contained" color="secondary" size="large" href="/">
          Go to home
        </Button>
      </Box>
    </Stack>
  );
};

export default NotFound;
