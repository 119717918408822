import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const CreateProject = lazy(() => import("./CreateProject"));

export const createProjectRoutes: RouteObject[] = [
  {
    path: `project/create/:projectId?`,
    element: <CreateProject />
  }
];
