import { Checkbox, useTheme, Paper, Tooltip } from "@mui/material";
import { EventHandlers } from "@/features/editor/hooks/useEditor";
import { ChangeEvent, memo, useCallback, useMemo } from "react";
import {
  LocalScriptBlockType,
  HandleBlockPlayType,
  HandleBlockSeekType
} from "../../types";
import TranslatedTextBlock from "./components/TranslatedTextBlock";
import OriginalTextBlock from "./components/OriginalTextBlock";
import {
  deSelectBlock,
  selectBlock
} from "@/reducers/slices/currentScriptProjectSlice/currentScriptProjectSlice";
import { useAppDispatch } from "@/config/configureAppStore";
import { UserAvatar } from "@/components/elements";
import { batch } from "react-redux";
import useTranslationVoPermissions from "@/features/scriptStudio/hooks/useTranslationVoPermissions";
import { PLAYER_MODE } from "@/reducers/slices/currentScriptProjectSlice/types";
import { BLOCK_ERROR_TYPE } from "../../constants";
import { getBlockColors } from "./utils";

export interface TextBlockProps {
  block: LocalScriptBlockType;
  audioPlayerId: string;
  eventHandlers: EventHandlers;
  isActive: boolean;
  isSelected: boolean;
  speakerName: string;
  handleBlockPlayClick: HandleBlockPlayType;
  handleBlockSeek: HandleBlockSeekType;
  isOriginalPlaying: boolean;
  isTranslationPlaying: boolean;
  readOnly: boolean;
  qcEnabled: boolean;
  buffering: boolean;
  textEditDisabled: boolean;
  isBlockPlaying: boolean;
  richTextEnabled: boolean;
}

function TextBlock({
  block,
  audioPlayerId,
  eventHandlers,
  isSelected,
  isActive,
  speakerName,
  handleBlockPlayClick,
  handleBlockSeek,
  isOriginalPlaying,
  isTranslationPlaying,
  readOnly,
  qcEnabled,
  buffering,
  textEditDisabled,
  isBlockPlaying,
  richTextEnabled
}: TextBlockProps) {
  // console.log("rendering text block");
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { hasAccessToSidePanel } = useTranslationVoPermissions();

  const { hasError, errorMessage, hasTextLengthError } = useMemo(() => {
    let hasTextLengthError = false;
    if (block.blockErrors.length) {
      hasTextLengthError = block.blockErrors.some(
        (e) => e.type === BLOCK_ERROR_TYPE.TEXT_LIMIT_EXCEEDED_ERROR
      );
      // showing zeroth index error
      return {
        hasError: true,
        errorMessage: block.blockErrors[0].message,
        hasTextLengthError
      };
    }

    return {
      hasError: false,
      errorMessage: null,
      hasTextLengthError
    };
  }, [block.blockErrors]);

  const { bgColor, borderColor, color } = useMemo(
    () =>
      getBlockColors(theme, {
        isSelected,
        isActive,
        isBlockPlaying,
        hasError: Boolean(block.blockErrors.length),
        completed: block.completed
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      block.blockErrors.length,
      block.completed,
      isActive,
      isBlockPlaying,
      isSelected
    ]
  );

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      batch(() => {
        dispatch(selectBlock(block.blockId));
      });
    } else {
      dispatch(deSelectBlock(block.blockId));
    }
  };

  const handleOriginalPlayClick = useCallback(
    (isPlay: boolean) => {
      handleBlockPlayClick(block, PLAYER_MODE.ORIGINAL, isPlay);
    },
    [handleBlockPlayClick, block]
  );

  const handleTranslationPlayClick = useCallback(
    (isPlay: boolean) => {
      handleBlockPlayClick(block, PLAYER_MODE.TRANSLATION, isPlay);
    },
    [handleBlockPlayClick, block]
  );

  const handleBlockClick = () => {
    // avoiding block deselect by passing false
    eventHandlers.onFocus(block.blockId, false);
  };

  return (
    <Paper
      sx={{
        padding: 1,
        width: "100%",
        backgroundColor: bgColor,
        borderRadius: 3,
        display: "flex",
        gap: 1,
        borderColor
      }}
      variant="outlined"
      onClick={handleBlockClick}
    >
      {hasAccessToSidePanel ? (
        <div>
          <Tooltip title="Select">
            <Checkbox
              checked={isSelected}
              onChange={handleCheckBox}
              size="small"
              color={color}
            />
          </Tooltip>
        </div>
      ) : null}
      <UserAvatar displayName={speakerName || "Speaker"} sizes="40" />
      <OriginalTextBlock
        block={block}
        handleBlockPlay={handleOriginalPlayClick}
        isPlaying={isOriginalPlaying}
        textEditDisabled={readOnly || textEditDisabled}
        isActive={isActive}
        eventHandlers={eventHandlers}
      />
      <TranslatedTextBlock
        audioPlayerId={audioPlayerId}
        eventHandlers={eventHandlers}
        isActive={isActive}
        readOnly={readOnly}
        qcEnabled={qcEnabled}
        block={block}
        handleBlockPlay={handleTranslationPlayClick}
        handleBlockSeek={handleBlockSeek}
        isPlaying={isTranslationPlaying}
        hasError={hasError}
        hasTextLengthError={hasTextLengthError}
        errorMessage={errorMessage}
        buffering={buffering}
        textEditDisabled={readOnly || textEditDisabled}
        richTextEnabled={richTextEnabled}
      />
    </Paper>
  );
}

export default memo(TextBlock);
