import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const AcceptUserInvite = lazy(() => import("../components/AcceptUserInvite"));

export const linkPagesRoutes: RouteObject[] = [
  {
    path: "url-reply/accept-linking/:emailId/:workspaceId/:hmac",
    element: <AcceptUserInvite />
  },
  {
    path: "url-reply/accept-member-invite/:emailId/:hmac",
    element: <AcceptUserInvite isMember />
  }
];
