import { rest } from "msw";
import { API_BASE_URL } from "@/config/config";
import { mockAuthCheck } from "@/utils/mockUtils";
import { TUNING_FILE_DATA } from "../../constants";
import { APIResponse } from "@/types/apiResponse";
import { TuningFile } from "../../types";

export const tuningSourceFileMocks = [
  rest.get(
    `${API_BASE_URL}source-file/:id/tuning-detailed`,
    (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }
      const { id } = req.params;
      const fileResponse: APIResponse<TuningFile> = {
        responseData: {
          ...TUNING_FILE_DATA,
          sourceFileId: id as string
        },
        responseCode: "200",
        responseMessage: "Success",
        extra: null
      };
      if (id === "404") {
        return res(ctx.status(404));
      } else if (id === "429") {
        return res(ctx.delay(5000), ctx.status(200), ctx.json(fileResponse));
      }
      return res(ctx.delay(2000), ctx.status(200), ctx.json(fileResponse));
    }
  )
];
