import { createTheme } from "@mui/material/styles";
import {
  PaletteMode,
  ThemeOptions,
  responsiveFontSizes,
  useMediaQuery
} from "@mui/material";
import { lightThemePalette } from "./lightTheme";
import { useCallback, useMemo } from "react";
import { darkThemePalette } from "./darkTheme";
import { getTypographyOptions } from "./typography";
import { getThemedComponents } from "./themedComponents";
import { useTypedSelector } from "@/config/configureAppStore";

export const useMurfTheme = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const denseMode = useMediaQuery("@media (max-width:1290px)");
  const appTheme = useTypedSelector((state) => state.globalState.appTheme);

  const getDesignTokens = useCallback(
    (mode: PaletteMode): ThemeOptions => ({
      palette: mode === "light" ? lightThemePalette : darkThemePalette,
      spacing: denseMode ? 6 : 8,
      typography: getTypographyOptions(),
      components: getThemedComponents(mode)
    }),
    [denseMode]
  );

  const baseTheme = useMemo(() => {
    const isDarkMode =
      (appTheme === "system" && prefersDarkMode) || appTheme === "dark";

    let theme = createTheme(getDesignTokens(isDarkMode ? "dark" : "light"));
    theme = responsiveFontSizes(theme);
    return theme;
  }, [getDesignTokens, prefersDarkMode, appTheme]);

  return { baseTheme };
};
