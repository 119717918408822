import { useCallback, useState } from "react";
import { usePrepareBlocks } from ".";
import { chunkify } from "@/utils/json";
import { blockSynthesisApi } from "../api";
import { usePollBlocks } from "./usePollBlocks";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { handleRenderError } from "../util";

interface SynthesizeBlocksParams {
  blockIdList: string[];
  updatePercentageCallback: (successfulBlocks: number) => any;
}

interface ChunkSynthesizeBlocksParams {
  blockIdList: string[];
  onFinish?: () => any;
  onError?: () => any;
}

export function useBlockSynthesis() {
  const dispatch = useAppDispatch();
  const workspaceId = useTypedSelector(
    (state) => state.currentScriptProject.workspaceId
  );
  const fileId = useTypedSelector((state) => state.currentScriptProject.fileId);
  const dubId = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.dubId
  );
  const dubVersion = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.dubVersion
  );
  const maxRenderVersion = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.maxRenderVersion
  );

  const [percentage, setPercentage] = useState(0);
  const { prepareForRender } = usePrepareBlocks();
  const { pollBlocks } = usePollBlocks();

  const synthesizeBlocks = useCallback(
    ({
      blockIdList,
      updatePercentageCallback
    }: SynthesizeBlocksParams): Promise<any> => {
      return new Promise((resolve, reject) => {
        const renderStartTimeStamp = Date.now();
        const { payload, renderVersionMap } = prepareForRender({
          blocksThatNeedRendering: blockIdList,
          maxRenderVersion
        });
        blockSynthesisApi
          .renderBlocks({ dubId, dubVersion, fileId, workspaceId, payload })
          .then((res) => {
            pollBlocks({
              blockIdList,
              renderVersionMap,
              onEnd: ({ totalSuccessfulBlocks }) => {
                updatePercentageCallback(totalSuccessfulBlocks);
                resolve(res);
              },
              onError: (errMsg) => {
                reject({ res: { extra: errMsg }, blockIdList });
              },
              payload,
              startTimeStamp: renderStartTimeStamp
            });
          })
          .catch((err) => {
            reject({ res: err, blockIdList });
          });
      });
    },
    [
      dubId,
      dubVersion,
      fileId,
      maxRenderVersion,
      pollBlocks,
      prepareForRender,
      workspaceId
    ]
  );

  const chunkAndSynthesizeBlocks = useCallback(
    ({ blockIdList, onError, onFinish }: ChunkSynthesizeBlocksParams) => {
      const allPromises: Promise<any>[] = [];
      const processedChunks: { [key: string]: number } = {};

      function updateProcessedChunks(
        chunkIndex?: number,
        successfulBlocks?: number
      ) {
        if (chunkIndex !== undefined) {
          processedChunks[chunkIndex] = successfulBlocks || 0;
        }
        const totalBlocks = Object.values(processedChunks).reduce(
          (acc, curr) => acc + curr,
          0
        );
        setPercentage(Math.round((totalBlocks / blockIdList.length) * 100));
      }

      chunkify({
        data: blockIdList,
        callback: (blockIdChunk: string[], index?: number) => {
          allPromises.push(
            synthesizeBlocks({
              blockIdList: blockIdChunk,
              updatePercentageCallback: (successfulBlocks) =>
                updateProcessedChunks(index, successfulBlocks)
            })
          );
        },
        chunkSize: 100
      });

      Promise.all(allPromises)
        .then(() => {
          onFinish && onFinish();
        })
        .catch((err) => {
          if (err.blockIdList) {
            handleRenderError(err.blockIdList, dispatch, err.res);
          }
          onError && onError();
          !onError && console.log("Some blocks could not be rendered");
          setPercentage(0);
        })
        .finally(() => {
          setTimeout(() => {
            setPercentage(0);
          }, 5000);
        });
    },
    [synthesizeBlocks, dispatch]
  );

  return { chunkAndSynthesizeBlocks, percentage };
}
