type DRAFT_STATE =
  | "SETUP_PROJECT"
  | "UPLOAD_FILES"
  | "SELECT_LANGUAGE"
  | "REQUIREMENTS"
  | "FINISH"
  | "DONE";

type _CREATE_PROJECT_STEPS = {
  [key in DRAFT_STATE]: {
    enum: DRAFT_STATE;
    label: string;
    int: number;
    title: string;
    caption: string;
    hidden?: boolean;
  };
};

export const CREATE_PROJECT_STEPS: _CREATE_PROJECT_STEPS = {
  SETUP_PROJECT: {
    enum: "SETUP_PROJECT",
    label: "Name Project",
    int: 0,
    title: "Name your project",
    caption:
      "Quick tip: Place related videos or audios into a single project for ease and better flow. You can add more anytime"
  },
  UPLOAD_FILES: {
    enum: "UPLOAD_FILES",
    label: "Upload Files",
    int: 1,
    title: "Upload files to dub",
    caption: "Choose video or audio files to get started"
  },
  REQUIREMENTS: {
    enum: "REQUIREMENTS",
    label: "Select Requirements",
    int: 2,
    title: "Select dubbing requirements",
    caption:
      "Get your content quality assured by our in-house experts. Optimize your dubs with Lip Sync."
  },
  SELECT_LANGUAGE: {
    enum: "SELECT_LANGUAGE",
    label: "Add Languages",
    int: 3,
    title: "Choose dubbing languages",
    caption: "Translate your content into multiple languages at the same time"
  },
  FINISH: {
    enum: "FINISH",
    label: "Confirm",
    int: 4,
    title: "Project Summary",
    caption:
      "Your content is ready to be dubbed. All dubs will be delivered in 1080p"
  },
  DONE: {
    enum: "DONE",
    int: 5,
    title: "",
    caption: "",
    label: "",
    hidden: true
  }
};

export const CREATE_PROJECT_STEP_LABELS = [
  "Setup Project",
  "Upload files",
  "Select Language", // have to remove this
  "Requirements",
  "Finish"
];

export const PROJECT_NAME_MAX_LENGTH = 65;
