import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorCheck } from "@/utils/reduxUtils";
import {
  AddLanguagesParams,
  CreateProjectParams,
  DuplicateProjectParams,
  UpdateProjectParams,
  clientProjectApi
} from "@/features/createProject/api";
import { projectDetailsApi } from "@/features/clientProjectDetails/api";

export const clientProjectThunk = {
  getProjectById: createAsyncThunk(
    "getProjectById",
    async ({ projectId }: { projectId: string }, thunkAPI) => {
      return errorCheck(async () => {
        const response = await clientProjectApi.getById({
          projectId
        });
        return response;
      }, thunkAPI);
    }
  ),
  createProject: createAsyncThunk(
    "createProject",
    async ({ projectName }: CreateProjectParams, thunkAPI) => {
      return errorCheck(async () => {
        const response = await clientProjectApi.create({
          projectName
        });
        return response;
      }, thunkAPI);
    }
  ),
  updateProject: createAsyncThunk(
    "updateProject",
    async ({ projectId, payload }: UpdateProjectParams, thunkAPI) => {
      return errorCheck(async () => {
        const response = await clientProjectApi.update({
          projectId: projectId,
          payload: payload
        });
        return response;
      }, thunkAPI);
    }
  ),
  addLanguage: createAsyncThunk(
    "addLanguage",
    async (
      { projectId, languages, dubPreviousFiles }: AddLanguagesParams,
      thunkAPI
    ) => {
      return errorCheck(async () => {
        const response = await clientProjectApi.addLanguages({
          projectId,
          languages,
          dubPreviousFiles
        });
        return response;
      }, thunkAPI);
    }
  ),
  fetchSourceFile: createAsyncThunk(
    "fetchSourceFile",
    async ({ projectId }: { projectId: string }, thunkAPI) => {
      return errorCheck(async () => {
        const res = await projectDetailsApi.fetchSourceFiles({
          projectId
        });
        return res;
      }, thunkAPI);
    }
  ),
  duplicateProject: createAsyncThunk(
    "duplicateProject",
    async ({ projectName, projectId }: DuplicateProjectParams, thunkAPI) => {
      return errorCheck(async () => {
        const response = await clientProjectApi.duplicateProject({
          projectName,
          projectId
        });
        return response;
      }, thunkAPI);
    }
  )
};
