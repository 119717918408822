import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import {
  GeneralIssueAPIPayload,
  ICreateComment,
  ICreateIssue,
  IDeleteComment,
  IEditComment,
  IIssue,
  UpdateResolveStatusPayload
} from "..";
import { issueCommentThunk } from "@/reducers/thunks/issueCommentThunk";
import { setSnackBar } from "@/reducers/slices/dialogSlice/dialogSlice";
import { QC_ACCESS_ROLES } from "@/types/user";
import { useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { setIssueResolveStatus } from "@/reducers/slices/issueSlice/issueSlice";
import { STATUS } from "@/constants/status";
import { useSnackbar } from "@/components/elements/MurfSnackbar";

export enum ISSUE_ERROR_TYPE {
  NO_TEXT = "NO_TEXT",
  NO_TAGS = "NO_TAGS",
  NO_PERMISSION = "NO_PERMISSION",
  EXCEEDS_CHAR_LIMIT = "EXCEEDS_CHAR_LIMIT"
}

export const COMMENT_CHAR_LIMIT = 500;

interface CallbackOption {
  option?: {
    onSuccess?: () => void;
    onError?: (reason?: ISSUE_ERROR_TYPE | string) => void;
  };
}

export const useIssuesComments = () => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { showError } = useSnackbar();

  const currentUser = useTypedSelector((state) => state.user);
  const activeWorkspaceId = useTypedSelector(
    (state) => state.user.activeWorkspaceId
  );
  const currentDub = useTypedSelector((state) => state.issues.currentDubData);
  const currentWorkspaceUsers = useTypedSelector(
    (state) => state.user.activeWorkspaceData?.users
  );
  const _workspaceId = currentDub?.workspaceId || activeWorkspaceId;
  const allUsersOfWorkspaceId = useTypedSelector((state) =>
    _workspaceId ? state.workspace?.userList?.[_workspaceId]?.users : {}
  );
  const internalMemberDataForExternal = useTypedSelector(
    (state) => state.workspace.internalMembers
  );
  const internalMembers = useTypedSelector(
    (state) => state.userManagement.users
  );
  const allIssues = useTypedSelector((state) => state.issues.allIssues);

  const _canProceedWithIssueAPI = (payload: GeneralIssueAPIPayload) => {
    if (!payload) {
      return false;
    }

    const { dubId, fileId, issueId } = payload;
    if (!dubId || !fileId || !issueId) {
      return false;
    }
    return true;
  };

  const getUserIfAvailable = useCallback(
    (userId: string) => {
      let user =
        currentUser?.userId === userId
          ? currentUser
          : currentWorkspaceUsers?.[userId] ||
            allUsersOfWorkspaceId?.[userId] ||
            internalMemberDataForExternal?.[userId];

      if (!user) {
        user = internalMembers.find((member) => member.userId === userId);
      }

      return user || null;
    },
    [
      currentUser,
      currentWorkspaceUsers,
      allUsersOfWorkspaceId,
      internalMembers,
      internalMemberDataForExternal
    ]
  );

  const getCreatedBy = useCallback(
    (createdBy: string) => {
      const user = getUserIfAvailable(createdBy);

      return {
        displayName: user?.displayName || "Unknown user",
        avatarUrl: user?.profileImageUrl
      };
    },
    [getUserIfAvailable]
  );

  const filteredIssues = useMemo(() => {
    if (!currentDub || !allIssues) {
      return [];
    }
    // Backend will be sending the filtered issues based on the dubId
    return Object.values(allIssues[currentDub.dubId] || {});
  }, [currentDub, allIssues]);

  const createIssue = ({
    dubId,
    fileId,
    internalIssue,
    startTimeStamp,
    tags,
    description,
    option,
    projectId
  }: Omit<ICreateIssue, "dubVersion" | "workspaceId"> & CallbackOption) => {
    if (!dubId || !fileId || !projectId) {
      console.debug(
        "[createIssue Err] no dubId or fileId or projectId provided"
      );
      if (option?.onError) {
        option.onError();
      }
      return;
    }
    const titleText = description.textDescriptionList
      ?.map((obj) => obj.text)
      .join("");

    if (!titleText?.trim()?.length || titleText?.length > COMMENT_CHAR_LIMIT) {
      if (option?.onError) {
        option.onError(
          titleText?.trim().length
            ? ISSUE_ERROR_TYPE.EXCEEDS_CHAR_LIMIT
            : ISSUE_ERROR_TYPE.NO_TEXT
        );
      }
      return;
    }
    if (internalIssue) {
      if (currentDub?.accessLevel !== QC_ACCESS_ROLES.QUALITY_CHECK) {
        dispatch(
          setSnackBar({
            message: "You don't have permission to create issues.",
            open: true,
            type: "warning"
          })
        );
        if (option?.onError) {
          option.onError(ISSUE_ERROR_TYPE.NO_PERMISSION);
        }
        return;
      }
      if (!tags?.length) {
        if (option?.onError) {
          option.onError(ISSUE_ERROR_TYPE.NO_TAGS);
        }
        return;
      }
    }
    dispatch(
      issueCommentThunk.createIssue({
        dubId,
        fileId,
        internalIssue,
        startTimeStamp,
        tags,
        projectId,
        description,
        dubVersion: currentDub?.dubVersion || "",
        workspaceId: _workspaceId || ""
      })
    )
      .unwrap()
      .then((res) => {
        console.debug("[createIssue SUCCESS]", res);
        if (res?.responseCode === STATUS.SUCCESS) {
          if (option?.onSuccess) {
            option.onSuccess();
          }
        } else {
          if (option?.onError) {
            option.onError();
          }
        }
      })
      .catch((err) => {
        console.debug("[createIssue catch Err]", err);
        if (option?.onError) {
          option.onError();
        }
      });
  };

  const updateIssue = ({
    issueId,
    updatedObj,
    options
  }: {
    issueId: string;
    updatedObj: Partial<IIssue>;
    options?: CallbackOption["option"];
  }) => {
    if (!issueId?.length) {
      console.debug("[updateIssue Err] no issueId provided");
      return;
    }

    if ("description" in updatedObj) {
      const titleText = updatedObj.description?.textDescriptionList
        ?.map((obj) => obj.text)
        .join("");
      if (
        !titleText?.trim()?.length ||
        titleText?.length > COMMENT_CHAR_LIMIT
      ) {
        const msg = titleText?.trim().length
          ? `Issue description should not exceed ${COMMENT_CHAR_LIMIT} characters`
          : "Please enter your issue to get started.";

        if (options?.onError) {
          options.onError(msg);
        }
        return;
      }
    }

    if ("tags" in updatedObj && !updatedObj.tags?.length) {
      dispatch(
        setSnackBar({
          message: "Please select at least one tag.",
          open: true,
          type: "warning"
        })
      );
      if (options?.onError) {
        options.onError();
      }
      return;
    }

    dispatch(
      issueCommentThunk.updateIssue({
        ...updatedObj,
        workspaceId: _workspaceId!,
        dubVersion: currentDub?.dubVersion
      })
    )
      .unwrap()
      .then((res) => {
        console.debug("[updateIssue SUCCESS]", res);
        if (options?.onSuccess) {
          options.onSuccess();
        }
      })
      .catch((err) => {
        console.debug("[updateIssue catch Err]", err);
        if (options?.onError) {
          options.onError();
        }
      });
  };

  const removeIssue = (
    payload: Omit<GeneralIssueAPIPayload, "fileId" | "workspaceId">
  ) => {
    if (!payload) {
      console.debug("[removeIssue Err] no payload provided");
      return;
    }
    const { dubId, dubVersion, issueId } = payload;
    if (!dubId || !dubVersion || !issueId) {
      console.debug(
        "[removeIssue Err] no dubId or dubVersion or issueId provided"
      );
      return;
    }
    dispatch(
      issueCommentThunk.removeIssue({
        ...payload,
        workspaceId: currentDub?.workspaceId || activeWorkspaceId || ""
      })
    )
      .unwrap()
      .then((res) => {
        console.debug("[removeIssue SUCCESS]", res);
      })
      .catch((err) => {
        console.debug("[removeIssue catch Err]", err);
      });
  };

  const getIssueById = (payload: GeneralIssueAPIPayload) => {
    if (_canProceedWithIssueAPI(payload)) {
      console.debug("[removeIssue Err] no issueId provided");
      return;
    }
    dispatch(issueCommentThunk.getIssueById(payload))
      .then((res) => {
        console.debug("[getIssueById SUCCESS]", res);
      })
      .catch((err) => {
        console.debug("[getIssueById catch Err]", err);
      });
  };

  const updateIssueTagResolveStatus = (payload: UpdateResolveStatusPayload) => {
    const { dubId, dubVersion, issueId, workspaceId } = payload;
    if (!dubId || !dubVersion || !issueId || !workspaceId) {
      console.debug(
        "[updateIssueTagResolveStatus Err] no dubId or dubVersion or issueId or workspaceId provided"
      );
      return;
    }
    const data = payload.data.map((tag) => ({
      ...tag,
      resolvedBy: currentUser?.userId || null
    }));
    dispatch(
      setIssueResolveStatus({
        dubId,
        issueId,
        updatedTags: data
      })
    );
    dispatch(
      issueCommentThunk.updateIssueTagResolveStatus({
        ...payload,
        data
      })
    );
  };

  const markAsRead = (payload: Omit<GeneralIssueAPIPayload, "fileId">) => {
    const { dubId, dubVersion, issueId, workspaceId } = payload;
    if (!dubId || !dubVersion || !issueId || !workspaceId) {
      console.debug(
        "[markAsRead Err] no dubId or dubVersion or issueId or workspaceId provided",
        { payload }
      );
      return;
    }
    dispatch(issueCommentThunk.markAsReadIssue(payload));
  };

  const markAsUnread = (payload: GeneralIssueAPIPayload) => {
    if (_canProceedWithIssueAPI(payload)) {
      dispatch(issueCommentThunk.markAsUnreadIssue(payload));
    }
  };

  const _canAddOrEditComment = ({
    dubId,
    fileId,
    issueId,
    textDescriptionList
  }: Partial<ICreateComment>) => {
    if (!dubId || !fileId) {
      console.debug("[_canAddOrEditComment Err] no dubId or fileId provided");
      return false;
    }

    if (!issueId || !textDescriptionList?.length) {
      console.debug("[_canAddOrEditComment Err] no issueId or text provided");
      return false;
    }

    const _text = textDescriptionList?.map((obj) => obj.text).join("");
    if (!_text?.trim()?.length) {
      dispatch(
        setSnackBar({
          message: "Please enter your comment first.",
          open: true,
          type: "warning"
        })
      );
      return false;
    } else if (_text?.length > COMMENT_CHAR_LIMIT) {
      dispatch(
        setSnackBar({
          message: `Comment should not exceed ${COMMENT_CHAR_LIMIT} characters.`,
          open: true,
          type: "warning"
        })
      );
      return false;
    }
    return true;
  };

  const addComment = ({
    issueId,
    textDescriptionList,
    fileId,
    dubId,
    externalComment,
    dubVersion,
    option
  }: Partial<ICreateComment> & CallbackOption) => {
    if (
      !_canAddOrEditComment({ dubId, fileId, issueId, textDescriptionList })
    ) {
      if (option?.onError) {
        option.onError();
      }
      return;
    }

    // const isTeamComment = true; // will be decided on user role and other context
    dispatch(
      issueCommentThunk.addComment({
        issueId: issueId!,
        textDescriptionList: textDescriptionList!,
        fileId: fileId!,
        dubId: dubId!,
        dubVersion: dubVersion || currentDub?.dubVersion || "",
        externalComment: externalComment || false,
        projectId: projectId || currentDub?.projectId || "",
        workspaceId: currentDub?.workspaceId || activeWorkspaceId || ""
      })
    )
      .unwrap()
      .then((res) => {
        console.debug("[addComment SUCCESS]", res);
        if (option?.onSuccess) {
          option.onSuccess();
        }
      })
      .catch((err) => {
        console.debug("[addComment catch Err]", err);
        showError("Something went wrong. Please try again in sometime...");
        if (option?.onError) {
          option.onError();
        }
      });
  };

  const editComment = ({
    issueId,
    textDescriptionList,
    fileId,
    dubId,
    commentId,
    externalComment,
    dubVersion,
    option
  }: Omit<IEditComment, "workspaceId" | "projectId"> & CallbackOption) => {
    if (
      !commentId ||
      !_canAddOrEditComment({
        dubId,
        fileId,
        issueId,
        textDescriptionList
      })
    ) {
      if (option?.onError) {
        option.onError();
      }
      return;
    }

    dispatch(
      issueCommentThunk.editComment({
        dubId,
        fileId,
        issueId,
        commentId,
        textDescriptionList,
        dubVersion: dubVersion || currentDub?.dubVersion || "",
        externalComment: externalComment,
        projectId: projectId || currentDub?.projectId || "",
        workspaceId: currentDub?.workspaceId || ""
      })
    )
      .unwrap()
      .then((res) => {
        console.debug("[editComment SUCCESS]", res);
        if (option?.onSuccess) {
          option.onSuccess();
        }
      })
      .catch((err) => {
        console.debug("[editComment catch Err]", err);
        if (option?.onError) {
          option.onError();
        }
      });
  };

  const deleteComment = ({
    commentId,
    issueId,
    option
  }: Omit<IDeleteComment, "workspaceId"> & CallbackOption) => {
    dispatch(
      issueCommentThunk.deleteComment({
        issueId: issueId!,
        commentId: commentId!,
        workspaceId: currentDub?.workspaceId || ""
      })
    )
      .unwrap()
      .then((res) => {
        console.debug("[deleteComment SUCCESS]", res);
        if (option?.onSuccess) {
          option.onSuccess();
        }
      })
      .catch((err) => {
        console.debug("[deleteComment catch Err]", err);
        if (option?.onError) {
          option.onError();
        }
      });
  };

  const requestChangesOnDub = ({
    isInternal,
    option
  }: {
    isInternal: boolean;
    option?: CallbackOption["option"];
  }) => {
    if (!currentDub) {
      console.debug("[requestChangesOnDub Err] no currentDub provided");
      return;
    }
    dispatch(
      issueCommentThunk.requestChanges({
        dubId: currentDub.dubId,
        fileId: currentDub.fileId || "",
        dubVersion: currentDub.dubVersion,
        workspaceId: currentDub.workspaceId!,
        projectId: projectId!,
        isInternal
      })
    )
      .then((res) => {
        console.debug("[requestChanges SUCCESS]", res);
        if (res.payload.responseCode !== STATUS.SUCCESS) {
          if (option?.onError) {
            let reason = res.payload.extra;
            if (reason === "DUB_VERSION_LIMIT_EXCEEDED") {
              reason =
                "You have created the maximum number of versions allowed.";
            }
            option.onError(
              reason || "Something went wrong. Please try again in sometime..."
            );
          }
        } else {
          if (option?.onSuccess) {
            option.onSuccess();
          }
        }
      })
      .catch((err) => {
        console.debug("[requestChanges catch Err]", err);
        if (option?.onError) {
          option.onError(err);
        }
      });
  };

  return {
    filteredIssues,
    createIssue,
    updateIssue,
    removeIssue,
    getIssueById,
    updateIssueTagResolveStatus,
    markAsRead,
    markAsUnread,
    addComment,
    editComment,
    deleteComment,
    getUserIfAvailable,
    getCreatedBy,
    requestChangesOnDub
  };
};
