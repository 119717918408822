import { MurfSnackbar } from "@/components/elements/MurfSnackbar";
import { darkThemePalette } from "@/themes/darkTheme";
import { getThemedComponents } from "@/themes/themedComponents";
import { getTypographyOptions } from "@/themes/typography";
import {
  GlobalStyles,
  ThemeProvider,
  createTheme,
  dividerClasses,
  outlinedInputClasses,
  styled,
  useTheme
} from "@mui/material";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";

const Wrapper = styled("div")(({ theme }) => ({
  color: theme.palette.text.primary,

  "& .title": {
    color: "#FFCE00"
  },

  [`& .${dividerClasses.wrapper}`]: {
    ...theme.typography.subtitle2,
    color: "#9E9E9E"
  },

  [`& .${outlinedInputClasses.root}`]: {
    background: theme.palette.background.default
  },
  ["@media (max-height: 580px)"]: {
    ["& .auth-pattern-bg"]: {
      transform: "scale(.75)"
    }
  }
}));

// It is a good practice to hoist the <GlobalStyles /> to a static constant, to avoid rerendering.
// This will ensure that the <style> tag generated would not recalculate on each render.
const authGlobalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      body: {
        backgroundColor: theme.palette.background.default
      }
    })}
  />
);

const AuthLayout = () => {
  const theme = useTheme();

  const authTheme = useMemo(() => {
    return createTheme(theme, {
      palette: {
        ...darkThemePalette
      },
      typography: getTypographyOptions(),
      components: getThemedComponents("dark")
    });
  }, [theme]);

  return (
    <ThemeProvider theme={authTheme}>
      {authGlobalStyles}
      <MurfSnackbar />
      <Wrapper>
        <Outlet />
      </Wrapper>
    </ThemeProvider>
  );
};

export default AuthLayout;
