import ClientDefaultLayout from "@/components/layout/ClientDefaultLayout";
import { useTypedSelector } from "@/config/configureAppStore";
import { STATUS } from "@/constants/status";
import { isInternalUserPolicy, usePermissions } from "@/features/permissions";
import { Stack, styled } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const StyledStack = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(4),
  marginLeft: "auto",
  marginRight: "auto",
  gap: theme.spacing(5),
  maxWidth: 800,
  width: "90%"
}));

const SettingsLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { canAccess } = usePermissions();
  const linkedWorkspaces = useTypedSelector(
    (state) => state.user.linkedWorkspaces
  );
  const apiStatus = useTypedSelector((state) => state.user.apiStatus);

  /**
   * if there are no linkedWorkspaces and user is internal then we are not allowing user to settings page
   * only allow profile page
   */
  useEffect(() => {
    if (
      apiStatus === STATUS.SUCCESS &&
      !window.location.pathname.includes("/user") &&
      !linkedWorkspaces.length &&
      canAccess({
        policy: isInternalUserPolicy,
        policyArgs: null
      })
    ) {
      navigate("/internal");
    }
  }, [apiStatus, canAccess, location, linkedWorkspaces.length, navigate]);

  return (
    <ClientDefaultLayout showSideBar={Boolean(linkedWorkspaces.length)}>
      <StyledStack direction="column">
        <Outlet />
      </StyledStack>
    </ClientDefaultLayout>
  );
};

export default SettingsLayout;
