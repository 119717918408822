import { AppDispatch, RootState } from "@/config/configureAppStore";
import { createAsyncThunk } from "@reduxjs/toolkit";

export interface ErrorCheck {
  (f: any, thunkAPI: any): Promise<any>;
}

export const errorCheck: ErrorCheck = async (f, thunkAPI) => {
  try {
    return await f();
  } catch (err: any) {
    console.dir(err);
    return thunkAPI.rejectWithValue(err);
  }
};

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
  extra: any;
}>();
