import { useAppDispatch } from "@/config/configureAppStore";
import { setRangeData } from "@/reducers/slices/currentScriptProjectSlice/currentScriptProjectSlice";
import { setPronunciationDialog } from "@/reducers/slices/dialogSlice/dialogSlice";
import { Button, Paper, useTheme, Zoom } from "@mui/material";
import { MutableRefObject, useEffect, useRef } from "react";
import { batch } from "react-redux";
import { RangeData } from "../../util";
import PronunciationIcon from "@/components/assets/PronunciationIcon";
import useTvoTrackingEvent from "@/features/scriptStudio/hooks/useTvoTrackingEvent";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";

interface TextSelectionMenuProps {
  top: number;
  left: number;
  width: number;
  editorRef: MutableRefObject<HTMLElement | null>;
  rangeData: RangeData | null;
  closeMenu: () => void;
}

const MENU_WIDTH = 100;

const TextSelectionMenu = ({
  top,
  left,
  width,
  editorRef,
  rangeData,
  closeMenu
}: TextSelectionMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const trackEvent = useTvoTrackingEvent();

  useEffect(() => {
    if (menuRef.current && editorRef.current) {
      menuRef.current.style.top =
        top - editorRef.current.getBoundingClientRect().top - 25 + "px";

      menuRef.current.style.left =
        left -
        editorRef.current.getBoundingClientRect().left -
        MENU_WIDTH / 1.4 +
        width / 2 +
        "px";
    }
    //eslint-disable-next-line
  }, [menuRef.current]);

  const openPronunciation = () => {
    // setting range data in redux and opening pronunciation popup
    batch(() => {
      dispatch(setRangeData(rangeData));
      dispatch(setPronunciationDialog({ open: true }));
    });
    trackEvent(MIXPANEL_EVENTS.PRONUNCIATION_START, {
      word: rangeData?.text
    });
    closeMenu();
  };

  // const openEmphasis = () => {
  //   dispatch(setEmphasisDialog({ open: true }));
  // };

  return (
    <Zoom in>
      <Paper
        sx={{
          display: "flex",
          gap: 0.5,
          borderRadius: 1,
          backgroundColor: "background",
          padding: 0.5,
          position: "absolute",
          border: "none",
          height: 35
        }}
        ref={menuRef}
        elevation={0}
      >
        <Button
          id="pronunciation-selection-button"
          color="secondary"
          size="small"
          variant="outlined"
          onMouseDown={openPronunciation}
          onClick={openPronunciation}
          onBlur={() => closeMenu()}
          sx={{
            "&:focus": {
              borderColor: "primary.main"
            }
          }}
          startIcon={<PronunciationIcon color={theme.palette.text.primary} />}
        >
          Pronunciation
        </Button>
        {/* <Button
          color="secondary"
          size="small"
          variant="outlined"
          onMouseDown={openEmphasis}
        >
          Emphasis
        </Button> */}
      </Paper>
    </Zoom>
  );
};

export default TextSelectionMenu;
