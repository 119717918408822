import { api } from "@/lib/axios";
import { DubPreviewDetails } from "@/reducers/slices/dubPreviewSlice/types";
import { APIResponse } from "@/types/apiResponse";

interface QcPreviewParams {
  fileId: string;
  dubId: string;
  workspaceId: string;
  dubVersion?: string;
}

export interface QcPreviewDetailsParams extends QcPreviewParams {
  projectId: string;
  dubVersion?: string;
}

interface ReOpenDubParams extends QcPreviewParams {
  projectId: string;
  isInternal: boolean;
}

export const dubPreviewInternalApi = {
  fetchQCDubPreview: ({
    fileId,
    dubId,
    projectId,
    workspaceId,
    dubVersion
  }: QcPreviewDetailsParams) =>
    api.get<APIResponse<DubPreviewDetails>>(
      `member/quality-check/dub-response/project/${projectId}/file/${fileId}/dub/${dubId}?workspaceId=${workspaceId}&dubVersion=${dubVersion}`
    ),
  startQcDialog: ({ fileId, dubId, workspaceId }: QcPreviewParams) =>
    api.post(
      `member/quality-check/start/file/${fileId}/dub/${dubId}?workspaceId=${workspaceId}`
    ),
  qcMarkAsDone: ({ fileId, dubId, workspaceId }: QcPreviewParams) =>
    api.post(
      `member/quality-check/mark-as-done/file/${fileId}/dub/${dubId}?workspaceId=${workspaceId}`
    ),
  reOpenDub: ({
    fileId,
    dubId,
    projectId,
    workspaceId,
    isInternal
  }: ReOpenDubParams) =>
    api.post<APIResponse<null>>(
      `member/quality-check/reopen-dub/project/${projectId}/file/${fileId}/dub/${dubId}?workspaceId=${workspaceId}&isInternalReopenDub=${isInternal}`
    ),
  sendToCustomer: ({ fileId, dubId, workspaceId }: QcPreviewParams) =>
    api.post(
      `member/quality-check/send-dub/file/${fileId}/dub/${dubId}?workspaceId=${workspaceId}`
    ),
  discardDraft: ({ fileId, dubId }: { fileId: string; dubId: string }) =>
    api.post(
      `/file/${fileId}/dub/${dubId}/translation-vo/discard-draft?workspaceId=${window.activeWorkspaceId}`
    )
};
