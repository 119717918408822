import { api } from "@/lib/axios";
import {
  CreateIssuePayload,
  GeneralIssueAPIPayload,
  GetAllIssuePayload,
  ICreateComment,
  IDeleteComment,
  IEditComment,
  IIssue,
  UpdateResolveStatusPayload
} from "..";

export const issueCommentApi = {
  createIssue: (payload: CreateIssuePayload) =>
    api.post(`issue/create?workspaceId=${payload.workspaceId}`, {
      ...payload,
      endTimeStamp: payload.startTimeStamp
    }),
  updateIssue: (payload: Partial<IIssue>) =>
    api.post(`issue/update?workspaceId=${payload.workspaceId}`, {
      ...payload,
      endTimeStamp: payload.startTimeStamp
    }),
  removeIssue: (payload: Omit<GeneralIssueAPIPayload, "fileId">) =>
    api.put(
      `issue/delete/dub/${payload.dubId}/version/${payload.dubVersion}/issueId/${payload.issueId}?workspaceId=${payload.workspaceId}`
    ),
  markAsReadIssue: (payload: Omit<GeneralIssueAPIPayload, "fileId">) =>
    api.post(
      `comments/mark-as-read/dub/${payload.dubId}/version/${payload.dubVersion}/issueId/${payload.issueId}?workspaceId=${payload.workspaceId}`
    ),
  markAsUnreadIssue: (
    payload: GeneralIssueAPIPayload //@TODO;
  ) =>
    api.post(
      `file/${payload.fileId}/dub/${payload.dubId}/issue/${payload.issueId}/UNREAD`
    ),
  updateIssueTagResolveStatus: (payload: UpdateResolveStatusPayload) =>
    api.post(
      `issue/resolve-tag/dub/${payload.dubId}/version/${payload.dubVersion}/issueId/${payload.issueId}?workspaceId=${payload.workspaceId}`,
      payload.data
    ),
  getIssueById: (payload: GeneralIssueAPIPayload) =>
    api.get(
      `issue/fetch/dub/${payload.dubId}/version/${payload.dubVersion}/issueId/${payload.issueId}?workspaceId=${payload.workspaceId}`
    ),
  getCommentsByIssueId: (payload: GeneralIssueAPIPayload) =>
    api.get(
      `comments/list/issueId/${payload.issueId}?workspaceId=${payload.workspaceId}`
    ),
  addComment: (payload: ICreateComment) => {
    const { workspaceId, ..._payload } = payload;
    return api.post(`comments/action/ADD?workspaceId=${workspaceId}`, _payload);
  },
  editComment: (payload: IEditComment) => {
    const { workspaceId, ..._payload } = payload;
    return api.post(
      `comments/action/EDIT?workspaceId=${workspaceId}`,
      _payload
    );
  },
  deleteComment: (payload: IDeleteComment) => {
    const { workspaceId, ..._payload } = payload;
    return api.post(
      `comments/action/DELETE?workspaceId=${workspaceId}`,
      _payload
    );
  },
  // Change request related APIs
  getChangeRequestData: (
    payload: Omit<GeneralIssueAPIPayload, "fileId" | "issueId">
  ) => {
    return api.get(
      `change-request/fetch/dub/${payload.dubId}/version/${payload.dubVersion}?workspaceId=${payload.workspaceId}`
    );
  },
  // Issues for external Page
  getAllIssues: (payload: GetAllIssuePayload) =>
    api.get(
      `issue/list/dub/${payload.dubId}/version/${payload.dubVersion}?workspaceId=${payload.workspaceId}`
    ),
  // Issues for TVO Page
  getAllTVoIssues: (payload: GetAllIssuePayload) =>
    api.get(
      `file/${payload.fileId}/dub/${payload.dubId}/translation-vo/issue-list/?workspaceId=${payload.workspaceId}`
    ),
  // Issues for Internal QA Page
  getAllInternalQAPageIssues: (payload: GetAllIssuePayload) =>
    api.get(
      `member/quality-check/issue-list/dub/${payload.dubId}/file/${payload.fileId}/version/${payload.dubVersion}?workspaceId=${payload.workspaceId}`
    )
};

export * from "./__mocks__";
