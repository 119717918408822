import { internalProjectThunks } from "@/reducers/thunks/internalProject";
import {
  TBaseProject,
  TInternalSourceFile,
  TInternalTask
} from "@/types/project";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface InternalProjectState {
  projectData: TBaseProject | null;
  files: TInternalSourceFile[];
  isLoading: boolean;
  error?: string;
}

const initialState: InternalProjectState = {
  projectData: null,
  files: [],
  isLoading: false,
  error: ""
};

export const internalProjectSlice = createSlice({
  name: "internalProject",
  initialState,
  reducers: {
    updateTaskAssignee: (state, action: PayloadAction<TInternalTask>) => {
      // TODO: need to change the data structure for efficient update
      const newFiles = state.files.map((file) => {
        if (file.sourceFileId !== action.payload.sourceFileId) {
          return file;
        }
        if (action.payload.taskType === "FACE_MARKING") {
          return {
            ...file,
            faceMarking: action.payload
          };
        } else {
          return {
            ...file,
            dubResponseList: file.dubResponseList.map((dub) => {
              if (dub.dubId !== action.payload.dubId) {
                return dub;
              }

              return {
                ...dub,
                dubTasks: dub.dubTasks.map((task) => {
                  if (task.dubTaskId !== action.payload.dubTaskId) {
                    return task;
                  }

                  return {
                    ...task,
                    assigneeId: action.payload.assigneeId
                  };
                })
              };
            })
          };
        }
      });

      state.files = newFiles;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(internalProjectThunks.fetchProjectData.pending, (state) => {
      state.isLoading = true;
      state.files = [];
      state.projectData = null;
      state.error = "";
    });
    builder.addCase(
      internalProjectThunks.fetchProjectData.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.files = action.payload.sourceFiles;
        state.projectData = action.payload.project;
      }
    );
    builder.addCase(
      internalProjectThunks.fetchProjectData.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      }
    );
  }
});

export const { updateTaskAssignee } = internalProjectSlice.actions;

export default internalProjectSlice.reducer;
