import { MurfSnackbar } from "@/components/elements/MurfSnackbar";
import WorkspaceSelector from "@/components/elements/WorkspaceSelector";
import ClientSideBar from "@/components/layout/ClientSideBar";
import useClientHome from "@/features/clientHome/hooks/useClientHomeFetch";
import { SearchBar } from "@/features/clientSearch";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import React, { Suspense, useMemo, useState } from "react";
import MurfAppBar from "../MurfAppBar";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { CREDIT_EVENT_TYPE } from "@/features/credits";
import { Warning } from "@mui/icons-material";
import { setCreditsDialog } from "@/reducers/slices/dialogSlice/dialogSlice";
import LoaderWrapper from "../LoaderWrapper";

const ClientDefaultLayout = ({
  children,
  showSideBar = true
}: {
  children: React.ReactNode;
  showSideBar?: boolean;
}): React.ReactNode => {
  useClientHome();

  const dispatch = useAppDispatch();
  const workspace = useTypedSelector((state) => state.user.activeWorkspaceData);

  const hideCreditText = useMediaQuery("(max-width:1085px)");

  const [lowCreditsVariant, setLowCreditsVariant] = useState<
    CREDIT_EVENT_TYPE | undefined
  >(undefined);

  const showLowCreditsInfo = useMemo(() => {
    if (!workspace) {
      return false;
    }
    const { usedCredits, assignedCredits } = workspace;
    const availableCredits = assignedCredits - usedCredits;
    if (availableCredits <= 0) {
      setLowCreditsVariant(CREDIT_EVENT_TYPE.CREDITS_EXHAUSTED);
      return true;
    } else if (availableCredits / assignedCredits < 0.2) {
      setLowCreditsVariant(CREDIT_EVENT_TYPE.BUY_CREDITS);
      return true;
    }
    return true;
  }, [workspace]);

  const handleCreditsDialog = () => {
    dispatch(setCreditsDialog(true));
  };

  return (
    <>
      <Box sx={{ maxHeight: "100%", height: "100vh", overflow: "hidden" }}>
        <MurfAppBar>
          <Grid container minHeight={62} gap={2}>
            <Grid
              item
              sx={{
                maxWidth: 240
              }}
            >
              <WorkspaceSelector />
            </Grid>
            <SearchBar />
            <Stack
              direction={"row"}
              gap={2}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                marginLeft: "auto",
                marginRight: 2,
                py: 1
              }}
            >
              {showLowCreditsInfo ? (
                workspace &&
                lowCreditsVariant === CREDIT_EVENT_TYPE.BUY_CREDITS ? (
                  <Stack
                    p={0.5}
                    gap={1}
                    direction={"row"}
                    alignItems={"center"}
                  >
                    <Tooltip
                      title={
                        hideCreditText ? (
                          <Typography color={"warning.main"} variant="body2">
                            {workspace?.assignedCredits -
                              workspace?.usedCredits}{" "}
                            Credits left
                          </Typography>
                        ) : null
                      }
                    >
                      <Warning color="warning" sx={{ height: 20, width: 20 }} />
                    </Tooltip>
                    <Typography
                      color={"warning.main"}
                      variant="body2"
                      sx={{
                        display: hideCreditText ? "none" : "block"
                      }}
                    >
                      {workspace?.assignedCredits - workspace?.usedCredits}
                      &nbsp;Credits left
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{ ml: 1 }}
                      onClick={handleCreditsDialog}
                    >
                      Buy More Credits
                    </Button>
                  </Stack>
                ) : null
              ) : null}
              <Divider orientation="vertical" sx={{ maxHeight: 40 }} />
            </Stack>
          </Grid>
        </MurfAppBar>
        <Stack
          sx={{
            minHeight: "calc(100vh - 65px)",
            height: "100%"
          }}
          direction={"row"}
        >
          {showSideBar ? <ClientSideBar /> : null}
          <Box
            sx={{
              overflow: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "row",
              width: "100%",
              height: "calc(100% - 65px)",
              px: 4
            }}
          >
            <Suspense fallback={<LoaderWrapper loading />}>{children}</Suspense>
          </Box>
        </Stack>
      </Box>
      <MurfSnackbar />
    </>
  );
};

export default ClientDefaultLayout;
