import { Box, Button, Stack, chipClasses, styled } from "@mui/material";
import TaskProgress, { TaskProgressProps } from "../TaskProgress";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { TMember } from "@/features/user";
import React, { useMemo } from "react";
import AvatarChip from "../AvatarChip";
import { DUB_STATUS, TASK_STATUS, TASK_TYPE } from "@/types/project";
import { getTimeDifference } from "@/utils/time";

interface DubStepProps extends Omit<TaskProgressProps, "assigneeId"> {
  inline?: boolean;
  user?: TMember;
  changeRequestData?: {
    changeRequestCount: number;
    dubStatus: DUB_STATUS;
  };
  onAssignClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const Wrapper = styled(Stack, {
  shouldForwardProp: (props) => props !== "inline"
})<{ inline?: boolean }>(({ theme, inline }) => ({
  alignItems: inline ? "center" : "normal",
  gap: theme.spacing(1),
  ["& > div:first-child"]: {
    flex: "1 0 auto"
  },

  [`& .${chipClasses.root}`]: {
    justifyContent: "start"
  }
}));

const DubStep = ({
  inline = true,
  user,
  onAssignClick,
  changeRequestData,
  ...taskProgressProps
}: DubStepProps) => {
  const alertMessage = useMemo(() => {
    const timeDifference = getTimeDifference(taskProgressProps.dueBy ?? 0);
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    if (daysDifference <= 0) {
      return "MISSED DEADLINE";
    } else if (daysDifference <= 1) {
      return "DELAYED";
    }
    return "";
  }, [taskProgressProps.dueBy]);

  const color = useMemo(() => {
    if (taskProgressProps.taskStatus === TASK_STATUS.COMPLETED) {
      return "success";
    }
    if (alertMessage === "DELAYED") {
      return "warning";
    }
    if (alertMessage === "MISSED DEADLINE") {
      return "error";
    }
    if (!user?.userId || user?.userId === "NOT_ASSIGNED") {
      return "warning";
    }
    return "secondary";
  }, [taskProgressProps.taskStatus, alertMessage, user?.userId]);

  const handleAssignClick = (event: React.MouseEvent<HTMLElement>) => {
    if (taskProgressProps.taskStatus === TASK_STATUS.COMPLETED) {
      if (
        taskProgressProps.taskType === TASK_TYPE.MEDIA_MERGE &&
        changeRequestData?.changeRequestCount
      ) {
        onAssignClick?.(event);
      }
      return;
    }
    onAssignClick?.(event);
  };

  return (
    <Wrapper direction={inline ? "row" : "column"} inline={inline}>
      <TaskProgress {...taskProgressProps} assigneeId={user?.userId} />
      <Box
        sx={{
          width: inline ? "140px" : "auto"
        }}
      >
        {user ? (
          <AvatarChip user={user} onClick={handleAssignClick} />
        ) : (
          <Button
            startIcon={<PersonAddRounded />}
            variant={color === "secondary" ? "outlined" : "contained"}
            size="small"
            color={color}
            onClick={handleAssignClick}
            fullWidth
          >
            Assign
          </Button>
        )}
      </Box>
    </Wrapper>
  );
};

export default DubStep;
