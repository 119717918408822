import { useTypedSelector } from "@/config/configureAppStore";
import {
  AutocompleteValue,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  listItemIconClasses,
  listItemTextClasses,
  styled
} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { MurfAutocomplete } from "@/components/elements/MurfAutocomplete";
import { MURF_ROLES, TMember } from "@/features/user";
import { activeMemberSelector } from "@/reducers/selectors";
import { useMemo } from "react";
import { TASK_TYPE } from "@/types/project";
import { LANGUAGES } from "@/constants/languages";

const UserItemWrapper = styled(ListItem)(() => ({
  [`& .${listItemTextClasses.primary}, & .${listItemTextClasses.secondary}`]: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  [`& .${listItemIconClasses.root}`]: {
    minWidth: "auto"
  }
}));

interface UserListMenuProps<Multiple> {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  selectedUid?: Multiple extends true ? string[] : string | null;
  onSelect: (value: Multiple extends true ? TMember[] : TMember) => void;
  accessType?: "ALL_USERS" | "TASK_USERS" | "MANAGERS";
  taskType?: TASK_TYPE;
  sourceLanguage?: LANGUAGES;
  targetLanguage?: LANGUAGES;
  workspaceId?: string;
  accessorRole?: MURF_ROLES;
  userId?: string;
  multiple?: Multiple;
  filterSelectedOptions?: boolean;
}

const UserListMenu = <Multiple extends boolean | undefined = false>({
  anchorEl,
  selectedUid,
  onClose,
  onSelect,
  accessType = "TASK_USERS",
  workspaceId = "",
  sourceLanguage,
  targetLanguage,
  taskType,
  accessorRole = MURF_ROLES.USER,
  userId,
  multiple,
  filterSelectedOptions
}: UserListMenuProps<Multiple>) => {
  const { users, userMap } = useTypedSelector(activeMemberSelector);

  const filteredUsers = useMemo(() => {
    if (accessType === "ALL_USERS") return users;

    if (accessType === "MANAGERS")
      return users.filter((user) =>
        [MURF_ROLES.ADMIN, MURF_ROLES.DELIVERY_MANAGER].includes(user.murfRole)
      );

    return users.filter((user) => {
      if (
        [MURF_ROLES.CONTRIBUTOR, MURF_ROLES.USER].includes(accessorRole) &&
        user.userId !== userId
      ) {
        return false;
      }

      if (
        user.murfRole === MURF_ROLES.ADMIN ||
        user.murfRole === MURF_ROLES.DELIVERY_MANAGER
      ) {
        return user.assignedWorkspaces?.includes(workspaceId!);
      }

      return (
        (user.languages?.includes(sourceLanguage!) ||
          user.languages?.includes(targetLanguage!)) &&
        user.specialisation?.includes(taskType!)
      );
    });
  }, [
    accessType,
    accessorRole,
    sourceLanguage,
    targetLanguage,
    taskType,
    userId,
    users,
    workspaceId
  ]);

  const handleClose = () => {
    onClose?.();
  };

  const handleSelection = (
    user: Multiple extends true ? TMember[] : TMember
  ) => {
    onSelect(user);
  };

  const selectedValue = useMemo(() => {
    if (multiple) {
      return selectedUid && Array.isArray(selectedUid)
        ? selectedUid.map((uid) => userMap[uid])
        : [];
    } else {
      return selectedUid && typeof selectedUid === "string"
        ? userMap[selectedUid]
        : null;
    }
  }, [multiple, selectedUid, userMap]);

  return (
    <MurfAutocomplete
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      options={filteredUsers}
      placeholder="Search People"
      getOptionLabel={(user) => user.displayName}
      noOptionsText="No Users found"
      renderOption={(props, option, { selected }) => (
        <UserItemWrapper {...props} key={option.userId}>
          <ListItemAvatar>
            <Avatar
              src={option?.profileImageUrl ?? undefined}
              alt={option?.displayName}
            >
              {option?.displayName?.[0]}{" "}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{ color: "text.primary" }}
            primary={option.displayName}
            secondary={option.emailId}
          />
          {selected && (
            <ListItemIcon>
              <CheckRoundedIcon />
            </ListItemIcon>
          )}
        </UserItemWrapper>
      )}
      value={
        selectedValue as AutocompleteValue<TMember, Multiple, false, false>
      }
      onChange={(_, newValue) => {
        if (!newValue) {
          return;
        }
        handleSelection(
          newValue as Multiple extends true ? TMember[] : TMember
        );
      }}
      multiple={multiple}
      isOptionEqualToValue={(option, value) => option.userId === value.userId}
      filterSelectedOptions={filterSelectedOptions}
    />
  );
};

export default UserListMenu;
