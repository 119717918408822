import { PaletteOptions } from "@mui/material";
import { themeVariables } from "./themeVariables";

export interface MurfPaletteOptions {
  selected?: string;
  focusVisible?: string;
  outlinedBorder?: string;
  hover?: string;
}

export const lightThemePalette: PaletteOptions = {
  mode: "light",
  primary: {
    main: "#FFCE00",
    light: "#FFEB99",
    dark: "#D69A00",
    contrastText: "#191D25",
    hover: "#FFCE000A",
    selected: "#FFCE0014",
    focusVisible: "#FFCE004D",
    outlinedBorder: "#FFCE0080"
  },
  secondary: {
    main: "#3A4358",
    light: "#717C96",
    dark: "#212632",
    contrastText: "#FFFFFF",
    hover: "#3B4F7D0A",
    selected: "#3B4F7D14",
    focusVisible: "#3B4F7D4D",
    outlinedBorder: "#3B4F7D66"
  },
  info: {
    main: "#2B96F9",
    light: "#99CEFF",
    dark: "#006ACC",
    contrastText: "#FFFFFF",
    hover: "#8FB8FF1A",
    selected: "#8FB8FF40",
    focusVisible: "#8FB8FF66",
    outlinedBorder: "#8FB8FF99"
  },
  success: {
    main: "#009961",
    light: "#7DE8C8",
    dark: "#006641",
    contrastText: "#FFFFFF",
    hover: "#0099611A",
    selected: "#00996140",
    focusVisible: "#00996166",
    outlinedBorder: "#00996199"
  },
  error: {
    main: "#E54F4D",
    light: "#EC9397",
    dark: "#C32230",
    contrastText: "#FFFFFF",
    hover: "#ED615E1A",
    selected: "#ED615E40",
    focusVisible: "#ED615E66",
    outlinedBorder: "#ED615E99"
  },
  warning: {
    main: "#F99415",
    light: "#FFD299",
    dark: "#D67A05",
    contrastText: "#FFFFFF",
    hover: "#F9941533",
    selected: "#F9941540",
    focusVisible: "#F9941566",
    outlinedBorder: "#F9941599"
  },
  action: {
    active: "#212632",
    hover: "#3B4F7D0F",
    selected: "#3B4F7D14",
    focus: "#3B4F7D1F",
    disabledBackground: "#3B4F7D1C"
  },
  text: {
    primary: "#202533",
    secondary: "#191D2599",
    disabled: "#191D254D"
  },
  divider: themeVariables.light.dividerColor,
  background: {
    default: themeVariables.light.backgroundDefault,
    paper: themeVariables.light.backgroundPaper,
    elevation0: themeVariables.light.elevation[0],
    elevation1: themeVariables.light.elevation[1],
    elevation2: themeVariables.light.elevation[2],
    elevation3: themeVariables.light.elevation[3]
  },
  elevation: {
    outlinedBorder: themeVariables.light.elevationOutline
  }
};
