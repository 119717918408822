import { lazy } from "react";
import { RouteObject } from "react-router-dom";
const Projects = lazy(() => import("./Projects"));
const Project = lazy(() => import("./Project"));

export const projectInternalRoutes: RouteObject[] = [
  {
    path: "project/:projectId",
    element: <Project />
  },
  {
    path: "projects",
    element: <Projects />
  }
];
