import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  BlocksMap,
  UpdateBlocksPayload,
  TimelineSlice,
  TickDimensions,
  RenderedBlocksMap
} from "./types";
import { translationVoThunks } from "@/reducers/thunks/translationVo";
import {
  MAX_SLICE_WIDTH_PX,
  TIMELINE_VARIANTS
} from "@/features/timeline/constants/variants";
import { BlockError, RenderedBlockType } from "@/features/blocks";
import { QC_ACCESS_ROLES } from "@/types/user";
import { ScriptDubData } from "@/features/scriptStudio/types";
import { unicodeAwareEqualsIgnoringCase } from "@/features/scriptStudio/utils/pronunciation";
import { getPercentage, getValueFromPercentage } from "@/utils/misc";
import { doesChangeAffectsRender } from "@/features/scriptStudio/utils";
import {
  getBlockErrors,
  checkIfBlockIsCompleted
} from "@/features/blocks/utils";
import {
  BLOCK_ERROR_MESSAGES,
  BLOCK_ERROR_TYPE
} from "@/features/blocks/constants";
import { UpdateSpeakerPayload } from "../currentScriptProjectSlice/types";
import { areVoicesEqual } from "@/features/synthesis/util";
import {
  handleProjectPronunciation,
  getMurfTokensUsingDom,
  convertRichTextToHtmlText,
  removeDubPronunciation,
  splitTextToWordsBySpace,
  stripHtml,
  cleanText
} from "@/features/editor";
import { issueCommentThunk } from "@/reducers/thunks/issueCommentThunk";

interface ProjectUtilitySliceState {
  currentBlockId?: string | undefined;
  currentPlayingBlockId?: string | undefined;
  translationVoProgress: number;
  blocksMap: BlocksMap;
  renderedBlocksMap: RenderedBlocksMap;
  blockPropertyQueue: string[];
  transcriptionBlockPropertyQueue: string[];
  renderingBlocks: string[];
  renderUpdatedBlocksQueue: string[];
  scrollBlockCounter: number;
  timeline: {
    slices: TimelineSlice[];
    pxPerSec: number;
    timelineWidth: number;
    timelineMarginBottom: number;
    tickDimensions: TickDimensions;
    seekerStartPosition: number;
  };
  renderCircuitBreaker: boolean;
  tvoAccessLevel: QC_ACCESS_ROLES | null;
  richTextEnabled: boolean;
  resetProgressivePlayers: boolean;
  tvoIssues: { [key: string]: string[] };
}

const initialState: ProjectUtilitySliceState = {
  currentBlockId: undefined,
  currentPlayingBlockId: undefined,
  translationVoProgress: 0,
  blocksMap: {},
  renderedBlocksMap: {},
  blockPropertyQueue: [],
  transcriptionBlockPropertyQueue: [],
  renderingBlocks: [],
  renderUpdatedBlocksQueue: [],
  scrollBlockCounter: 0,
  timeline: {
    slices: [],
    pxPerSec: 50,
    timelineWidth: MAX_SLICE_WIDTH_PX * 2,
    timelineMarginBottom: TIMELINE_VARIANTS.NORMAL.marginBottom,
    tickDimensions: {
      ticksHeight: TIMELINE_VARIANTS.NORMAL.ticksHeight,
      ticksMargin: TIMELINE_VARIANTS.NORMAL.ticksMargin
    },
    seekerStartPosition: 0
  },
  renderCircuitBreaker: false,
  tvoAccessLevel: null,
  richTextEnabled: false,
  resetProgressivePlayers: false,
  tvoIssues: {}
};

export const projectUtilitySlice = createSlice({
  name: "projectUtility",
  initialState,
  reducers: {
    setCurrentBlockId: (state, action) => {
      state.currentBlockId = action.payload;
    },
    setCurrentPlayingBlockId: (state, action) => {
      state.currentPlayingBlockId = action.payload;
    },
    setResetProgressivePlayers: (state, action) => {
      state.resetProgressivePlayers = action.payload;
    },
    addTranslationVariant: (
      state,
      action: PayloadAction<{ blockId: string; list: string[] }>
    ) => {
      const { blockId, list } = action.payload;
      state.blocksMap[blockId].translationVariants = list;
    },
    markBlock: (
      state,
      action: PayloadAction<{
        blockIds: string[];
        checked: boolean;
        needToSync?: boolean;
      }>
    ) => {
      // check if tvoAccessLevel is present
      const accessLevel = state.tvoAccessLevel;
      if (accessLevel) {
        const payload = action.payload;
        const totalBlocks = Object.keys(state.blocksMap).length;
        // calculating progress percentage change after marking block
        let completedBlocksCount = getValueFromPercentage(
          state.translationVoProgress,
          totalBlocks
        );

        payload.blockIds.forEach((id) => {
          if (!state.blocksMap[id].completed && payload.checked) {
            completedBlocksCount++;
          } else if (state.blocksMap[id].completed && !payload.checked)
            completedBlocksCount--;

          state.blocksMap[id] = {
            ...state.blocksMap[id],
            completed: payload.checked
          };
        });

        state.translationVoProgress = getPercentage(
          completedBlocksCount,
          totalBlocks
        );

        if (payload.needToSync && payload.blockIds.length) {
          state.blockPropertyQueue = [
            ...new Set([...state.blockPropertyQueue, ...payload.blockIds])
          ];
        }
      }
    },
    addAffectedBlocksToSyncApi: (state, action) => {
      if (action.payload.blockIds) {
        state.blockPropertyQueue = [
          ...new Set([...state.blockPropertyQueue, ...action.payload.blockIds])
        ];
      }
    },
    cleanBlockApiState: (state) => {
      state.blockPropertyQueue = [];
    },
    cleanBlockPropertyState: (
      state,
      action: PayloadAction<{ blockIdsToDelete: string[] }>
    ) => {
      if (action && action.payload) {
        const blockIds = action.payload.blockIdsToDelete;
        if (blockIds.length) {
          state.blockPropertyQueue = state.blockPropertyQueue.filter(
            (id) => !blockIds.includes(id)
          );
        } else {
          state.blockPropertyQueue = [];
        }
      } else {
        state.blockPropertyQueue = [];
      }
    },
    cleanTranscriptionBlockPropertyState: (
      state,
      action: PayloadAction<string[]>
    ) => {
      if (action.payload.length) {
        state.transcriptionBlockPropertyQueue =
          state.transcriptionBlockPropertyQueue.filter(
            (id) => !action.payload.includes(id)
          );
        return;
      }
      state.transcriptionBlockPropertyQueue = [];
    },
    addRenderingBlockIds: (
      state,
      action: PayloadAction<{ blockIds: string[] }>
    ) => {
      const blockIds = action.payload.blockIds;

      state.renderingBlocks = Array.from(
        new Set([...state.renderingBlocks, ...blockIds])
      );
    },
    deleteRenderingBlockIds: (
      state,
      action: PayloadAction<{ blockIds?: string[]; blocksMap?: any }>
    ) => {
      const blockIds =
        action.payload.blockIds ?? Object.keys(action.payload.blocksMap);
      state.renderingBlocks = state.renderingBlocks.filter(
        (f) => !blockIds.includes(f)
      );
    },
    addRenderUpdatedBlocksQueue: (
      state,
      action: PayloadAction<{ blockIds: string[] }>
    ) => {
      const blockIds = action.payload.blockIds;

      state.renderUpdatedBlocksQueue = Array.from(
        new Set([...state.renderUpdatedBlocksQueue, ...blockIds])
      );
    },
    deleteRenderUpdatedBlocksQueue: (
      state,
      action: PayloadAction<{ blockIds?: string[]; blocksMap?: any }>
    ) => {
      const blockIds =
        action.payload.blockIds ?? Object.keys(action.payload.blocksMap);
      state.renderUpdatedBlocksQueue = state.renderUpdatedBlocksQueue.filter(
        (f) => !blockIds.includes(f)
      );
    },
    /**
     * action used to update or insert error in block
     * We can upsert same error for multiple blocks by providing type at payload level
     * also we can upsert particular error type to certain block by providing type at block level
     * either provide type at payload level or provide at block level
     */
    upsertBlockError: (
      state,
      action: PayloadAction<{
        blocksErrorMap: Record<string, Partial<BlockError>>;
        type: BLOCK_ERROR_TYPE;
      }>
    ) => {
      const { blocksErrorMap, type } = action.payload;
      const blockIds = Object.keys(blocksErrorMap);

      blockIds.forEach((blockId) => {
        const blockError = blocksErrorMap[blockId];
        const block = state.blocksMap[blockId];
        if (block) {
          // if its given in block then use it else use type
          const errorType = blockError.type || type;
          const error = {
            type: errorType,
            message: blockError.message || BLOCK_ERROR_MESSAGES[errorType]
          };

          // giving more priority to new error
          block.blockErrors.unshift(error);
          // converting array to map
          const blockErrors: { [key: string]: BlockError } =
            block.blockErrors.reduce(
              (prev, curr) => ({ ...prev, [curr.type]: curr }),
              {}
            );

          block.blockErrors = Object.values(blockErrors);
        }
      });
    },
    /**
     * action used to remove block error
     * We can remove remove error type for all provided blocks
     * also we can remove block level error by providing type in map
     * either provide type at payload level or provide at block level
     */
    removeBlockError: (
      state,
      action: PayloadAction<{
        blocksErrorMap: Record<string, Partial<BlockError>>;
        type?: BLOCK_ERROR_TYPE;
      }>
    ) => {
      const { blocksErrorMap, type } = action.payload;
      const blockIds = Object.keys(blocksErrorMap);
      blockIds.map((blockId) => {
        const blockError = blocksErrorMap[blockId];
        const block = state.blocksMap[blockId];
        // if error type given at block level then use it else use type
        const errorType = blockError.type || type;
        // removing error type from errors
        block.blockErrors = block.blockErrors.filter(
          (error) => error.type !== errorType
        );
      });
    },
    // check and update the possible errors in block
    checkBlockError: (
      state,
      action: PayloadAction<{ blocksMap: BlocksMap }>
    ) => {
      const blocks = Object.values(action.payload.blocksMap);
      blocks.map((block) => {
        const oldErrors = state.blocksMap[block.blockId].blockErrors;
        // preserving text length error from local blocks
        const textLengthError = oldErrors.find(
          (error) => error.type === BLOCK_ERROR_TYPE.TEXT_LIMIT_EXCEEDED_ERROR
        );
        let newErrors = [];
        if (textLengthError) newErrors.push(textLengthError);
        newErrors = newErrors.concat(getBlockErrors(block, true));

        state.blocksMap[block.blockId].blockErrors = newErrors;
      });
    },
    upsertRenderedBlocksMap: (
      state,
      action: PayloadAction<{
        renderedBlocksMap: Record<string, Partial<RenderedBlockType>>;
      }>
    ) => {
      const blocks = Object.values(action.payload.renderedBlocksMap);

      blocks.forEach((block) => {
        if (block?.blockId) {
          const oldBlock = state.renderedBlocksMap[block.blockId];

          state.renderedBlocksMap[block.blockId] = {
            ...oldBlock,
            ...block
          };
        }
      });
    },
    setTimelineSlices: (
      state,
      action: PayloadAction<{ slices: TimelineSlice[] }>
    ) => {
      state.timeline.slices = action.payload.slices;
    },
    setTimelinePxPerSec: (
      state,
      action: PayloadAction<{ pxPerSec: number }>
    ) => {
      state.timeline.pxPerSec = action.payload.pxPerSec;
    },
    setRenderCircuitBreaker: (
      state,
      action: PayloadAction<{ renderCircuitBreaker: boolean }>
    ) => {
      state.renderCircuitBreaker = action.payload.renderCircuitBreaker;
    },
    setScrollBlock: (state, action: PayloadAction<string>) => {
      window.scrollToBlockId = action.payload;
      window.scrollToBlockNeeded = true;
      state.scrollBlockCounter++;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        translationVoThunks.fetchTranslationVoDetails.fulfilled,
        (state, action: PayloadAction<ScriptDubData>) => {
          const data = action.payload;
          const projectBlocks = data.blocks;
          const newBlocksMap: BlocksMap = {};
          const renderedBlocksMap: RenderedBlocksMap = {};
          const firstBlockId = projectBlocks[0].blockId;
          // calculating progress percentage
          let completedBlocksCount = 0;

          projectBlocks.forEach((block, ind) => {
            const htmlText = data.richTextEnabled
              ? convertRichTextToHtmlText(
                  block.phrasePropertiesList,
                  data.pronunciations || []
                )
              : block.richText;

            // stripping html to count the length of text
            block.richText = data.richTextEnabled
              ? stripHtml(htmlText)
              : block.richText || "";

            const blockErrors = getBlockErrors(block);
            // if block has errors then completed is false
            const completed = blockErrors?.length
              ? false
              : checkIfBlockIsCompleted(
                  block,
                  data.qcEnabled,
                  data.accessLevel
                );
            if (completed) {
              completedBlocksCount++;
            }

            let correctedDuration = block.original.duration;
            if (ind < projectBlocks.length - 1) {
              correctedDuration = parseInt(
                (
                  projectBlocks[ind + 1].original.startTime -
                  block.original.startTime
                ).toFixed(3)
              );
            }
            let endTime = block.original.startTime + block.original.duration;
            if (block.original.duration >= correctedDuration) {
              endTime = endTime - 0.000001;
            }

            newBlocksMap[block.blockId] = {
              ...block,
              original: {
                ...block.original,
                text: block.original.text?.trim(),
                localText: block.original.text?.trim(),
                englishText: block.original.englishText?.trim() || "",
                localEnglishText: block.original.englishText?.trim() || "",
                endTime,
                correctedDuration
              },
              htmlText,
              completed,
              blockNumber: ind + 1,
              // handling block errors
              blockErrors,
              translationVariants: [htmlText],
              affectedByBlockProperties: block.needsRendering,
              affectedBySpeakerProperties: block.needsRendering,
              associatedIssueIds:
                state.blocksMap?.[block.blockId]?.associatedIssueIds
            };

            const renderedSpeaker = data.speakers.find(
              (speaker) => speaker.speakerId === block.speakerId
            );
            const newBlock = newBlocksMap[block.blockId];
            // storing required fields only
            renderedBlocksMap[block.blockId] = {
              blockId: newBlock.blockId,
              htmlText: newBlock.htmlText,
              needsRendering: newBlock.needsRendering,
              renderStatus: newBlock.renderStatus,
              renderVersion: newBlock.renderVersion,
              speakerId: newBlock.speakerId,
              renderedPitch: renderedSpeaker?.pitch || 0,
              renderedVoiceVariant:
                renderedSpeaker?.selectedVoiceVariant || null
            };
          });
          state.blocksMap = newBlocksMap;
          state.renderedBlocksMap = renderedBlocksMap;
          state.currentBlockId = firstBlockId;
          state.richTextEnabled = data.richTextEnabled;
          state.timeline.timelineWidth = data.originalCleanSpeech.audioLength;
          state.renderCircuitBreaker = false;
          state.translationVoProgress = getPercentage(
            completedBlocksCount,
            projectBlocks.length
          );
          if (data.qcEnabled) {
            state.tvoAccessLevel = data.accessLevel;
          }
        }
      )
      .addCase(issueCommentThunk.getAllTVoIssues.fulfilled, (state, data) => {
        const res = data.payload;
        if (res.responseCode === "SUCCESS") {
          const { issues } = res.responseData;
          if (Array.isArray(issues)) {
            Object.values(state.blocksMap).map((block) => {
              const blockStartTime = block.original.startTime;
              const _issues = issues.filter((issue) => {
                return (
                  blockStartTime <= issue.startTimeStamp &&
                  issue.startTimeStamp <
                    blockStartTime + block.original.duration
                );
              });

              if (_issues?.length) {
                const issueIds = Array.from(
                  new Set(_issues.map((issue) => issue.issueId))
                );

                state.tvoIssues[block.blockId] = issueIds;
                block.associatedIssueIds = issueIds;
              }
            });
          }
        }
      })
      .addCase(
        translationVoThunks.updateBlocks.fulfilled,
        (state, action: PayloadAction<UpdateBlocksPayload>) => {
          // thunk to update multiple block properties
          // used to remove rendering blocks and render failed blocks
          const payload = action.payload;
          const blocks = payload.blocks;
          const blockIds: string[] = [];
          const affectedBlocks: string[] = [];
          blocks.forEach((block) => {
            if (block.blockId) {
              const prevBlock = state.blocksMap[block.blockId];
              const renderedBlock = state.renderedBlocksMap[block.blockId];
              // if block have rich text then use it else get it from current block
              let phrasePropertiesList =
                block.phrasePropertiesList || prevBlock.phrasePropertiesList;
              const richText = block.richText || prevBlock.richText;
              // sync transcriptionUpdatedAt with original block updatedAt if text is changed
              let transcriptionUpdatedAt = prevBlock.transcriptionUpdatedAt;
              let englishTextUpdatedAt = prevBlock.englishTextUpdatedAt;
              /**
               * converting html to rich text
               * if text changed then convert it to rich text and update Rich text
               */
              if (
                state.richTextEnabled &&
                typeof block.htmlText === "string" &&
                prevBlock.htmlText !== block.htmlText
              ) {
                phrasePropertiesList = getMurfTokensUsingDom(block.htmlText);
                transcriptionUpdatedAt = prevBlock.original.textUpdatedAt;
                englishTextUpdatedAt = prevBlock.original.englishTextUpdatedAt;
              }

              const newBlock = {
                ...state.blocksMap[block.blockId],
                ...block,
                richText,
                phrasePropertiesList,
                transcriptionUpdatedAt,
                englishTextUpdatedAt
              };
              newBlock.original = {
                ...state.blocksMap[block.blockId].original,
                endTime:
                  state.blocksMap[block.blockId].original.startTime +
                  state.blocksMap[block.blockId].original.duration
              };

              blockIds.push(block.blockId);

              /**
               * checking if change affects render if yes then setting localNeedsRendering to true
               */
              if (
                payload.affectsRender &&
                doesChangeAffectsRender(newBlock, renderedBlock)
              ) {
                // if field is provided in block then use it else true
                newBlock.affectedByBlockProperties =
                  block.affectedByBlockProperties ?? true;
                affectedBlocks.push(block.blockId);
                // if block is affected then completed is false
                newBlock.completed = false;
              } else
                newBlock.affectedByBlockProperties =
                  renderedBlock.needsRendering;

              state.blocksMap[block.blockId] = newBlock;
            }
          });

          // remove affected blocks from rendering
          if (affectedBlocks.length && state.renderingBlocks.length) {
            state.renderingBlocks = state.renderingBlocks.filter(
              (blockId) => !affectedBlocks.includes(blockId)
            );
          }

          if (payload.renderCircuitBreaker) {
            state.renderCircuitBreaker = payload.renderCircuitBreaker;
          }

          // after updating if sync is needed
          if (payload.needToSync && blockIds.length) {
            state.blockPropertyQueue = [
              ...new Set([...state.blockPropertyQueue, ...blockIds])
            ];
          }
        }
      )
      .addCase(
        translationVoThunks.updateSpeaker.fulfilled,
        (state, action: PayloadAction<UpdateSpeakerPayload>) => {
          const speaker = action.payload.speaker;
          if (speaker.speakerId) {
            /**
             * going through all rendered blocks and checking if speaker change affects the block
             * checking all affected blocks
             */
            const renderedBlocks = Object.values(state.renderedBlocksMap);
            const affectedBlocks: string[] = [];
            renderedBlocks.forEach((rBlock) => {
              let affected = false;
              // block with changed speaker
              if (rBlock.speakerId === speaker.speakerId) {
                // comparing speaker properties
                if (
                  speaker.selectedVoiceVariant &&
                  !areVoicesEqual(
                    speaker.selectedVoiceVariant,
                    rBlock.renderedVoiceVariant
                  )
                ) {
                  affected = true;
                }
                if (
                  typeof speaker.pitch === "number" &&
                  speaker.pitch !== rBlock.renderedPitch
                ) {
                  affected = true;
                }
              }

              // if block is affected by change then set localNeedsRendering to true
              if (affected) {
                state.blocksMap[rBlock.blockId].affectedBySpeakerProperties =
                  true;
                // if block is marked as done and its completed then un-mark it and sync
                if (state.blocksMap[rBlock.blockId].completed) {
                  state.blocksMap[rBlock.blockId].completed = false;
                  affectedBlocks.push(rBlock.blockId);
                }
                // remove affected blocks from rendering
                state.renderingBlocks = state.renderingBlocks.filter(
                  (blockId) => rBlock.blockId !== blockId
                );
              } else
                state.blocksMap[rBlock.blockId].affectedBySpeakerProperties =
                  rBlock.needsRendering;
            });

            // syncing affected blocks
            if (affectedBlocks.length) {
              state.blockPropertyQueue = [
                ...new Set([...state.blockPropertyQueue, ...affectedBlocks])
              ];
            }
          }
        }
      )
      .addCase(translationVoThunks.resetState.fulfilled, (state) => {
        // resetting translation-vo page states
        state.blocksMap = initialState.blocksMap;
        state.currentBlockId = initialState.currentBlockId;
        state.currentPlayingBlockId = initialState.currentPlayingBlockId;
        state.blockPropertyQueue = initialState.blockPropertyQueue;
        state.translationVoProgress = initialState.translationVoProgress;
        state.renderedBlocksMap = initialState.renderedBlocksMap;
        state.renderingBlocks = initialState.renderingBlocks;
        if (state.renderingBlocks.length) {
          state.renderCircuitBreaker = true;
        }
        state.tvoIssues = initialState.tvoIssues;
      })
      .addCase(
        translationVoThunks.savePronunciations.fulfilled,
        (state, action) => {
          const pronunciation = action.meta.arg.newPronunciation;
          const blocksThatNeedToBeSynced: string[] = [];

          if (pronunciation.word) {
            Object.values(state.blocksMap).map((block) => {
              const matchFound = block.htmlText
                ? splitTextToWordsBySpace(block.htmlText).find((paraWord) =>
                    unicodeAwareEqualsIgnoringCase(paraWord, pronunciation.word)
                  )
                : false;

              //Todo(Sujit): update needsRendering and render failure state for affected blocks
              if (matchFound) {
                // if alt and word are same then remove dub pronunciation highlight
                const { text, oldPronunciationAffected } =
                  unicodeAwareEqualsIgnoringCase(
                    pronunciation.alternate,
                    pronunciation.word
                  )
                    ? removeDubPronunciation(
                        block.htmlText,
                        pronunciation,
                        true
                      )
                    : handleProjectPronunciation(
                        block.htmlText,
                        pronunciation,
                        true
                      );
                block.htmlText = text;
                block.phrasePropertiesList = getMurfTokensUsingDom(text);

                block.needsRendering = true;

                // if block is affected by operation then sync the block
                if (oldPronunciationAffected)
                  blocksThatNeedToBeSynced.push(block.blockId);
              }
            });
          }

          // syncing affected blocks
          if (blocksThatNeedToBeSynced.length) {
            state.blockPropertyQueue = [
              ...new Set([
                ...state.blockPropertyQueue,
                ...blocksThatNeedToBeSynced
              ])
            ];
          }
        }
      )
      .addCase(
        translationVoThunks.updateTranscriptionBlock.fulfilled,
        (state, action) => {
          const blocks = action.payload.blocks;
          Object.keys(blocks).forEach((blockId) => {
            const originalBlock = blocks[blockId];
            const currentOriginalBlock = state.blocksMap[blockId].original;

            if (typeof originalBlock.localText === "string") {
              state.blocksMap[blockId].original.localText =
                originalBlock.localText;
            }

            if (typeof originalBlock.localEnglishText === "string") {
              state.blocksMap[blockId].original.localEnglishText =
                originalBlock.localEnglishText;
            }

            if (action.payload.save) {
              if (
                currentOriginalBlock.localText &&
                currentOriginalBlock.text !== currentOriginalBlock.localText
              ) {
                currentOriginalBlock.localText = cleanText(
                  currentOriginalBlock.localText
                );
                currentOriginalBlock.text = currentOriginalBlock.localText;

                currentOriginalBlock.textUpdatedAt = Date.now();
              }
              if (
                currentOriginalBlock.localEnglishText &&
                currentOriginalBlock.englishText !==
                  currentOriginalBlock.localEnglishText
              ) {
                currentOriginalBlock.localEnglishText = cleanText(
                  currentOriginalBlock.localEnglishText
                );
                currentOriginalBlock.englishText =
                  currentOriginalBlock.localEnglishText;

                currentOriginalBlock.englishTextUpdatedAt = Date.now();
              }
              state.blocksMap[blockId].original = currentOriginalBlock;
              state.transcriptionBlockPropertyQueue = [
                ...new Set([...state.transcriptionBlockPropertyQueue, blockId])
              ];
            }
          });
        }
      );
  }
});

export const {
  setCurrentBlockId,
  setCurrentPlayingBlockId,
  addAffectedBlocksToSyncApi,
  addRenderingBlockIds,
  deleteRenderingBlockIds,
  cleanBlockApiState,
  addTranslationVariant,
  cleanBlockPropertyState,
  setTimelineSlices,
  setTimelinePxPerSec,
  markBlock,
  upsertRenderedBlocksMap,
  upsertBlockError,
  removeBlockError,
  checkBlockError,
  addRenderUpdatedBlocksQueue,
  deleteRenderUpdatedBlocksQueue,
  setRenderCircuitBreaker,
  setResetProgressivePlayers,
  setScrollBlock,
  cleanTranscriptionBlockPropertyState
} = projectUtilitySlice.actions;

export default projectUtilitySlice.reducer;
