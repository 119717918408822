import { Reducer, configureStore, combineReducers } from "@reduxjs/toolkit";
import reducer from "../reducers/rootReducer";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const store = configureStore({
  reducer: reducer
});

const asyncReducers: { [key: string]: Reducer } = {};

export function injectReducer(key: string, asyncReducer: Reducer) {
  asyncReducers[key] = asyncReducer;
  store.replaceReducer(
    combineReducers({
      ...reducer,
      ...asyncReducers
    })
  );
}

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
