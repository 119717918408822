import { LANGUAGES } from "@/constants/languages";

export const enum PROJECT_ACTION_PENDING_STATE {
  IDEAL = "IDEAL",
  CHANGE_REQUEST = "CHANGE_REQUEST",
  UPLOAD_FAILED = "UPLOAD_FAILED",
  ASSIGNMENT = "ASSIGNMENT",
  CUSTOMER_REVIEW = "CUSTOMER_REVIEW"
}

export const enum PROJECT_STATE {
  DRAFT = "DRAFT",
  UPLOADING = "UPLOADING",
  REVIEW_WAITING = "REVIEW_WAITING",
  DELIVERED = "DELIVERED",
  PROCESSING = "PROCESSING"
}

export const enum ACTION_PENDING_STATE {
  CHANGE_REQUEST = "CHANGE_REQUEST"
}

export const enum PROJECT_DRAFT_STATE {
  SETUP_PROJECT = "SETUP_PROJECT",
  UPLOAD_FILES = "UPLOAD_FILES",
  SELECT_LANGUAGE = "SELECT_LANGUAGE",
  REQUIREMENTS = "REQUIREMENTS",
  FINISH = "FINISH",
  DONE = "DONE"
}

export const enum PROJECT_FILE_STATUS {
  WAITING_FOR_UPLOAD = "WAITING_FOR_UPLOAD",
  PREPROCESSING = "PREPROCESSING",
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
  FAILED = "FAILED",
  ERROR = "ERROR",
  PROCESSING = "PROCESSING",
  PROCESSING_FAILED = "PROCESSING_FAILED",
  QUEUED = "QUEUED"
}

export const enum PROJECT_QC_STATES {
  QUALITY_CHECK = "QUALITY_CHECK",
  PARTIAL_QUALITY_CHECK = "PARTIAL_QUALITY_CHECK",
  NON_QUALITY_CHECK = "NON_QUALITY_CHECK"
}

export type TBaseProject = {
  projectId: string;
  projectName: string;
  createdAt: number;
  dueBy: number;
  targetLocales: LANGUAGES[];
  sourceLocale: LANGUAGES;
  workspaceId: string;
  qualityCheckEnabled: boolean;
  createdBy: string;
  lipSyncEnabled: boolean;
  projectState: PROJECT_STATE;
  actionPendingState: PROJECT_ACTION_PENDING_STATE;
  draft: boolean;
  autoApprove: boolean;
  archived: boolean;
  numberOfVideos: number;
  totalDubs: number;
  completedDubs: number;
  draftState: PROJECT_DRAFT_STATE;
  workspaceName?: string;
  projectQCType?: PROJECT_QC_STATES;
};

export type TBaseSourceFile = {
  sourceFileId: string;
  fileName: string;
  uploadedAt: number;
  duration: number;
  thumbUrl?: string;
  videoUrl?: string;
  lipSyncEnabled: boolean;
  contentType?: string;
  fileType: "VIDEO" | "IMAGE" | "BG_AUDIO"; //TODO should be updated this is same as backend
  archived: boolean;
  fileSize: string;
  fileStatus: PROJECT_FILE_STATUS;
};

export const enum DUB_STATUS {
  CREATED = "CREATED",
  WAITING_FOR_UPLOAD = "WAITING_FOR_UPLOAD",
  PROCESSING = "PROCESSING",
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
  REVISION_IN_PROCESS = "REVISION_IN_PROCESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  QC_IN_PROCESS = "QC_IN_PROCESS",
  QUEUED = "QUEUED"
}

export type TBaseDub = {
  dubId: string;
  locale: string;
  dubStatus: DUB_STATUS;
  qcEnabled: boolean;
  sourceFileId: string;
  dueBy: number;
  deliveredOn: number | null;
  dubVersion: string;
  downloadUrl?: string;
  changeRequestCount?: number;
  changeRequestDraftVersion?: string;
};

export const enum TASK_TYPE {
  TRANSLATION = "TRANSLATION",
  VOICE_OVER = "VOICE_OVER",
  FACE_MARKING = "FACE_MARKING",
  MEDIA_MERGE = "MEDIA_MERGE"
}

export const enum TASK_STATUS {
  UPCOMING = "UPCOMING",
  TODO = "TO_DO",
  ONGOING = "ONGOING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  CHANGE_REQUEST = "CHANGE_REQUEST"
}

export type TBaseDubTask = {
  dubTaskId: string; //primary key
  taskType: TASK_TYPE;
  sourceFileId: string;
  percentageCompletion: number;
  dubId: string; //will be empty incase of face marking
  dueBy: number;
};

export type TInternalTask = TBaseDubTask & {
  assigneeId: string;
  targetLocale: LANGUAGES | null;
  sourceLocal: LANGUAGES | null;
  projectName: string;
  videoDuration: number;
  startedAt: number;
  workspaceId: string;
  fileName: string;
  taskStatus: TASK_STATUS;
  externalTaskStatus: TASK_STATUS;
  projectId: string;
  totalNumberOfWords?: number;
  disabled?: boolean; //frontend only field defaults to false/undefined
};

export type TDubInternal = TBaseDub & {
  dubTasks: TInternalTask[];
  internalChangeRequestCount?: number;
};

export type TInternalSourceFile = TBaseSourceFile & {
  faceMarking: TInternalTask | null;
  dubResponseList: TDubInternal[];
  totalNumberOfWords?: number;
};
