import { useTypedSelector } from "@/config/configureAppStore";
import {
  generalDateStringForClientSide,
  getRelativeTime,
  returnDateString
} from "@/utils/time";
import {
  CheckCircleRounded,
  DownloadRounded,
  ErrorRounded,
  MailRounded,
  WatchLaterRounded
} from "@mui/icons-material";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius * 3,
  padding: theme.spacing(3),
  width: "100%",
  textAlign: "center",
  gap: theme.spacing(0.5),
  justifyContent: "center",
  alignItems: "center"
}));

export const DubStatusContent = ({
  onDownload
}: {
  onDownload: () => void;
}): React.ReactNode => {
  const { fileId, projectId, dubId } = useParams();
  const dueBy = useTypedSelector((state) => state.dubPreview.dubDetails.dueBy);
  const dubStatus = useTypedSelector(
    (state) => state.dubPreview.dubDetails.dubStatus
  );
  const completedAt = useTypedSelector(
    (state) => state.dubPreview.dubDetails.completedAt
  );

  const isDueByPassed = useMemo(
    () => Boolean(!dueBy || Date.now() > new Date(dueBy).valueOf()),
    [dueBy]
  );

  const { dubVersion, qcDubVersions, qcEnabled } = useTypedSelector(
    (state) => state.dubPreview.dubDetails
  );

  const estimatedDeliveryComponent = useMemo(() => {
    const isNonQAVersion =
      !qcEnabled ||
      (qcEnabled &&
        dubVersion &&
        qcDubVersions &&
        !qcDubVersions.includes(dubVersion));

    if (isNonQAVersion) {
      return (
        <>
          <Typography
            variant="caption"
            sx={{
              color: "text.disabled"
            }}
          >
            Estimated time of completion
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: "text.secondary"
            }}
          >
            {getRelativeTime(dueBy as Date)}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography
          variant="caption"
          sx={{
            color: "text.disabled"
          }}
        >
          Estimated delivery by
        </Typography>
        <Typography
          variant="caption"
          sx={{
            color: "text.secondary"
          }}
        >
          {returnDateString(dueBy as Date, undefined, {
            year: "numeric",
            month: "long",
            day: "numeric"
          })}
        </Typography>
      </>
    );
  }, [dubVersion, dueBy, qcDubVersions, qcEnabled]);

  if (dubStatus === "FAILED" && isDueByPassed) {
    return (
      <Wrapper>
        <Stack gap={1} justifyContent={"center"} alignItems={"center"}>
          <ErrorRounded
            color="error"
            sx={{
              height: 36,
              width: 36
            }}
          />
          <Typography
            variant="h6"
            sx={{
              color: "error.main"
            }}
          >
            Error in processing
          </Typography>
          <Typography
            variant="caption"
            color={"text.primary"}
            textAlign={"center"}
          >
            We are unable to process the dub right now, please contact support
          </Typography>
        </Stack>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            href={`mailto:support.murfdub@murf.ai?subject=${encodeURI(
              "My dub has failed"
            )}&body=${encodeURI(
              `My dub for project: ${projectId}, fileId: ${fileId} and dub: ${dubId} has failed.\nPlease look into this.`
            )}`}
            color="secondary"
            target="_blank"
            startIcon={<MailRounded sx={{ height: 20, width: 20 }} />}
            size="medium"
          >
            <Typography variant="caption" color={"text.secondary"}>
              Contact support
            </Typography>
          </Button>
        </Box>
      </Wrapper>
    );
  }

  if (dubStatus === "COMPLETED") {
    return (
      <>
        <Wrapper>
          <CheckCircleRounded
            color="success"
            sx={{
              height: 36,
              width: 36
            }}
          />
          <Typography
            variant="h6"
            sx={{
              color: "success.main"
            }}
          >
            {qcEnabled ? "Delivered" : "Processing finished"}
          </Typography>
          {completedAt ? (
            <Typography mt={0.5} variant="body2" color={"text.secondary"}>
              Finished on{" "}
              {generalDateStringForClientSide(new Date(completedAt))}
            </Typography>
          ) : null}
        </Wrapper>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          onClick={onDownload}
          startIcon={<DownloadRounded />}
        >
          Download Dub{" "}
        </Button>
      </>
    );
  }

  return (
    <Wrapper>
      <WatchLaterRounded
        color="info"
        sx={{
          height: 36,
          width: 36,
          mb: 0.5
        }}
      />
      <Typography
        variant="h6"
        sx={{
          color: "info.main"
        }}
      >
        Processing changes
      </Typography>

      <Typography
        variant="caption"
        sx={{
          color: "text.primary"
        }}
      >
        We are processing your changes to deliver the dub
      </Typography>
      <Stack
        direction={"row"}
        gap={0.5}
        textAlign={"center"}
        justifyContent={"center"}
      >
        {dueBy && Date.now() < new Date(dueBy).valueOf() ? (
          estimatedDeliveryComponent
        ) : (
          <Typography
            variant="caption"
            sx={{
              color: "text.disabled"
            }}
          >
            Delayed. We'll notify you via email.
          </Typography>
        )}
      </Stack>
    </Wrapper>
  );
};
