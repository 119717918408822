import getIndexRedirect from "@/utils/getIndexRedirect";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import AuthLayout from "../../../components/layout/AuthLayout";
import AuthPattern1 from "@/components/layout/AuthLayout/AuthPattern1";
const Login = lazy(() => import("./Login"));
const Signup = lazy(() => import("./Signup"));
const BookDemo = lazy(() => import("./BookDemo"));
const VerifyEmail = lazy(() => import("./VerifyEmail"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const Logout = lazy(() => import("./Logout"));
const SSOLogin = lazy(() => import("./SSOLogin"));
const EmailVerified = lazy(() => import("./EmailVerified"));

export const authenticationRoutes: RouteObject = {
  path: `/auth`,
  element: <AuthLayout />,
  children: [
    getIndexRedirect("/auth/signup", true),
    {
      element: <AuthPattern1 />,
      children: [
        {
          path: "login",
          element: <Login />
        },
        {
          path: "signup",
          element: <Signup />
        },
        {
          path: "sso",
          element: <SSOLogin />
        },
        {
          path: "verify-email",
          element: <VerifyEmail />
        }
      ]
    },
    {
      path: "email-verified",
      element: <EmailVerified />
    },
    {
      path: "book-demo",
      element: <BookDemo />
    },

    {
      path: "reset-password",
      element: <ResetPassword />
    },
    {
      path: "logout",
      element: <Logout />
    }
  ]
};
