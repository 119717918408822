import useSWR from "swr";
import { clientHomeApi } from "@/features/clientHome/api";
import { IClientProjectHomeData } from "..";
import { setInitialHomeState } from "@/reducers/slices/clientHomeSlice/clientHomeSlice";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { useMemo } from "react";

const useClientHomeFetch = () => {
  const dispatch = useAppDispatch();

  const activeWorkspaceId = useTypedSelector(
    (state) => state.user.activeWorkspaceId
  );

  const key = useMemo(
    () => `workspace/${activeWorkspaceId}/all-projects`,
    [activeWorkspaceId]
  );

  const { data, error, isLoading } = useSWR(
    key,
    () => clientHomeApi.getAllProjects(),
    {
      revalidateOnFocus: false,
      onSuccess: (data) => {
        dispatch(setInitialHomeState(data?.data?.responseData));
      }
    }
  );

  return {
    data: data?.data?.responseData as IClientProjectHomeData[],
    isLoading,
    isError: error
  };
};

export default useClientHomeFetch;
