import { LANGUAGES } from "@/constants/languages";
import { IIssue } from "@/features/comments";
import { DUB_STATUS, TASK_STATUS } from "@/types/project";
import { QC_ACCESS_ROLES } from "@/types/user";

export interface DubPreviewDetails {
  dubId: string;
  fileId: string;
  workspaceId?: string;
  fileName: string;
  projectName: string;
  lastUpdated: number;
  sourceLocale: LANGUAGES;
  language: LANGUAGES;
  blocks: DubPreviewBlockType[];
  downloadUrl: string;
  previewUrl?: string;
  originalAudioUrl: string;
  qcEnabled: boolean;
  lipSyncEnabled?: boolean;
  dubVersion: string;
  dubVersions: string[];
  draftDubVersion?: string | null;
  dubStatus: DUB_STATUS;
  dubVersionInProgress?: string | null;
  completedAt?: Date;
  dueBy?: Date;
  externalTaskStatus?: TASK_STATUS;
  accessLevel?: QC_ACCESS_ROLES;
  originalFileDownloadUrl?: string;
  downloadZipUrl?: string;
  qcDubVersions?: string[];
  thumbnailUrl?: string;
  changeRequestData?: IChangeRequestData;
  changeRequestDraftVersion?: string;
  changeRequestCount?: number;
  internalChangeRequestCount?: number;
  canCreateIssue?: boolean;
  audioOnly?: boolean;
}

export interface DubPreviewBlockType {
  blockId: string;
  original: {
    speakerId: string;
    startTime: number;
    endTime?: number;
    duration: number;
    text: string;
    englishText: string;
  };
  translatedText: string;
  renderLength?: number;
}

export enum ChangeRequestStatus {
  CREATED = "CREATED",
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  IN_REVIEW = "IN_REVIEW",
  MARKED_AS_DONE = "MARKED_AS_DONE",
  DELIVERED = "DELIVERED",
  // @deprecated
  COMPLETED = "COMPLETED"
}

export interface IChangeRequestData {
  dubId: string;
  dubVersion: string;
  fileId: string;
  projectId: string;
  status: ChangeRequestStatus;
  externalRequest: boolean;
  workspaceId: string;
  issues: IIssue[];
  submittedAt?: Date;
}
