export const PROFILE_ICON_PALETTE = [
  "#84BB9C",
  "#FCC1A8",
  "#B9B8FF",
  "#F49D9D",
  "#FFBA75",
  "#FFDB6F",
  "#D9EBA2",
  "#B5DD8D",
  "#86AF78",
  "#62E9E9",
  "#F49D9D",
];

export function stringToColor(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).slice(-2);
  }
  return colour;
}

export function stringToColorFromPalette(str: string, palette: string[] = []) {
  if (!str?.length) {
    return "#000000";
  }
  if (palette?.length === 0) {
    return stringToColor(str);
  }
  const hash = [...str]
    .map((ch) => ch.charCodeAt(0))
    .reduce((prev, curr) => {
      return prev + curr;
    }, 0);

  const color = hash % palette.length;

  return palette[color];
}

export function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColorFromPalette(name, PROFILE_ICON_PALETTE),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export function invertColor(hex: string, bw: boolean) {
  function padZero(str: string, len?: number) {
    len = len || 2;
    const zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  }

  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    console.log(hex);
    throw new Error("Invalid HEX color.");
  }
  const r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  }
  const r_str: string = (255 - r).toString(16);
  const g_str: string = (255 - g).toString(16);
  const b_str: string = (255 - b).toString(16);
  return "#" + padZero(r_str) + padZero(g_str) + padZero(b_str);
}
