import {
  Box,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import { useState } from "react";
import { UserAvatar } from "..";
import { UnfoldMoreRounded } from "@mui/icons-material";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { updateActiveWorkspaceId } from "@/reducers/slices/userSlice/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { userThunks } from "@/reducers/thunks/user";
import { setWorkspaceProps } from "@/utils/mixpanel";

const WorkspaceSelector = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const workspaces = useTypedSelector((state) => state.user.linkedWorkspaces);
  const activeWorkspaceId = useTypedSelector(
    (state) => state.user.activeWorkspaceId
  );
  const [selectedIndex, setSelectedIndex] = useState(
    window.activeWorkspaceId
      ? workspaces.findIndex((_) => _.workspaceId === window.activeWorkspaceId)
      : 0
  );
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const dispatch = useAppDispatch();

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setAnchorEl(null);
    if (activeWorkspaceId === workspaces[index].workspaceId) {
      return;
    }
    setSelectedIndex(index);
    setAnchorEl(null);
    dispatch(
      updateActiveWorkspaceId({
        workspaceId: workspaces[index].workspaceId
      })
    );
    dispatch(userThunks.fetchWorkspaceState()).then((res) => {
      if (res.meta.requestStatus !== "rejected") {
        setWorkspaceProps(res.payload);
      }
    });
    if (!location.pathname.startsWith("/home")) {
      navigate("/home");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!workspaces?.length) {
    return null;
  }

  return (
    <Box
      sx={{
        width: 240
      }}
    >
      <List component="nav" sx={{ p: 0.5 }}>
        <ListItemButton
          aria-haspopup="listbox"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          color="secondary"
          sx={{
            py: 0.5,
            borderRadius: 2
          }}
          selected={open}
        >
          <ListItemAvatar sx={{ minWidth: 48 }}>
            <UserAvatar
              displayName={workspaces[selectedIndex]?.displayName}
              avatarUrl={workspaces[selectedIndex]?.profileImage || ""}
              alt=""
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="subtitle1" noWrap>
                {workspaces[selectedIndex]?.displayName}
              </Typography>
            }
            sx={{
              my: 0,
              minHeight: 48,
              display: "flex",
              alignItems: "center"
            }}
          />
          <Icon
            sx={{
              ml: 1
            }}
          >
            <UnfoldMoreRounded />
          </Icon>
        </ListItemButton>
      </List>

      <Menu
        id="workspace-selector"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
          sx: {
            width: 280
          }
        }}
        sx={{
          marginTop: "2px"
        }}
      >
        {workspaces.map((option, index) => (
          <MenuItem
            key={option.workspaceId}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            sx={{
              p: 0
            }}
          >
            <ListItem
              button
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickListItem}
            >
              <ListItemAvatar>
                <UserAvatar
                  displayName={option.displayName}
                  avatarUrl={option.profileImage || ""}
                  alt=""
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle1"
                    noWrap
                    textOverflow={"ellipsis"}
                  >
                    {option.displayName}
                  </Typography>
                }
              />
            </ListItem>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default WorkspaceSelector;
