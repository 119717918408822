import { PlayButtonBase, PlayButtonBaseProps } from ".";
import { useMediaPlayer, playPause } from "@/features/mediaPlayer";

interface PlayButtonProps extends PlayButtonBaseProps {
  audioPlayerId: string;
  audioSrc?: string;
}

export function PlayButton({
  audioPlayerId,
  audioSrc,
  ...props
}: PlayButtonProps) {
  const { state, dispatch: mediaPlayerDispatch } =
    useMediaPlayer(audioPlayerId)!;
  const computedIsPlaying = props.isPlaying ?? state?.isPlaying;

  function play() {
    playPause(mediaPlayerDispatch, {
      mediaUrl: audioSrc ? audioSrc : state.mediaUrl,
      playState: true
    });
  }

  function pause() {
    playPause(mediaPlayerDispatch, {
      mediaUrl: audioSrc ? audioSrc : state.mediaUrl,
      playState: false
    });
  }

  return (
    <PlayButtonBase
      {...props}
      isPlaying={computedIsPlaying}
      play={play}
      pause={pause}
    />
  );
}
