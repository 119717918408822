import { IComment, ICommentObj, ICommentsSlice } from "@/features/comments";
import { issueCommentThunk } from "@/reducers/thunks/issueCommentThunk";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const commentsSlice = createSlice({
  name: "commentsSlice",
  initialState: {
    fetchState: {
      hasLoadedOnce: null,
      isLoading: null,
      error: undefined
    },
    allComments: {}
  } as ICommentsSlice,
  reducers: {
    setCommentsOfIssueId: (
      state,
      action: PayloadAction<{
        issueId: string;
        comments: IComment[];
      }>
    ) => {
      const { issueId, comments } = action.payload;
      if (Array.isArray(comments) && issueId?.length) {
        const _temp: ICommentObj = {};
        comments.forEach((comment) => {
          _temp[comment.commentId] = comment;
        });
        state.allComments[issueId] = _temp;
      }
    },
    addCommentToIssue: (
      state,
      action: PayloadAction<{
        issueId: string;
        comment: IComment;
      }>
    ) => {
      const { issueId, comment } = action.payload;
      if (state.allComments[issueId]) {
        state.allComments[issueId][comment.commentId] = comment;
      } else {
        state.allComments[issueId] = {
          [comment.commentId]: comment
        };
      }
    },
    editComment: (
      state,
      action: PayloadAction<{
        issueId: string;
        editedComment: Partial<IComment>;
      }>
    ) => {
      const { issueId, editedComment } = action.payload;
      if (!editedComment.commentId) {
        return;
      }
      if (state.allComments[issueId]?.[editedComment.commentId]) {
        state.allComments[issueId][editedComment.commentId] = {
          ...state.allComments[issueId][editedComment.commentId],
          ...editedComment
        };
      }
    },
    removeComment: (
      state,
      action: PayloadAction<{
        issueId: string;
        commentId: string;
      }>
    ) => {
      const { issueId, commentId } = action.payload;
      if (issueId && commentId && state.allComments[issueId]?.[commentId]) {
        delete state.allComments[issueId][commentId];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        issueCommentThunk.getCommentsByIssueId.pending,
        (state, data) => {
          const issueId = data.meta.arg.issueId;
          state.fetchState.isLoading = issueId;
          if (state.fetchState.hasLoadedOnce !== issueId) {
            state.fetchState.hasLoadedOnce = null;
          }
        }
      )
      .addCase(
        issueCommentThunk.getCommentsByIssueId.fulfilled,
        (state, data) => {
          const res = data.payload;
          if (res.responseCode === "SUCCESS") {
            const { issueId } = data.meta.arg;
            const { comments } = res.responseData;
            state.fetchState.isLoading = null;
            state.fetchState.hasLoadedOnce = issueId;
            if (Array.isArray(comments)) {
              state.allComments[issueId] = comments.reduce((obj, comment) => {
                obj[comment.commentId] = comment;
                return obj;
              }, {});
            }
          }
        }
      )
      .addCase(issueCommentThunk.addComment.fulfilled, (state, data) => {
        const res = data.payload;
        if (res.responseCode === "SUCCESS") {
          const { issueId, ...comment } = res.responseData;
          if (state.allComments[issueId]) {
            state.allComments[issueId][comment.commentId] = {
              ...comment,
              issueId
            };
          } else {
            state.allComments[issueId] = {
              [comment.commentId]: comment
            };
          }
        }
      })
      .addCase(issueCommentThunk.editComment.fulfilled, (state, data) => {
        const res = data.payload;
        if (res.responseCode === "SUCCESS") {
          const { issueId, ...comment } = res.responseData;
          const commentId = comment.commentId;
          if (state.allComments[issueId]?.[commentId]) {
            state.allComments[issueId][commentId] = {
              ...comment,
              issueId
            };
          }
        }
      })
      .addCase(issueCommentThunk.deleteComment.fulfilled, (state, data) => {
        const res = data.payload;
        if (res.responseCode === "SUCCESS") {
          const { issueId, commentId } = data.meta.arg;
          if (state.allComments[issueId]?.[commentId]) {
            delete state.allComments[issueId][commentId];
          }
        }
      });
  }
});

export const {
  setCommentsOfIssueId,
  addCommentToIssue,
  editComment,
  removeComment
} = commentsSlice.actions;

export default commentsSlice.reducer;
