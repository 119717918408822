import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const DubPreviewInternal = lazy(() => import("./DubPreviewInternal"));

export const dubPreviewInternalRoutes: RouteObject[] = [
  {
    path: `project/:projectId/file/:fileId/dub/:dubId/preview`,
    element: <DubPreviewInternal />
  }
];
