import { LANGUAGES } from "@/constants/languages";
import { IClientProjectData, IFileData } from "@/features/clientProjectDetails";
import { clientProjectThunk } from "@/reducers/thunks/clientProject";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectName: "",
  sourceLocale: LANGUAGES["ENGLISH"],
  targetLocales: [],
  qualityCheckEnabled: undefined,
  lipSyncEnabled: false,
  currentStep: 0,
  numberOfVideos: 0

  // projectName: "New Project 12",
  // sourceLocale: "ENGLISH",
  // targetLocales: [],
  // qualityCheckEnabled: true,
  // lipSyncEnabled: true,
  // currentStep: 1,
  // projectId: "P017001365395614K6"

  // projectName: "New Project 13",
  // sourceLocale: "ENGLISH",
  // targetLocales: ["HINDI", "FRENCH", "ITALIAN"],
  // qualityCheckEnabled: true,
  // lipSyncEnabled: true,
  // currentStep: 4,
  // projectId: "P017002088857001TR",
  // numberOfVideos: 4
} as IClientProjectData;

const clientProjectPopupSlice = createSlice({
  name: "clientProjectPopup",
  initialState,
  reducers: {
    incrementStep: (state, action) => {
      state.currentStep += action.payload;
      if (state.currentStep < 0) {
        state.currentStep = 0;
      }
    },
    setDraftState: (state, action) => {
      state.draftState = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setProjectName: (state, action) => {
      state.projectName = action.payload;
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    setSourceLanguage: (state, action) => {
      state.sourceLocale = action.payload;
    },
    setDestinationLanguage: (state, action) => {
      state.targetLocales = action.payload;
    },
    updateDestinationLanguage: (state, action) => {
      if (
        state.targetLocales &&
        !state.targetLocales.includes(action.payload)
      ) {
        state.targetLocales = [...state.targetLocales, action.payload];
      }
    },
    removeDestinationLanguage: (state, action) => {
      const targetLocales = state.targetLocales ? [...state.targetLocales] : [];
      const index = targetLocales.indexOf(action.payload);
      if (index > -1) {
        targetLocales.splice(index, 1);
      }

      state.targetLocales = targetLocales;
    },
    toggleLipSync: (state) => {
      state.lipSyncEnabled = !state.lipSyncEnabled;
    },
    setLipSync: (state, action) => {
      state.lipSyncEnabled = action.payload;
    },
    setQualityCheck: (state, action) => {
      state.qualityCheckEnabled = action.payload;
    },
    toggleQualityCheck: (state) => {
      state.qualityCheckEnabled = !state.qualityCheckEnabled;
    },
    putInitialState: (state, action) => {
      state = { ...action.payload };
      return state;
    },
    setNumberOfVideos: (state, action) => {
      state.numberOfVideos = action.payload;
    },
    updateSourceFileResponseList: (
      state,
      action: PayloadAction<Partial<IFileData>>
    ) => {
      const { sourceFileId, ...newData } = action.payload;
      if (sourceFileId && state.sourceFileResponseList?.[sourceFileId]) {
        state.sourceFileResponseList[sourceFileId] = {
          ...state.sourceFileResponseList[sourceFileId],
          ...newData
        };
      }
    },
    resetState: () => {
      return initialState;
    },
    removeFileFromPopUp: (
      state,
      action: PayloadAction<{
        fileId: string;
        projectId: string;
      }>
    ) => {
      const { fileId, projectId } = action.payload;
      if (fileId?.length && projectId?.length) {
        if (state.projectId === projectId) {
          delete state.sourceFileResponseList?.[fileId];
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(clientProjectThunk.updateProject.fulfilled, (state, action) => {
        const project = action.payload?.data.responseData;
        if (state.projectId === project.projectId) {
          state = {
            ...state,
            ...project
          };
        }
        return state;
      })
      .addCase(
        clientProjectThunk.duplicateProject.fulfilled,
        (state, action) => {
          const project = action.payload?.data.responseData.project;
          state = {
            ...state,
            ...project
          };
          return state;
        }
      );
  }
});

export const {
  setProjectName,
  setDraftState,
  setSourceLanguage,
  setDestinationLanguage,
  updateDestinationLanguage,
  toggleLipSync,
  setQualityCheck,
  toggleQualityCheck,
  incrementStep,
  setCurrentStep,
  setProjectId,
  putInitialState,
  setNumberOfVideos,
  removeDestinationLanguage,
  updateSourceFileResponseList,
  resetState,
  removeFileFromPopUp,
  setLipSync
} = clientProjectPopupSlice.actions;

export default clientProjectPopupSlice.reducer;
