import { Dialog } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import useReOpenDub from "../../hooks/useReOpenDub";
import ReOpenDubContent from "../ReOpenDubContent";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { setMarkAsDoneDubPreviewDialog } from "@/reducers/slices/dialogSlice/dialogSlice";
// import { dubPreviewInternalApi } from "../../api";
// import { qcMarkAsDone } from "@/reducers/slices/dubPreviewSlice/dubPreviewSlice";

export const ReOpenDubPopup = ({
  dubId
}: {
  dubId: string;
}): React.ReactNode => {
  const { fileId, projectId } = useParams();
  const dispatch = useAppDispatch();
  const isOpen = useTypedSelector(
    (state) => state.dialog.markAsDoneDubPreviewDialog.open
  );
  const setIsOpen = (open: boolean) => {
    dispatch(setMarkAsDoneDubPreviewDialog(open));
  };
  const workspaceId = useTypedSelector(
    (state) => state.dubPreview.dubDetails.workspaceId
  );
  // const dubVersionForChangeRequest = useTypedSelector(
  //   (state) =>
  //     state.dubPreview.dubDetails.changeRequestDraftVersion ||
  //     state.dubPreview.dubDetails.dubVersion
  // );

  const {
    assignedUsers,
    setAssignedUsers,
    reopeningDub,
    handleReOpenDub,
    isFetchingPreviousData,
    relatedTags
    // allIssues,
    // dubDetails
    // canReopenDub
  } = useReOpenDub({
    dubId,
    projectId: projectId!,
    setIsOpen
  });

  const _handleReOpenDub = async () => {
    if (workspaceId && dubId && fileId) {
      // trackUserFlowEvent(USER_FLOW_EVENTS.MARK_AS_DONE_QA, {});
      // setMarkFlow({ status: STATUS.LOADING });
      // await dubPreviewInternalApi
      //   .qcMarkAsDone({ dubId, workspaceId, fileId })
      //   .then((res) => {
      //     // setMarkFlow({ status: STATUS.SUCCESS });
      //     dispatch(qcMarkAsDone());
      //     // dispatch(
      //     //   issueCommentThunk.getChangeRequestData({
      //     //     dubId,
      //     //     dubVersion: dubVersionForChangeRequest,
      //     //     workspaceId
      //     //   })
      //     // );
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     // setMarkFlow({ status: STATUS.ERROR });
      //     // showError(err?.extra || UNKNOWN_ERROR_MESSAGE);
      //     console.debug("[ERR] qcMarkAsDone", { err });
      //   });
      console.debug("calling handleReOpenDub");
      handleReOpenDub({
        isInternal: true // @TODO
      });
    }
  };

  // if (
  //   allIssues?.length === 0 ||
  //   dubDetails.changeRequestData?.status !== ChangeRequestStatus.SUBMITTED
  //   // dubDetails.dubStatus !== DUB_STATUS.COMPLETED
  // ) {
  //   return null;
  // }

  return (
    <>
      <Dialog
        PaperProps={{ elevation: 0 }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="re-open-dub-modal"
        fullWidth
        maxWidth="xs"
        id="re-open-dub-modal"
      >
        <ReOpenDubContent
          isFetchingPreviousData={isFetchingPreviousData}
          relatedTags={relatedTags}
          assignedUsers={assignedUsers}
          handleReOpenDub={_handleReOpenDub}
          setAssignedUsers={setAssignedUsers}
          reopeningDub={reopeningDub}
          setIsOpen={setIsOpen}
          variant="popup"
          paperProps={{
            elevation: 1,
            sx: { borderRadius: 2 }
          }}
        />
      </Dialog>
    </>
  );
};
