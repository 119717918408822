import { lazy } from "react";
import { RouteObject } from "react-router-dom";
const Workspaces = lazy(() => import("./Workspaces"));

export const workspaceRoutes: RouteObject[] = [
  {
    path: "workspaces",
    element: <Workspaces />
  }
];
