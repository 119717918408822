import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorCheck } from "@/utils/reduxUtils";
import {
  FetchTranslationVoDetailsParams,
  SyncBGMParams,
  translationVoApi
} from "@/features/scriptStudio";
import {
  UpdateBlocksPayload,
  UpdateTranscriptionBlockPayload
} from "../slices/projectUtilitySlice/types";
import { UpdateSpeakerPayload } from "../slices/currentScriptProjectSlice/types";
import {
  pronunciationApi,
  SavePronunciationsBulkParams
} from "@/features/scriptStudio/api/pronunciation";

export const translationVoThunks = {
  fetchTranslationVoDetails: createAsyncThunk(
    "fetchTranslationVoDetails",
    async (
      {
        dubId,
        fileId,
        workspaceId,
        dubVersion
      }: FetchTranslationVoDetailsParams,
      thunkAPI
    ) => {
      return errorCheck(async () => {
        const response = await translationVoApi.fetchDetails({
          dubId,
          fileId,
          workspaceId,
          dubVersion
        });
        return response.data.responseData;
      }, thunkAPI);
    }
  ),
  updateBlocks: createAsyncThunk(
    "updateBlocks",
    async (props: UpdateBlocksPayload) => {
      return props;
    }
  ),
  updateTranscriptionBlock: createAsyncThunk(
    "updateTranscriptionBlocks",
    async (props: UpdateTranscriptionBlockPayload) => {
      return props;
    }
  ),
  updateSpeaker: createAsyncThunk(
    "updateSpeaker",
    async (props: UpdateSpeakerPayload) => props
  ),
  savePronunciations: createAsyncThunk(
    "savePronunciations",
    async ({
      dubId,
      fileId,
      workspaceId,
      dubVersion,
      pronunciations,
      newPronunciation
    }: SavePronunciationsBulkParams) => {
      const response = await pronunciationApi.savePronunciationBulk({
        dubId,
        fileId,
        workspaceId,
        dubVersion,
        pronunciations,
        newPronunciation
      });

      return response.data.responseData.pronunciations;
    }
  ),
  resetState: createAsyncThunk("resetState", async () => {
    return;
  }),
  muteBGM: createAsyncThunk("muteBGM", async (payload: SyncBGMParams) => {
    const response = await translationVoApi.muteBGM(payload);
    return response.data.responseData;
  })
};
