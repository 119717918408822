import useUppy from "@/features/createProject/uppyFactory/hooks/useUppy";
import { Outlet } from "react-router-dom";
import LoaderWrapper from "../LoaderWrapper";
import useConnectivityHook from "@/hooks/useConnectivityHook";
import { withAuthentication } from "@/lib/firebase";
import { useTypedSelector } from "@/config/configureAppStore";
import { STATUS } from "@/constants/status";
import SentryWrapper from "@/lib/sentry/SentryWrapper";
import React, { Suspense, useCallback, useEffect } from "react";
import Hotjar from "@hotjar/browser";
import { useAuth } from "@/lib/firebase/firebaseHooks";
import useCreditsPrompt from "@/hooks/useCreditsPrompt";
import useAnalytics from "@/hooks/useAnalytics";

const CreditsPrompt = React.lazy(() =>
  import("@/features/credits").then((module) => ({
    default: module.CreditsPrompt
  }))
);

const AppLayout = () => {
  const appInitializationStatus = useTypedSelector(
    (state) => state.globalState.appInitializationStatus
  );

  const { creditsDialog } = useTypedSelector((state) => state.dialog);

  const auth = useAuth();

  useUppy();
  useConnectivityHook();
  useCreditsPrompt();
  useAnalytics();

  const initializeHotjar = useCallback(() => {
    Hotjar.init(
      import.meta.env.VITE_MURF_HOTJAR_SITE_ID,
      import.meta.env.VITE_MURF_HOTJAR_VERSION
    );
  }, []);

  useEffect(() => {
    initializeHotjar();
    return () => {};
  }, [initializeHotjar]);

  useEffect(() => {
    if (auth) {
      const identifyHotjarUser = () => {
        Hotjar.identify(auth.uid, {
          display_name: auth.displayName,
          email: auth.email
        });
      };
      identifyHotjarUser();
    }
    return () => {};
  }, [auth]);

  return (
    <LoaderWrapper loading={appInitializationStatus !== STATUS.SUCCESS}>
      <SentryWrapper>
        <Outlet />
        {creditsDialog.open && (
          <Suspense fallback={<div />}>
            <CreditsPrompt />
          </Suspense>
        )}
      </SentryWrapper>
    </LoaderWrapper>
  );
};

const AppLayoutAuth = withAuthentication(AppLayout);

export default AppLayoutAuth;
