import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const ClientProjectDetails = lazy(() => import("./ClientProjectDetails"));

export const clientProjectDetailsRoute: RouteObject[] = [
  {
    path: `project/:projectId`,
    element: <ClientProjectDetails />
  }
];
