import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "@/constants/status";
import {
  AudioData,
  ScriptDubData,
  Speaker
} from "@/features/scriptStudio/types";
import { QC_ACCESS_ROLES } from "@/types/user";
import { DubPronunciation } from "@/features/scriptStudio/types/pronunciations";
import { LANGUAGES } from "@/constants/languages";
import { translationVoThunks } from "@/reducers/thunks/translationVo";
import { PLAYER_MODE, UpdateSpeakerPayload } from "./types";
import { separateNumericPart } from "@/utils/misc";
import { UNKNOWN_ERROR_MESSAGE } from "@/constants/errors";
import { RangeData } from "@/features/editor";
import { updateVoiceNames } from "@/features/scriptStudio/utils";
import { TASK_STATUS } from "@/types/project";

interface CurrentScriptProjectSliceState {
  currentProject: {
    dubId: string;
    dubVersion: string;
    blocksOrder: string[];
    speakers: { [key: string]: Speaker };
    workspaceName: string;
    projectName: string;
    fileName: string;
    projectId: string;
    progress: number;
    dueDate: number;
    bgMusicMuted: boolean;
    originalAudioUrl: string;
    sourceLanguage: LANGUAGES;
    targetLanguage: LANGUAGES;
    volume: number;
    translationMarkAsDone: boolean;
    voiceOverMarkAsDone: boolean;
    originalBackgroundNoise: AudioData;
    originalCleanSpeech: AudioData;
    audioOnly?: boolean;
    videoWithCleanSpeech: string;
    maxRenderVersion: number;
    qcEnabled: boolean;
    externalTaskStatus?: TASK_STATUS;
    newTranslationApiEnabled: boolean;
  };
  pronunciations: DubPronunciation[];
  speakerPropertyQueue: string[];
  rangeData: RangeData | null;
  selectedBlocks: {
    blockIds: { [key: string]: string };
    size: number;
  };
  translationSuggestions: { [key: string]: string[] };
  saveInProgress: boolean;
  dubApiStatus: STATUS;
  apiFailureReason: string;
  accessLevel?: QC_ACCESS_ROLES;
  workspaceId: string;
  fileId: string;
  playerMode: PLAYER_MODE;
  blockPlayBreakPoint: number | null;
  showPip: boolean;
  pipShownOnFirstPlay: boolean;
  readOnly: boolean;
  searchText?: string;
  searchInTranscription?: boolean;
  showReferenceLanguage?: boolean;
}

const initialState: CurrentScriptProjectSliceState = {
  currentProject: {
    dubId: "",
    dubVersion: "",
    blocksOrder: [],
    speakers: {},
    workspaceName: "",
    projectName: "",
    fileName: "",
    projectId: "",
    progress: 0,
    dueDate: 0,
    bgMusicMuted: false,
    originalAudioUrl: "",
    sourceLanguage: LANGUAGES.ENGLISH,
    targetLanguage: LANGUAGES.FRENCH,
    volume: 0,
    translationMarkAsDone: false,
    voiceOverMarkAsDone: false,
    originalBackgroundNoise: {
      audioLength: 0,
      audioUrl: ""
    },
    originalCleanSpeech: {
      audioLength: 0,
      audioUrl: ""
    },
    videoWithCleanSpeech: "",
    maxRenderVersion: 0,
    qcEnabled: false,
    newTranslationApiEnabled: false
  },
  pronunciations: [],
  speakerPropertyQueue: [],
  rangeData: null,
  selectedBlocks: { blockIds: {}, size: 0 },
  translationSuggestions: {},
  saveInProgress: false,
  dubApiStatus: STATUS.IDLE,
  apiFailureReason: "",
  workspaceId: "",
  fileId: "",
  playerMode: PLAYER_MODE.TRANSLATION,
  blockPlayBreakPoint: null,
  showPip: false,
  pipShownOnFirstPlay: false,
  readOnly: false
};

export const currentScriptProjectSlice = createSlice({
  name: "currentScriptProject",
  initialState,
  reducers: {
    setDubId: (state, action) => {
      state.currentProject.dubId = action.payload;
    },
    setReadOnlyMode: (state, action: PayloadAction<boolean>) => {
      state.readOnly = action.payload;
    },
    setRangeData: (state, action) => {
      state.rangeData = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setSearchInTranscription: (state, action: PayloadAction<boolean>) => {
      state.searchInTranscription = action.payload;
    },
    selectBlock: (state, action) => {
      state.selectedBlocks.blockIds[action.payload] = action.payload;
      state.selectedBlocks.size++;
    },
    deSelectBlock: (state, action) => {
      delete state.selectedBlocks.blockIds[action.payload];
      state.selectedBlocks.size--;
    },
    selectAllBlock: (state, action: PayloadAction<string[] | undefined>) => {
      const newObject: { [key: string]: string } = {};
      const blockIds = action.payload;
      let size = 0;
      if (blockIds) {
        blockIds.forEach((blockId) => {
          newObject[blockId] = blockId;
          size++;
        });
      } else {
        state.currentProject.blocksOrder.forEach((blockId) => {
          newObject[blockId] = blockId;
          size++;
        });
      }

      state.selectedBlocks = {
        blockIds: newObject,
        size
      };
    },
    deSelectAllBlock: (state) => {
      // if blocks are selected then removing them
      if (state.selectedBlocks.size) {
        state.selectedBlocks = {
          blockIds: {},
          size: 0
        };
      }
    },
    addTranslationSuggestion: (
      state,
      action: PayloadAction<{
        blockId: string;
        suggestion: string[];
      }>
    ) => {
      const { blockId, suggestion } = action.payload;
      state.translationSuggestions[blockId] = suggestion;
    },
    cleanSpeakerPropertyState: (
      state,
      action: PayloadAction<{ speakerIds: string[] }>
    ) => {
      if (action && action.payload) {
        const speakerIds = action.payload.speakerIds;
        if (speakerIds.length) {
          state.speakerPropertyQueue = state.speakerPropertyQueue.filter(
            (id) => !speakerIds.includes(id)
          );
        } else {
          state.speakerPropertyQueue = [];
        }
      } else {
        state.speakerPropertyQueue = [];
      }
    },
    setSaveInProgress: (state, action: PayloadAction<boolean>) => {
      state.saveInProgress = action.payload;
    },
    setPlayerMode: (state, action: PayloadAction<PLAYER_MODE>) => {
      state.playerMode = action.payload;
    },
    setBlockPlayBreakPoint: (state, action: PayloadAction<number | null>) => {
      state.blockPlayBreakPoint = action.payload;
    },
    setShowPip: (
      state,
      action: PayloadAction<{ show: boolean; pipShownOnFirstPlay?: boolean }>
    ) => {
      state.showPip = action.payload.show;
      if (action.payload.pipShownOnFirstPlay !== undefined) {
        state.pipShownOnFirstPlay = action.payload.pipShownOnFirstPlay;
      }
    },
    setShowReferenceLanguage: (state, action: PayloadAction<boolean>) => {
      state.showReferenceLanguage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        translationVoThunks.fetchTranslationVoDetails.pending,
        (state) => {
          // clearing virtual list cache if dub details is updated
          if (window._vCache) {
            window._vCache?.clearAll();
          }
          state.dubApiStatus = STATUS.LOADING;
        }
      )
      .addCase(
        translationVoThunks.fetchTranslationVoDetails.fulfilled,
        (state, action) => {
          const data: ScriptDubData = action.payload;

          const speakersMap = updateVoiceNames(data.speakers).reduce(
            (map, speaker) => {
              map[speaker.speakerId] = {
                ...speaker,
                name: separateNumericPart(speaker.name)[0]
              };
              return map;
            },
            {} as { [key: string]: Speaker }
          );

          state.currentProject = {
            dubId: data.dubId,
            dubVersion: data.dubVersion,
            blocksOrder: data.blocks.map((block) => {
              return block.blockId;
            }),
            speakers: speakersMap,
            workspaceName: data.workspaceName,
            projectName: data.projectName,
            fileName: data.fileName,
            projectId: data.projectId,
            progress: data.progress,
            dueDate: data.dueDate,
            originalAudioUrl: data.originalAudioUrl,
            sourceLanguage: data.sourceLanguage,
            targetLanguage: data.targetLanguage,
            volume: data.volume,
            translationMarkAsDone: data.translationMarkAsDone,
            voiceOverMarkAsDone: data.voiceOverMarkAsDone,
            originalBackgroundNoise: data.originalBackgroundNoise,
            originalCleanSpeech: data.originalCleanSpeech,
            audioOnly: data.audioOnly,
            videoWithCleanSpeech:
              data.videoWithCleanSpeech || data.originalCleanSpeech.audioUrl,
            maxRenderVersion: data.maxRenderVersion,
            qcEnabled: data.qcEnabled,
            externalTaskStatus: data.externalTaskStatus,
            newTranslationApiEnabled: data.newTranslationApiEnabled,
            bgMusicMuted: data.bgMusicMuted
          };
          state.pronunciations = data.pronunciations || [];
          state.workspaceId = action.meta.arg.workspaceId;
          state.fileId = action.meta.arg.fileId;
          state.dubApiStatus = STATUS.SUCCESS;
          // if qcEnabled checking access level in data else user has access to everything
          if (data.qcEnabled) {
            state.accessLevel = data.accessLevel;
          } else state.accessLevel = QC_ACCESS_ROLES.TRANSLATION_VOICEOVER;
          state.readOnly = data.readOnly;
        }
      )
      .addCase(
        translationVoThunks.fetchTranslationVoDetails.rejected,
        (state, action: PayloadAction<any>) => {
          state.dubApiStatus = STATUS.ERROR;
          state.apiFailureReason =
            action.payload?.extra || UNKNOWN_ERROR_MESSAGE;
        }
      )
      .addCase(
        translationVoThunks.updateSpeaker.fulfilled,
        (state, action: PayloadAction<UpdateSpeakerPayload>) => {
          const payload = action.payload;
          const speaker = payload.speaker;
          if (speaker.speakerId) {
            state.currentProject.speakers[speaker.speakerId] = {
              ...state.currentProject.speakers[speaker.speakerId],
              ...payload.speaker
            };

            // after updating if sync is needed
            if (payload.needToSync) {
              state.speakerPropertyQueue = [
                ...new Set([...state.speakerPropertyQueue, speaker.speakerId])
              ];
            }
          }
        }
      )
      .addCase(
        translationVoThunks.savePronunciations.fulfilled,
        (state, action) => {
          state.pronunciations = action.payload || [];
        }
      )
      .addCase(translationVoThunks.resetState.fulfilled, () => {
        // state cleanup (route change)
        return initialState;
      })
      .addCase(translationVoThunks.muteBGM.fulfilled, (state, action) => {
        const { isMuted } = action.payload;
        state.currentProject.bgMusicMuted = isMuted;
      });
  }
});

export const {
  setDubId,
  setReadOnlyMode,
  setRangeData,
  selectBlock,
  deSelectBlock,
  selectAllBlock,
  deSelectAllBlock,
  addTranslationSuggestion,
  cleanSpeakerPropertyState,
  setSaveInProgress,
  setPlayerMode,
  setBlockPlayBreakPoint,
  setShowPip,
  setSearchText,
  setSearchInTranscription,
  setShowReferenceLanguage
} = currentScriptProjectSlice.actions;

export default currentScriptProjectSlice.reducer;
