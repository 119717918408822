import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const ClientHome = lazy(() => import("./ClientHome"));

export const clientHomeRoutes: RouteObject[] = [
  {
    path: `home`,
    element: <ClientHome />
  }
];
