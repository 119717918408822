export const CLIENT_PROJECT_DETAILS_QUERY_PARAMS = {
  WORKSPACE_ID: "workspaceId",
  SEARCH: "search",
  FILE_ID: "fileId"
};

export const BASE_VERSION = "V1";

export const FILE_THUMBNAIL_HEIGHT = 60;
export const FILE_THUMBNAIL_WIDTH = 88;

export const FILE_NAME_WIDTH = 376;
