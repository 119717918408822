import { TASK_STATUS, TASK_TYPE } from "@/types/project";
import MicRounded from "@mui/icons-material/MicRounded";
import FaceRounded from "@mui/icons-material/FaceRounded";
import TranslateRounded from "@mui/icons-material/TranslateRounded";
import CheckCircleRounded from "@mui/icons-material/CheckCircleRounded";
import { convertArrayToMap } from "@/utils/json";

type TaskMeta = {
  title: string;
  icon: JSX.Element;
  task: TASK_TYPE;
};

export const TaskMetaData: TaskMeta[] = [
  {
    title: "Translation",
    icon: <TranslateRounded />,
    task: TASK_TYPE.TRANSLATION
  },
  {
    title: "Voiceover",
    icon: <MicRounded />,
    task: TASK_TYPE.VOICE_OVER
  },
  {
    title: "Face Marking",
    icon: <FaceRounded />,
    task: TASK_TYPE.FACE_MARKING
  },
  {
    title: "Quality Assurance",
    icon: <CheckCircleRounded />,
    task: TASK_TYPE.MEDIA_MERGE
  }
];

export const TaskMetaDataMap = convertArrayToMap(TaskMetaData, "task");

type TaskStatusMetaData = {
  title: string;
  taskType: TASK_STATUS;
};

export const TaskStatusMetaData: TaskStatusMetaData[] = [
  {
    taskType: TASK_STATUS.UPCOMING,
    title: "Upcoming"
  },
  {
    taskType: TASK_STATUS.TODO,
    title: "To Do"
  },
  {
    taskType: TASK_STATUS.ONGOING,
    title: "In-Progress"
  },
  {
    taskType: TASK_STATUS.COMPLETED,
    title: "Done"
  },
  {
    taskType: TASK_STATUS.FAILED,
    title: "Failed"
  }
];

export const TaskStatusMetaDataMap = convertArrayToMap(
  TaskStatusMetaData,
  "taskType"
);
