import LoaderWrapper from "@/components/layout/LoaderWrapper";
import { Suspense } from "react";
import { store } from "@/config/configureAppStore";
import { Provider as ReduxProvider } from "react-redux";
import MurfThemeProvider from "./MurfThemeProvider";

type AppProviderProps = {
  children: React.ReactNode;
};

const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ReduxProvider store={store}>
      <MurfThemeProvider>
        <Suspense fallback={<LoaderWrapper loading />}>{children}</Suspense>
      </MurfThemeProvider>
    </ReduxProvider>
  );
};

export default AppProvider;
