import { TypographyOptions } from "@mui/material/styles/createTypography";
import defaultTheme from "./defaultTheme";

const getTypographyOptions = (): TypographyOptions => ({
  fontFamily:
    "Figtree, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI ,sans-serif",
  subtitle1: {
    fontWeight: 600,
    letterSpacing: "0.00937em",
    lineHeight: 1.75,
    [defaultTheme.breakpoints.down("sm")]: {
      fontSize: defaultTheme.typography.pxToRem(14)
    }
  },
  subtitle2: {
    fontWeight: 600,
    letterSpacing: "0.00714em",
    lineHeight: 1.57,
    [defaultTheme.breakpoints.down("sm")]: {
      fontSize: defaultTheme.typography.pxToRem(12)
    }
  },
  body1: {
    letterSpacing: "0.0093em",
    lineHeight: 1.5,
    [defaultTheme.breakpoints.down("sm")]: {
      fontSize: defaultTheme.typography.pxToRem(14)
    }
  },
  body2: {
    letterSpacing: "0.01214em",
    lineHeight: 1.43,
    [defaultTheme.breakpoints.down("sm")]: {
      fontSize: defaultTheme.typography.pxToRem(12)
    }
  },
  h6: {
    fontWeight: 600,
    letterSpacing: "0.0075em",
    lineHeight: 1.6
  },
  h5: {
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 1.33
  },
  h4: {
    fontWeight: 700,
    letterSpacing: "0.00735em",
    lineHeight: 1.235
  },
  h3: {
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 1.17
  },
  h2: {
    fontWeight: 700,
    letterSpacing: "-0.00833em",
    lineHeight: 1.2
  },
  h1: {
    fontWeight: 500,
    letterSpacing: "-0.01562em",
    lineHeight: 1.16
  },
  caption: {
    letterSpacing: "0.0166em",
    lineHeight: 1.66
  },
  overline: {
    letterSpacing: "0.0833em",
    lineHeight: 2.66
  },
  button: {
    letterSpacing: "0.00714em",
    lineHeight: 1.57
  }
});

export { getTypographyOptions };
