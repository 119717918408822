import { JSON_TYPE, TEXT_TYPE, api } from "@/lib/axios";
import { APIResponse } from "@/types/apiResponse";

export const authApi = {
  validateEmail: (email: string) =>
    api.post<APIResponse<any>>(
      `ping/validate-email/`,
      { email },
      {
        headers: {
          "Content-Type": JSON_TYPE
        }
      }
    ),
  signInWithSSO: (email: string) =>
    api.post<APIResponse<any>>(`ping/validate-saml-domain`, email, {
      headers: {
        "Content-Type": TEXT_TYPE
      }
    }),

  sendVerification: () =>
    api.post<APIResponse<any>>("user/send-verification-email")
};
