import { useSnackbar } from "@/components/elements/MurfSnackbar";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { STATUS } from "@/constants/status";
import { IssueTags, IssueTagsToTaskType } from "@/features/comments";
import { useIssuesComments } from "@/features/comments/hooks";
import { dubTaskApi } from "@/features/task";
import { dubThunks } from "@/reducers/thunks/dub";
import { TASK_TYPE } from "@/types/project";
import { useEffect, useMemo, useState } from "react";

const useReOpenDub = ({
  dubId,
  projectId,
  setIsOpen
}: {
  dubId: string;
  projectId: string;
  setIsOpen: (_: boolean) => void;
}) => {
  const { showError, showSuccess } = useSnackbar();
  const [assignedUsers, setAssignedUsers] = useState<{
    [K in TASK_TYPE]: string;
  }>({
    TRANSLATION: "NOT_ASSIGNED",
    VOICE_OVER: "NOT_ASSIGNED",
    FACE_MARKING: "NOT_ASSIGNED",
    MEDIA_MERGE: "NOT_ASSIGNED"
  });
  const [reopeningDub, setReopeningDub] = useState(false);

  const [isFetchingPreviousData, setIsFetchingPreviousData] = useState<STATUS>(
    STATUS.IDLE
  );
  const [taskIdMap, setTaskIdMap] = useState<{
    [K in TASK_TYPE]: string;
  }>(() => ({
    TRANSLATION: "",
    VOICE_OVER: "",
    FACE_MARKING: "",
    MEDIA_MERGE: ""
  }));

  const dubDetails = useTypedSelector((state) => state.dubPreview.dubDetails);

  const dispatch = useAppDispatch();

  const { filteredIssues: allIssues } = useIssuesComments();

  const canReopenDub = useMemo(() => {
    if (!allIssues) {
      return false;
    }
    if (allIssues.some((issue) => !issue.tags?.length)) {
      return false;
    }
    return true;
  }, [allIssues]);

  const relatedTags = useMemo(() => {
    if (!allIssues) {
      return [];
    }
    const tags = new Set<string>();
    allIssues.forEach((issue) => {
      if (issue.tags?.length) {
        issue.tags.forEach((tag) => tags.add(IssueTagsToTaskType[tag.name]));
      }
    });
    if (
      tags.has(IssueTagsToTaskType[IssueTags.TRANSLATION]) &&
      !tags.has(IssueTagsToTaskType[IssueTags.VOICEOVER])
    ) {
      tags.add(IssueTagsToTaskType[IssueTags.VOICEOVER]);
    }
    const allTags = Array.from(tags);
    return allTags as TASK_TYPE[];
  }, [allIssues]);

  const handleReOpenDub = ({ isInternal }: { isInternal: boolean }) => {
    setReopeningDub(true);
    const { fileId, workspaceId } = dubDetails;
    Promise.all(
      relatedTags.map((task: string) => {
        return dubTaskApi.assignDubTask({
          taskId: taskIdMap[task as TASK_TYPE],
          assigneeId: assignedUsers[task as TASK_TYPE],
          sourceFileId: fileId,
          taskType: task,
          workspaceId: workspaceId!
        });
      })
    )
      .then((res) => {
        console.log("[Done] assignDubTask", res);
        dispatch(
          dubThunks.reOpenDub({
            dubId,
            fileId,
            projectId: projectId!,
            workspaceId: workspaceId!,
            isInternal
          })
        )
          .unwrap()
          .then((res) => {
            console.log("[Done] handleReOpenDub", res);
            setReopeningDub(false);
            showSuccess("Dub Reopened Successfully");
            setIsOpen(false);
            dispatch(
              dubThunks.fetchDubPreviewInternal({
                fileId,
                workspaceId: workspaceId!,
                projectId,
                dubId
              })
            );
          })
          .catch((err) => {
            console.debug("[Err] handleReOpenDub", err);
            setReopeningDub(false);
            showError(
              err?.extra ||
                "Something went wrong. Please try again in sometime."
            );
          });
      })
      .catch((err) => {
        console.debug("[Err] handleReOpenDub", err);
        setReopeningDub(false);
      });
  };

  useEffect(() => {
    if (
      !allIssues ||
      allIssues.length === 0 ||
      isFetchingPreviousData !== STATUS.IDLE
    ) {
      return;
    }

    setIsFetchingPreviousData(STATUS.LOADING);
    dubTaskApi
      .fetchTaskDetails({
        fileId: dubDetails.fileId,
        dubId,
        workspaceId: dubDetails.workspaceId!
      })
      .then((res) => {
        const { dubTasks } = res.data.responseData;
        if (dubTasks?.length) {
          dubTasks.forEach(
            (task: {
              taskType: TASK_TYPE;
              dubTaskId: string;
              assigneeId: string;
            }) => {
              setTaskIdMap((state) => ({
                ...state,
                [task.taskType]: task.dubTaskId
              }));
              setAssignedUsers((state) => ({
                ...state,
                [task.taskType]: task.assigneeId
              }));
            }
          );
        }
        setIsFetchingPreviousData(STATUS.SUCCESS);
      })
      .catch((err) => {
        console.log("fetchTaskDetails err", err);
        setIsFetchingPreviousData(STATUS.ERROR);
      });
  }, [
    dubId,
    isFetchingPreviousData,
    dubDetails.fileId,
    dubDetails.workspaceId,
    allIssues
  ]);

  return {
    assignedUsers,
    setAssignedUsers,
    reopeningDub,
    handleReOpenDub,
    isFetchingPreviousData,
    relatedTags,
    allIssues,
    dubDetails,
    canReopenDub
  };
};

export default useReOpenDub;
