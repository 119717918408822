import {
  BLOCK_ERROR_TYPES,
  BLOCK_ERROR_MESSAGES
} from "../constants/errorMessages";

export function getBlockError(
  renderFailureReason: BLOCK_ERROR_TYPES = BLOCK_ERROR_TYPES.RENDER_FAILED,
  blockLength: number = 0
) {
  const renderFailureReasons = BLOCK_ERROR_MESSAGES({ blockLength });
  return {
    errorType: renderFailureReason,
    errorMessage:
      renderFailureReason && renderFailureReasons[renderFailureReason]
        ? renderFailureReasons[renderFailureReason]
        : "Render Failed"
  };
}
