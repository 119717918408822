import { api } from "@/lib/axios";
import { APIResponse } from "@/types/apiResponse";
import { ScriptDubData, TVoApiParams } from "../types";
import { LANGUAGES } from "@/constants/languages";
import { TranslationPromptTypes } from "@/features/blocks/components/TextBlock/constants";

export const getTVoApiUrl = (
  route: string,
  params: TVoApiParams,
  queryParams?: { [key: string]: string | undefined }
) => {
  let query = `?workspaceId=${params.workspaceId}`;
  if (params.dubVersion) query += `&dubVersion=${params.dubVersion}`;
  if (queryParams) {
    for (const key in queryParams) {
      if (typeof queryParams[key] !== "undefined")
        query += `&${key}=${queryParams[key]}`;
    }
  }
  return `/file/${params.fileId}/dub/${params.dubId}/translation-vo/${route}${query}`;
};

export interface FetchDetailedProjectParams extends TVoApiParams {}

export interface GenerateTranslationParams extends TVoApiParams {
  blockId: string;
  payload: {
    prompt: string;
    fromLanguage: LANGUAGES;
    toLanguage: LANGUAGES;
    textGiven?: boolean;
    text?: string;
  };
}

interface ReTranslateParams extends TVoApiParams {
  blockId: string;
  speakerId?: string;
  signal?: AbortSignal;
  payload: {
    prompts: TranslationPromptTypes[];
    sourceLanguage: LANGUAGES;
    targetLanguage: LANGUAGES;
    text: string;
    translation: string;
    customPrompt: string;
    promptOnOriginal: boolean;
    surroundingText?: string;
  };
}

export interface PollTranslationStatusParams extends TVoApiParams {}

export interface FetchTranslationVoDetailsParams extends TVoApiParams {}

export interface MarkAsDoneParams extends TVoApiParams {}

export interface StartTaskParams extends TVoApiParams {}

export interface SyncBGMParams extends TVoApiParams {
  isMuted: boolean;
}

export const translationVoApi = {
  fetchDetails: (params: FetchTranslationVoDetailsParams) =>
    api.get<APIResponse<ScriptDubData>>(getTVoApiUrl("details", params)),
  generateTranslation: ({
    workspaceId,
    fileId,
    dubId,
    payload,
    blockId
  }: GenerateTranslationParams) =>
    api.put<APIResponse<string[]>>(
      getTVoApiUrl(`custom-prompt/${blockId}`, { workspaceId, fileId, dubId }),
      payload
    ),
  reTranslate: ({
    workspaceId,
    fileId,
    dubId,
    payload,
    blockId,
    signal,
    dubVersion,
    speakerId
  }: ReTranslateParams) =>
    api.post<APIResponse<string[]>>(
      getTVoApiUrl(
        `re-translate/${blockId}`,
        {
          workspaceId,
          fileId,
          dubId,
          dubVersion
        },
        {
          speakerId
        }
      ),
      payload,
      {
        signal
      }
    ),
  startTask: ({ dubId, dubVersion, fileId, workspaceId }: MarkAsDoneParams) =>
    api.post<APIResponse<null>>(
      getTVoApiUrl("start", {
        workspaceId,
        dubId,
        dubVersion,
        fileId
      })
    ),
  markAsDone: ({ dubId, dubVersion, fileId, workspaceId }: MarkAsDoneParams) =>
    api.post<APIResponse<string[]>>(
      getTVoApiUrl("mark-as-done", {
        workspaceId,
        dubId,
        dubVersion,
        fileId
      })
    ),
  discardChanges: ({ dubId, dubVersion, fileId, workspaceId }: TVoApiParams) =>
    api.post<APIResponse<null>>(
      getTVoApiUrl("discard-draft", {
        workspaceId,
        dubId,
        dubVersion,
        fileId
      })
    ),
  muteBGM: ({ dubId, fileId, workspaceId, isMuted }: SyncBGMParams) =>
    api.post(
      `/file/${fileId}/dub/${dubId}/translation-vo/sync-bg-music-property`,
      {},
      {
        params: {
          workspaceId,
          mute: isMuted
        }
      }
    )
};
