import { useCallback } from "react";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { generateRenderBlocksPayload } from "../util";
import { addRenderingBlockIds } from "@/reducers/slices/projectUtilitySlice/projectUtilitySlice";

export interface PrepareForRenderArgs {
  blocksThatNeedRendering: string[];
  maxRenderVersion: number;
  isDataFromLocal?: boolean;
}

export function usePrepareBlocks() {
  const dispatch = useAppDispatch();

  const blocksMap = useTypedSelector((state) => state.projectUtility.blocksMap);
  const speakers = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.speakers
  );
  const renderedBlocksMap = useTypedSelector(
    (state) => state.projectUtility.renderedBlocksMap
  );
  const richTextEnabled = useTypedSelector(
    (state) => state.projectUtility.richTextEnabled
  );

  const prepareForRender = useCallback(
    ({
      blocksThatNeedRendering,
      maxRenderVersion,
      isDataFromLocal = false
    }: PrepareForRenderArgs) => {
      const renderVersionMap: { [key: string]: number } = {}; // map of subBlockId to renderVersion

      const payload = generateRenderBlocksPayload(
        blocksMap,
        speakers,
        blocksThatNeedRendering.map((blockId: string) => ({
          blockId,
          renderVersion: blocksMap[blockId].renderVersion ?? 0
        })),
        renderedBlocksMap,
        maxRenderVersion,
        richTextEnabled,
        isDataFromLocal
      );

      Object.keys(payload).forEach((id) => {
        renderVersionMap[id] = Number(
          payload[id].blockProperties.renderVersion
        );
      });

      dispatch(
        addRenderingBlockIds({
          blockIds: Object.keys(payload)
        })
      );

      // latestRenderVersionMap.current = renderVersionMap;

      return { payload, renderVersionMap };
    },
    [blocksMap, speakers, renderedBlocksMap, dispatch, richTextEnabled]
  );

  return { prepareForRender };
}
