export enum BLOCK_ERROR_TYPES {
  TEXT_LIMIT_EXCEEDED_ERROR = "TEXT_LIMIT_EXCEEDED_ERROR",
  VGT_EXCEEDED_ERROR = "VGT_EXCEEDED_ERROR",
  INCOMPATIBLE_CHARACTER_ERROR = "INCOMPATIBLE_CHARACTER_ERROR",
  PROFANITY_LIKELY = "PROFANITY_LIKELY",
  TOO_LONG_PHONEME = "TOO_LONG_PHONEME",
  RENDER_FAILED = "RENDER_FAILED"
}

export const BLOCK_ERROR_MESSAGES = ({ blockLength = 0 }) => {
  return {
    [BLOCK_ERROR_TYPES.TEXT_LIMIT_EXCEEDED_ERROR]: `Block has ${blockLength} characters (exceeds text limit of 1000 characters). Split the Block to Continue`,
    [BLOCK_ERROR_TYPES.VGT_EXCEEDED_ERROR]:
      "Render failed. You have consumed your Voice Generation Time.",
    [BLOCK_ERROR_TYPES.INCOMPATIBLE_CHARACTER_ERROR]:
      "Render failed. Incompatible character found in text.",
    [BLOCK_ERROR_TYPES.TOO_LONG_PHONEME]:
      "Block is too long, please split the Block to render audio.",
    [BLOCK_ERROR_TYPES.RENDER_FAILED]: "Render failed. PRONUNCIATION FAILED.",
    [BLOCK_ERROR_TYPES.PROFANITY_LIKELY]:
      "This render failed as the system has detected some words or phrases which are inappropriate, profane, defamatory, obscene, indecent, or unlawful topic, name, material, or information. You could try removing such words or contact support if this has been incorrectly tagged."
  };
};
