import DubStep from "@/components/elements/DubStep";
import { useTypedSelector } from "@/config/configureAppStore";
import UserListMenu from "@/features/projectInternal/components/UserListMenu";
import { MURF_ROLES, TMember } from "@/features/user";
import { activeMemberSelector } from "@/reducers/selectors";
import { TASK_TYPE } from "@/types/project";
import React, { useState } from "react";

interface ITaskAssignment {
  task: TASK_TYPE;
  handleUserSelection: (user: TMember) => void;
  assigneeId: string | "NOT_ASSIGNED";
}

export const TaskAssignment = ({
  task,
  assigneeId = "NOT_ASSIGNED",
  handleUserSelection
}: ITaskAssignment): React.ReactNode => {
  const { userId, role } = useTypedSelector((state) => state.user);
  const { userMap } = useTypedSelector(activeMemberSelector);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { language, workspaceId } = useTypedSelector(
    (state) => state.dubPreview.dubDetails
  );

  const handleAssignClick = (event: React.MouseEvent<HTMLElement>) => {
    if (role !== MURF_ROLES.ADMIN && role !== MURF_ROLES.DELIVERY_MANAGER) {
      if (assigneeId !== "NOT_ASSIGNED") {
        return;
      }
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const _handleUserSelection = (user: TMember) => {
    handleClose();
    console.log("user", user);
    handleUserSelection(user);
  };

  return (
    <>
      <DubStep
        taskType={task}
        //   taskStatus={TASK_STATUS.ONGOING}
        dueBy={Date.now() + 1000000000000000}
        user={userMap[assigneeId]}
        onAssignClick={handleAssignClick}
        // onClick={handleTaskClick}
        accessorRole={role}
        userId={userId}
        size="large"
      />
      {anchorEl ? (
        <UserListMenu
          selectedUid={assigneeId}
          anchorEl={anchorEl}
          onClose={handleClose}
          onSelect={_handleUserSelection}
          taskType={task}
          targetLanguage={language!}
          workspaceId={workspaceId}
          accessorRole={role}
          userId={userId}
        />
      ) : null}
    </>
  );
};
