const PronunciationIcon = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99485 2C7.44257 2 6.99485 2.44772 6.99485 3V4.64564C6.99485 4.81036 7.03554 4.97254 7.11331 5.11775L9.73117 10.0059H8.00063C7.73542 10.0059 7.48106 10.1113 7.29353 10.2988C7.10599 10.4864 7.00063 10.7407 7.00063 11.0059L7.00064 13.0051H4.0133C3.46101 13.0051 3.0133 13.4529 3.0133 14.0051C3.0133 15.2201 3.24203 16.4999 4.0681 17.4813C4.73632 18.2751 5.69053 18.7533 6.91008 18.9244C6.87074 19.0963 6.81455 19.2568 6.73532 19.3977C6.64053 19.5663 6.5145 19.7026 6.33037 19.8039C6.14117 19.908 5.83598 20.0049 5.34229 20.0049H3C2.44771 20.0049 2 20.4526 2 21.0049C2 21.5572 2.44771 22.0049 3 22.0049H5.34229C6.09982 22.0049 6.75181 21.8548 7.29457 21.5561C7.8424 21.2547 8.22311 20.8324 8.47865 20.3779C8.96624 19.5107 8.99485 18.5267 8.99485 17.9961C8.99485 17.4438 8.54714 16.9961 7.99485 16.9961C6.62074 16.9961 5.95329 16.6152 5.5982 16.1933C5.35515 15.9046 5.1855 15.512 5.09367 15.0051H8.00064C8.26585 15.0051 8.52021 14.8998 8.70775 14.7122C8.89528 14.5247 9.00064 14.2704 9.00064 14.0051L9.00063 12.0059H11.4011C11.7527 12.0059 12.0784 11.8213 12.259 11.5197C12.4396 11.2181 12.4486 10.8438 12.2826 10.5338L8.99485 4.39472V3C8.99485 2.44772 8.54714 2 7.99485 2Z"
        fill={color}
      />
      <path
        d="M2 6.5C2 7.32843 2.67157 8 3.5 8C4.32843 8 5 7.32843 5 6.5C5 5.67157 4.32843 5 3.5 5C2.67157 5 2 5.67157 2 6.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6899 5.63169C17.0807 5.24141 17.7139 5.2418 18.1042 5.63257C19.9768 7.50757 21.0287 10.0492 21.0287 12.6992C21.0287 15.3492 19.9768 17.8909 18.1042 19.7659C17.7139 20.1567 17.0807 20.1571 16.6899 19.7668C16.2992 19.3765 16.2988 18.7433 16.6891 18.3526C18.1872 16.8526 19.0287 14.8192 19.0287 12.6992C19.0287 10.5792 18.1872 8.5459 16.6891 7.0459C16.2988 6.65513 16.2992 6.02197 16.6899 5.63169Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2927 8.29306C14.6832 7.90244 15.3163 7.90229 15.7069 8.29273C16.3108 8.89635 16.7899 9.61304 17.1168 10.4019C17.4436 11.1907 17.6118 12.0362 17.6118 12.89C17.6118 13.7438 17.4436 14.5893 17.1168 15.3781C16.7899 16.167 16.3108 16.8837 15.7069 17.4873C15.3163 17.8777 14.6832 17.8776 14.2927 17.4869C13.9023 17.0963 13.9024 16.4632 14.2931 16.0727C14.7111 15.6548 15.0428 15.1587 15.2691 14.6126C15.4954 14.0665 15.6118 13.4811 15.6118 12.89C15.6118 12.2989 15.4954 11.7135 15.2691 11.1674C15.0428 10.6213 14.7111 10.1252 14.2931 9.70727C13.9024 9.31684 13.9023 8.68368 14.2927 8.29306Z"
        fill={color}
      />
    </svg>
  );
};

export default PronunciationIcon;
