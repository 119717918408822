import {
  AutocompleteProps,
  ChipTypeMap,
  InputAdornment,
  InputBase,
  Popover,
  autocompleteClasses,
  inputBaseClasses,
  popoverClasses,
  styled
} from "@mui/material";
import { PopoverAutocomplete } from ".";
import { MurfAutocompleteProps } from "./types";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

// TODO: removed unnecessary styles after theme fix
const StyledPopper = styled(Popover)(({ theme }) => ({
  [`& .${popoverClasses.paper}`]: {
    borderRadius: theme.shape.borderRadius * 2,
    minWidth: 300,
    border: "none",
    boxShadow: theme.shadows[1]
  },

  [`& .${inputBaseClasses.root}`]: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: "100%"
  },
  [`& .${autocompleteClasses.paper}`]: {
    border: "none"
  }
}));

export const MurfAutocomplete = <
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"]
>({
  placeholder,
  anchorEl,
  onClose,
  open = false,
  anchorOrigin,
  transformOrigin,
  ...props
}: MurfAutocompleteProps &
  Omit<
    AutocompleteProps<
      Value,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >,
    "open" | "renderInput" | "disableCloseOnSelect" | "onClose" | "slotProps"
  >) => {
  return (
    <StyledPopper
      open={open}
      anchorEl={anchorEl}
      onClose={() => onClose?.()}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <PopoverAutocomplete
        disableCloseOnSelect
        onClose={(_, reason) => {
          if (reason === "escape") {
            onClose?.();
          }
        }}
        slotProps={{
          paper: {
            elevation: 0
          }
        }}
        renderInput={(params) => (
          <InputBase
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            //   className={params.InputProps.className}
            autoFocus
            placeholder={placeholder}
            startAdornment={
              <InputAdornment position="start">
                <SearchRoundedIcon />
              </InputAdornment>
            }
          />
        )}
        {...props}
      />
    </StyledPopper>
  );
};
