import { projectApi } from "@/features/projectInternal";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const internalProjectThunks = {
  fetchProjectData: createAsyncThunk(
    "internal/fetchProjectData",
    async ({
      projectId,
      workspaceId
    }: {
      projectId: string;
      workspaceId: string;
    }) => {
      const response = await projectApi.fetchProjectsData(
        projectId,
        workspaceId
      );

      return response.data.responseData;
    }
  )
};
