import {
  IconButton,
  CircularProgress,
  useTheme,
  SvgIconOwnProps,
  CircularProgressProps,
  Tooltip
} from "@mui/material";
import { PauseRounded, PlayArrowRounded } from "@mui/icons-material";
import { useCallback } from "react";

export interface PlayButtonBaseProps {
  disabled?: boolean;
  isPlaying?: boolean;
  iconProps?: SvgIconOwnProps | CircularProgressProps;
  onClick?: () => void | undefined;
  play?: () => void;
  pause?: () => void;
  buffering?: boolean;
  tooltipText?: string;
}

export function PlayButtonBase({
  disabled = false,
  isPlaying,
  play,
  pause,
  buffering,
  iconProps,
  onClick,
  tooltipText
}: PlayButtonBaseProps) {
  const theme = useTheme();

  const showButtonIcon = useCallback(
    (props?: SvgIconOwnProps) => {
      if (buffering) {
        return (
          <CircularProgress
            {...props}
            sx={{ padding: theme.spacing(0.2) }}
            size={theme.spacing(3)}
            color="secondary"
          />
        );
      } else if (isPlaying) {
        return <PauseRounded {...props} />;
      }
      return <PlayArrowRounded {...props} />;
    },
    [isPlaying, buffering, theme]
  );

  function handleButtonClick(e: any) {
    e.stopPropagation();
    if (onClick) {
      onClick();
      return;
    }
    if (isPlaying && pause) {
      pause();
    } else if (play) {
      play();
    }
  }

  return (
    <Tooltip title={tooltipText ? tooltipText : isPlaying ? "Pause" : "Play"}>
      <span>
        <IconButton
          onClick={handleButtonClick}
          disabled={disabled || buffering}
          size="small"
        >
          {showButtonIcon(iconProps)}
        </IconButton>
      </span>
    </Tooltip>
  );
}
