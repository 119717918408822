import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorCheck } from "@/utils/reduxUtils";
import {
  faceMarkClipApi,
  SplitClipParams,
  MergeClipParams
} from "@/features/tuning";

export const faceMarkingThunks = {
  splitClips: createAsyncThunk(
    "splitClips",
    async (args: SplitClipParams, thunkAPI) => {
      return errorCheck(async () => {
        const response = await faceMarkClipApi.splitClip(args);
        return response.data;
      }, thunkAPI);
    }
  ),
  mergeClips: createAsyncThunk(
    "mergeClips",
    async ({ fileId, clips, workspaceId }: MergeClipParams, thunkAPI) => {
      return errorCheck(async () => {
        const response = await faceMarkClipApi.mergeClip({
          fileId,
          clips,
          workspaceId
        });
        return response.data;
      }, thunkAPI);
    }
  )
};
