import { RestRequest, rest } from "msw";
import { API_BASE_URL } from "@/config/config";
import { mockAuthCheck } from "@/utils/mockUtils";

export const blockSynthesisMocks = [
  rest.post(`${API_BASE_URL}dub/:id/render-blocks`, (req, res, ctx) => {
    if (!mockAuthCheck(req)) {
      return res(ctx.status(401));
    }
    return res(
      ctx.status(200),
      ctx.json({
        responseCode: "SUCCESS",
        extra: null,
        activeWorkspaceId: "aLmn1yq3tAaw8BAyCDAWksDbQdq2",
        responseMessage: "Operation was successful"
      })
    );
  }),
  rest.post(
    `${API_BASE_URL}dub/:id/render-blocks-status`,
    (
      req: RestRequest<{ renderVersion: number; blockIds: string[] }>,
      res,
      ctx
    ) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      if (req.body?.renderVersion === 0) {
        const blocksRes: any = {};
        req.body.blockIds.forEach((id) => {
          blocksRes[id] = {
            renderStatus: "PROCESSING",
            audioUrl: "something",
            renderVersion: req.body.renderVersion + 1
          };
        });
        return res(
          ctx.status(200),
          ctx.json({
            responseCode: "SUCCESS",
            extra: null,
            activeWorkspaceId: "aLmn1yq3tAaw8BAyCDAWksDbQdq2",
            responseMessage: "Operation was successful",
            blocks: blocksRes
          })
        );
      }
      return res(
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          activeWorkspaceId: "aLmn1yq3tAaw8BAyCDAWksDbQdq2",
          responseMessage: "Operation was successful"
        })
      );
    }
  )
];
