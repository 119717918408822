import { Navigate, RouteObject } from "react-router-dom";

const getIndexRedirect = (
  path: string,
  replace: boolean = true
): RouteObject => {
  return {
    index: true,
    element: <Navigate to={path} replace={replace} />
  };
};

export default getIndexRedirect;
