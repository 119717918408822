import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorCheck } from "@/utils/reduxUtils";
import { workspaceAdminAPI } from "@/features/workspace/api";

export const workspaceThunk = {
  getAllUsersOfWorkspace: createAsyncThunk(
    "getAllUsersOfWorkspace",
    async ({ workspaceId }: { workspaceId: string }, thunkAPI) => {
      return errorCheck(async () => {
        const response = await workspaceAdminAPI.getAllUsersOfWorkspace(
          workspaceId
        );
        return response.data;
      }, thunkAPI);
    }
  ),
  getInternalMembers: createAsyncThunk(
    "getInternalMembers",
    async (userIds: string[], thunkAPI) => {
      return errorCheck(async () => {
        const response = await workspaceAdminAPI.getInternalMembers(userIds);
        return response.data;
      }, thunkAPI);
    }
  )
};
