import { RouteObject } from "react-router-dom";
import { lazy } from "react";

const SearchPage = lazy(() => import("../component/SearchPage"));

export const searchPageRoutes: RouteObject[] = [
  {
    path: `search`,
    element: <SearchPage />
  }
];
