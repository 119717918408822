import { TUser } from "@/features/user";
import { TBaseMember } from "./user";

export enum WORKSPACE_ROLES {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
  VIEWER = "VIEWER"
}

export interface WorkspaceUser extends TBaseMember {
  role: {
    role: WORKSPACE_ROLES;
  };
}

export interface PendingInviteUsers {
  [email: string]: {
    role: WORKSPACE_ROLES;
    createdAt: number;
    createdByUid: string;
    roleEnum: WORKSPACE_ROLES;
  };
}

export interface IWorkspace {
  workspaceId: string;
  displayName: string;
  profileImage?: string;
  roleType: WORKSPACE_ROLES;
  usedCredits: number;
  assignedCredits: number;
  planExpiryMillis: number;
  lipSyncEnabled?: boolean;
  qcEnabled?: boolean;
  disableAutomatedDub?: boolean;
  pendingInvitationsEmails?: PendingInviteUsers;
}

export type DUB_PLAN = "SUBSCRIPTION" | "TRIAL";

export interface InternalWorkspace extends IWorkspace {
  dubPlan: DUB_PLAN;
  owner: null | TUser;
  murfMembers: null | string[];
}
