import { styled } from "@mui/material";
import React, { useRef } from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

interface VisuallyHiddenFileInputProps {
  acceptedFormats: string;
  onChange: (e: any) => void;
  max?: number;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
}

export const VisuallyHiddenFileInput = ({
  acceptedFormats,
  onChange,
  max = 1,
  inputRef
}: VisuallyHiddenFileInputProps): React.ReactNode => {
  const localRef = useRef<HTMLInputElement | null>(null);
  return (
    <VisuallyHiddenInput
      type="file"
      max={max}
      ref={inputRef || localRef}
      accept={acceptedFormats}
      onChange={onChange}
    />
  );
};
