import { api } from "@/lib/axios";
import { APIResponse } from "@/types/apiResponse";
import {
  DubPronunciation,
  GetPronunciationSuggestionsParams,
  PronunciationLibraryData,
  PronunciationSuggestionsData,
  UserPronunciation
} from "../types/pronunciations";
import { TVoApiParams } from "../types";
import { getTVoApiUrl } from "./project";

export interface PronunciationPreviewParams extends TVoApiParams {
  blockId: string;
  voiceId: string;
  pronunciation: UserPronunciation;
}

export interface SavePronunciationsBulkParams extends TVoApiParams {
  pronunciations: DubPronunciation[];
  newPronunciation: DubPronunciation;
}

export interface SyncPronunciationsParams {
  type: "PRIVATE" | "SHARED";
  pronunciations: UserPronunciation[];
  workspaceId: string;
}

export const pronunciationApi = {
  getPronunciationSuggestions: ({
    voiceId,
    word,
    workspaceId
  }: GetPronunciationSuggestionsParams) =>
    api.get<APIResponse<PronunciationSuggestionsData>>(
      `pronunciation/suggest/${voiceId}?word=${word}&workspaceId=${workspaceId}`
    ),
  getPronunciationPreview: ({
    dubId,
    dubVersion,
    fileId,
    workspaceId,
    blockId,
    voiceId,
    pronunciation
  }: PronunciationPreviewParams) =>
    api.post<APIResponse<{ url: string }>>(
      getTVoApiUrl(`${blockId}/check-pronunciation/${voiceId}`, {
        workspaceId,
        dubId,
        dubVersion,
        fileId
      }),
      pronunciation
    ),
  savePronunciationBulk: ({
    dubId,
    dubVersion,
    fileId,
    workspaceId,
    pronunciations
  }: SavePronunciationsBulkParams) =>
    api.post<APIResponse<{ pronunciations: DubPronunciation[] }>>(
      getTVoApiUrl(`save-pronunciations-bulk`, {
        workspaceId,
        dubId,
        dubVersion,
        fileId
      }),
      {
        projectPronunciations: pronunciations,
        paraPronunciations: []
      }
    ),
  getPronunciationLibrary: ({ workspaceId }: { workspaceId: string }) =>
    api.get<APIResponse<PronunciationLibraryData>>(
      `pronunciation/library?workspaceId=${workspaceId}`
    ),
  syncPronunciations: ({
    type,
    pronunciations,
    workspaceId
  }: SyncPronunciationsParams) =>
    api.post<APIResponse<PronunciationLibraryData>>(
      `pronunciation/sync/${type}?workspaceId=${workspaceId}`,
      pronunciations
    )
};
