import { DubPronunciation, Pronunciation } from "../types/pronunciations";

export const unicodeAwareEqualsIgnoringCase = (text: string, other: string) => {
  return (
    text &&
    text.localeCompare(other, undefined, { sensitivity: "accent" }) === 0
  );
};

export const arePronunciationsSame = (
  pronun1: Pronunciation | null,
  pronun2: Pronunciation | null
) => {
  return Boolean(
    pronun1 &&
      pronun2 &&
      unicodeAwareEqualsIgnoringCase(pronun1.alt, pronun2.alt) &&
      pronun1.sayAs === pronun2.sayAs
  );
};

export const areDubPronunciationsSame = (
  pronoun: Pronunciation | null,
  dubPronoun: DubPronunciation | null
) => {
  return Boolean(
    dubPronoun &&
      pronoun &&
      unicodeAwareEqualsIgnoringCase(pronoun.alt, dubPronoun.alternate) &&
      dubPronoun.sayAs === pronoun.sayAs
  );
};
