import { ExportConfig, ExportLevel } from "../../types/export";
import { LocalExportConfig } from "./Config";

enum ExportAs {
  VOICE_ONLY = "VOICE_ONLY",
  VIDEO = "VIDEO",
  VIDEO_WITH_VTT = "VIDEO_WITH_VTT",
  VIDEO_WITH_SRT = "VIDEO_WITH_SRT",
  SCRIPT = "SCRIPT"
}

export const generateExportConfig = (
  config: LocalExportConfig,
  exportLevel: ExportLevel
): ExportConfig => {
  let exportAs = ExportAs.VIDEO;
  let burnSubtitlesToVideo = false;
  let includePauseInSubtitles = false;
  let rate;

  switch (config.value) {
    case "VIDEO":
      {
        config.settings?.checkbox?.forEach((checkbox) => {
          if (checkbox.value === "burnSubtitles" && checkbox.checked) {
            burnSubtitlesToVideo = true;
          }
          if (checkbox.value === "downloadSubtitles" && checkbox.checked) {
            if (checkbox.settings?.options?.selectedValue === "VTT") {
              exportAs = ExportAs.VIDEO_WITH_VTT;
            } else {
              exportAs = ExportAs.VIDEO_WITH_SRT;
            }

            if (checkbox.settings?.checkbox) {
              checkbox.settings.checkbox.forEach((subCheckbox) => {
                if (
                  subCheckbox.value === "includePauses" &&
                  subCheckbox.checked
                ) {
                  includePauseInSubtitles = true;
                }
              });
            }
          }
        });
      }
      break;
    case "AUDIO":
      {
        exportAs = ExportAs.VOICE_ONLY;
        if (config.settings?.options?.selectedValue) {
          rate = config.settings.options.selectedValue;
        }
      }
      break;
    case "SUBTITLES": {
      exportAs = ExportAs.SCRIPT;
      if (config.settings?.checkbox?.length) {
        config.settings.checkbox.forEach((checkbox) => {
          if (checkbox.value === "includePauses" && checkbox.checked) {
            includePauseInSubtitles = true;
          }
        });
      }
    }
  }

  return {
    exportAs,
    burnSubtitlesToVideo,
    includePauseInSubtitles,
    rate,
    format: config.selectedFormat,
    exportLevel
  };
};
