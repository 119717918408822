import { motion } from "framer-motion";
import { MutableRefObject, memo, useCallback, useRef, useState } from "react";
import {
  CompressRounded,
  ExpandRounded,
  RedoRounded,
  RestartAltRounded,
  UndoRounded
} from "@mui/icons-material";
import {
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  useTheme
} from "@mui/material";
import TranslationPopup from "../TranslationPopup";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { STATUS } from "@/constants/status";
import { translationVoApi } from "@/features/scriptStudio";
import { addTranslationVariant } from "@/reducers/slices/projectUtilitySlice/projectUtilitySlice";
import { LocalScriptBlockType } from "@/features/blocks";
import {
  OpenedByTypes,
  TranslationActionTypes,
  TranslationPrompts,
  TranslationPromptTypes
} from "../../constants";
import { addTranslationSuggestion } from "@/reducers/slices/currentScriptProjectSlice/currentScriptProjectSlice";
import { useSnackbar } from "@/components/elements/MurfSnackbar";
import { UNKNOWN_ERROR_MESSAGE } from "@/constants/errors";
import {
  getContentEditableEl,
  simulateEditableChangeEvent,
  convertRichTextToPlainText
} from "@/features/editor";
import useTvoTrackingEvent from "@/features/scriptStudio/hooks/useTvoTrackingEvent";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { LanguageDataMap } from "@/constants/languages";
import { BLOCK_ERROR_TYPE } from "@/features/blocks/constants";
import { useBlockAutoRender } from "@/features/scriptStudio/hooks/useBlockAutoRender";

interface ContentProps {
  currentTranslationVariant: MutableRefObject<number>;
  block: LocalScriptBlockType;
}

const Content = ({ currentTranslationVariant, block }: ContentProps) => {
  const workspaceId = useTypedSelector(
    (state) => state.currentScriptProject.workspaceId
  );
  const fileId = useTypedSelector((state) => state.currentScriptProject.fileId);
  const dubId = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.dubId
  );
  const dubVersion = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.dubVersion
  );
  const [generateStatus, setGenerateStatus] = useState(STATUS.IDLE);
  const menuRef = useRef<HTMLDivElement>(null);
  const showOnlyLoader = useRef(false);
  const openedBy = useRef<OpenedByTypes>(OpenedByTypes.SUGGESTIONS);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const trackEvent = useTvoTrackingEvent();
  const renderBlock = useBlockAutoRender();

  const currentBlockId = block.blockId;

  const translationVariants = useTypedSelector(
    (state) =>
      state.projectUtility.blocksMap[currentBlockId].translationVariants ?? null
  );
  const translationSuggestions = useTypedSelector(
    (state) =>
      state.currentScriptProject.translationSuggestions[currentBlockId] ?? null
  );

  const sourceLanguage = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.sourceLanguage
  );

  const targetLanguage = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.targetLanguage
  );
  const richTextEnabled = useTypedSelector(
    (state) => state.projectUtility.richTextEnabled
  );

  const newTranslationApiEnabled = useTypedSelector(
    (state) =>
      state.currentScriptProject.currentProject.newTranslationApiEnabled
  );

  const { showError } = useSnackbar();

  const openTranslationPopup = () => {
    setAnchorEl(menuRef.current || null);
  };

  const closeTranslationPopup = () => {
    // if loading then don't close
    showOnlyLoader.current = false;
    setAnchorEl(null);
    setGenerateStatus(STATUS.IDLE);
  };

  const applyTranslation = useCallback(
    (
      text: string,
      addToList = true,
      type?: string,
      prompt?: string,
      promptSource?: string
    ) => {
      if (currentBlockId && text) {
        // updating text and simulating change event for block (helps in sync and block height adjustments)
        const editable = getContentEditableEl(currentBlockId);
        if (editable) {
          editable.innerHTML = text;
          simulateEditableChangeEvent(editable);
        }

        if (addToList) {
          if (text !== block.htmlText) {
            renderBlock(currentBlockId);
          }
          // check if its present in list if yes then remove it and add to last
          const newList = translationVariants.filter((t: string) => t !== text);
          newList.push(text);
          currentTranslationVariant.current = newList.length - 1;
          dispatch(
            addTranslationVariant({
              blockId: currentBlockId,
              list: newList
            })
          );
        }

        if (type) {
          const audioLengthErrors = block.blockErrors.find(
            (be) =>
              be.type === BLOCK_ERROR_TYPE.RENDER_LENGTH_EXCEEDED ||
              be.type === BLOCK_ERROR_TYPE.RENDER_LENGTH_INSUFFICIENT
          );
          trackEvent(MIXPANEL_EVENTS.TRANSLATE_SUCCESS, {
            "Source Language": LanguageDataMap[sourceLanguage]?.name,
            "Target Language": LanguageDataMap[targetLanguage]?.name,
            Type: type,
            Prompt: prompt,
            "Prompt Source": promptSource,
            "Block Length":
              audioLengthErrors?.type ===
              BLOCK_ERROR_TYPE.RENDER_LENGTH_INSUFFICIENT
                ? "Short"
                : audioLengthErrors?.type ===
                  BLOCK_ERROR_TYPE.RENDER_LENGTH_EXCEEDED
                ? "Long"
                : "Ok"
          });
        }
      }
    },
    [
      currentBlockId,
      currentTranslationVariant,
      dispatch,
      translationVariants,
      sourceLanguage,
      targetLanguage,
      trackEvent,
      block.blockErrors,
      block.htmlText,
      renderBlock
    ]
  );

  const getTranslation = useCallback(
    (
      prompt: string,
      prompts: TranslationPromptTypes[],
      useLocalText = false,
      type?: string
    ) => {
      if (currentBlockId && generateStatus !== STATUS.LOADING) {
        setGenerateStatus(STATUS.LOADING);
        let text;
        if (useLocalText) {
          text = richTextEnabled
            ? convertRichTextToPlainText(block.phrasePropertiesList)
            : block.richText;
        }
        let fromLanguage = sourceLanguage,
          toLanguage = targetLanguage;
        if (useLocalText) {
          // if we are using local text (translated text) then from and to language are same
          fromLanguage = targetLanguage;
          toLanguage = targetLanguage;
        }
        const audioLengthErrors = block.blockErrors.find(
          (be) =>
            be.type === BLOCK_ERROR_TYPE.RENDER_LENGTH_EXCEEDED ||
            be.type === BLOCK_ERROR_TYPE.RENDER_LENGTH_INSUFFICIENT
        );
        trackEvent(MIXPANEL_EVENTS.TRANSLATE_START, {
          "Source Language": LanguageDataMap[sourceLanguage]?.name,
          "Target Language": LanguageDataMap[targetLanguage]?.name,
          Type: type,
          "Block Length":
            audioLengthErrors?.type ===
            BLOCK_ERROR_TYPE.RENDER_LENGTH_INSUFFICIENT
              ? "Short"
              : audioLengthErrors?.type ===
                BLOCK_ERROR_TYPE.RENDER_LENGTH_EXCEEDED
              ? "Long"
              : "Ok"
        });
        return (
          newTranslationApiEnabled
            ? translationVoApi.reTranslate({
                fileId,
                dubId,
                workspaceId,
                blockId: currentBlockId,
                dubVersion,
                payload: {
                  prompts,
                  sourceLanguage,
                  targetLanguage,
                  translation: text || block.richText,
                  text: block.original.text,
                  customPrompt: prompts.includes(
                    TranslationPromptTypes.CUSTOM_PROMPT
                  )
                    ? prompt
                    : "",
                  promptOnOriginal: !useLocalText
                }
              })
            : translationVoApi.generateTranslation({
                fileId,
                dubId,
                workspaceId,
                blockId: currentBlockId,
                payload: {
                  prompt: prompt,
                  fromLanguage,
                  toLanguage,
                  textGiven: useLocalText,
                  text
                }
              })
        )
          .then((res) => {
            setGenerateStatus(STATUS.IDLE);
            // remove empty string
            const suggestions: string[] = [];
            res.data.responseData?.forEach((text) => {
              // removing empty suggestions and converting pauses to seconds
              if (text.length) {
                suggestions.push(text);
              }
            });

            return suggestions;
          })
          .catch((err) => {
            console.log(err);
            setGenerateStatus(STATUS.IDLE);
            const errorMessage = err?.extra || UNKNOWN_ERROR_MESSAGE;
            showError(errorMessage);
            throw new Error(errorMessage);
          });
      }

      return Promise.reject("Loading");
    },
    [
      block.richText,
      currentBlockId,
      generateStatus,
      dubId,
      dubVersion,
      fileId,
      sourceLanguage,
      targetLanguage,
      workspaceId,
      showError,
      newTranslationApiEnabled,
      block.original.text,
      richTextEnabled,
      block.phrasePropertiesList,
      trackEvent,
      block.blockErrors
    ]
  );

  const generate = useCallback(
    (
      prompt: string,
      prompts: TranslationPromptTypes[],
      useLocalText = false,
      type?: string
    ) => {
      if (generateStatus === STATUS.IDLE) {
        showOnlyLoader.current = true;
        getTranslation(
          prompt || TranslationPrompts.DEFAULT,
          prompts,
          useLocalText,
          type
        )
          .then((data) => {
            applyTranslation(
              data[0],
              true,
              prompt?.includes("shorter") ? "Make it Shorter" : "Make it Longer"
            );
          })
          .finally(() => {
            closeTranslationPopup();
          });
      }
    },
    [applyTranslation, generateStatus, getTranslation]
  );

  const generateSuggestions = () => {
    openTranslationPopup();
    openedBy.current = OpenedByTypes.SUGGESTIONS;
    if (!translationSuggestions?.length) {
      showOnlyLoader.current = true;
      getTranslation(
        TranslationPrompts.getSuggestions(3),
        [TranslationPromptTypes.VARIANTS],
        false,
        "Translate Button"
      )
        .then((suggestion) => {
          showOnlyLoader.current = false;
          dispatch(
            addTranslationSuggestion({
              blockId: currentBlockId,
              suggestion
            })
          );
        })
        .catch(() => {
          closeTranslationPopup();
        });
    }
  };

  const handleMenuAction = useCallback(
    (action: TranslationActionTypes) => {
      switch (action) {
        case TranslationActionTypes.UNDO: {
          const vLength = translationVariants.length;
          if (vLength && currentTranslationVariant.current >= 1) {
            --currentTranslationVariant.current;
            applyTranslation(
              translationVariants[currentTranslationVariant.current],
              false
            );
          }
          break;
        }
        case TranslationActionTypes.REDO: {
          const vLength = translationVariants.length;
          if (vLength && currentTranslationVariant.current < vLength - 1) {
            ++currentTranslationVariant.current;
            applyTranslation(
              translationVariants[currentTranslationVariant.current],
              false
            );
          }
          break;
        }

        case TranslationActionTypes.SHORT: {
          generate(
            TranslationPrompts.MAKE_SHORTER,
            [TranslationPromptTypes.SHORTER],
            true,
            "Make it Shorter"
          );
          break;
        }
        case TranslationActionTypes.LONG: {
          generate(
            TranslationPrompts.MAKE_LONGER,
            [TranslationPromptTypes.LONGER],
            true,
            "Make it Longer"
          );
        }
      }
    },
    [applyTranslation, currentTranslationVariant, generate, translationVariants]
  );

  const isLoading = generateStatus === STATUS.LOADING;

  return (
    <motion.div
      initial="collapsed"
      animate="open"
      exit="collapsed"
      transition={{
        type: "linear",
        duration: 0.2
      }}
      variants={{
        open: {
          opacity: 1,
          height: 46
        },
        collapsed: { opacity: 0, height: 0, marginBottom: 0 }
      }}
      ref={menuRef}
      style={{
        gap: theme.spacing(1),
        display: "flex",
        padding: "8px 0",
        height: 46
      }}
    >
      <Button
        startIcon={<RestartAltRounded fontSize="small" />}
        size="small"
        color="secondary"
        variant="contained"
        sx={{ fontSize: { md: 10, lg: 14 } }}
        onClick={() => generateSuggestions()}
      >
        Generate more options
      </Button>
      <TranslationPopup
        isOpen={isLoading || Boolean(anchorEl)}
        anchorEl={showOnlyLoader.current ? menuRef.current : anchorEl}
        closePopup={closeTranslationPopup}
        applyTranslation={applyTranslation}
        getTranslation={getTranslation}
        isLoading={isLoading}
        showOnlyLoader={showOnlyLoader.current}
        openedBy={openedBy}
        translationSuggestions={translationSuggestions}
      />
      <Tooltip title="Make it shorter">
        <IconButton
          onClick={() => handleMenuAction(TranslationActionTypes.SHORT)}
          size="small"
          color="secondary"
        >
          <CompressRounded fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Make it longer">
        <IconButton
          onClick={() => handleMenuAction(TranslationActionTypes.LONG)}
          size="small"
          color="secondary"
        >
          <ExpandRounded fontSize="small" />
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" />
      <Tooltip title="Undo">
        <span>
          <IconButton
            size="small"
            color="secondary"
            onClick={() => handleMenuAction(TranslationActionTypes.UNDO)}
            disabled={currentTranslationVariant.current <= 0}
          >
            <UndoRounded fontSize="small" />{" "}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Redo">
        <span>
          <IconButton
            size="small"
            color="secondary"
            onClick={() => handleMenuAction(TranslationActionTypes.REDO)}
            disabled={
              currentTranslationVariant.current >=
              translationVariants.length - 1
            }
          >
            <RedoRounded fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
    </motion.div>
  );
};

interface TranslationMenuProps {
  isActive: boolean;
  block: LocalScriptBlockType;
}

const TranslationMenu = ({ isActive, block }: TranslationMenuProps) => {
  // Used for undo and redo. to keep record of current variant
  const currentTranslationVariant = useRef<number>(0);

  return (
    <Stack height={46}>
      {isActive ? (
        <Content
          currentTranslationVariant={currentTranslationVariant}
          block={block}
        />
      ) : null}
    </Stack>
  );
};

export default memo(TranslationMenu);
