import { FormatQuoteRounded, RestartAltRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
  styled
} from "@mui/material";
import { ChangeEvent, MutableRefObject, useRef, useState } from "react";
import Loader from "../TranslationMenu/Loader";
import TranslationVariant from "./TranslationVariant";
import {
  OpenedByTypes,
  TranslationActionTypes,
  TranslationPromptTypes
} from "../../constants";

const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    width: 500,
    maxHeight: 400,
    overflow: "auto",
    gap: theme.spacing(1),
    display: "flex",
    flexDirection: "column"
  }
}));

const Content = ({
  closePopup,
  getTranslation,
  applyTranslation,
  isLoading,
  translationSuggestions,
  openedBy
}: TranslationPopupProps) => {
  const [useLocalText, setUseLocalText] = useState(false);
  const [promptText, setPromptText] = useState("");
  const isOpenedByCustomPrompt =
    openedBy.current === OpenedByTypes.CUSTOM_PROMPT;
  const [showCustomPrompt, setShowCustomPrompt] = useState(
    isOpenedByCustomPrompt
  );
  const [promptResults, setPromptResults] = useState<string[]>([]);
  const lastExecutedPrompt = useRef("");

  const toggleCustomPrompt = () => {
    setShowCustomPrompt((state) => !state);
  };

  const generate = () => {
    if (!promptText) return;
    if (!isOpenedByCustomPrompt) openedBy.current = OpenedByTypes.CUSTOM_PROMPT;
    getTranslation(
      promptText,
      [TranslationPromptTypes.CUSTOM_PROMPT],
      useLocalText,
      `Custom Prompt ${useLocalText ? "Translate" : "Block"}`
    ).then((data) => {
      lastExecutedPrompt.current = promptText;
      setPromptResults(data);
    });
  };

  const handlePromptChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPromptText(value);
  };

  const handleActionClick = (action: TranslationActionTypes, text: string) => {
    switch (action) {
      case TranslationActionTypes.APPLY: {
        applyTranslation(
          text,
          true,
          promptResults.length
            ? `Custom Prompt ${useLocalText ? "Translate" : "Block"}`
            : "Translate Button",
          promptResults.length ? promptText : undefined,
          promptResults.length
            ? useLocalText
              ? "Current"
              : "Original"
            : undefined
        );
        closePopup();
        break;
      }
    }
  };

  const results = isOpenedByCustomPrompt
    ? promptResults
    : translationSuggestions || [];

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: 2,
          padding: 2,
          border: "none"
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="body2" color="text.secondary">
              Use custom prompt to refine translation
            </Typography>
          </Box>
          <Button
            startIcon={<FormatQuoteRounded />}
            size="small"
            color="secondary"
            variant="outlined"
            onClick={toggleCustomPrompt}
          >
            {showCustomPrompt ? "Hide Prompt" : "Show Prompt"}
          </Button>
        </Stack>
        {showCustomPrompt ? (
          <>
            <Stack gap={1}>
              <Typography variant="body2" color="text.secondary">
                Apply prompt on
              </Typography>
              <ButtonGroup size="small" disabled={isLoading}>
                <Button
                  variant={useLocalText ? "outlined" : "contained"}
                  color="secondary"
                  onClick={() => setUseLocalText(false)}
                >
                  Transcribed text
                </Button>
                <Button
                  variant={useLocalText ? "contained" : "outlined"}
                  color="secondary"
                  onClick={() => setUseLocalText(true)}
                >
                  Current Translation
                </Button>
              </ButtonGroup>
            </Stack>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              color="secondary"
              maxRows={4}
              size="small"
              value={promptText}
              disabled={isLoading}
              onChange={handlePromptChange}
              placeholder="For example, “do not translate specific word” or “make the translation more professional”"
              fullWidth
            />
            {isLoading ? (
              <Loader small />
            ) : (
              <Button
                startIcon={
                  isLoading ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <RestartAltRounded />
                  )
                }
                size="small"
                color="secondary"
                variant="contained"
                onClick={generate}
                disabled={
                  !promptText || lastExecutedPrompt.current === promptText
                }
              >
                Generate
              </Button>
            )}
          </>
        ) : null}
      </Paper>
      {results.length && !isLoading ? (
        <Paper
          elevation={0}
          sx={{
            gap: 1,
            padding: 2,
            borderRadius: 2,
            border: "none",
            maxHeight: 500,
            overflow: "auto"
          }}
          className="customized-scrollbar"
        >
          <Typography variant="body2" color="text.secondary">
            {isOpenedByCustomPrompt ? "Results" : "Alternate translations"}
          </Typography>
          {results.map((text, ind) => (
            <TranslationVariant
              key={ind}
              text={text}
              handleActionClick={handleActionClick}
            />
          ))}
        </Paper>
      ) : null}
    </>
  );
};

interface TranslationPopupProps {
  isOpen: boolean;
  anchorEl: HTMLDivElement | null;
  closePopup: () => void;
  getTranslation: (
    prompt: string,
    prompts: TranslationPromptTypes[],
    useLocalText: boolean,
    type?: string
  ) => Promise<string[]>;
  applyTranslation: (
    text: string,
    addToList: boolean,
    type?: string,
    prompt?: string,
    promptSource?: string
  ) => void;
  isLoading: boolean;
  showOnlyLoader: boolean;
  translationSuggestions: string[] | null;
  openedBy: MutableRefObject<OpenedByTypes>;
}

const TranslationPopup = ({
  anchorEl,
  closePopup,
  isOpen,
  getTranslation,
  applyTranslation,
  isLoading,
  showOnlyLoader,
  translationSuggestions,
  openedBy
}: TranslationPopupProps) => {
  return (
    <StyledPopover
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      onClose={closePopup}
      sx={{
        top: 5
      }}
      slotProps={{
        paper: {
          elevation: 3
        }
      }}
    >
      {isOpen ? (
        showOnlyLoader ? (
          <Loader />
        ) : (
          <Content
            anchorEl={anchorEl}
            applyTranslation={applyTranslation}
            closePopup={closePopup}
            getTranslation={getTranslation}
            isLoading={isLoading}
            isOpen={isOpen}
            openedBy={openedBy}
            showOnlyLoader={showOnlyLoader}
            translationSuggestions={translationSuggestions}
          />
        )
      ) : (
        <Loader />
      )}
    </StyledPopover>
  );
};

export default TranslationPopup;
