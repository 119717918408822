import { ContentPasteGoRounded } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  styled
} from "@mui/material";
import { TranslationActionTypes } from "../../constants";

const StyledTranslationVariant = styled(Box)(({ theme }) => ({
  "&:hover": { backgroundColor: theme.palette.action.hover },
  display: "flex",
  borderRadius: theme.spacing(2),
  cursor: "pointer",
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  justifyContent: "space-between"
}));

interface TranslationVariantProps {
  text: string;
  handleActionClick: (action: TranslationActionTypes, text: string) => void;
}

const TranslationVariant = ({
  text,
  handleActionClick
}: TranslationVariantProps) => {
  return (
    <StyledTranslationVariant
      onClick={() => handleActionClick(TranslationActionTypes.APPLY, text)}
    >
      <Typography variant="body2" color="text.primary">
        {text}
      </Typography>

      <Stack>
        <Box display="flex" gap={1}>
          <Divider orientation="vertical" />
          <IconButton size="small" color="secondary">
            <ContentPasteGoRounded fontSize="small" />{" "}
          </IconButton>
        </Box>
      </Stack>
    </StyledTranslationVariant>
  );
};

export default TranslationVariant;
