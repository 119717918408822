import { api } from "@/lib/axios";
import { APIResponse } from "@/types/apiResponse";
import { FaceMarkClip } from "@/features/tuning/types";

export interface SplitClipParams {
  fileId: string;
  frameIdx: number;
  currentClipStartFrame: number;
  workspaceId: string;
}

export interface MergeClipParams {
  fileId: string;
  clips: Partial<FaceMarkClip>[];
  workspaceId: string;
}

export const faceMarkClipApi = {
  splitClip: ({
    fileId,
    frameIdx,
    currentClipStartFrame,
    workspaceId
  }: SplitClipParams) =>
    api.put<APIResponse<FaceMarkClip>>(
      `/member/face-mark/${fileId}/split-clip/${frameIdx}?workspaceId=${workspaceId}`,
      {
        startFrameIdx: currentClipStartFrame
      }
    ),
  mergeClip: ({ fileId, clips, workspaceId }: MergeClipParams) =>
    api.put<APIResponse<FaceMarkClip>>(
      `/member/face-mark/${fileId}/merge-clip?workspaceId=${workspaceId}`,
      clips
    )
};
