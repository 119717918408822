import {
  TextField,
  TextFieldProps,
  formLabelClasses,
  inputBaseClasses,
  styled
} from "@mui/material";

const Wrapper = styled(TextField)(({ theme }) => ({
  ["& legend"]: {
    width: 0
  },
  [`& .${formLabelClasses.root}`]: {
    transform: "none",
    fontSize: ".75rem",
    lineHeight: 1,
    position: "static",
    marginBottom: theme.spacing(1),
    zIndex: 0
  },
  [`& .${inputBaseClasses.root}`]: {
    [`&.${inputBaseClasses.focused}`]: {
      backgroundColor: theme.palette.background.default
    }
  }
}));

const OutlinedTextField = (props: Omit<TextFieldProps, "variant">) => {
  return (
    <Wrapper {...props} InputLabelProps={{ shrink: true }} variant="outlined" />
  );
};

OutlinedTextField.defaultProps = {
  color: "secondary"
};

export default OutlinedTextField;
