export enum MEDIA_TYPE_EXTN {
  VIDEO = "video",
  AUDIO = "audio"
}

export const SEE_ALL_RESULTS = "SEE_ALL_RESULTS";

export const SEE_ALL_RESULTS_OPTION = {
  label: "See all results",
  id: SEE_ALL_RESULTS,
  type: "BUTTON"
};

export type PAGE_TYPE = "HOME" | "PROJECT_DETAIL" | "NA";

export enum MEDIA_TYPE {
  VIDEO = "VIDEO",
  AUDIO = "AUDIO"
}
