import { lazy } from "react";
const Swagger = lazy(() => import("@/components/admin/Swagger"));
import RequiresAuth from "@/components/auth/RequiresAuth";
import { RouteObject } from "react-router-dom";

export const commonRoutes: RouteObject[] = [
  {
    path: "/support/swagger",
    element: (
      <RequiresAuth>
        <Swagger />
      </RequiresAuth>
    ),
  },
];
