import { BoundingBoxStatus, Clip } from "../types";
import { Theme } from "@mui/material";

export function getClipStatus({ clip }: { clip: Clip }): BoundingBoxStatus {
  if (clip.selectedFaceIdx === -1) {
    return BoundingBoxStatus.DISCARDED;
  } else {
    return BoundingBoxStatus.SELECTED;
  }
}

export function getTuningStatusColor({
  status,
  theme
}: {
  status: BoundingBoxStatus;
  theme?: Theme;
}) {
  switch (status) {
    case BoundingBoxStatus.SELECTED:
      return theme ? theme.palette.success.main : "success.main";
    case BoundingBoxStatus.DISCARDED:
      return theme ? theme.palette.error.main : "error.main";
    case BoundingBoxStatus.IDLE:
      return theme ? theme.palette.primary.main : "primary.main";
    default:
      return theme ? theme.palette.primary.main : "primary.main";
  }
}

export function getTuningTimelineStatusColor({
  status,
  theme,
  isPlaying
}: {
  status: BoundingBoxStatus;
  theme?: Theme;
  isPlaying: boolean;
}) {
  switch (status) {
    case BoundingBoxStatus.SELECTED:
      return theme
        ? isPlaying
          ? theme.palette.success.main
          : theme.palette.success.focusVisible
        : isPlaying
        ? "success.main"
        : "success.focusVisible";
    case BoundingBoxStatus.DISCARDED:
      return theme
        ? isPlaying
          ? theme.palette.error.main
          : theme.palette.error.focusVisible
        : isPlaying
        ? "error.main"
        : "error.focusVisible";
    case BoundingBoxStatus.IDLE:
      return theme ? theme.palette.primary.main : "secondary.focusVisible";
    default:
      return theme ? theme.palette.primary.main : "secondary.focusVisible";
  }
}

export function getTuningTimelineStatusBorderColor({
  status,
  theme
}: {
  status: BoundingBoxStatus;
  theme?: Theme;
}) {
  switch (status) {
    case BoundingBoxStatus.SELECTED:
      return theme ? theme.palette.success.main : "success.main";
    case BoundingBoxStatus.DISCARDED:
      return theme ? theme.palette.error.main : "error.main";
    case BoundingBoxStatus.IDLE:
      return theme
        ? theme.palette.secondary.outlinedBorder
        : "secondary.outlinedBorder";
    default:
      return theme
        ? theme.palette.secondary.outlinedBorder
        : "secondary.outlinedBorder";
  }
}

export function getFrameNumber({
  timestamp,
  fps
}: {
  timestamp: number;
  fps: number;
}) {
  return Math.ceil(timestamp * fps);
}

export function getTimestampFromFrameNumber({
  frameNumber,
  fps
}: {
  frameNumber: number;
  fps: number;
}) {
  return frameNumber / fps;
}

export function findClipIdFromTimeStamp({
  clipsList, // this should be a list of clips sorted by startFrame
  currentTime,
  fps
}: {
  clipsList: Clip[];
  currentTime: number;
  fps: number;
}): number | null {
  const currentFrame = getFrameNumber({ fps: fps, timestamp: currentTime });
  let low = 0;
  let high = clipsList.length - 1;
  let mid = 0;
  while (low <= high) {
    mid = Math.floor((low + high) / 2);
    if (
      clipsList[mid].startFrame <= currentFrame &&
      clipsList[mid].endFrame >= currentFrame
    ) {
      return clipsList[mid].startFrame;
    } else if (clipsList[mid].startFrame > currentFrame) {
      high = mid - 1;
    } else {
      low = mid + 1;
    }
  }
  return null;
}
