import { VoiceVariant } from "@/features/scriptStudio/types";
import { BLOCK_ERROR_TYPES } from "@/features/synthesis/constants/errorMessages";
import { PLAYER_MODE } from "@/reducers/slices/currentScriptProjectSlice/types";
import { BLOCK_ERROR_TYPE } from "../constants";

export enum TextBlockStatus {
  IDLE = "IDLE",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  LOADING = "LOADING",
  FAILED = "FAILED",
  DOES_NOT_EXIST = "DOES_NOT_EXIST",
  DONE = "DONE"
}

export interface RichTextType {
  text: string;
  /**emphasis */
  emphasis?: string;
  /**pronunciation */
  alt?: string;
  sayAs?: boolean;
  isPause?: boolean;
}

export interface OriginalScriptBlockType {
  text: string;
  englishText?: string;
  speakerId: string;
  duration: number;
  startTime: number;
  endTime: number;
  correctedDuration?: number; // duration is not always equal to block2.startTime - block1.startTime. This is the corrected duration. But this value may not be the duration of the generated original audio
  textUpdatedAt: number;
  // englishTextUpdatedAt is the timestamp when the english text was last updated
  englishTextUpdatedAt: number;
}

// ScriptBlockType is the data type used by backend and used for block processing in frontend
export interface ScriptBlockType {
  blockId: string;
  translationMarkAsDone: boolean;
  voiceOverMarkAsDone: boolean;
  originalAudioPlayed: boolean;
  translationAudioPlayed: boolean;
  original: OriginalScriptBlockType;
  richText: string;
  phrasePropertiesList: RichTextType[];
  baseTranslation?: string;
  speakerId: string;
  volume: number;
  audioUrl: string;
  renderLength: number;
  renderVersion: number;
  needsRendering: boolean;
  renderStatus: TextBlockStatus;
  renderFailureReason?: BLOCK_ERROR_TYPES;
  transcriptionUpdatedAt: number;
  // englishTextUpdatedAt is the timestamp when the english text was last updated and synced with translation
  englishTextUpdatedAt?: number;
}

export interface BlockError {
  type: BLOCK_ERROR_TYPE;
  message: string;
}

export interface LocalScriptBlockType extends ScriptBlockType {
  original: OriginalScriptBlockType & {
    localText: string;
    localEnglishText?: string;
  };
  htmlText: string;
  completed: boolean;
  blockNumber: number;
  blockErrors: BlockError[];
  translationVariants: string[];
  /**
   * block is affected by speaker property changes (block needs render)
   */
  affectedBySpeakerProperties: boolean;
  /**
   * block is affected by block property changes (block needs render)
   */
  affectedByBlockProperties: boolean;
  associatedIssueIds?: string[];
}

export interface RenderedBlockType
  extends Pick<
    ScriptBlockType,
    | "blockId"
    | "needsRendering"
    | "renderStatus"
    | "renderVersion"
    | "speakerId"
  > {
  htmlText: string;
  renderedPitch: number;
  renderedVoiceVariant: VoiceVariant | null;
}

export type HandleBlockPlayType = (
  block: LocalScriptBlockType,
  playerType: PLAYER_MODE,
  isPlay: boolean
) => void;

export type HandleBlockSeekType = (block: LocalScriptBlockType) => void;
