import { MurfInputLabel } from "@/components/elements/MurfInputLabel";
import { Button, ButtonGroup } from "@mui/material";

interface OptionsProps {
  options: {
    title: string;
    value: string;
    description: string;
  }[];
  label: string;
  onChange: (value: string) => void;
  selectedValue: string;
}

const Options = ({ options, label, onChange, selectedValue }: OptionsProps) => {
  return (
    <>
      {label ? <MurfInputLabel sx={{ mb: 1 }}>{label}</MurfInputLabel> : null}
      <ButtonGroup>
        {options.map((option) => (
          <Button
            variant={selectedValue === option.value ? "contained" : "outlined"}
            color="secondary"
            onClick={() => onChange(option.value)}
            key={option.value}
          >
            {option.title}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
};

export default Options;
