export * from "./richEditor";

import { pauseRegexWithoutBrackets } from "@/utils/regex";

export const formatBlockText = (text: string) => {
  if (!text) return text;
  return text
    .replace(pauseRegexWithoutBrackets, function (r) {
      return `[${r}]`;
    })
    .replaceAll("[[", "[")
    .replaceAll("]]", "]");
};

export const sanitizeContentEditable = (str: string = "") => {
  return str
    ?.replace(/&nbsp;|\u202F|\u00A0/g, " ")
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">");
};

export const desanitizeText = (text: string = "") => {
  return sanitizeContentEditable(text)
    ?.replace(/&/g, "&amp;")
    .replace(/>/g, "&gt;")
    .replace(/</g, "&lt;");
};

/**
 * function to convert text with millisecond pauses to seconds pauses
 * @param text - text with millisecond pauses eg. [pause3100]
 * @returns
 */
export const convertPausesToSeconds = (text: string): string => {
  if (!text || typeof text !== "string") {
    console.debug("Method cannot handle `text` which is not of string type");
    return text;
  }
  const pauseRegEx = new RegExp(/\[pause\s*(\d+)\s*\]/gi);
  const newText = text.replaceAll(pauseRegEx, (_, ms) => {
    try {
      const temp = Number(ms);
      const sec = (temp / 1000).toFixed(1);
      if (temp < 100) {
        return "[pause 0.1s]";
      }
      return `[pause ${sec}s]`;
    } catch (err) {
      return `[pause ${ms}]`;
    }
  });
  return newText;
};

/**
 * function to convert pauses from seconds to milliseconds
 * @param text - text with seconds pauses eg. `hello [pause 5.9s]`
 * @returns - new string
 */
export const convertPausesToMs = (text: string): string => {
  if (!text || typeof text !== "string") {
    console.debug("Method cannot handle `text` which is not of string type");
    return text;
  }
  const pauseRegEx = new RegExp(/\[pause\s*([\d.]+)s?\]/gi);
  const newText = text.replaceAll(pauseRegEx, (_, sec) => {
    try {
      const ms = Number(sec) * 1000;

      return `[pause${ms}]`;
    } catch (err) {
      return `[pause${sec}]`;
    }
  });
  return newText;
};
