export * from "./util";
import {
  ClipboardEvent,
  FocusEventHandler,
  KeyboardEvent,
  memo,
  SyntheticEvent,
  useEffect,
  useRef,
  useState
} from "react";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import "./Editor.css";
import { EventHandlers } from "./hooks/useEditor";
import { Box, styled } from "@mui/material";
import TextSelectionMenu from "./components/TextSelectionMenu";
import { nextTick } from "process";
import { debounce, KEY_BOARD_KEYS } from "@/utils/misc";
import { BLOCK_ERROR_TYPE, BLOCK_MAX_CHAR } from "../blocks/constants";
import {
  removeBlockError,
  upsertBlockError
} from "@/reducers/slices/projectUtilitySlice/projectUtilitySlice";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import {
  dispatchGlobalSelectEvent,
  getRangeDataForRedux,
  getSelectionDimensions,
  isSelectionProperWord,
  RangeData,
  replaceNodeInHtmlString,
  sanitizeContentEditable,
  simulateEditableChangeEvent,
  stripHtml
} from "./util";
import useTvoTrackingEvent from "../scriptStudio/hooks/useTvoTrackingEvent";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { unicodeAwareEqualsIgnoringCase } from "../scriptStudio/utils/pronunciation";
import { escapeRegExp } from "@/utils/regex";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  ".editor": {
    outline: "none",
    border: "1px solid transparent",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5),
    overflow: "hidden",
    wordBreak: "break-word",
    whiteSpace: "normal",
    transition: "border-width 0.3s ease",
    "&.active": {
      backgroundColor: theme.palette.background.paper
    },
    "&:focus": {
      boxShadow: `0 0 0 2px ${theme.palette.secondary.main}`,
      borderColor: theme.palette.secondary.main
    },
    "&:hover:not(:disable)": {
      borderColor: theme.palette.secondary.outlinedBorder
    },
    "&.active:not(:focus)": {
      borderColor: theme.palette.action.disabled
    }
  }
}));

interface EditorProps {
  eventHandlers: Partial<EventHandlers>;
  text: string;
  index: number;
  disabled: boolean;
  blockId: string;
  isActive?: boolean;
  hasTextLengthError?: boolean;
  translationEditor?: boolean;
  isAudioShort?: boolean;
  isAudioLong?: boolean;
}

const Editor = ({
  eventHandlers,
  blockId,
  index,
  text,
  isActive,
  disabled,
  hasTextLengthError,
  translationEditor,
  isAudioLong,
  isAudioShort
}: EditorProps) => {
  const [selectionMenu, setSelectionMenu] = useState({
    open: false,
    left: 0,
    top: 0,
    selectionWidth: 0
  });
  const editorRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<string>(text);
  const lastAdjustedSize = useRef<number>(0);
  const isMenuOpen = useRef<boolean>(false);
  const currentRangeData = useRef<RangeData | null>(null);
  const stopSelectionMenuFromClosing = useRef(false);
  const dispatch = useAppDispatch();
  const trackEvent = useTvoTrackingEvent();
  const searchText = useTypedSelector(
    (state) => state.currentScriptProject.searchText
  );
  const searchInTranscription = useTypedSelector(
    (state) => state.currentScriptProject.searchInTranscription
  );

  /**
   * if block text changes update local text
   */
  useEffect(() => {
    if (editorRef.current && text !== textRef.current) {
      editorRef.current.innerHTML = text;
    }
    textRef.current = text;
  }, [text]);

  /**
   * highlighting search text in editor
   * checking if transcription search is enabled
   */
  useEffect(() => {
    if (
      editorRef.current &&
      typeof searchText === "string" &&
      (translationEditor || searchInTranscription)
    ) {
      editorRef.current.innerHTML = replaceNodeInHtmlString(
        textRef.current,
        (element) => {
          if (
            element.textContent &&
            unicodeAwareEqualsIgnoringCase(
              element.textContent.trim(),
              searchText
            )
          ) {
            element.classList.add("highlighted");
          }
          return element;
        },
        (text) => {
          if (text) {
            const cleanText = escapeRegExp(searchText);
            return text.replace(
              new RegExp(`(${cleanText})`, "gi"),
              `<span class="highlighted">$1</span>`.trim()
            );
          }

          return text;
        }
      );
    }
  }, [searchText, searchInTranscription, translationEditor]);

  useEffect(() => {
    lastAdjustedSize.current = editorRef.current?.clientHeight ?? 0;
  }, []);

  useEffect(() => {
    const editorElement = editorRef.current;
    // checking if its a string
    if (
      translationEditor &&
      editorElement &&
      typeof editorElement.textContent === "string"
    ) {
      const textLength = editorElement.textContent.length;
      if (textLength >= BLOCK_MAX_CHAR) {
        // handle text character exceeded error
        if (!hasTextLengthError) {
          dispatch(
            upsertBlockError({
              blocksErrorMap: {
                [blockId]: { type: BLOCK_ERROR_TYPE.TEXT_LIMIT_EXCEEDED_ERROR }
              },
              type: BLOCK_ERROR_TYPE.TEXT_LIMIT_EXCEEDED_ERROR
            })
          );
        }
      } else if (hasTextLengthError) {
        // remove error
        dispatch(
          removeBlockError({
            blocksErrorMap: {
              [blockId]: { type: BLOCK_ERROR_TYPE.TEXT_LIMIT_EXCEEDED_ERROR }
            }
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const adjustSize = debounce(() => {
    window._vCache.clear(index, 0);
    lastAdjustedSize.current = editorRef.current?.scrollHeight ?? 0;
    // after adjusting removing scroll from editor
    if (editorRef.current) {
      editorRef.current.style.height = "unset";
      editorRef.current.style.overflow = "hidden";
    }
  }, 500);

  const handleChange = (e: ContentEditableEvent) => {
    const value = e.target.value;
    textRef.current = value;

    if (editorRef.current) {
      const scrollHeight = editorRef.current.scrollHeight;
      const clientHeight = editorRef.current.clientHeight;
      // if height is more than last adjusted height due to next line then adjust height
      const diff = (scrollHeight ?? 0) - lastAdjustedSize.current;
      if (scrollHeight < lastAdjustedSize.current)
        lastAdjustedSize.current = clientHeight;
      if (diff >= 20 || diff <= -20) {
        // if user goes on next line then adding scroll to editor and adjusting height
        editorRef.current.style.height = `${lastAdjustedSize.current}px`;
        editorRef.current.style.overflowY = "auto";
        adjustSize();
      }
    }

    eventHandlers.onChange?.(e);
  };

  const handlePaste = (e: ClipboardEvent) => {
    // cleaning pasted text and removing html tags
    e.preventDefault();
    // Get the pasted text
    let pastedText =
      e.clipboardData.getData("text/html") ||
      e.clipboardData.getData("text/plain");
    pastedText = sanitizeContentEditable(pastedText);
    pastedText = stripHtml(pastedText)?.trim();

    // if function is available then using it
    if (typeof document.execCommand === "function") {
      document.execCommand("insertText", false, pastedText);
    } else {
      const selection = window.getSelection();

      if (editorRef.current && selection) {
        const textNode = document.createTextNode(pastedText);
        const range = selection.getRangeAt(0);
        // insert text content at cursor position
        range.deleteContents();
        range.insertNode(textNode);

        // simulating change event for editor after paste
        simulateEditableChangeEvent(editorRef.current);
      }
    }
  };

  const handleSelect = (e: SyntheticEvent) => {
    dispatchGlobalSelectEvent({ target: e.target });
    const selection = window.getSelection();
    if (selection?.rangeCount && isSelectionProperWord(textRef.current)) {
      const range = selection.getRangeAt(0);
      const rangeData = getRangeDataForRedux(range);
      if (!rangeData.rangeError) {
        const { left, width, top } = getSelectionDimensions();
        setSelectionMenu({
          left: left,
          selectionWidth: width,
          top,
          open: true
        });
        isMenuOpen.current = true;

        currentRangeData.current = rangeData;
      }
    } else closeMenu();
  };

  const handleFocus: FocusEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    eventHandlers.onFocus?.(blockId);

    if (translationEditor) {
      trackEvent(MIXPANEL_EVENTS.EDIT_TRANSLATION_START, {
        "Block ID": blockId,
        "Block Length": isAudioLong ? "Long" : isAudioShort ? "Short" : "Ok"
      });
    }
  };

  const handleBlur = () => {
    nextTick(() => {
      if (!stopSelectionMenuFromClosing.current) {
        closeMenu();
      } else {
        stopSelectionMenuFromClosing.current = false;
      }
    }, 100);

    trackEvent(MIXPANEL_EVENTS.EDIT_TRANSLATION_SUCCESS, {
      "Block ID": blockId,
      "Block Length": isAudioLong ? "Long" : isAudioShort ? "Short" : "Ok"
    });
  };

  const closeMenu = () => {
    if (isMenuOpen.current) {
      isMenuOpen.current = false;
      setSelectionMenu({ ...selectionMenu, open: false });
    }
  };

  const onKeyDown = (e: KeyboardEvent) => {
    // if its enter then prevent it from going to next line
    if (e.which === 13) e.preventDefault();
    if (
      e.key === KEY_BOARD_KEYS.ArrowUp &&
      window.getSelection()?.toString().length
    ) {
      e.preventDefault();
      stopSelectionMenuFromClosing.current = true;
      const pronunciationMenu = document.getElementById(
        "pronunciation-selection-button"
      );
      if (pronunciationMenu) pronunciationMenu.focus();
    }
  };

  return (
    <StyledBox>
      <ContentEditable
        className={`editor customized-scrollbar ${
          !disabled && isActive ? "active" : ""
        }`}
        html={textRef.current}
        onChange={handleChange}
        onFocus={handleFocus}
        onSelect={translationEditor ? handleSelect : undefined}
        onBlur={translationEditor ? handleBlur : undefined}
        onKeyDown={translationEditor ? onKeyDown : undefined}
        onPaste={handlePaste}
        innerRef={editorRef}
        data-blockid={translationEditor ? blockId : `transcription-${blockId}`}
        disabled={disabled}
        id="tvo-editor"
      />
      {selectionMenu.open ? (
        <TextSelectionMenu
          left={selectionMenu.left}
          top={selectionMenu.top}
          width={selectionMenu.selectionWidth}
          editorRef={editorRef}
          rangeData={currentRangeData.current}
          closeMenu={closeMenu}
        />
      ) : null}
    </StyledBox>
  );
};

export default memo(Editor);
