export type TBaseMember = {
  uid: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  middleName: string | null;
  profileImageUrl: string | undefined;
};

export enum QC_ACCESS_ROLES {
  TRANSLATOR = "TRANSLATOR",
  VO_SPECIALIST = "VO_SPECIALIST",
  FACE_MARKING = "FACE_MARKING",
  QC = "QC",
  TRANSLATION = "TRANSLATION",
  VOICEOVER = "VOICEOVER",
  TRANSLATION_VOICEOVER = "TRANSLATION_VOICEOVER",
  QUALITY_CHECK = "QUALITY_CHECK"
}

export enum USER_ROLES {
  ADMIN = "ADMIN",
  ACC_MANAGER = "ACC_MANAGER",
  TRANSLATOR = "TRANSLATOR",
  VO_SPECIALIST = "VO_SPECIALIST",
  USER = "USER",
  QUALITY_CHECK = "QUALITY_CHECK"
}
