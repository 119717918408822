import { rest } from "msw";
import { PRONUNCIATION_SUGGESTIONS } from "../../constants/dummy";
import { API_BASE_URL } from "@/config/config";
import { mockAuthCheck } from "@/utils/mockUtils";
import { SERVER_RESPONSES } from "@/constants/serverConstants";

export const pronunciationMocks = [
  rest.get(
    `${API_BASE_URL}pronunciation/suggest/:id?word=good`,
    (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }
      const { id } = req.params;
      const pronunSuggestions = {
        responseData: PRONUNCIATION_SUGGESTIONS,
        responseCode: SERVER_RESPONSES.SUCCESS
      };
      if (id === "404") {
        return res(ctx.status(404));
      } else if (id === "429") {
        return res(
          ctx.delay(5000),
          ctx.status(200),
          ctx.json(pronunSuggestions)
        );
      }
      return res(ctx.status(200), ctx.json(pronunSuggestions));
    }
  ),
  rest.post(
    `${API_BASE_URL}/:blockId/check-pronunciation/:voiceId`,
    (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }
      const { id } = req.params;
      const payload = {
        url: "https://murf-inc-user-upload-dev.s3.us-east-2.amazonaws.com/W017103313737213VY/multipart/processed/ASSET_BG_AUD/MP7way3rl3ZC_48Kclean.wav?response-cache-control=max-age%3D604801&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240508T000000Z&X-Amz-SignedHeaders=host&X-Amz-Expires=259200&X-Amz-Credential=AKIAVUFZ2XGAH2CKTPTO%2F20240508%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=7678afe6c733291bafc2da2b4d1d95b48ddd7189e71c491ecce7549d58b7bcd8"
      };
      if (id === "404") {
        return res(ctx.status(404));
      } else if (id === "429") {
        return res(ctx.delay(5000), ctx.status(200), ctx.json(payload));
      }
      return res(ctx.status(200), ctx.json(payload));
    }
  )
];
