import { useEffect, useRef } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useTypedSelector, useAppDispatch } from "@/config/configureAppStore";
import { setSnackBar } from "@/reducers/slices/dialogSlice/dialogSlice";
import { useCallback } from "react";

const SNACKBAR_DURATION = 6000;

export function MurfSnackbar() {
  const { open, message, type } = useTypedSelector(
    (state) => state.dialog.snackBar
  );

  const dispatch = useAppDispatch();

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setSnackBar({ open: false, message: "", type: type }));
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={SNACKBAR_DURATION}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert variant="filled" severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export function useSnackbar() {
  const dispatch = useAppDispatch();
  const { open } = useTypedSelector((state) => state.dialog.snackBar);
  const openRef = useRef<boolean>();

  // This is to reset the redux store in the rare scenario that
  // the snackbar control is used at a location where
  // Snackbar is not rendered
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;
    openRef.current = open;

    if (open) {
      timer = setTimeout(() => {
        if (openRef.current) {
          dispatch(setSnackBar({ open: false, message: "", type: undefined }));
          console.warn("Snackbar auto close called");
        }
      }, SNACKBAR_DURATION + 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [open, dispatch]);

  const showSnackbar = useCallback(
    (message: string, type: "success" | "error" | "warning" | "info") => {
      dispatch(setSnackBar({ open: true, message, type }));
    },
    [dispatch]
  );

  const showSuccess = useCallback(
    (message: string) => {
      showSnackbar(message, "success");
    },
    [showSnackbar]
  );

  const showError = useCallback(
    (message: string) => {
      showSnackbar(message, "error");
    },
    [showSnackbar]
  );

  return { showSnackbar, showError, showSuccess };
}
