import { MURF_ROLES } from "@/features/user";
import { PolicyFunction } from "..";
import { WORKSPACE_ROLES } from "@/types/workspace";

// Check if the user is a customer
export const isCustomerPolicy: PolicyFunction<null> = ({ user }) => {
  return user?.role === MURF_ROLES.USER;
};

// Check if the user is a customer admin
export const isWorkspaceManagerPolicy: PolicyFunction<null> = ({
  workspace
}) => {
  return [WORKSPACE_ROLES.ADMIN, WORKSPACE_ROLES.OWNER].includes(
    workspace?.roleType as WORKSPACE_ROLES
  );
};

// Check if the user's workspace has enough credits
export const hasEnoughCreditsPolicy: PolicyFunction<{
  requiredCredits: number;
}> = ({ workspace, policyArgs }) => {
  const availableCredits =
    (workspace?.assignedCredits || 0) - (workspace?.usedCredits || 0);
  if (!availableCredits) return false;
  return availableCredits >= policyArgs.requiredCredits;
};

export const shouldShowCreditsPromptPolicy: PolicyFunction<null> = ({
  workspace
}) => {
  if (!workspace) {
    return false;
  }
  const availableCredits =
    (workspace?.assignedCredits || 0) - (workspace?.usedCredits || 0);
  if (availableCredits <= 0 || workspace.planExpiryMillis < Date.now()) {
    return true;
  }

  return false;
};
