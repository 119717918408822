import { useCallback, useMemo } from "react";
import { PolicyFunction } from "../types";
import { useTypedSelector } from "@/config/configureAppStore";
import {
  canMurfRoleAccessPolicy,
  canWorkspaceRoleAccessPolicy,
  isMurfAdminPolicy,
  isMurfManagerPolicy
} from "..";
import { WORKSPACE_ROLES } from "@/types/workspace";
import { MURF_ROLES } from "@/features/user";

export function usePermissions() {
  const user = useTypedSelector((state) => state.user);
  const activeWorkspaceId = useTypedSelector(
    (state) => state.user.activeWorkspaceId
  );
  const userLinkedWorkspaces = useTypedSelector(
    (state) => state.user.linkedWorkspaces
  );

  const currentWorkspace = useMemo(() => {
    return userLinkedWorkspaces.find(
      (w) => w.workspaceId === activeWorkspaceId
    );
  }, [userLinkedWorkspaces, activeWorkspaceId]);

  const canAccess = useCallback(
    <T,>({
      policy,
      policyArgs
    }: {
      policy: PolicyFunction<T>;
      policyArgs: T;
    }) => {
      return policy({ user, workspace: currentWorkspace, policyArgs });
    },
    [user, currentWorkspace]
  );

  const canMurfRoleAccess = useCallback(
    ({ allowedRoles }: { allowedRoles: MURF_ROLES[] }) => {
      return canAccess({
        policy: canMurfRoleAccessPolicy,
        policyArgs: { allowedRoles }
      });
    },
    [canAccess]
  );

  const canWorkspaceRoleAccess = useCallback(
    ({ allowedRoles }: { allowedRoles: WORKSPACE_ROLES[] }) => {
      return canAccess({
        policy: canWorkspaceRoleAccessPolicy,
        policyArgs: { allowedRoles }
      });
    },
    [canAccess]
  );

  const isAdmin = useMemo(
    () => canAccess({ policy: isMurfAdminPolicy, policyArgs: null }),
    [canAccess]
  );

  const isDeliveryManager = useMemo(
    () => canAccess({ policy: isMurfManagerPolicy, policyArgs: null }),
    [canAccess]
  );

  return {
    canAccess,
    canMurfRoleAccess,
    canWorkspaceRoleAccess,
    isAdmin,
    isDeliveryManager
  };
}

export * from "../types";
