import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const ScriptStudio = lazy(() => import("./ScriptStudio"));

export const scriptStudioRoutes: RouteObject[] = [
  {
    path: `file/:fileId/dub/:dubId/translation-vo`,
    element: <ScriptStudio />
  }
];
