import {
  captureException,
  init,
  reactRouterV6BrowserTracingIntegration,
  setUser
} from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom";

export const initializeSentry = () => {
  if (window.location.hostname === "localhost") {
    console.debug("Sentry not initialized in localhost");
    return;
  }
  init({
    dsn: "https://0366874e131924352541902ca1a51475@o4504603155759104.ingest.sentry.io/4506823256309760",
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType: useNavigationType,
        createRoutesFromChildren: createRoutesFromChildren,
        matchRoutes: matchRoutes
      })
    ],
    environment: import.meta.env.VITE_MURF_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0
  });
};

export const setSentryUser = (userId: string) => {
  setUser({ id: userId });
};

export const logSentryException = (exception: any) => {
  captureException(exception);
};
