import { rest } from "msw";
import { API_BASE_URL } from "@/config/config";

import { mockAuthCheck } from "@/utils/mockUtils";

const getAllIssuesCount = 5;
const tags: string[] = [];

const customText =
  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo, quis earum libero quae aliquid autem ut, tempore cupiditate id consequatur molestias debitis repellat fugit aut";

const _allIssues: any[] = [];

const USER_ID = "PJci7SK7OnMK9vxz7yAuBgjdPOA3";

let DummyComments = [
  {
    commentId: Date.now().toString(),
    createdBy: "jVZfLOo1azNMBlNJcn2X3SNOX343",
    createdAt: new Date(),
    updatedAt: new Date(),
    text: [
      {
        text: "can we do this later?",
        type: "TEXT"
      }
    ],
    isTeamComment: false
  },
  {
    commentId: (Date.now() + 120000).toString(),
    createdBy: USER_ID,
    createdAt: new Date(),
    updatedAt: new Date(),
    text: [
      {
        text: "nope. need this on urgent basis",
        type: "TEXT"
      }
    ],
    isTeamComment: true
  }
];

export const issueCommentsMocks = [
  // ISSUE_ADD
  rest.post(
    `${API_BASE_URL}file/:fileId/dub/:dubId/issue/ADD`,
    async (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      const { dubId, fileId } = req.params;

      const data = await req.json();

      return res(
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          data: {
            dubId: dubId,
            fileId: fileId,
            issue: {
              issueId: Date.now(),
              createdBy: USER_ID,
              createdAt: new Date(),
              startTimeStamp: data.startTimeStamp,
              tags: data.tags,
              title: data.title,
              comments: [],
              dubId,
              fileId
            }
          }
        })
      );
    }
  ),
  // ISSUE_EDIT
  rest.post(
    `${API_BASE_URL}file/:fileId/dub/:dubId/issue/:issueId/EDIT`,
    async (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      const { dubId, fileId, issueId } = req.params;

      const data = await req.json();

      return res(
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          data: {
            dubId: dubId,
            fileId: fileId,
            issue: {
              issueId: issueId,
              createdBy: USER_ID,
              startTimeStamp: data.startTimeStamp,
              tags: data.tags,
              title: data.title,
              dubId,
              fileId
            }
          }
        })
      );
    }
  ),
  // ISSUE_DELETE
  rest.delete(
    `${API_BASE_URL}file/:fileId/dub/:dubId/issue/:issueId/DELETE`,
    async (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      const { dubId, fileId, issueId } = req.params;

      return res(
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          data: {
            dubId: dubId,
            fileId: fileId,
            issueId: issueId
          }
        })
      );
    }
  ),
  // GET ALL ISSUES
  rest.get(
    `${API_BASE_URL}file/:fileId/dub/:dubId/version/:dubVersion/ALL`,
    async (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      const { dubId, fileId, dubVersion } = req.params;

      const duration = req.url.searchParams.get("duration");

      const allIssues = Array(Math.ceil(Math.random() * getAllIssuesCount))
        .fill("")
        .map(() => ({
          issueId: Date.now(),
          createdBy: USER_ID,
          createdAt: new Date(),
          startTimeStamp: Math.floor(Math.random() * Number(duration)),
          tags: tags
            .sort(() => Math.random() - 0.5)
            .slice(0, Math.floor(Math.random() * tags.length)),
          title: [
            {
              type: "TEXT",
              text: customText.slice(
                0,
                Math.floor(Math.random() * customText.length)
              )
            }
          ],
          comments: [],
          dubId,
          fileId
        }));

      _allIssues.push(...allIssues);

      const allIssuesObj = _allIssues.reduce((obj, issue) => {
        obj[issue.issueId] = issue;
        return obj;
      }, {});

      const data = {
        dubId: dubId,
        fileId: fileId,
        dubVersion: dubVersion,
        issues: allIssuesObj
      };

      return res(
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          data: data
        })
      );
    }
  ),
  // ISSUE_MARK_AS_READ
  rest.post(
    `${API_BASE_URL}file/:fileId/dub/:dubId/issue/:issueId/READ`,
    async (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      const { dubId, fileId, issueId } = req.params;

      return res(
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          data: {
            dubId: dubId,
            fileId: fileId,
            issue: {
              issueId: issueId,
              lastRead: new Date(),
              dubId,
              fileId
            }
          }
        })
      );
    }
  ),
  // ISSUE_MARK_AS_UNREAD
  rest.post(
    `${API_BASE_URL}file/:fileId/dub/:dubId/issue/:issueId/UNREAD`,
    async (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      const { dubId, fileId, issueId } = req.params;

      return res(
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          data: {
            dubId: dubId,
            fileId: fileId,
            issue: {
              issueId: issueId,
              lastRead: 0,
              dubId,
              fileId
            }
          }
        })
      );
    }
  ),
  // ISSUE_MARK_RESOLVED
  rest.post(
    `${API_BASE_URL}file/:fileId/dub/:dubId/issue/:issueId/RESOLVED`,
    async (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      const { dubId, fileId, issueId } = req.params;

      return res(
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          data: {
            dubId: dubId,
            fileId: fileId,
            issue: {
              issueId: issueId,
              resolved: true,
              dubId,
              fileId
            }
          }
        })
      );
    }
  ),
  // ISSUE_MARK_UNRESOLVED
  rest.post(
    `${API_BASE_URL}file/:fileId/dub/:dubId/issue/:issueId/UNRESOLVED`,
    async (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      const { dubId, fileId, issueId } = req.params;

      return res(
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          data: {
            dubId: dubId,
            fileId: fileId,
            issue: {
              issueId: issueId,
              resolved: false,
              dubId,
              fileId
            }
          }
        })
      );
    }
  ),
  // GET_ALL_COMMENTS_BY_ISSUE_ID
  rest.get(
    `${API_BASE_URL}file/:fileId/dub/:dubId/issue/:issueId/comments/ALL`,
    async (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      const { dubId, fileId, issueId } = req.params;

      const allCommentsObj: { [key: string]: any } = DummyComments.reduce(
        (obj: { [key: string]: any }, comment) => {
          obj[comment.commentId] = {
            ...comment,
            dubId,
            fileId,
            issueId
          };
          return obj;
        },
        {}
      );

      return res(
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          data: {
            dubId: dubId,
            fileId: fileId,
            issueId: issueId,
            comments: allCommentsObj
          }
        })
      );
    }
  ),
  // COMMENT_ADD
  rest.post(
    `${API_BASE_URL}file/:fileId/dub/:dubId/issue/:issueId/comment/ADD`,
    async (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      const { fileId, dubId, issueId } = req.params;

      const data = await req.json();

      const comment = {
        commentId: Date.now().toString(),
        createdBy: USER_ID,
        createdAt: new Date(),
        updatedAt: new Date(),
        text: data.text,
        isTeamComment: data.isTeamComment
      };

      DummyComments.push(comment);

      return res(
        ctx.delay(2000),
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          data: {
            issueId,
            comment: {
              ...comment,
              dubId,
              fileId,
              issueId
            }
          }
        })
      );
    }
  ),
  // COMMENT_EDIT
  rest.post(
    `${API_BASE_URL}file/:fileId/dub/:dubId/issue/:issueId/comment/:commentId/EDIT`,
    async (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      const { dubId, fileId, issueId, commentId } = req.params;

      const data = await req.json();

      const newPayload = {
        commentId: commentId,
        createdBy: USER_ID,
        updatedAt: new Date(),
        text: data.text,
        isTeamComment: data.isTeamComment
      };

      DummyComments = DummyComments.map((comment) => {
        if (comment.commentId === commentId) {
          return {
            ...comment,
            ...newPayload
          } as (typeof DummyComments)[0];
        }
        return comment;
      });

      return res(
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          data: {
            issueId,
            comment: {
              ...newPayload,
              dubId,
              fileId,
              issueId
            }
          }
        })
      );
    }
  ),
  // COMMENT_DELETE
  rest.delete(
    `${API_BASE_URL}file/:fileId/dub/:dubId/issue/:issueId/comment/:commentId/DELETE`,
    async (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }

      const { issueId, commentId } = req.params;

      return res(
        ctx.status(200),
        ctx.json({
          responseCode: "SUCCESS",
          extra: null,
          data: {
            issueId,
            commentId
          }
        })
      );
    }
  )
];
