import { TuningFile, Clip, FaceMarkClip } from "@/features/tuning/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { sourceFileThunks } from "@/reducers/thunks/sourceFile";
import { APIResponse } from "@/types/apiResponse";
import { STATUS } from "@/constants/status";
import { MergeClipParams, findClipIdFromTimeStamp } from "@/features/tuning";
import { faceMarkingThunks } from "@/reducers/thunks/facemarking";

interface ClipSplit {
  clip1StartFrame: number;
  clip2StartFrame: number;
}

export interface TuningSliceState {
  sourceFile: TuningFile;
  clipsMap: Record<string, Clip>;
  clipsOrder: number[];
  clipPropertyQueue: number[];
  clipChangeStack: ClipSplit[];
  sourceFileAPIStatus: STATUS;
  currentClipId: number | undefined;
  clipApiStatus: STATUS;
}

const initialState: TuningSliceState = {
  sourceFile: {
    projectName: "",
    sourceFileId: "",
    fileName: "",
    uploadedAt: 0,
    duration: 0,
    downloadUrl: "",
    faceMarkClips: {
      videoFPS: 24,
      clips: [],
      inputVideoResolution: [0, 0]
    }
  },
  clipPropertyQueue: [],
  clipChangeStack: [],
  clipsMap: {},
  clipsOrder: [],
  currentClipId: undefined,
  sourceFileAPIStatus: STATUS.IDLE,
  clipApiStatus: STATUS.IDLE
};

export const tuningSlice = createSlice({
  name: "tuning",
  initialState,
  reducers: {
    updateFileAPIStatus: (state, action) => {
      state.sourceFileAPIStatus = action.payload;
    },
    updateCurrentClipId: (state, action) => {
      state.currentClipId = action.payload;
    },
    splitClip: (
      state,
      action: PayloadAction<{ frame: number; timeStamp: number }>
    ) => {
      const timeStamp = action.payload.timeStamp;
      let frameToUse = action.payload.frame;
      const clipsList = state.clipsOrder.map((id) => state.clipsMap[id]);

      const originalClipStartFrame = findClipIdFromTimeStamp({
        clipsList,
        currentTime: timeStamp,
        fps: state.sourceFile.faceMarkClips.videoFPS
      });

      if (
        originalClipStartFrame !== undefined &&
        originalClipStartFrame !== null
      ) {
        // if the timestamp is at the start or end of the clip, we need to move it by 1 frame
        if (frameToUse === state.clipsMap[originalClipStartFrame].startFrame) {
          frameToUse = frameToUse + 1;
        } else if (
          frameToUse === state.clipsMap[originalClipStartFrame].endFrame
        ) {
          frameToUse = frameToUse - 1;
        }

        const newClip: Clip = {
          ...state.clipsMap[originalClipStartFrame],
          startFrame: frameToUse,
          endFrame: state.clipsMap[originalClipStartFrame].endFrame
        };
        state.clipsMap[frameToUse] = newClip;

        state.clipsMap[originalClipStartFrame] = {
          ...state.clipsMap[originalClipStartFrame],
          endFrame: frameToUse - 1
        };

        const clipIndex = state.clipsOrder.indexOf(originalClipStartFrame);
        state.clipsOrder.splice(clipIndex + 1, 0, frameToUse);
        state.clipChangeStack.push({
          clip1StartFrame: originalClipStartFrame,
          clip2StartFrame: frameToUse
        });
      } else {
        throw new Error("No clip found at this timestamp");
      }
    },
    mergeClipFromChangeStack: (
      state,
      action: PayloadAction<{ lastChange: ClipSplit }>
    ) => {
      const lastChange = action.payload.lastChange;
      if (lastChange) {
        const clip1StartFrame = lastChange.clip1StartFrame;
        const clip2StartFrame = lastChange.clip2StartFrame;
        const clip1 = state.clipsMap[clip1StartFrame];
        const clip2 = state.clipsMap[clip2StartFrame];

        const newClip = {
          ...clip1,
          endFrame: clip2.endFrame
        };

        state.clipsMap[clip1StartFrame] = newClip;

        delete state.clipsMap[clip2StartFrame];

        const clip2Index = state.clipsOrder.indexOf(clip2StartFrame);
        state.clipsOrder.splice(clip2Index, 1);
        state.clipChangeStack.pop();
      }
    },
    updateClip: (
      state,
      action: PayloadAction<{
        clipId: number;
        data: Partial<Clip>;
        shouldNotSync?: boolean;
      }>
    ) => {
      const clipId = action.payload.clipId;
      state.clipsMap[clipId] = {
        ...state.clipsMap[clipId],
        ...action.payload.data
      };
      if (!action.payload.shouldNotSync) {
        state.clipPropertyQueue = [
          ...new Set([...state.clipPropertyQueue, clipId])
        ];
      }
    },
    updateFaceMarkClipSelectedFace: (
      state,
      action: PayloadAction<{
        clipStartFrame: number;
        boundingBoxIndex: number;
      }>
    ) => {
      const clipStartFrame = action.payload.clipStartFrame;
      if (clipStartFrame) {
        state.clipsMap[clipStartFrame].selectedFaceIdx =
          action.payload.boundingBoxIndex;

        state.clipPropertyQueue = [
          ...new Set([...state.clipPropertyQueue, clipStartFrame])
        ];
      }
    },
    addAffectedClipsToSyncApi: (
      state,
      action: PayloadAction<{ clipIds: number[] }>
    ) => {
      if (action.payload.clipIds) {
        state.clipPropertyQueue = [
          ...new Set([...state.clipPropertyQueue, ...action.payload.clipIds])
        ];
      }
    },
    cleanClipPropertyState: (
      state,
      action: PayloadAction<{ clipIdsToDelete: number[] }>
    ) => {
      if (action && action.payload) {
        const clipIds = action.payload.clipIdsToDelete;
        if (clipIds.length) {
          state.clipPropertyQueue = state.clipPropertyQueue.filter(
            (id) => !clipIds.includes(id)
          );
        } else {
          state.clipPropertyQueue = [];
        }
      } else {
        state.clipPropertyQueue = [];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sourceFileThunks.fetchDetailedTuningData.pending, (state) => {
        state.sourceFileAPIStatus = STATUS.LOADING;
      })
      .addCase(
        sourceFileThunks.fetchDetailedTuningData.fulfilled,
        (state, action: PayloadAction<APIResponse<TuningFile>>) => {
          const payload = action.payload.responseData;
          const faceMarkClipClips = payload.faceMarkClips.clips;

          const newClipsMap: Record<string, Clip> = {};

          const newClipsOrder: number[] = [];

          faceMarkClipClips.forEach((clip: FaceMarkClip) => {
            newClipsMap[clip.startFrameIdx] = {
              startFrame: clip.startFrameIdx,
              endFrame: clip.endFrameIdx,
              selectedFaceIdx: clip.selectedFaceIdx,
              boundingBoxes: clip.faceBoundingBoxes,
              thumbnail: clip.thumbnailDownloadUrl ?? "",
              selected: false
            };
            newClipsOrder.push(clip.startFrameIdx);
          });

          state.clipsMap = newClipsMap;
          state.clipsOrder = newClipsOrder;
          state.sourceFile = {
            ...payload,
            faceMarkClips: {
              ...payload.faceMarkClips,
              clips: []
            }
          };
          state.sourceFileAPIStatus = STATUS.SUCCESS;
        }
      )
      .addCase(sourceFileThunks.fetchDetailedTuningData.rejected, (state) => {
        state.sourceFileAPIStatus = STATUS.ERROR;
      })
      .addCase(faceMarkingThunks.splitClips.pending, (state) => {
        state.clipApiStatus = STATUS.LOADING;
      })
      .addCase(
        faceMarkingThunks.splitClips.fulfilled,
        (
          state,
          action: PayloadAction<APIResponse<Partial<FaceMarkClip>[]>>
        ) => {
          state.clipApiStatus = STATUS.IDLE;
          const clips = action.payload.responseData;
          clips.forEach((clip) => {
            const newClip: Clip = {
              startFrame: clip.startFrameIdx!,
              endFrame: clip.endFrameIdx!,
              selectedFaceIdx: clip.selectedFaceIdx!,
              thumbnail: clip.thumbnailDownloadUrl ?? "",
              boundingBoxes: clip.faceBoundingBoxes ?? null,
              selected: false
            };
            state.clipsMap[clip.startFrameIdx!] = newClip;
          });
          const clipIndex = state.clipsOrder.indexOf(clips[0].startFrameIdx!);
          state.clipsOrder.splice(clipIndex + 1, 0, clips[1].startFrameIdx!);

          state.clipChangeStack.push({
            clip1StartFrame: clips[0].startFrameIdx!,
            clip2StartFrame: clips[1].startFrameIdx!
          });
        }
      )
      .addCase(faceMarkingThunks.splitClips.rejected, (state) => {
        state.clipApiStatus = STATUS.IDLE;
      })
      .addCase(faceMarkingThunks.mergeClips.pending, (state) => {
        state.clipApiStatus = STATUS.LOADING;
      })
      .addCase(
        faceMarkingThunks.mergeClips.fulfilled,
        (
          state,
          action: PayloadAction<
            APIResponse<Partial<FaceMarkClip>>,
            string,
            { arg: MergeClipParams }
          >
        ) => {
          state.clipApiStatus = STATUS.IDLE;
          const secondClipIndex = state.clipsOrder.indexOf(
            action.meta.arg.clips[1].startFrameIdx!
          );
          state.clipsOrder.splice(secondClipIndex, 1);
          const secondClipStartFrame = action.meta.arg.clips[1].startFrameIdx!;

          delete state.clipsMap[secondClipStartFrame];

          state.clipsMap[action.payload.responseData.startFrameIdx!] = {
            ...state.clipsMap[action.payload.responseData.startFrameIdx!],
            endFrame: action.payload.responseData.endFrameIdx!,
            thumbnail: action.payload.responseData.thumbnailDownloadUrl ?? ""
          };
        }
      );
  }
});

export const {
  updateFileAPIStatus,
  updateCurrentClipId,
  updateClip,
  updateFaceMarkClipSelectedFace,
  splitClip,
  mergeClipFromChangeStack,
  addAffectedClipsToSyncApi,
  cleanClipPropertyState
} = tuningSlice.actions;

export default tuningSlice.reducer;
