import { ScriptBlockType, TextBlockStatus } from "@/features/blocks";

const DUMMY_AUDIO_URL_1 = {
  url: "https://murf.ai/public-assets/misc/example_audio/tts_eg_4s.mp3",
  duration: 4.416
};

const DUMMY_AUDIO_URL_2 = {
  url: "https://murf.ai/public-assets/misc/example_audio/tts_eg_12s.mp3",
  duration: 12.264
};

const DUMMY_AUDIO_URL_3 = {
  url: "https://murf.ai/public-assets/misc/example_audio/tts_eg_17s.mp3",
  duration: 17.544
};

const DUMMY_AUDIO_URL_4 = {
  url: "https://murf.ai/public-assets/misc/example_audio/tts_eg_31s.mp3",
  duration: 30.576
};

export const DUMMY_AUDIO_URL_5 =
  "https://murf.ai/public-assets/new-home/usecase_audios/voice/author_clint_audio.mp3";
export const DUMMY_AUDIO_URL_6 =
  "https://murf.ai/public-assets/new-home/usecase_audios/voice/product_developer_rachel_audio.mp3";
export const DUMMY_AUDIO_URL_7 =
  "https://murf.ai/public-assets/new-home/usecase_audios/voice/educator_marcus_audio.mp3";
export const DUMMY_AUDIO_URL_8 =
  "https://murf.ai/public-assets/new-home/usecase_audios/voice/marketer_ken_natalie_audio.mp3";

// create an array that contains 10 TextBlockType objects with dummy data
// each object should be a different variant of the TextBlockType interface with different "text" and "audioUrl" values
export const FAKE_TEXT_BLOCKS: ScriptBlockType[] = [
  {
    blockId: "1",
    translationMarkAsDone: true,
    translationAudioPlayed: false,
    originalAudioPlayed: true,
    voiceOverMarkAsDone: true,
    original: {
      text: "The value that you want to pass to all the components reading this context inside this provider, no matter how deep. The context value can be of any type. A component calling useContext(SomeContext) inside of the provider receives the value of the innermost corresponding context provider above it.",
      speakerId: "1",
      startTime: 0,
      endTime: DUMMY_AUDIO_URL_1.duration,
      duration: DUMMY_AUDIO_URL_1.duration,
      textUpdatedAt: 0,
      englishTextUpdatedAt: 0
    },
    richText:
      "The value that you want to pass to all the components reading this context inside this provider, no matter how deep. The context value can be of any type. A component calling useContext(SomeContext) inside of the provider receives the value of the innermost corresponding context provider above it.",
    phrasePropertiesList: [
      {
        text: "The value that you want to pass to all the components reading this context inside this provider, no matter how deep. The context value can be of any type. A component calling useContext(SomeContext) inside of the provider receives the value of the innermost corresponding context provider above it.",
        emphasis: "1",
        alt: "1",
        sayAs: false
      }
    ],
    speakerId: "1",
    renderLength: DUMMY_AUDIO_URL_1.duration,
    volume: 0,
    audioUrl: DUMMY_AUDIO_URL_1.url,
    renderVersion: 1,
    needsRendering: false,
    renderStatus: TextBlockStatus.IDLE,
    transcriptionUpdatedAt: 0
  },
  {
    blockId: "2",
    translationMarkAsDone: false,
    translationAudioPlayed: false,
    originalAudioPlayed: true,
    voiceOverMarkAsDone: false,
    original: {
      text: "Hello, world 2!",
      speakerId: "1",
      startTime: DUMMY_AUDIO_URL_1.duration,
      endTime: DUMMY_AUDIO_URL_1.duration + DUMMY_AUDIO_URL_2.duration,
      duration: DUMMY_AUDIO_URL_2.duration,
      textUpdatedAt: 0,
      englishTextUpdatedAt: 0
    },
    richText: "Hello, world 2!",
    phrasePropertiesList: [
      {
        text: "Hello, world 2!",
        emphasis: "1",
        alt: "1",
        sayAs: false
      }
    ],
    speakerId: "2",
    renderLength: DUMMY_AUDIO_URL_2.duration,
    volume: 0,
    audioUrl: DUMMY_AUDIO_URL_2.url,
    renderVersion: 1,
    needsRendering: false,
    renderStatus: TextBlockStatus.SUCCESS,
    transcriptionUpdatedAt: 0
  },
  {
    blockId: "3",
    translationMarkAsDone: false,
    translationAudioPlayed: false,
    originalAudioPlayed: true,
    voiceOverMarkAsDone: false,
    original: {
      text: "Hello, world 3!",
      speakerId: "1",
      startTime: DUMMY_AUDIO_URL_1.duration + DUMMY_AUDIO_URL_2.duration,
      endTime:
        DUMMY_AUDIO_URL_1.duration +
        DUMMY_AUDIO_URL_2.duration +
        DUMMY_AUDIO_URL_3.duration,
      duration: DUMMY_AUDIO_URL_3.duration,
      textUpdatedAt: 0,
      englishTextUpdatedAt: 0
    },
    richText: "Hello, world 3!",
    phrasePropertiesList: [
      {
        text: "Hello, world 3!",
        emphasis: "1",
        alt: "1",
        sayAs: false
      }
    ],
    speakerId: "1",
    renderLength: DUMMY_AUDIO_URL_3.duration,
    volume: 0,
    audioUrl: DUMMY_AUDIO_URL_3.url,
    renderVersion: 1,
    needsRendering: false,
    renderStatus: TextBlockStatus.ERROR,
    transcriptionUpdatedAt: 0
  },
  {
    blockId: "4",
    translationMarkAsDone: false,
    translationAudioPlayed: false,
    originalAudioPlayed: true,
    voiceOverMarkAsDone: false,
    original: {
      text: "Hello, world 4!",
      speakerId: "1",
      startTime:
        DUMMY_AUDIO_URL_1.duration +
        DUMMY_AUDIO_URL_2.duration +
        DUMMY_AUDIO_URL_3.duration,
      endTime:
        DUMMY_AUDIO_URL_1.duration +
        DUMMY_AUDIO_URL_2.duration +
        DUMMY_AUDIO_URL_3.duration +
        DUMMY_AUDIO_URL_4.duration,
      duration: DUMMY_AUDIO_URL_4.duration,
      textUpdatedAt: 0,
      englishTextUpdatedAt: 0
    },
    richText: "Hello, world 4!",
    phrasePropertiesList: [
      {
        text: "Hello, world 4!",
        emphasis: "1",
        alt: "1",
        sayAs: false
      }
    ],
    speakerId: "3",
    renderLength: DUMMY_AUDIO_URL_4.duration,
    volume: 0,
    audioUrl: DUMMY_AUDIO_URL_4.url,
    renderVersion: 1,
    needsRendering: false,
    renderStatus: TextBlockStatus.ERROR,
    transcriptionUpdatedAt: 0
  },
  {
    blockId: "5",
    translationMarkAsDone: true,
    translationAudioPlayed: false,
    originalAudioPlayed: true,
    voiceOverMarkAsDone: false,
    original: {
      text: "Hello, world 5!",
      speakerId: "1",
      startTime: 17,
      endTime: 19.5,
      duration: 2.5,
      textUpdatedAt: 0,
      englishTextUpdatedAt: 0
    },
    richText: "Hello, world 5!",
    phrasePropertiesList: [
      {
        text: "Hello, world 5!",
        emphasis: "1",
        alt: "1",
        sayAs: false
      }
    ],
    speakerId: "2",
    renderLength: DUMMY_AUDIO_URL_1.duration,
    volume: 0,
    audioUrl: DUMMY_AUDIO_URL_1.url,
    renderVersion: 1,

    needsRendering: false,
    renderStatus: TextBlockStatus.SUCCESS,
    transcriptionUpdatedAt: 0
  },
  {
    blockId: "6",
    translationMarkAsDone: false,
    translationAudioPlayed: false,
    originalAudioPlayed: true,
    voiceOverMarkAsDone: false,
    original: {
      text: "Hello, world 6!",
      speakerId: "1",
      startTime: 20,
      endTime: 24.5,
      duration: 4.5,
      textUpdatedAt: 0,
      englishTextUpdatedAt: 0
    },
    richText: "Hello, world 6!",
    phrasePropertiesList: [
      {
        text: "Hello, world 6!",
        emphasis: "1",
        alt: "1",
        sayAs: false
      }
    ],
    speakerId: "3",
    renderLength: DUMMY_AUDIO_URL_2.duration,
    volume: 0,
    audioUrl: DUMMY_AUDIO_URL_2.url,
    renderVersion: 1,

    needsRendering: false,
    renderStatus: TextBlockStatus.SUCCESS,
    transcriptionUpdatedAt: 0
  },
  {
    blockId: "7",
    translationMarkAsDone: false,
    translationAudioPlayed: false,
    originalAudioPlayed: true,
    voiceOverMarkAsDone: false,
    original: {
      text: "Hello, world 7!",
      speakerId: "1",
      startTime: 25,
      endTime: 26.5,
      duration: 1.5,
      textUpdatedAt: 0,
      englishTextUpdatedAt: 0
    },
    richText: "Hello, world 7!",
    phrasePropertiesList: [
      {
        text: "Hello, world 7!",
        emphasis: "1",
        alt: "1",
        sayAs: false
      }
    ],
    speakerId: "4",
    renderLength: DUMMY_AUDIO_URL_3.duration,
    volume: 0,
    audioUrl: DUMMY_AUDIO_URL_3.url,
    renderVersion: 1,

    needsRendering: false,
    renderStatus: TextBlockStatus.IDLE,
    transcriptionUpdatedAt: 0
  },
  {
    blockId: "8",
    translationMarkAsDone: false,
    translationAudioPlayed: false,
    originalAudioPlayed: true,
    voiceOverMarkAsDone: false,
    original: {
      text: "Hello, world 8!",
      speakerId: "1",
      startTime: 27,
      endTime: 29.5,
      duration: 2.5,
      textUpdatedAt: 0,
      englishTextUpdatedAt: 0
    },
    richText: "Hello, world 8!",
    phrasePropertiesList: [
      {
        text: "Hello, world 8!",
        emphasis: "1",
        alt: "1",
        sayAs: false
      }
    ],
    speakerId: "1",
    renderLength: DUMMY_AUDIO_URL_4.duration,
    volume: 0,
    audioUrl: DUMMY_AUDIO_URL_4.url,
    renderVersion: 1,
    needsRendering: false,
    renderStatus: TextBlockStatus.IDLE,
    transcriptionUpdatedAt: 0
  },
  {
    blockId: "9",
    translationMarkAsDone: false,
    translationAudioPlayed: false,
    originalAudioPlayed: true,
    voiceOverMarkAsDone: false,
    original: {
      text: "Hello, world 9!",
      speakerId: "1",
      startTime: 30,
      endTime: 34.5,
      duration: 4.5,
      textUpdatedAt: 0,
      englishTextUpdatedAt: 0
    },
    richText: "Hello, world 9!",
    phrasePropertiesList: [
      {
        text: "Hello, world 9!",
        emphasis: "1",
        alt: "1",
        sayAs: false
      }
    ],
    speakerId: "1",
    renderLength: DUMMY_AUDIO_URL_1.duration,
    volume: 0,
    audioUrl: DUMMY_AUDIO_URL_1.url,
    renderVersion: 1,

    needsRendering: false,
    renderStatus: TextBlockStatus.SUCCESS,
    transcriptionUpdatedAt: 0
  },
  {
    blockId: "10",
    translationMarkAsDone: false,
    translationAudioPlayed: false,
    originalAudioPlayed: true,
    voiceOverMarkAsDone: false,
    original: {
      text: "Hello, world 10!",
      speakerId: "1",
      startTime: 35,
      endTime: 39.5,
      duration: 4.5,
      textUpdatedAt: 0,
      englishTextUpdatedAt: 0
    },
    richText: "Hello, world 10!",
    phrasePropertiesList: [
      {
        text: "Hello, world 10!",
        emphasis: "1",
        alt: "1",
        sayAs: false
      }
    ],
    speakerId: "2",

    volume: 0,
    audioUrl: DUMMY_AUDIO_URL_2.url,
    renderVersion: 1,
    needsRendering: false,
    renderLength: 0,
    renderStatus: TextBlockStatus.SUCCESS,
    transcriptionUpdatedAt: 0
  }
];

export const PRONUNCIATION_SUGGESTIONS = {
  dictionary: [
    {
      alt: null,
      ipa: "ɡʊd",
      tags: ["adj", "US", "Received-Pronunciation"],
      voiceId: null
    },
    {
      alt: null,
      ipa: "ˈɡʊd",
      tags: null,
      voiceId: null
    },
    {
      alt: null,
      ipa: "ɡɪd",
      tags: null,
      voiceId: null
    },
    {
      alt: null,
      ipa: "ɡˈʊd",
      tags: null,
      voiceId: null
    }
  ],
  personalList: [
    {
      alt: "goodat",
      ipa: null,
      tags: null,
      voiceId: "VM016480263749071LD"
    },
    {
      alt: null,
      ipa: "ɡɪd",
      tags: null,
      voiceId: "VM016480263749071LD"
    },
    {
      alt: "[Goood (Exclaim)]",
      ipa: null,
      tags: null,
      voiceId: "VM016480263749071LD"
    },
    {
      alt: "gooday",
      ipa: null,
      tags: null,
      voiceId: "VM016480263749071LD"
    },
    {
      alt: "thisisgoodday",
      ipa: null,
      tags: null,
      voiceId: "VM016480263749071LD"
    },
    {
      alt: null,
      ipa: "car",
      tags: null,
      voiceId: "VM016406731623311DD"
    },
    {
      alt: null,
      ipa: "wel",
      tags: null,
      voiceId: "VM016406731623311DD"
    }
  ],
  sharedList: null,
  numerics: null,
  abbreviations: null,
  interjections: [
    {
      alt: "[Goood (Exclaim)]",
      ipa: null,
      tags: ["Goood", "Exclaim"],
      voiceId: null
    },
    {
      alt: "[Gadzooks (Xclaim)]",
      ipa: null,
      tags: ["Gadzooks", "Xclaim"],
      voiceId: null
    },
    {
      alt: "[You go girl (Short)]",
      ipa: null,
      tags: ["You go girl", "Short"],
      voiceId: null
    }
  ],
  others: null
};
