const FourOFourSvg = ({ color }: { color: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="391"
    height="151"
    viewBox="0 0 391 151"
    fill="none"
  >
    <path
      d="M0.199219 113.637V110.228L66.6765 2.27344H72.0742L6.73331 108.523V109.092H89.6879V113.637H0.199219ZM66.9606 147.728V56.8189H71.506V147.728H66.9606Z"
      fill={color}
    />
    <path
      d="M301.309 113.637V110.228L367.786 2.27344H373.184L307.843 108.523V109.092H390.797V113.637H301.309ZM368.07 147.728V56.8189H372.615V147.728H368.07Z"
      fill={color}
    />
    <path
      d="M267.25 75.2852C267.25 115.492 235.098 148.035 195.5 148.035C155.902 148.035 123.75 115.492 123.75 75.2852C123.75 35.0779 155.902 2.53516 195.5 2.53516C235.098 2.53516 267.25 35.0779 267.25 75.2852Z"
      stroke={color}
      strokeWidth="4.5"
    />
    <circle cx="195" cy="114" r="4" fill={color} />
    <path
      d="M191.06 35.9995C191.027 33.8004 192.801 32 195 32C197.199 32 198.973 33.8004 198.94 35.9996L198.045 96.0003C198.02 97.6642 196.664 99 195 99C193.336 99 191.98 97.6642 191.955 96.0003L191.06 35.9995Z"
      fill={color}
    />
  </svg>
);

export default FourOFourSvg;
