import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorCheck } from "@/utils/reduxUtils";
import { tuningProjectApi } from "@/features/tuning";

export const sourceFileThunks = {
  fetchDetailedTuningData: createAsyncThunk(
    "fetchDetailedTuningData",
    async (
      {
        sourceFileId,
        workspaceId
      }: { sourceFileId: string; workspaceId: string },
      thunkAPI
    ) => {
      return errorCheck(async () => {
        const response = await tuningProjectApi.fetchDetailedTuningData({
          sourceFileId: sourceFileId,
          workspaceId
        });
        return response.data;
      }, thunkAPI);
    }
  )
};
