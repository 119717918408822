import {
  Stack,
  Typography,
  Switch,
  useTheme,
  Box,
  Tooltip,
  FormControlLabel
} from "@mui/material";
import { HandleBlockSeekType, LocalScriptBlockType } from "@/features/blocks";
import RenderPlayButton from "../../../RenderPlayButton";
import { markBlock } from "@/reducers/slices/projectUtilitySlice/projectUtilitySlice";
import { ChangeEvent, useMemo } from "react";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { getMSUsingLocaleString } from "@/utils/time";
import { canMarkAsDone } from "@/features/blocks/utils";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import useTvoTrackingEvent from "@/features/scriptStudio/hooks/useTvoTrackingEvent";
import { useIssuesComments } from "@/features/comments/hooks";

interface TextBlockMenuProps {
  block: LocalScriptBlockType;
  showCompleteCheck?: boolean;
  showUnMarkMessage: boolean;
  readOnly?: boolean;
  showPlayback: boolean;
  handleBlockPlay: (isPlay: boolean) => void;
  handleBlockSeek: HandleBlockSeekType;
  isPlaying: boolean;
  hasError: boolean;
  errorMessage: string | null;
  hasTextLengthError: boolean;
  buffering: boolean;
}

export function TextBlockMenu({
  showCompleteCheck,
  showUnMarkMessage,
  block,
  showPlayback = true,
  readOnly = false,
  handleBlockPlay,
  handleBlockSeek,
  isPlaying,
  hasError,
  errorMessage,
  hasTextLengthError,
  buffering
}: TextBlockMenuProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const trackEvent = useTvoTrackingEvent();
  const accessLevel = useTypedSelector(
    (state) => state.currentScriptProject.accessLevel
  );

  const { filteredIssues } = useIssuesComments();

  const markAsDone = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      markBlock({
        blockIds: [block.blockId],
        checked: e.target.checked,
        needToSync: true
      })
    );

    if (e.target.checked) {
      trackEvent(MIXPANEL_EVENTS.MARK_AS_DONE, {
        level: "Block",
        "Entry Point": "Block"
      });
    }
  };

  const durationString = useMemo(() => {
    return getMSUsingLocaleString(block.renderLength, true);
  }, [block.renderLength]);

  const { canMark, reason } = useMemo(
    () => canMarkAsDone(block, accessLevel, filteredIssues),
    [accessLevel, block, filteredIssues]
  );

  return (
    <Stack direction="row" justifyContent={"space-between"}>
      <Stack direction="row" alignItems={"center"} gap={1}>
        {showPlayback ? (
          <RenderPlayButton
            block={block}
            readOnly={readOnly}
            disabled={hasTextLengthError}
            onClick={handleBlockPlay}
            handleBlockSeek={handleBlockSeek}
            isPlaying={isPlaying}
            buffering={buffering}
          />
        ) : (
          <Box sx={{ paddingX: theme.spacing(0.15) }} />
        )}
        <Typography variant="caption" color={theme.palette.text.secondary}>
          {durationString}
        </Typography>
        {hasError ? (
          <Box maxWidth={showCompleteCheck ? 240 : 320}>
            <Tooltip title={errorMessage} placement="top">
              <Typography
                noWrap
                component="p"
                variant="caption"
                color="error.main"
              >
                {errorMessage}
              </Typography>
            </Tooltip>
          </Box>
        ) : null}
      </Stack>
      {showCompleteCheck ? (
        <Tooltip
          placement="top"
          arrow
          disableHoverListener={readOnly}
          open={showUnMarkMessage === true ? showUnMarkMessage : undefined}
          title={
            showUnMarkMessage
              ? "Un-mark to edit"
              : !block.completed && !canMark
              ? reason
              : ""
          }
          PopperProps={{
            disablePortal: true
          }}
        >
          <FormControlLabel
            labelPlacement="start"
            sx={{ gap: 1, marginLeft: 0, marginRight: -1 }}
            slotProps={{ typography: { variant: "caption" } }}
            control={
              <Switch
                size="small"
                color={hasError ? "error" : "success"}
                onChange={markAsDone}
                checked={block.completed}
                disabled={readOnly || !canMark}
              />
            }
            color="text.disabled"
            label="Mark as Done"
          />
        </Tooltip>
      ) : (
        <></>
      )}
    </Stack>
  );
}
