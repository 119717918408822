import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const Users = lazy(() => import("./Users"));
const AddInternalUser = lazy(() => import("./AddInternalUser"));

export const userManagementRoutes: RouteObject[] = [
  {
    path: "user-management",
    element: <Users />,
    children: [
      {
        path: "create",
        element: <AddInternalUser />
      },
      {
        path: "edit/:emailId",
        element: <AddInternalUser edit />
      }
    ]
  }
];
