import { styled } from "@mui/material";

interface GlassmorphismContainerProps {
  width?: number | string;
  height?: number | string;
}

const GlassmorphismContainer = styled("div", {
  shouldForwardProp: (prop) => {
    const propsToBeNotForwarded = ["width", "height", "maxHeight", "maxWidth"];
    return !propsToBeNotForwarded.includes(prop.toString());
  }
})<GlassmorphismContainerProps>(({ theme, width, height }) => ({
  borderRadius: theme.shape.borderRadius * 6,
  padding: theme.spacing(6, 7),
  background: "rgba(45, 51, 61, 0.70)",
  boxShadow: "2px 2px 36px 16px rgba(7, 10, 15, 0.26)",
  backdropFilter: "blur(40px)",
  height: height ?? "100%",
  minWidth: 400,
  maxHeight: 772,
  maxWidth: 560,
  width,
  // borderWidth: 2,
  // borderStyle: "solid",
  // borderImage:
  //   "linear-gradient(to right, rgba(41,43,47,.6), rgba(142,103,69,.44)) 1"
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(3, 8),
    minWidth: "auto"
  }
}));

export default GlassmorphismContainer;
