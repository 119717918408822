import {
  TaskMetaDataMap,
  TaskStatusMetaDataMap
} from "@/features/task/constants";
import { MURF_ROLES } from "@/features/user";
import { TASK_STATUS, TASK_TYPE } from "@/types/project";
import CheckCircleRounded from "@mui/icons-material/CheckCircleRounded";
import ChevronRightRounded from "@mui/icons-material/ChevronRightRounded";
import {
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  linearProgressClasses,
  styled
} from "@mui/material";
import React, { useMemo } from "react";
import isPropValid from "@emotion/is-prop-valid";
import { getTimeDifference } from "@/utils/time";

export type TaskProgressProps = {
  size?: "small" | "large";
  taskType: TASK_TYPE;
  taskStatus?: TASK_STATUS;
  assigneeId?: string;
  dueBy?: number;
  percentageCompletion?: number;
  pendingIssues?: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  accessorRole?: MURF_ROLES;
  userId?: string;
  taskName?: string;
  filterDisabled?: boolean;
};

type TaskColor = "secondary" | "warning" | "success" | "error";

type TaskStyleProps = Pick<TaskProgressProps, "size"> & {
  color: TaskColor;
  disabled?: boolean;
  filterDisabled?: boolean;
};

const TaskWrapper = styled("div", {
  shouldForwardProp: (propName) =>
    typeof propName === "string" && isPropValid(propName),
  name: "MFTaskProgress",
  slot: "root"
})<TaskStyleProps>(({ theme, size, color, disabled, filterDisabled }) => ({
  padding: theme.spacing(size === "small" ? 0.5 : 0.25),
  borderRadius: theme.shape.borderRadius / (size === "small" ? 0.5 : 1),
  cursor: disabled ? "not-allowed" : "pointer",

  ...(filterDisabled && { opacity: 0.5 }),

  "&:hover": {
    backgroundColor:
      color === "secondary"
        ? theme.palette.action.hover
        : theme.palette[color].hover
  },

  [`& .MFTask-secondary-text`]: {
    fontSize: "0.625rem",
    lineHeight: 1.3,
    letterSpacing: "0.1px"
  },

  [`& .${linearProgressClasses.root}, & .${linearProgressClasses.bar}`]: {
    borderRadius: theme.shape.borderRadius
  }
}));

const TaskProgress = ({
  size = "large",
  taskType,
  assigneeId = "NOT_ASSIGNED",
  taskStatus = TASK_STATUS.UPCOMING,
  dueBy = 0,
  percentageCompletion = 0,
  pendingIssues = 0,
  accessorRole = MURF_ROLES.CONTRIBUTOR,
  onClick,
  userId,
  taskName,
  filterDisabled
}: TaskProgressProps) => {
  const alertMessage = useMemo(() => {
    const timeDifference = getTimeDifference(dueBy);
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    if (daysDifference <= 0) {
      return "MISSED DEADLINE";
    } else if (daysDifference <= 1) {
      return "DELAYED";
    }
    return "";
  }, [dueBy]);

  const color = useMemo<TaskColor>(() => {
    if (taskStatus === TASK_STATUS.COMPLETED) {
      return "success";
    } else if (taskStatus === TASK_STATUS.FAILED) {
      return "error";
    }
    if (alertMessage === "DELAYED") {
      return "warning";
    }
    if (alertMessage === "MISSED DEADLINE") {
      return "error";
    }
    if (!assigneeId || assigneeId === "NOT_ASSIGNED") {
      return "warning";
    }
    return "secondary";
  }, [taskStatus, alertMessage, assigneeId]);

  // not a complex operation so no need of useMemo
  const percentage =
    taskStatus === TASK_STATUS.COMPLETED
      ? 100
      : taskStatus === TASK_STATUS.ONGOING
      ? percentageCompletion
      : 0;

  const disabledReason = useMemo<string>(() => {
    if (taskStatus === TASK_STATUS.UPCOMING) {
      return "Please wait, Task is still Processing";
    } else if (taskStatus === TASK_STATUS.FAILED) {
      return "Processing Failed, Please contact Admin";
    }

    if (
      !assigneeId ||
      (assigneeId === "NOT_ASSIGNED" && accessorRole === MURF_ROLES.CONTRIBUTOR)
    ) {
      return "Task Assignment is Pending, Please assign the task";
    } else if (
      userId !== assigneeId &&
      accessorRole === MURF_ROLES.CONTRIBUTOR
    ) {
      return "Not Permitted to access Task, Please contact Admin to reassign";
    }
    return "";
  }, [taskStatus, assigneeId, accessorRole, userId]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabledReason) {
      return;
    }
    onClick?.(event);
  };

  return (
    <Tooltip title={disabledReason}>
      <TaskWrapper
        disabled={Boolean(disabledReason)}
        size={size}
        color={color}
        onClick={handleClick}
        filterDisabled={filterDisabled}
      >
        <LinearProgress
          variant="determinate"
          color={color}
          value={percentage}
        />

        <Stack direction="row" gap={0.5} mt={0.5} alignItems="start">
          {taskStatus === TASK_STATUS.COMPLETED && (
            <CheckCircleRounded sx={{ width: 16 }} color="success" />
          )}
          <div style={{ flexGrow: 1 }}>
            <Stack direction="row" gap={0.5} alignItems="center">
              <Typography variant="subtitle2">
                {taskName ?? TaskMetaDataMap[taskType]?.title ?? ""}
              </Typography>
              {alertMessage && taskStatus !== TASK_STATUS.COMPLETED ? (
                <Typography
                  className="MFTask-secondary-text"
                  fontWeight={500}
                  color={(theme) => theme.palette[color].main}
                >
                  {alertMessage}
                </Typography>
              ) : null}
            </Stack>
            <Stack direction="row" gap={0.5} alignItems="center">
              {!assigneeId || assigneeId === "NOT_ASSIGNED" ? (
                <Typography
                  className="MFTask-secondary-text"
                  color="text.secondary"
                >
                  Assignment Pending
                </Typography>
              ) : null}
              <Typography
                className="MFTask-secondary-text"
                color="text.secondary"
              >
                {TaskStatusMetaDataMap[taskStatus]?.title ?? ""}
              </Typography>
              {pendingIssues && taskStatus !== TASK_STATUS.COMPLETED ? (
                <Typography
                  className="MFTask-secondary-text"
                  color="text.secondary"
                >
                  &nbsp;&#x2022; {pendingIssues} Issue
                  {pendingIssues > 1 ? "s" : ""}
                </Typography>
              ) : null}
            </Stack>
          </div>
          {!disabledReason && (
            <ChevronRightRounded sx={{ alignSelf: "center", width: 16 }} />
          )}
        </Stack>
      </TaskWrapper>
    </Tooltip>
  );
};

export default TaskProgress;
