import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { UserSliceState } from "@/reducers/slices/userSlice/userSlice";
import { AuthUser } from "@/types/firebase";
import userflow from "userflow.js";
import { detectOs } from "./misc";
import { USER_FLOW_EVENTS } from "@/constants/userflow";

export const initializeUserFlow = () => {
  try {
    userflow.init(import.meta.env.VITE_USER_FLOW_TOKEN);
  } catch (e) {
    console.log(e);
  }
};

export const identifyUserFlowUser = (
  authUser: AuthUser,
  userData: UserSliceState
) => {
  if (authUser && userData) {
    const createdAt = authUser?.metadata?.createdAt
      ? new Date(Number(authUser.metadata.createdAt))
      : undefined;

    const displayName =
      `${userData.firstName || ""} ${userData.lastName || ""}` || undefined;

    try {
      userflow.identify(authUser?.uid, {
        user_id: authUser?.uid,
        name: displayName || undefined,
        email: authUser?.email,
        signed_up_at: createdAt?.toISOString() || undefined,
        "Operating system": detectOs(),
        ENV: import.meta.env.VITE_MURF_ENVIRONMENT,
        "Country Code": userData.countryCode || undefined
      });
    } catch (e) {
      console.log(e);
    }
  }
};

export const trackUserFlowEvent = (
  event: MIXPANEL_EVENTS | USER_FLOW_EVENTS,
  properties?: any
) => {
  try {
    if (userflow?.isIdentified()) {
      const res = userflow.track(event, {
        ...properties,
        ENV: import.meta.env.VITE_MURF_ENVIRONMENT
      });
      console.log("USERFLOW", res);
    }
  } catch (e) {
    console.log(e);
  }
};
