import { LANGUAGES } from "@/constants/languages";
import { PolicyFunction } from "..";
import { MURF_ROLES } from "@/features/user";
import { TASK_TYPE } from "@/types/project";

// Check if current user is an "internal" user
export const isInternalUserPolicy: PolicyFunction<null> = ({ user }) => {
  return [
    MURF_ROLES.ADMIN,
    MURF_ROLES.DELIVERY_MANAGER,
    MURF_ROLES.CONTRIBUTOR
  ].includes(user?.role as MURF_ROLES);
};

// Check if current user is a "manager"
export const isMurfManagerPolicy: PolicyFunction<null> = ({ user }) => {
  return [MURF_ROLES.ADMIN, MURF_ROLES.DELIVERY_MANAGER].includes(
    user?.role as MURF_ROLES
  );
};
export const isMurfAdminPolicy: PolicyFunction<null> = ({ user }) => {
  return user?.role === MURF_ROLES.ADMIN;
};

// Check if current user has view access to QC pages
export const hasQCViewAccessPolicy: PolicyFunction<{
  allowedQCAccessRoles: TASK_TYPE[];
}> = ({ user, policyArgs }) => {
  return (
    [MURF_ROLES.ADMIN, MURF_ROLES.DELIVERY_MANAGER].includes(user.role) ||
    user.specialisation.some((role) =>
      policyArgs.allowedQCAccessRoles.includes(role)
    )
  );
};

// Check if the current user has edit access to QC pages
export const hasQCEditAccessPolicy: PolicyFunction<{
  assignedUserId: string;
  allowedQCAccessRoles: TASK_TYPE[];
}> = ({ user, policyArgs }) => {
  return (
    user.specialisation.some((role) =>
      policyArgs.allowedQCAccessRoles.includes(role)
    ) && user.userId === policyArgs.assignedUserId
  );
};

// Check if the current user has access to a specific list of languages
export const languageAccessPolicy: PolicyFunction<{
  allowedLanguages: LANGUAGES[];
}> = ({ user, policyArgs }) => {
  return policyArgs.allowedLanguages.some((language) =>
    user.languages.includes(language)
  );
};

export const languageAndQCEditAccessPolicy: PolicyFunction<{
  allowedLanguages: LANGUAGES[];
  assignedUserId: string;
  allowedQCAccessRoles: TASK_TYPE[];
}> = ({ user, policyArgs }) => {
  return (
    hasQCEditAccessPolicy({ user, policyArgs }) &&
    languageAccessPolicy({ user, policyArgs })
  );
};

export const canReplyToCustomerIssuePolicy: PolicyFunction<null> = ({
  user
}) => {
  return [MURF_ROLES.ADMIN, MURF_ROLES.DELIVERY_MANAGER].includes(user.role);
};
