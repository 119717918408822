import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { STATUS } from "@/constants/status";
import { DUB_STATUS, TASK_STATUS } from "@/types/project";
import { Check } from "@mui/icons-material";
import { Button, CircularProgress, Stack, Tooltip } from "@mui/material";
import { useMemo, useState } from "react";
import { dubPreviewInternalApi } from "../..";
import { QC_ACCESS_ROLES } from "@/types/user";
import {
  isInternalUserPolicy,
  isMurfManagerPolicy,
  usePermissions
} from "@/features/permissions";
import { dubThunks } from "@/reducers/thunks/dub";
import { useParams } from "react-router-dom";
import { UNKNOWN_ERROR_MESSAGE } from "@/constants/errors";
import { dubPreviewApi } from "@/features/preview";
import { MurfSnackbar, useSnackbar } from "@/components/elements/MurfSnackbar";
import { useIssuesComments } from "@/features/comments/hooks";
import { ISSUE_TAG_RESOLVE_STATUS } from "@/features/comments";
import { setMarkAsDoneDubPreviewDialog } from "@/reducers/slices/dialogSlice/dialogSlice";

const Actions = () => {
  const { fileId, projectId, dubId } = useParams();
  const [reOpenStatus, setReOpenStatus] = useState<STATUS>(STATUS.IDLE);

  const { showError, showSuccess } = useSnackbar();

  const externalTaskStatus = useTypedSelector(
    (state) => state.dubPreview.dubDetails.externalTaskStatus
  );
  const accessLevel = useTypedSelector(
    (state) => state.dubPreview.dubDetails.accessLevel
  );
  const dubStatus = useTypedSelector(
    (state) => state.dubPreview.dubDetails.dubStatus
  );
  const workspaceId = useTypedSelector(
    (state) => state.dubPreview.dubDetails.workspaceId
  );
  const dubVersions = useTypedSelector(
    (state) => state.dubPreview.dubDetails.dubVersions
  );
  const isQcEnabled = useTypedSelector(
    (state) => state.dubPreview.dubDetails.qcEnabled
  );

  const { filteredIssues: allIssues } = useIssuesComments();

  const dispatch = useAppDispatch();
  const { canAccess } = usePermissions();

  const isInternalUser = canAccess({
    policy: isInternalUserPolicy,
    policyArgs: null
  });

  const isMurfManager = isInternalUser
    ? canAccess({
        policy: isMurfManagerPolicy,
        policyArgs: null
      })
    : false;

  const anyIssueIsUnresolved = useMemo(
    () =>
      allIssues.some(
        (issue) => issue.status !== ISSUE_TAG_RESOLVE_STATUS.RESOLVED
      ),
    [allIssues]
  );

  const handleMarkAsDone = () => {
    dispatch(setMarkAsDoneDubPreviewDialog(true));
  };

  const onReOpenDubSuccess = ({
    fileId,
    workspaceId,
    projectId,
    dubId,
    dubVersion
  }: {
    fileId: string;
    workspaceId: string;
    projectId: string;
    dubId: string;
    dubVersion: string;
  }) => {
    setReOpenStatus(STATUS.SUCCESS);
    showSuccess("Dub re-opened successfully");
    dispatch(
      dubThunks.fetchDubPreviewInternal({
        fileId,
        workspaceId,
        projectId,
        dubId,
        dubVersion
      })
    );
  };

  const onReOpenDubError = (err: any) => {
    showError(err?.extra || UNKNOWN_ERROR_MESSAGE);
    setReOpenStatus(STATUS.IDLE);
    console.log({ err });
  };

  const handleReopenDub = () => {
    if (workspaceId && dubId && fileId && projectId) {
      setReOpenStatus(STATUS.LOADING);
      if (!isQcEnabled) {
        dubPreviewApi
          .requestQc({
            projectId,
            fileId,
            dubId,
            workspaceId
          })
          .then((res) => {
            onReOpenDubSuccess({
              fileId,
              workspaceId,
              projectId,
              dubId,
              dubVersion: res.data.extra || ""
            });
          })
          .catch(onReOpenDubError);
      } else {
        dubPreviewInternalApi
          .reOpenDub({
            dubId,
            workspaceId,
            fileId,
            projectId,
            isInternal: true
          })
          .then((res: any) => {
            onReOpenDubSuccess({
              fileId,
              workspaceId,
              projectId,
              dubId,
              dubVersion: res.data.extra || ""
            });
          })
          .catch(onReOpenDubError);
      }
    }
  };

  const qcTaskCompleted = useMemo(() => {
    return externalTaskStatus === TASK_STATUS.COMPLETED;
  }, [externalTaskStatus]);

  const nonQcTaskCompleted =
    !isQcEnabled && externalTaskStatus === TASK_STATUS.TODO;

  const isDubCompleted = dubStatus === DUB_STATUS.COMPLETED;

  const canMarkAsDone =
    accessLevel === QC_ACCESS_ROLES.QUALITY_CHECK &&
    externalTaskStatus === TASK_STATUS.ONGOING &&
    dubStatus !== DUB_STATUS.COMPLETED;

  const canReOpenDub =
    isMurfManager &&
    (qcTaskCompleted || nonQcTaskCompleted) &&
    isDubCompleted &&
    false;

  const reOpening = reOpenStatus === STATUS.LOADING;
  const areDubVersionsFull = dubVersions?.length >= 5;

  return (
    <Stack direction={"row"} gap={2} mr={2}>
      {isInternalUser ? (
        <>
          {canReOpenDub ? (
            <Tooltip
              placement="bottom"
              arrow
              hidden={!areDubVersionsFull}
              title="More than 5 versions are not allowed"
            >
              <Button
                variant="contained"
                startIcon={reOpening ? <CircularProgress size={18} /> : null}
                onClick={handleReopenDub}
                disabled={reOpening || areDubVersionsFull}
              >
                {reOpening ? "Re-Opening" : "Re-open Dub for fixes"}
              </Button>
            </Tooltip>
          ) : null}

          <Button
            variant="contained"
            startIcon={<Check />}
            onClick={handleMarkAsDone}
            disabled={!canMarkAsDone}
          >
            {dubStatus === DUB_STATUS.COMPLETED || qcTaskCompleted
              ? "Done"
              : anyIssueIsUnresolved
              ? "Re-Open Dub"
              : "Mark as Done"}
          </Button>
        </>
      ) : null}
      <MurfSnackbar />
    </Stack>
  );
};

export default Actions;
