import { IClientProjectData, IFileData } from "@/features/clientProjectDetails";
import { IUpdateFile } from "@/features/createProject/api";
import { clientProjectThunk } from "@/reducers/thunks/clientProject";
import { APIResponse } from "@/types/apiResponse";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

const initialFiltersDubStatus = {
  waitingForUpload: false,
  processing: false,
  queued: false,
  waitingForApproval: false,
  finished: false
};

export enum FilterFileType {
  ALL = "ALL",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO"
}

export const initialFilterState = {
  dubStatus: initialFiltersDubStatus,
  fileType: FilterFileType.ALL,
  showArchived: false,
  showActionsNeeded: false,
  selectedLanguages: []
};

interface ICurrentClientProjectSlice {
  [key: string]: {
    project: IClientProjectData;
    sourceFileResponseList: IFileData[];
    filters: {
      dubStatus: {
        waitingForUpload: boolean;
        processing: boolean;
        queued: boolean;
        waitingForApproval: boolean;
        finished: boolean;
      };
      fileType: FilterFileType;
      showArchived: boolean;
      showActionsNeeded: boolean;
    };
    selectedLanguages: string[];
  };
}

const currentClientProjectSlice = createSlice({
  name: "currentClientProject",
  initialState: {} as ICurrentClientProjectSlice,
  reducers: {
    removeFile: (
      state,
      action: PayloadAction<{
        fileId: IFileData["sourceFileId"];
        projectId: string;
      }>
    ) => {
      const { fileId, projectId } = action.payload;
      let _temp = state[projectId]?.sourceFileResponseList;
      if (_temp?.length) {
        _temp = _temp.filter((file) => file?.sourceFileId !== fileId);
      }
      state[projectId] = {
        ...state[projectId],
        sourceFileResponseList: _temp
      };
      return state;
    },
    updateFile: (
      state,
      action: PayloadAction<{
        fileId: IFileData["sourceFileId"];
        projectId: string;
        updatedData: IUpdateFile;
      }>
    ) => {
      const { fileId, projectId, updatedData } = action.payload;
      if (!updatedData || !Object.keys(updatedData)?.length) {
        return;
      }
      if (!fileId || !projectId) {
        return;
      }
      const _temp = state[projectId]?.sourceFileResponseList?.map(
        (file: IFileData) => {
          if (file.sourceFileId === fileId) {
            return Object.assign({}, file, updatedData);
          }
          return file;
        }
      );

      state[projectId] = {
        ...state[projectId],
        sourceFileResponseList: _temp
      };
      return state;
    },
    updateFilterDubStatus: (
      state,
      action: PayloadAction<{
        projectId: string;
        filter: { [key: string]: boolean };
      }>
    ) => {
      const { projectId, filter } = action.payload;
      if (state[projectId].filters?.dubStatus) {
        state[projectId].filters.dubStatus = {
          ...initialFiltersDubStatus,
          ...filter
        };
        state[projectId].filters.showActionsNeeded = false;
      }
    },
    updateFilterMediaType: (
      state,
      action: PayloadAction<{
        projectId: string;
        selectedValue: FilterFileType;
      }>
    ) => {
      const { projectId, selectedValue } = action.payload;
      if (state[projectId].filters?.fileType) {
        state[projectId].filters.fileType = selectedValue;
      }
    },
    updateFilterToggleArchived: (
      state,
      action: PayloadAction<{
        projectId: string;
        archive?: boolean;
      }>
    ) => {
      const { projectId, archive } = action.payload;
      if (state[projectId].filters) {
        const newState = archive || !state[projectId].filters.showArchived;
        state[projectId].filters.showArchived = newState;
        if (newState) {
          state[projectId].filters.showActionsNeeded = false;
          state[projectId].filters.dubStatus = {
            ...initialFiltersDubStatus,
            finished: true
          };
        } else {
          state[projectId].filters = initialFilterState;
        }
      }
    },
    updateFilterToggleActionNeeded: (
      state,
      action: PayloadAction<{
        projectId: string;
      }>
    ) => {
      const { projectId } = action.payload;
      if (state[projectId].filters) {
        const newState = !state[projectId].filters.showActionsNeeded;
        const currentMediaTypeFilters = state[projectId].filters.fileType;
        if (newState) {
          state[projectId].filters = {
            ...initialFilterState,
            fileType: currentMediaTypeFilters,
            showActionsNeeded: true
          };
        } else {
          state[projectId].filters = {
            ...initialFilterState,
            fileType: currentMediaTypeFilters
          };
        }
      }
    },
    resetTabFilters: (
      state,
      action: PayloadAction<{
        projectId: string;
      }>
    ) => {
      const { projectId } = action.payload;
      if (projectId && state?.[projectId]) {
        state[projectId].filters.dubStatus = initialFilterState.dubStatus;
        state[projectId].filters.showActionsNeeded =
          initialFilterState.showActionsNeeded;
        state[projectId].filters.showArchived = initialFilterState.showArchived;
      }
    },
    resetPopUpFilters: (
      state,
      action: PayloadAction<{
        projectId: string;
      }>
    ) => {
      const { projectId } = action.payload;
      if (projectId && state?.[projectId]) {
        state[projectId].filters.showArchived = initialFilterState.showArchived;
        state[projectId].filters.fileType = initialFilterState.fileType;
      }
    },
    resetAllFilters: (
      state,
      action: PayloadAction<{
        projectId: string;
      }>
    ) => {
      const { projectId } = action.payload;
      if (projectId && state?.[projectId]) {
        state[projectId].filters = initialFilterState;
        state[projectId].selectedLanguages = [];
      }
    },
    setSelectedLanguages: (
      state,
      action: PayloadAction<{ projectId: string; selectedLanguages: string[] }>
    ) => {
      const { selectedLanguages, projectId } = action.payload;
      if (Array.isArray(selectedLanguages)) {
        state[projectId].selectedLanguages = selectedLanguages;
      }
    },
    updateSourceFileResponseList: (
      state,
      action: PayloadAction<{
        projectId: string;
        sourceFileResponseList: IFileData[];
      }>
    ) => {
      const { projectId, sourceFileResponseList } = action.payload;
      if (projectId && state[projectId]) {
        state[projectId].sourceFileResponseList = state[
          projectId
        ].sourceFileResponseList.map((file) => {
          const temp =
            sourceFileResponseList.filter(
              (updatedFile: IFileData) =>
                updatedFile.sourceFileId === file.sourceFileId
            )?.[0] || file;
          return {
            ...file,
            ...temp
          };
        });
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(clientProjectThunk.getProjectById.fulfilled, (state, action) => {
        const { projectId } = action.payload?.data?.responseData?.project || {};

        state[projectId] = {
          ...action.payload?.data?.responseData,
          filters: state[projectId]?.filters || initialFilterState,
          selectedLanguages: state[projectId]?.selectedLanguages || []
        };

        return state;
      })
      .addCase(
        clientProjectThunk.fetchSourceFile.fulfilled,
        (
          state,
          action: PayloadAction<AxiosResponse<APIResponse<IFileData[]>>>
        ) => {
          const sourceFilesMap = action.payload.data.responseData;
          const projectId = (action as any).meta?.arg?.projectId;
          if (projectId && state[projectId] && sourceFilesMap?.length) {
            state[projectId].sourceFileResponseList = sourceFilesMap;
          }
        }
      )
      .addCase(
        clientProjectThunk.addLanguage.fulfilled,
        (
          state,
          action: PayloadAction<AxiosResponse<APIResponse<IClientProjectData>>>
        ) => {
          if (action.payload.data?.responseCode === "SUCCESS") {
            const { projectId, targetLocales } =
              action.payload?.data?.responseData || {};
            if (projectId && state[projectId]) {
              state[projectId].project.targetLocales = targetLocales;
            }
          }
          return state;
        }
      );
  }
});

export const {
  removeFile,
  updateFile,
  updateFilterDubStatus,
  updateFilterMediaType,
  updateFilterToggleArchived,
  updateFilterToggleActionNeeded,
  resetAllFilters,
  resetTabFilters,
  resetPopUpFilters,
  setSelectedLanguages,
  updateSourceFileResponseList
} = currentClientProjectSlice.actions;

export default currentClientProjectSlice.reducer;
