import { LANGUAGES } from "@/constants/languages";
import { TASK_TYPE } from "@/types/project";

export enum MURF_ROLES {
  ADMIN = "ADMIN",
  DELIVERY_MANAGER = "DELIVERY_MANAGER",
  CONTRIBUTOR = "CONTRIBUTOR",
  USER = "USER"
}

export const MURF_ROLES_TITLE: Record<MURF_ROLES, string> = {
  [MURF_ROLES.ADMIN]: "Admin",
  [MURF_ROLES.DELIVERY_MANAGER]: "Delivery Manager",
  [MURF_ROLES.CONTRIBUTOR]: "Contributor",
  [MURF_ROLES.USER]: "User"
};

/**
 * @TUser Base User Type
 * @Id defaults to string provide string| null based on requirement
 */
export type TUser<Id extends string | null = string> = {
  email?: string;
  userId: Id;
  emailId: string;
  murfRole: MURF_ROLES;
  displayName: string;
  firstName: string;
  lastName: string;
  profileImageUrl?: string | null;
};

export type TMember<Id extends string | null = string> = TUser<Id> & {
  assignedWorkspaces: string[];
  languages: LANGUAGES[];
  specialisation: TASK_TYPE[];
  updatedAt: number;
  invitationPending: Id extends null ? true : false;
};
