import { MURF_ROLES } from "@/features/user";

type RoleMapping = {
  allowed: string | string[];
  blocked: string[];
};

export const roleToRoutes: Record<MURF_ROLES, RoleMapping> = {
  [MURF_ROLES.ADMIN]: {
    allowed: "*",
    blocked: []
  },
  [MURF_ROLES.USER]: {
    allowed: "*",
    blocked: ["/internal"]
  },
  [MURF_ROLES.DELIVERY_MANAGER]: {
    allowed: "*",
    blocked: []
  },
  [MURF_ROLES.CONTRIBUTOR]: {
    allowed: "*",
    blocked: [
      "internal/workspaces",
      "internal/project",
      "internal/user-management"
    ]
  }
};

export const canRoleAccessRoute = (
  route: string,
  role: MURF_ROLES
): boolean => {
  if (role === MURF_ROLES.ADMIN) {
    return true;
  }

  return !roleToRoutes[role].blocked.some((e) => route.includes(e));

  return false;
};
