import {
  GetAllIssuePayload,
  ICurrentDubData,
  IIssue,
  IIssueObj,
  IIssueSlice,
  IIssueTag,
  ISSUE_TAG_RESOLVE_STATUS
} from "@/features/comments";
import { dubThunks } from "@/reducers/thunks/dub";
import { issueCommentThunk } from "@/reducers/thunks/issueCommentThunk";
import { translationVoThunks } from "@/reducers/thunks/translationVo";
import { convertArrayToMap } from "@/utils/json";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const handleAllIssuesFetch = (
  state: IIssueSlice,
  data: PayloadAction<
    any,
    string,
    {
      arg: GetAllIssuePayload;
      requestId: string;
      requestStatus: "fulfilled";
    },
    never
  >
) => {
  const res = data.payload;
  if (res.responseCode === "SUCCESS") {
    const { dubId, issues } = res.responseData;
    if (Array.isArray(issues)) {
      state.allIssues[dubId] = issues.reduce((obj, issue) => {
        obj[issue.issueId] = issue;
        return obj;
      }, {});
    }
  }
};

const issueSlice = createSlice({
  name: "issueSlice",
  initialState: {
    isFetchingAllIssues: false,
    isIssuesPopOverOpen: false,
    currentDubData: undefined,
    allIssues: {},
    currentlyOpenedIssueInTvoPage: undefined
  } as IIssueSlice,
  reducers: {
    setIsFetchingAllIssues: (state, action: PayloadAction<boolean>) => {
      state.isFetchingAllIssues = action.payload;
    },
    setIsIssuesPopOverOpen: (state, action: PayloadAction<boolean>) => {
      state.isIssuesPopOverOpen = action.payload;
    },
    setDubDataForIssues: (state, action: PayloadAction<ICurrentDubData>) => {
      state.currentDubData = action.payload;
    },
    clearCurrentDubDataForIssues: (state) => {
      state.currentDubData = undefined;
    },
    setIssuesOfDubId: (
      state,
      action: PayloadAction<{
        dubId: string;
        issues: IIssue[];
      }>
    ) => {
      const { dubId, issues } = action.payload;
      if (Array.isArray(issues) && dubId?.length) {
        const _temp: IIssueObj = {};
        issues.forEach((issue) => {
          _temp[issue.issueId] = issue;
        });
        state.allIssues[dubId] = _temp;
      }
    },
    addIssueToDub: (
      state,
      action: PayloadAction<{
        dubId: string;
        issue: IIssue;
      }>
    ) => {
      const { dubId, issue } = action.payload;
      if (state.allIssues[dubId]) {
        state.allIssues[dubId][issue.issueId] = issue;
      } else {
        state.allIssues[dubId] = {
          [issue.issueId]: issue
        };
      }
    },
    updateIssue: (
      state,
      action: PayloadAction<{
        dubId: string;
        updatedIssue: Partial<IIssue>;
      }>
    ) => {
      const { dubId, updatedIssue } = action.payload;
      if (!updatedIssue?.issueId) {
        return;
      }
      if (state.allIssues[dubId]?.[updatedIssue.issueId]) {
        state.allIssues[dubId][updatedIssue.issueId] = {
          ...state.allIssues[dubId]?.[updatedIssue?.issueId],
          ...updatedIssue
        };
      }
    },
    removeIssue: (
      state,
      action: PayloadAction<{
        dubId: string;
        issueId: string;
      }>
    ) => {
      const { dubId, issueId } = action.payload;
      if (dubId && issueId && state.allIssues?.[dubId]?.[issueId]) {
        delete state.allIssues[dubId][issueId];
      }
    },
    setIssueResolveStatus: (
      state,
      action: PayloadAction<{
        dubId: string;
        issueId: string;
        updatedTags: any;
      }>
    ) => {
      const { dubId, issueId, updatedTags } = action.payload;
      const updatedTagsMap = updatedTags.reduce((obj: any, tag: any) => {
        obj[tag.name] = tag;
        return obj;
      }, {});
      if (state.allIssues[dubId]?.[issueId]) {
        state.allIssues[dubId][issueId].tags = state.allIssues[dubId][
          issueId
        ].tags.map((tag) => {
          if (tag.name in updatedTagsMap) {
            return {
              ...updatedTagsMap[tag.name],
              resolveAt: Date.now()
            };
          } else {
            return tag;
          }
        });
      }
    },
    setCurrentlyOpenedIssueInTvoPage: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.currentlyOpenedIssueInTvoPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(issueCommentThunk.getAllIssues.fulfilled, handleAllIssuesFetch)
      .addCase(
        issueCommentThunk.getAllTVoIssues.fulfilled,
        handleAllIssuesFetch
      )
      .addCase(
        issueCommentThunk.getAllInternalQAPageIssues.fulfilled,
        handleAllIssuesFetch
      )
      .addCase(issueCommentThunk.createIssue.fulfilled, (state, data) => {
        const res = data.payload;
        if (res.responseCode === "SUCCESS") {
          const { dubId, fileId, ...issue } = res.responseData;
          if (state.allIssues[dubId]) {
            state.allIssues[dubId][issue.issueId] = { ...issue, dubId, fileId };
          } else {
            state.allIssues[dubId] = {
              [issue.issueId]: { ...issue, dubId, fileId }
            };
          }
        }
      })
      .addCase(issueCommentThunk.getIssueById.fulfilled, (state, data) => {
        const res = data.payload;
        if (res.responseCode === "SUCCESS") {
          const { dubId, fileId, issue } = res.responseData;
          if (state.allIssues[dubId]) {
            state.allIssues[dubId][issue.issueId] = { ...issue, dubId, fileId };
          } else {
            state.allIssues[dubId] = {
              [issue.issueId]: issue
            };
          }
        }
      })
      .addCase(issueCommentThunk.updateIssue.fulfilled, (state, data) => {
        const res = data.payload;
        if (res.responseCode === "SUCCESS") {
          const { dubId, ...issue } = res.responseData;
          if (state.allIssues[dubId]?.[issue.issueId]) {
            state.allIssues[dubId][issue.issueId] = {
              ...state.allIssues[dubId]?.[issue.issueId],
              ...issue
            };
          }
        }
      })
      .addCase(issueCommentThunk.removeIssue.fulfilled, (state, data) => {
        const res = data.payload;
        if (res.responseCode === "SUCCESS") {
          const { dubId, issueId } = data.meta.arg;
          if (state.allIssues[dubId]?.[issueId]) {
            delete state.allIssues[dubId][issueId];
          }
        }
      })
      .addCase(issueCommentThunk.markAsReadIssue.fulfilled, (state, data) => {
        const res = data.payload;
        if (res.responseCode === "SUCCESS") {
          const { dubId, issueId } = data.meta.arg;
          if (state.allIssues[dubId]?.[issueId]) {
            state.allIssues[dubId][issueId].lastSeen = Date.now();
          }
        }
      })
      .addCase(issueCommentThunk.markAsUnreadIssue.fulfilled, (state, data) => {
        const res = data.payload;
        if (res.responseCode === "SUCCESS") {
          const { dubId, issue } = res.responseData;
          if (state.allIssues[dubId]?.[issue.issueId]) {
            state.allIssues[dubId][issue.issueId].lastSeen = issue.lastSeen;
          }
        }
      })
      .addCase(
        issueCommentThunk.updateIssueTagResolveStatus.fulfilled,
        (state, data) => {
          const res = data.payload;
          if (res.responseCode === "SUCCESS") {
            const { dubId, issues } = res.responseData;
            const issueId = data.meta.arg.issueId;
            if (state.allIssues[dubId]?.[issueId]) {
              state.allIssues[dubId][issueId] = issues.filter(
                (issue: IIssue) => issue.issueId === issueId
              )[0];
            }
          }
        }
      )
      .addCase(
        issueCommentThunk.updateIssueTagResolveStatus.rejected,
        (state, data) => {
          const { dubId, issueId } = data.meta.arg;
          if (state.allIssues[dubId]?.[issueId]) {
            const updatedTagsMap = convertArrayToMap(
              data.meta.arg.data,
              "name"
            );
            state.allIssues[dubId][issueId].tags = state.allIssues[dubId][
              issueId
            ].tags.map((tag) => {
              if (
                tag.name in updatedTagsMap &&
                tag.status === updatedTagsMap[tag.name].status
              ) {
                return {
                  ...updatedTagsMap[tag.name],
                  status:
                    updatedTagsMap[tag.name].status ===
                    ISSUE_TAG_RESOLVE_STATUS.RESOLVED
                      ? ISSUE_TAG_RESOLVE_STATUS.UNRESOLVED
                      : ISSUE_TAG_RESOLVE_STATUS.RESOLVED
                } as IIssueTag;
              } else {
                return tag;
              }
            });
          }
        }
      )
      .addCase(dubThunks.fetchDubPreviewInternal.fulfilled, (state, action) => {
        const { workspaceId, projectId } = action.meta.arg;
        const { dubId, dubVersion, fileId, accessLevel } = action.payload;
        state.currentDubData = {
          workspaceId,
          projectId,
          fileId,
          dubId,
          dubVersion: dubVersion,
          accessLevel
        };
        return state;
      })
      .addCase(
        translationVoThunks.fetchTranslationVoDetails.fulfilled,
        (state, action) => {
          const { accessLevel, dubVersion, dubId, projectId } = action.payload;
          const { fileId, workspaceId } = action.meta.arg;

          state.currentDubData = {
            workspaceId,
            accessLevel,
            dubVersion,
            dubId,
            fileId,
            projectId
          };
          return state;
        }
      )
      .addCase(translationVoThunks.resetState.fulfilled, (state) => {
        state.currentDubData = undefined;
        return state;
      });
  }
});

export const {
  setIsFetchingAllIssues,
  setIsIssuesPopOverOpen,
  setIssuesOfDubId,
  setIssueResolveStatus,
  addIssueToDub,
  updateIssue,
  removeIssue,
  setDubDataForIssues,
  clearCurrentDubDataForIssues,
  setCurrentlyOpenedIssueInTvoPage
} = issueSlice.actions;

export default issueSlice.reducer;
