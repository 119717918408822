import { STATUS } from "@/constants/status";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IAuthState {
  email: string;
  status: STATUS;
  error: string;
}

const initialState: IAuthState = {
  email: "",
  status: STATUS.IDLE,
  error: ""
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSubmittedEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setAuthStatus: (state, action: PayloadAction<STATUS>) => {
      state.status = action.payload;
    },
    setAuthError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    }
  }
});

export const { setSubmittedEmail, setAuthStatus, setAuthError } =
  authSlice.actions;

export default authSlice.reducer;
