import { MURF_ROLES } from "@/features/user";
import { PolicyFunction } from "..";
import { WORKSPACE_ROLES } from "@/types/workspace";

// Check if the current user is allowed to perform a specific action depending on their murf role
export const canMurfRoleAccessPolicy: PolicyFunction<{
  allowedRoles: MURF_ROLES[];
}> = ({ user, policyArgs }) => {
  if (!user?.role) {
    return false;
  }
  return policyArgs.allowedRoles.includes(user?.role as MURF_ROLES);
};

// Check if the current user is allowed to perform a specific action depending on their workspace role
export const canWorkspaceRoleAccessPolicy: PolicyFunction<{
  allowedRoles: WORKSPACE_ROLES[];
}> = ({ user, workspace, policyArgs }) => {
  if (!user?.role) {
    return false;
  }
  return policyArgs.allowedRoles.includes(
    workspace?.roleType as WORKSPACE_ROLES
  );
};
