import { api } from "@/lib/axios";
import { APIResponse } from "@/types/apiResponse";
import { IInternalProject, IInternalProjectData } from "..";

export const projectApi = {
  fetchProjects: ({
    topLevelFilters = {},
    isAdminFilter = false
  }: {
    topLevelFilters?: Record<string, string[]>;
    isAdminFilter?: boolean;
  }) =>
    api.post<APIResponse<IInternalProjectData[]>>(
      `/admin/project/NA/all-projects${
        isAdminFilter ? "/top-level-filter" : ""
      }`,
      {
        filters: {},
        topLevelFilters
      }
    ),
  fetchProjectsData: (projectId: string, workspaceId: string) =>
    api.get<APIResponse<IInternalProject>>(
      `/admin/project/${projectId}?workspaceId=${workspaceId}`
    )
};
