import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAppAsyncThunk, errorCheck } from "@/utils/reduxUtils";
import { dubPreviewApi } from "@/features/preview/api";
import {
  dubPreviewInternalApi,
  QcPreviewDetailsParams
} from "@/features/dubPreviewInternal";

export const dubThunks = {
  fetchDubPreview: createAsyncThunk(
    "fetchDubPreview",
    async (
      {
        dubId,
        projectId,
        fileId,
        dubVersion
      }: {
        dubId: string;
        projectId: string;
        fileId: string;
        dubVersion: string;
      },
      thunkAPI
    ) => {
      return errorCheck(async () => {
        const response = await dubPreviewApi.fetchDubPreview({
          dubId: dubId,
          projectId: projectId,
          fileId: fileId,
          dubVersion: dubVersion
        });
        return response.data;
      }, thunkAPI);
    }
  ),
  fetchDubPreviewInternal: createAppAsyncThunk(
    "fetchDubPreviewInternal",
    async (
      {
        dubId,
        projectId,
        fileId,
        workspaceId,
        dubVersion
      }: QcPreviewDetailsParams,
      thunkAPI
    ) => {
      return errorCheck(async () => {
        const response = await dubPreviewInternalApi.fetchQCDubPreview({
          dubId,
          projectId,
          fileId,
          workspaceId,
          dubVersion
        });
        return response.data.responseData;
      }, thunkAPI);
    }
  ),
  fetchDraftVersion: createAsyncThunk(
    "fetchDraftVersion",
    async (
      {
        dubId,
        projectId,
        fileId
      }: {
        dubId: string;
        projectId: string;
        fileId: string;
      },
      thunkAPI
    ) => {
      return errorCheck(async () => {
        const response = await dubPreviewApi.fetchDraftVersion({
          dubId: dubId,
          projectId: projectId,
          fileId: fileId
        });

        return response.data.extra;
      }, thunkAPI);
    }
  ),
  reOpenDub: createAsyncThunk(
    "reOpenDub",
    async (
      {
        dubId,
        fileId,
        projectId,
        workspaceId,
        isInternal
      }: {
        dubId: string;
        projectId: string;
        fileId: string;
        workspaceId: string;
        isInternal: boolean;
      },
      thunkAPI
    ) => {
      return errorCheck(async () => {
        const response = await dubPreviewInternalApi.reOpenDub({
          dubId,
          fileId,
          projectId,
          workspaceId,
          isInternal
        });

        return response.data.extra;
      }, thunkAPI);
    }
  )
};
