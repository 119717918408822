import { InputLabel, InputLabelProps, styled } from "@mui/material";
import React from "react";

const StyledInputLabel = styled(InputLabel)({
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: 1,
  letterSpacing: "0.15px",
  transform: "none"
});

interface MurfInputLabelProps extends InputLabelProps {
  children: string;
}

export const MurfInputLabel = ({
  children,
  ...props
}: MurfInputLabelProps): React.ReactNode => {
  return (
    <StyledInputLabel size="small" shrink={true} {...props}>
      {children}
    </StyledInputLabel>
  );
};
