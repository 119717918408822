import { useCallback } from "react";
import { OnProgressProps } from "react-player/base";
import { playPause, onMediaEnd, setBuffering } from ".";

interface UseMediaPlayerCallbacksArgs {
  dispatch: any;
  setIsError?: any;
  setProgress: any;
  isPlaying: boolean;
  seeking: boolean;
}

export function useMediaPlayerCallbacks({
  dispatch,
  setIsError,
  setProgress,
  isPlaying,
  seeking
}: UseMediaPlayerCallbacksArgs) {
  const onError = useCallback(
    (e: any) => {
      console.log("error", e);
      setIsError(true);
    },
    [setIsError]
  );

  const onProgress = useCallback(
    (e: OnProgressProps) => {
      if (e.playedSeconds !== 0 && !seeking) {
        setProgress(dispatch, e.playedSeconds);
      }
    },
    [dispatch, setProgress, seeking]
  );

  const onEnded = useCallback(() => {
    onMediaEnd(dispatch);
  }, [dispatch]);

  const onPause = useCallback(() => {
    if (isPlaying) {
      playPause(dispatch);
      setBuffering(dispatch, false);
    }
  }, [dispatch, isPlaying]);

  const onPlay = useCallback(() => {
    if (!isPlaying) {
      playPause(dispatch);
    }
  }, [dispatch, isPlaying]);

  const onBufferEnd = useCallback(() => {
    setBuffering(dispatch, false);
  }, [dispatch]);

  const onReady = useCallback(() => {
    setBuffering(dispatch, false);
  }, [dispatch]);

  return {
    onError,
    onProgress,
    onEnded,
    onPause,
    onPlay,
    onBufferEnd,
    onReady
  };
}
