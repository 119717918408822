import { useTypedSelector } from "@/config/configureAppStore";
import { LanguageDataMap } from "@/constants/languages";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { trackMixpanelEvent } from "@/utils/mixpanel";

const useTvoTrackingEvent = () => {
  const projectId = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.projectId
  );
  const dubId = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.dubId
  );
  const dubVersion = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.dubVersion
  );
  const accessLevel = useTypedSelector(
    (state) => state.currentScriptProject.accessLevel
  );
  const qcEnabled = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.qcEnabled
  );
  const sourceLanguage = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.sourceLanguage
  );
  const targetLanguage = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.targetLanguage
  );

  const trackEvent = (eventName: MIXPANEL_EVENTS, properties?: any) => {
    trackMixpanelEvent(eventName, {
      ...properties,
      "Project ID": projectId,
      "Dub ID": dubId,
      "Dub Version": dubVersion,
      "Source Language": LanguageDataMap[sourceLanguage]?.name,
      "Target Language": LanguageDataMap[targetLanguage]?.name,
      "Task Type": accessLevel,
      "Type of dub": qcEnabled ? "QA" : "NON-QA"
    });
  };

  return trackEvent;
};

export default useTvoTrackingEvent;
