import { CircularProgress, Stack } from "@mui/material";

type LoaderWrapperProps = {
  loading?: boolean;
  children?: React.ReactNode;
};

const LoaderWrapper = ({ loading = false, children }: LoaderWrapperProps) => {
  return loading ? (
    <Stack
      height="100vh"
      width="100vw"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Stack>
  ) : (
    children
  );
};

export default LoaderWrapper;
