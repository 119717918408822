import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import {
  shouldShowCreditsPromptPolicy,
  usePermissions
} from "@/features/permissions";
import { setCreditsDialog } from "@/reducers/slices/dialogSlice/dialogSlice";
import { storageService } from "@/utils/storage";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

const CREDITS_PROMPT_SHOWN_AT = "creditsPromptShownAt";

const useCreditsPrompt = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const workspace = useTypedSelector(
    (state) => state?.user?.activeWorkspaceData
  );

  const { canAccess } = usePermissions();

  const shouldShowCreditsPrompt = canAccess({
    policy: shouldShowCreditsPromptPolicy,
    policyArgs: null
  });

  const showCreditsDialog = useCallback(() => {
    dispatch(setCreditsDialog(true));
  }, [dispatch]);

  useEffect(() => {
    if (!workspace || location.pathname.startsWith("/internal")) return;
    const localStorageKey =
      CREDITS_PROMPT_SHOWN_AT + "_" + workspace.workspaceId;
    if (shouldShowCreditsPrompt) {
      const lastPromptShownAt =
        storageService.getItem(localStorageKey) || undefined;
      if (
        !lastPromptShownAt ||
        Date.now() - parseInt(lastPromptShownAt) > 24 * 60 * 60 * 1000
      ) {
        showCreditsDialog();
        storageService.setItem(localStorageKey, Date.now().toString());
      }
    }
  }, [
    shouldShowCreditsPrompt,
    workspace,
    showCreditsDialog,
    location?.pathname
  ]);
};

export default useCreditsPrompt;
