import { LANGUAGES } from "@/constants/languages";
import { BASE_VERSION } from "@/features/clientProjectDetails";
import {
  DubPreviewBlockType,
  DubPreviewDetails
} from "@/reducers/slices/dubPreviewSlice/types";
import { DUB_STATUS } from "@/types/project";
// import { ScriptBlockType, TextBlockStatus } from "@/features/blocks";

// const DUMMY_AUDIO_URL_1 =
//   "https://murf.ai/public-assets/new-home/home_hero_audio_may_22_2.mp3";
// const DUMMY_AUDIO_URL_2 =
//   "https://murf.ai/public-assets/new-home/home_hero_audio_may_22_2.mp3";
// const DUMMY_AUDIO_URL_3 =
//   "https://murf.ai/public-assets/new-home/home_hero_audio_may_22_2.mp3";
// const DUMMY_AUDIO_URL_4 =
//   "https://murf.ai/public-assets/new-home/home_hero_audio_may_22_2.mp3";

const PREVIEW_FAKE_TEXT_BLOCKS: DubPreviewBlockType[] = [
  {
    blockId: "1",
    original: {
      speakerId: "1",
      text: "The value that you want to pass to all the components reading this context inside this provider, no matter how deep. The context value can be of any type. A component calling useContext(SomeContext) inside of the provider receives the value of the innermost corresponding context provider above it.",
      startTime: 0,
      endTime: 2.5,
      duration: 2.5,
      englishText: ""
    },
    translatedText:
      "The value that you want to pass to all the components reading this context inside this provider, no matter how deep. The context value can be of any type. A component calling useContext(SomeContext) inside of the provider receives the value of the innermost corresponding context provider above it."
  },
  {
    blockId: "2",
    original: {
      speakerId: "1",
      text: "Hello world 2!",
      startTime: 3,
      endTime: 9.5,
      duration: 6.5,
      englishText: ""
    },
    translatedText: "Hello world 2!"
  },
  {
    blockId: "3",
    original: {
      speakerId: "1",
      text: "Hello world 3!",
      startTime: 10,
      endTime: 14.5,
      duration: 4.5,
      englishText: ""
    },
    translatedText: "Hello world 3!"
  },
  {
    blockId: "4",
    original: {
      speakerId: "1",
      text: "Hello world 4!",
      startTime: 15,
      endTime: 16.5,
      duration: 1.5,
      englishText: ""
    },
    translatedText: "Hello world 4!"
  },
  {
    blockId: "5",
    original: {
      speakerId: "1",
      text: "Hello world 5!",
      startTime: 15,
      endTime: 16.5,
      duration: 1.5,
      englishText: ""
    },
    translatedText: "Hello world 5!"
  }
];

export const DUMMY_DUB_PREVIEW: DubPreviewDetails = {
  dubId: "1",
  fileId: "123",
  projectName: "Thermodynamics",
  fileName: "Video 001 - Introduction to Thermodynamics",
  lastUpdated: 1694800904000,
  language: LANGUAGES.GERMAN,
  blocks: PREVIEW_FAKE_TEXT_BLOCKS,
  downloadUrl:
    "https://murf.ai/public-assets/home/apex_wild_adventure_mike.mp4",
  qcEnabled: false,
  dubVersion: BASE_VERSION,
  dubVersions: [BASE_VERSION],
  dubStatus: DUB_STATUS.PROCESSING,
  originalAudioUrl: "",
  sourceLocale: LANGUAGES.ENGLISH
};
