import getIndexRedirect from "@/utils/getIndexRedirect";
import { RouteObject } from "react-router-dom";
import { lazy } from "react";
import SettingsLayout from "../components/SettingsLayout";

const User = lazy(() => import("../components/User"));
const Workspace = lazy(() => import("../components/Workspace"));
const UserManagement = lazy(() => import("../components/UserManagement"));
const CreditUtilization = lazy(() => import("../components/CreditUtilization"));

export const profileRoute: RouteObject[] = [
  {
    path: "profile",
    element: <SettingsLayout />,
    children: [
      getIndexRedirect("/profile/user"),
      {
        path: "user",
        element: <User />
      },
      {
        path: "workspace",
        element: <Workspace />
      },
      { path: "workspace/manage", element: <UserManagement /> }
    ]
  },
  {
    path: "profile/workspace/credit-utilization",
    element: <CreditUtilization />
  }
];
