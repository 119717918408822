import { useCallback, useEffect, useMemo, useRef } from "react";
import uppyFactory, {
  UppyCompleteMultiPartUpload,
  UppyCreateMultiPartUpload,
  UppyUpdateMultiPartUpload
} from "..";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { useParams } from "react-router-dom";
import {
  removeFileFromUppy,
  resetProjectFiles,
  setCurrentFilesData,
  setFile,
  updateFile
} from "@/reducers/slices/uppySlice/uppySlice";
import { CREATE_PROJECT_STEPS } from "../../constants";
import { UPLOAD_COMPLETE_STATES, uploadMultiPartFileApi } from "../..";
import { useSnackbar } from "@/components/elements/MurfSnackbar";
import { SnackBarState } from "@/reducers/slices/dialogSlice/types";
import { PROJECT_FILE_STATUS } from "@/types/project";

interface ICallbacks {
  postFileSelect?: () => void;
}

const useUppy = (props?: ICallbacks) => {
  const { postFileSelect } = props || {};

  const uploadedFiles = useTypedSelector(
    (state) => state.clientProjectPopup.sourceFileResponseList
  );

  const allProjects = useTypedSelector((state) => state.clientHome.allProjects);

  const { projectId } = useParams();
  const { showSnackbar } = useSnackbar();

  const isInitialDataSet = useRef(false);
  const isCurrentProjectDraft = useMemo(() => {
    if (projectId && allProjects?.[projectId]) {
      return (
        allProjects[projectId].draftState === CREATE_PROJECT_STEPS.DONE.enum
      );
    }
    return false;
  }, [allProjects, projectId]);

  const dispatch = useAppDispatch();

  const _allUppyData = useTypedSelector((state) => state.uppyHandler);

  const filesData = useMemo(() => {
    if (projectId && _allUppyData?.[projectId]) {
      return _allUppyData[projectId];
    }
    return {};
  }, [_allUppyData, projectId]);

  const allFilesData = useTypedSelector((state) => state.uppyHandler);

  const createProjectPopupSavedFiles = useTypedSelector(
    (state) => state.clientProjectPopup
  );

  const createProjectPopupFiles = useMemo(() => {
    if (!projectId) {
      return {};
    }
    let temp = {};
    if (allFilesData?.[projectId]) {
      temp = { ...allFilesData?.[projectId] };
    }
    if (
      createProjectPopupSavedFiles.projectId === projectId &&
      createProjectPopupSavedFiles.sourceFileResponseList
    ) {
      temp = {
        ...temp,
        ...createProjectPopupSavedFiles.sourceFileResponseList
      };
    }
    return temp;
  }, [projectId, allFilesData, createProjectPopupSavedFiles]);

  const setFilesData = useCallback(
    (data: any) => {
      if (!projectId) {
        return;
      }
      if (!data || !Object.values(data)?.length) {
        dispatch(
          resetProjectFiles({
            projectId
          })
        );
        return;
      }
      const projectToFilesMap: any = {};
      Object.keys(data).forEach((fileId) => {
        if (projectToFilesMap[data[fileId].projectId]) {
          projectToFilesMap[data[fileId].projectId][fileId] = data[fileId];
        } else {
          projectToFilesMap[data[fileId].projectId] = {
            [fileId]: data[fileId]
          };
        }
      });
      Object.keys(projectToFilesMap).forEach((_projectId) => {
        dispatch(
          setCurrentFilesData({
            projectId: _projectId,
            filesData: projectToFilesMap[_projectId]
          })
        );
      });
    },
    [projectId, dispatch]
  );

  const handleRemoveFile = async (fileId: string) => {
    if (!fileId) {
      return;
    }
    await uploadMultiPartFileApi
      .removeFile({
        fileId
      })
      .catch((err) => {
        console.debug("[Err useUppy-handleRemoveFile()]", err);
      });
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }
    console.debug("Initializing callbacks for uppy");
    uppyFactory.setCreateMultipartUpload(
      ({ fileId, fileData, replaceWith }: UppyCreateMultiPartUpload) => {
        if (replaceWith) {
          dispatch(
            removeFileFromUppy({
              projectId: fileData.projectId,
              sourceFileId: replaceWith
            })
          );
        }
        dispatch(
          setFile({
            projectId: fileData.projectId,
            fileId,
            fileData
          })
        );
        // post file select
        if (postFileSelect && typeof postFileSelect === "function") {
          postFileSelect();
        }
      }
    );
    uppyFactory.setSignPart(
      ({ fileId, projectId }: UppyUpdateMultiPartUpload) => {
        dispatch(
          updateFile({
            projectId,
            fileId,
            updatedData: {
              status: PROJECT_FILE_STATUS.UPLOADING
            }
          })
        );
      }
    );
    uppyFactory.setCompleteMultipartUpload(
      ({ fileId, projectId, fileData }: UppyCompleteMultiPartUpload) => {
        dispatch(
          updateFile({
            projectId,
            fileId,
            updatedData: fileData
          })
        );
      }
    );
    uppyFactory.setAbortMultipartUpload(
      ({ fileId, projectId }: UppyUpdateMultiPartUpload) => {
        if (fileId) {
          dispatch(
            updateFile({
              projectId,
              sourceFileId: fileId,
              updatedData: {
                status: PROJECT_FILE_STATUS.FAILED
              }
            })
          );
        }
        if (fileId && isCurrentProjectDraft) {
          handleRemoveFile(fileId);
        }
      }
    );
    uppyFactory.setRemoveFileWithToaster(
      (projectId: string, fileId: string, reason?: string) => {
        if (fileId) {
          dispatch(
            removeFileFromUppy({
              projectId,
              sourceFileId: fileId
            })
          );
          showSnackbar(
            reason ||
              "Something went wrong while uploading this file. Please try again.",
            "error"
          );
        }
      }
    );
    uppyFactory.setShowSnackBar(
      (message: string, type: SnackBarState["type"]) => {
        if (message.length && type) {
          showSnackbar(message, type);
        }
      }
    );
  }, [
    projectId,
    setFilesData,
    isCurrentProjectDraft,
    dispatch,
    showSnackbar,
    postFileSelect
  ]);

  // Set the initial data...
  useEffect(() => {
    if (isInitialDataSet.current) {
      return;
    }
    if (uppyFactory._selectedFiles && !Object.keys(filesData)?.length) {
      isInitialDataSet.current = true;
      setFilesData(uppyFactory._selectedFiles);
    }
  }, [filesData, setFilesData]);

  const isAnyUploadInProgress = useMemo(() => {
    if (!_allUppyData || !Object.keys(_allUppyData).length) {
      return false;
    }
    return Object.values(_allUppyData).some((projectFiles) =>
      Object.values(projectFiles).some(
        (file) =>
          file.status &&
          [
            PROJECT_FILE_STATUS.UPLOADING,
            PROJECT_FILE_STATUS.WAITING_FOR_UPLOAD
          ].includes(file.status)
      )
    );
  }, [_allUppyData]);

  useEffect(() => {
    window.onbeforeunload = (e) => {
      if (isAnyUploadInProgress) {
        e.preventDefault();
        e.returnValue("");
      }
    };
  }, [isAnyUploadInProgress]);

  const videoCount = useMemo(() => {
    const _count =
      Object.keys(filesData).length +
      Math.max(Object.keys(uploadedFiles || {}).length, 0);
    // dispatch(setNumberOfVideos(_count));
    return _count;
  }, [uploadedFiles, filesData]);

  const progress = useMemo(() => {
    const _filesData = Object.values(filesData);

    const _uploadedFilesCount = uploadedFiles
      ? Object.values(uploadedFiles).map((_) =>
          UPLOAD_COMPLETE_STATES.includes(_.fileStatus as PROJECT_FILE_STATUS)
        )?.length
      : 0;

    return (
      ((_filesData.filter((_data) =>
        UPLOAD_COMPLETE_STATES.includes(_data.status as PROJECT_FILE_STATUS)
      ).length +
        _uploadedFilesCount) /
        videoCount) *
      100
    );
  }, [filesData, videoCount, uploadedFiles]);

  return {
    filesData,
    videoCount,
    progress,
    uploadedFiles,
    createProjectPopupFiles,
    isAnyUploadInProgress
  };
};

export default useUppy;
