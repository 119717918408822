import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const Tuning = lazy(() => import("./Tuning"));

export const tuningRoutes: RouteObject[] = [
  {
    path: `source/:sourceFileId/tuning`,
    element: <Tuning />
  }
];
