import { RocketLaunchRounded } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";

const Loader = ({ small }: { small?: boolean }) => (
  <Paper
    elevation={0}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 2,
      padding: small ? 1 : 3,
      border: "none"
    }}
  >
    <Typography
      variant="body2"
      color="secondary.light"
      sx={{ display: "flex", alignItems: "center", gap: 1 }}
    >
      <RocketLaunchRounded fontSize="small" /> Generating...
    </Typography>
  </Paper>
);

export default Loader;
