export interface APIResponse<T = any> {
  responseData: T;
  responseCode: string;
  responseMessage: string;
  extra: string | null;
}

export enum TASK_STATUS_TYPES {
  IDLE = "IDLE",
  QUEUED = "QUEUED",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
  DOES_NOT_EXIST = "DOES_NOT_EXIST"
}
