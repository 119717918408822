import {
  CreateIssuePayload,
  GeneralIssueAPIPayload,
  GetAllIssuePayload,
  ICreateComment,
  IDeleteComment,
  IEditComment,
  IIssue,
  IRequestChanges,
  UpdateResolveStatusPayload,
  issueCommentApi
} from "@/features/comments";
import { dubPreviewApi } from "@/features/preview";
import { errorCheck } from "@/utils/reduxUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const issueCommentThunk = {
  createIssue: createAsyncThunk(
    "createIssue",
    async (payload: CreateIssuePayload, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.createIssue(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  updateIssue: createAsyncThunk(
    "updateIssue",
    async (updateObj: Partial<IIssue>, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.updateIssue(updateObj);
        return response.data;
      }, thunkAPI);
    }
  ),
  removeIssue: createAsyncThunk(
    "removeIssue",
    async (payload: Omit<GeneralIssueAPIPayload, "fileId">, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.removeIssue(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  markAsReadIssue: createAsyncThunk(
    "markAsReadIssue",
    async (payload: Omit<GeneralIssueAPIPayload, "fileId">, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.markAsReadIssue(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  markAsUnreadIssue: createAsyncThunk(
    "markAsUnreadIssue",
    async (payload: GeneralIssueAPIPayload, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.markAsUnreadIssue(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  updateIssueTagResolveStatus: createAsyncThunk(
    "updateIssueTagResolveStatus",
    async (payload: UpdateResolveStatusPayload, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.updateIssueTagResolveStatus(
          payload
        );
        return response.data;
      }, thunkAPI);
    }
  ),
  getIssueById: createAsyncThunk(
    "getIssueById",
    async (payload: GeneralIssueAPIPayload, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.getIssueById(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  getCommentsByIssueId: createAsyncThunk(
    "getCommentsByIssueId",
    async (payload: GeneralIssueAPIPayload, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.getCommentsByIssueId(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  addComment: createAsyncThunk(
    "addComment",
    async (payload: ICreateComment, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.addComment(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  editComment: createAsyncThunk(
    "editComment",
    async (payload: IEditComment, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.editComment(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  deleteComment: createAsyncThunk(
    "deleteComment",
    async (payload: IDeleteComment, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.deleteComment(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  requestChanges: createAsyncThunk(
    "requestChanges",
    async (payload: IRequestChanges, thunkAPI) => {
      return errorCheck(async () => {
        const response = await dubPreviewApi.requestChanges(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  getChangeRequestData: createAsyncThunk(
    "getChangeRequestData",
    async (
      payload: Omit<GeneralIssueAPIPayload, "fileId" | "issueId">,
      thunkAPI
    ) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.getChangeRequestData(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  getAllIssues: createAsyncThunk(
    "getAllIssues",
    async (payload: GetAllIssuePayload, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.getAllIssues(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  getAllTVoIssues: createAsyncThunk(
    "getAllTVoIssues",
    async (payload: GetAllIssuePayload, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.getAllTVoIssues(payload);
        return response.data;
      }, thunkAPI);
    }
  ),
  getAllInternalQAPageIssues: createAsyncThunk(
    "getAllInternalQAPageIssues",
    async (payload: GetAllIssuePayload, thunkAPI) => {
      return errorCheck(async () => {
        const response = await issueCommentApi.getAllInternalQAPageIssues(
          payload
        );
        return response.data;
      }, thunkAPI);
    }
  )
};
