export const TranslationPrompts = {
  DEFAULT: "Give another variant",
  MAKE_LONGER:
    "Make it little longer than input and don't use more than 1000 characters",
  MAKE_SHORTER: "Make it a little shorter than input",
  getSuggestions: (count: number) =>
    `Give ${count} different results and don't put any extra line between results`
};

export enum TranslationPromptTypes {
  VARIANTS = "VARIANTS",
  CUSTOM_PROMPT = "CUSTOM_PROMPT",
  SHORTER = "SHORTER",
  LONGER = "LONGER",
  BASE_TRANSLATION = "BASE_TRANSLATION"
}

export enum TranslationActionTypes {
  SHORT,
  LONG,
  APPLY,
  UNDO,
  REDO
}

export enum OpenedByTypes {
  SUGGESTIONS,
  CUSTOM_PROMPT
}
