import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
// import "./components/assets/font/murf-icon.css";
import AppProvider from "./providers/index.tsx";

// @TODO: MOCK SERVICE
// if (import.meta.env.MODE === "development") {
//   import("./mocks/browser.ts").then(({ worker }) => {
//     worker.start({
//       onUnhandledRequest() {
//         // Ignore all unhandled requests
//         return;
//       }
//     });
//   });
// }

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);
