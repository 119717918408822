import { MurfLogo, UserAvatar } from "@/components/elements";
import { useAuth } from "@/lib/firebase/firebaseHooks";
import {
  AppBar,
  AppBarOwnProps,
  Divider,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar
} from "@mui/material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import useAuthMethods from "@/hooks/useAuthMethods";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { useTypedSelector } from "@/config/configureAppStore";
import { isInternalUserPolicy, usePermissions } from "@/features/permissions";
import OpenInNewRounded from "@mui/icons-material/OpenInNewRounded";

interface MurfAppBarProps extends Pick<AppBarOwnProps, "position" | "color"> {
  children?: React.ReactNode;
  hideLogo?: boolean;
  logoHref?: string;
  gap?: number;
  appBarProps?: AppBarOwnProps;
}

const MurfAppBar = ({
  children,
  position = "sticky",
  color = "default",
  hideLogo = false,
  logoHref = "/",
  gap,
  appBarProps
}: MurfAppBarProps) => {
  const user = useAuth();
  const { logout } = useAuthMethods();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { isAdmin, canAccess } = usePermissions();

  const isInternalUser = useMemo(
    () =>
      canAccess({
        policy: isInternalUserPolicy,
        policyArgs: null
      }),
    [canAccess]
  );
  const userState = useTypedSelector((state) => state.user);
  const location = useLocation();

  const isInternalRoute = useMemo(
    () => location.pathname.includes("/internal"),
    [location.pathname]
  );

  const userName = useTypedSelector(
    (state) =>
      (state.user?.firstName || "") + " " + (state.user?.lastName || "")
  );

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // TODO:  temporary Solution will be removed
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    navigate("/profile/user");
  };

  const handleWorkspaceClick = () => {
    navigate("/profile/workspace");
  };

  const handleViewExternalPlatform = () => {
    navigate("/home");
  };

  const handleViewInternalPlatform = () => {
    navigate("/internal");
  };

  return (
    <AppBar elevation={0} position={position} color={color} {...appBarProps}>
      <Toolbar sx={{ gap, px: 2 }} disableGutters>
        {!hideLogo && (
          <Link href={logoHref} mr={2}>
            <MurfLogo width={28} height={28} />
          </Link>
        )}

        {children}
        {user ? (
          <UserAvatar
            sx={{ marginLeft: "auto", cursor: "pointer" }}
            displayName={user.displayName || userName}
            onClick={handleClick}
          />
        ) : null}
      </Toolbar>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ListItem
          onFocus={(event) =>
            (event.currentTarget.nextElementSibling as HTMLElement)?.focus()
          }
        >
          <ListItemText
            primary={user?.displayName}
            primaryTypographyProps={{ variant: "subtitle1" }}
            secondary={user?.email}
          />
        </ListItem>
        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={handleProfileClick}>
          <ListItemIcon>
            <PersonRoundedIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 500 }}
            primary="Profile Settings"
          />
        </MenuItem>
        <MenuItem onClick={handleWorkspaceClick}>
          <ListItemIcon>
            <BusinessRoundedIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 500 }}
            primary="Workspace Settings"
          />
        </MenuItem>
        {isInternalRoute && isAdmin && userState?.linkedWorkspaces?.length ? (
          <MenuItem onClick={handleViewExternalPlatform}>
            <ListItemIcon>
              <OpenInNewRounded />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontWeight: 500,
                pr: 3
              }}
              primary="Go to External Platform"
            />
          </MenuItem>
        ) : null}
        {!isInternalRoute && isInternalUser ? (
          <MenuItem onClick={handleViewInternalPlatform}>
            <ListItemIcon>
              <OpenInNewRounded />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontWeight: 500,
                pr: 3
              }}
              primary="Go to Internal Platform"
            />
          </MenuItem>
        ) : null}
        <Divider />
        <MenuItem
          onClick={() => {
            logout();
          }}
        >
          <ListItemIcon>
            <LogoutRoundedIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 500 }}
            primary="Logout"
          />
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default MurfAppBar;
