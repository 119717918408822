import { workspaceThunk } from "@/reducers/thunks/workspaceThunk";
import { convertArrayToMap } from "@/utils/json";
import { createSlice } from "@reduxjs/toolkit";

export interface WorkspaceSliceState {
  userList: Record<string, any>;
  internalMembers: Record<string, any>;
}

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState: {
    userList: {}
  } as WorkspaceSliceState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        workspaceThunk.getAllUsersOfWorkspace.fulfilled,
        (state, action) => {
          const { workspaceId, users, pendingInvitationsEmails } =
            action.payload.responseData;
          state.userList[workspaceId] = {
            users,
            pendingInvitationsEmails,
            workspaceId
          };
        }
      )
      .addCase(workspaceThunk.getInternalMembers.fulfilled, (state, action) => {
        const users = action.payload.responseData;
        state.internalMembers = {
          ...state.internalMembers,
          ...convertArrayToMap(users, "userId")
        };
      });
  }
});
export default workspaceSlice.reducer;
