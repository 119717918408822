import { rest } from "msw";
import { API_BASE_URL } from "@/config/config";
import { mockAuthCheck } from "@/utils/mockUtils";
import { DUMMY_DUB_PREVIEW } from "../../constants/dummy";
import { APIResponse } from "@/types/apiResponse";
import { DubPreviewDetails } from "@/reducers/slices/dubPreviewSlice/types";

export const dubPreviewMocks = [
  rest.get(
    `${API_BASE_URL}project/:projectId/dub-response/file/:fileId/dub/:dubId`,
    (req, res, ctx) => {
      if (!mockAuthCheck(req)) {
        return res(ctx.status(401));
      }
      const { id } = req.params;
      const projectResponse: APIResponse<DubPreviewDetails> = {
        responseData: {
          ...DUMMY_DUB_PREVIEW,
          dubId: id as string
        },
        responseCode: "200",
        responseMessage: "Success",
        extra: null
      };
      if (id === "404") {
        return res(ctx.status(404));
      } else if (id === "429") {
        return res(ctx.delay(5000), ctx.status(200), ctx.json(projectResponse));
      }
      return res(ctx.delay(2000), ctx.status(200), ctx.json(projectResponse));
    }
  )
];
