import { api } from "@/lib/axios";
import { APIResponse } from "@/types/apiResponse";
import { TasksAPIResponse } from "..";
import { TASK_TYPE, TInternalTask } from "@/types/project";
import { GenericAbortSignal } from "axios";

export const dubTaskApi = {
  assignDubTask: ({
    taskId,
    assigneeId,
    sourceFileId,
    taskType,
    workspaceId
  }: {
    taskId: string;
    assigneeId: string;
    sourceFileId: string;
    taskType: string;
    workspaceId: string;
  }) =>
    api.put<APIResponse<string>>(
      `/admin/dubtask/assign/${taskId}?workspaceId=${workspaceId}`,
      {
        assigneeId,
        sourceFileId,
        taskType
      }
    ),

  fetchTask: () => api.get<APIResponse<TasksAPIResponse>>(`/admin/tasks`),
  // for fetching previous data of dub tasks to re-open a dub
  fetchTaskDetails: ({
    fileId,
    dubId,
    workspaceId
  }: {
    fileId: string;
    dubId: string;
    workspaceId: string;
  }) =>
    api.get(
      `/admin/dubtask/dub-task/file/${fileId}/dub/${dubId}?workspaceId=${workspaceId}`
    )
};

export const taskApi = {
  fetchTasks: ({
    taskType,
    firstRequest = true,
    signal,
    lastEvaluatedKey,
    myTask = false,
    filters = {},
    topLevelFilters = {},
    isAdminFilter = false
  }: {
    taskType: TASK_TYPE | "ALL";
    firstRequest: boolean;
    signal?: GenericAbortSignal;
    lastEvaluatedKey?: Record<string, any>;
    myTask?: boolean;
    filters?: Record<string, string[]>;
    topLevelFilters?: Record<string, string[]>;
    isAdminFilter?: boolean;
  }) =>
    api.post<
      APIResponse<{
        dubTasks: TInternalTask[];
        faceMarkLastEvaluatedKey: Record<string, any> | null;
        qualityCheckLastEvaluatedKey: Record<string, any> | null;
        translationLastEvaluatedKey: Record<string, any> | null;
        voiceOverLastEvaluatedKey: Record<string, any> | null;
      }>
    >(
      `admin/dubtask/task-list${
        isAdminFilter && !myTask ? "/top-level-filter" : ""
      }`,
      {
        firstRequest: firstRequest,
        filters: filters,
        taskType,
        myTask: myTask,
        ...(lastEvaluatedKey ? { ...lastEvaluatedKey } : {}),
        ...(!myTask ? { topLevelFilters } : {})
      },
      {
        signal
      }
    )
};
