import { Switch, SwitchProps, styled } from "@mui/material";
import switchBaseClasses from "@mui/material/internal/switchBaseClasses";
import React from "react";

const Toggle = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .Mui-checked": {
    "& + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.dark,
      opacity: `1 !important`
    }
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: theme.palette.secondary.light,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 40,
      height: 22
    }
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.secondary.contrastText,
    boxShadow: "none",
    width: 20,
    height: 20,
    margin: 0
  }
}));

const ToggleSmall = styled(Switch)(({ theme, color }) => {
  const paletteColor = color && color !== "default" ? color : "primary";
  return {
    padding: 3,
    [`& .${switchBaseClasses.root}`]: {
      padding: 5
    },
    "& .Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette[paletteColor].main,
        opacity: `1 !important`
      }
    },
    "& .MuiSwitch-track": {
      borderRadius: 18 / 2,
      backgroundColor: theme.palette.secondary.light,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 34,
        height: 18
      }
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette[paletteColor].contrastText,
      boxShadow: "none",
      width: 14,
      height: 14,
      margin: 0
    }
  };
});

export const MFToggle = (props: SwitchProps): React.ReactNode => {
  if (props.size === "small") {
    return <ToggleSmall {...props} />;
  }
  return <Toggle {...props} />;
};
