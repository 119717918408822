import { useSnackbar } from "@/components/elements/MurfSnackbar";
import { useCallback, useEffect, useState } from "react";

const STATUS = {
  ONLINE: "online",
  OFFLINE: "offline"
};

const useConnectivityHook = () => {
  const { showError } = useSnackbar();
  const [isOnline, setIsOnline] = useState(true);

  const updateState = useCallback((e: any) => {
    setIsOnline(e.type === STATUS.ONLINE);
  }, []);

  useEffect(() => {
    window.addEventListener(STATUS.ONLINE, updateState);
    window.addEventListener(STATUS.OFFLINE, updateState);
    return () => {
      window.removeEventListener(STATUS.ONLINE, updateState);
      window.removeEventListener(STATUS.OFFLINE, updateState);
    };
  }, [updateState]);

  useEffect(() => {
    if (!isOnline) {
      showError("You are offline.");
    }
  }, [isOnline, showError]);

  return isOnline;
};

export default useConnectivityHook;
