import { Stack } from "@mui/material";
import Editor from "@/features/editor";
import { EventHandlers } from "@/features/editor/hooks/useEditor";
import { HandleBlockSeekType, LocalScriptBlockType } from "@/features/blocks";
import { TextBlockMenu } from "./TextBlockMenu";
import TranslationMenu from "./TranslationMenu";
import { BLOCK_ERROR_TYPE } from "@/features/blocks/constants";
import { useMemo } from "react";

interface TranslatedTextBlockProps {
  eventHandlers: EventHandlers;
  block: LocalScriptBlockType;
  audioPlayerId: string;
  readOnly: boolean;
  qcEnabled: boolean;
  isActive: boolean;
  handleBlockPlay: (isPlay: boolean) => void;
  handleBlockSeek: HandleBlockSeekType;
  isPlaying: boolean;
  hasError: boolean;
  hasTextLengthError: boolean;
  errorMessage: string | null;
  buffering: boolean;
  textEditDisabled: boolean;
  richTextEnabled: boolean;
}

const TranslatedTextBlock = ({
  eventHandlers,
  block,
  isActive,
  handleBlockPlay,
  handleBlockSeek,
  isPlaying,
  hasError,
  errorMessage,
  hasTextLengthError,
  readOnly,
  qcEnabled,
  buffering,
  textEditDisabled,
  richTextEnabled
}: TranslatedTextBlockProps) => {
  const audioLengthError = useMemo(() => {
    return block.blockErrors.find(
      (error) =>
        error.type === BLOCK_ERROR_TYPE.RENDER_LENGTH_EXCEEDED ||
        error.type === BLOCK_ERROR_TYPE.RENDER_LENGTH_INSUFFICIENT
    );
  }, [block.blockErrors]);

  return (
    <Stack width="53%">
      <TextBlockMenu
        showCompleteCheck={qcEnabled}
        block={block}
        showPlayback
        showUnMarkMessage={qcEnabled && block.completed && isActive}
        handleBlockPlay={handleBlockPlay}
        handleBlockSeek={handleBlockSeek}
        isPlaying={isPlaying}
        hasError={hasError}
        errorMessage={errorMessage}
        hasTextLengthError={hasTextLengthError}
        readOnly={readOnly}
        buffering={buffering}
      />
      <Editor
        eventHandlers={eventHandlers}
        blockId={block.blockId}
        index={block.blockNumber - 1}
        text={block.htmlText}
        isActive={isActive}
        hasTextLengthError={hasTextLengthError}
        disabled={textEditDisabled}
        translationEditor={richTextEnabled}
        isAudioShort={
          audioLengthError?.type === BLOCK_ERROR_TYPE.RENDER_LENGTH_INSUFFICIENT
        }
        isAudioLong={
          audioLengthError?.type === BLOCK_ERROR_TYPE.RENDER_LENGTH_EXCEEDED
        }
      />
      <TranslationMenu block={block} isActive={!textEditDisabled && isActive} />
    </Stack>
  );
};

export default TranslatedTextBlock;
