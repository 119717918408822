import RequiresAuth from "@/components/auth/RequiresAuth";
import { RouteObject } from "react-router-dom";
import { taskRoutes } from "@/features/task";
import { projectInternalRoutes } from "@/features/projectInternal";
import { tuningRoutes } from "@/features/tuning";
import { scriptStudioRoutes } from "@/features/scriptStudio";
import Internal from "./Internal";
import getIndexRedirect from "@/utils/getIndexRedirect";
import { workspaceRoutes } from "@/features/workspace";
import { userManagementRoutes } from "@/features/user";
import { dubPreviewInternalRoutes } from "@/features/dubPreviewInternal/routes";

export const internalRoute: RouteObject = {
  path: `/internal`,
  element: (
    <RequiresAuth>
      <Internal />
    </RequiresAuth>
  ),
  children: [
    getIndexRedirect("/internal/tasks"),
    ...tuningRoutes,
    ...scriptStudioRoutes,
    ...taskRoutes,
    ...projectInternalRoutes,
    ...workspaceRoutes,
    ...userManagementRoutes,
    ...dubPreviewInternalRoutes
  ]
};
