import { Button, Divider, Stack } from "@mui/material";
import { ChevronLeftRounded } from "@mui/icons-material";
import { memo } from "react";
import MurfAppBar from "@/components/layout/MurfAppBar";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { clearDubDetails } from "@/reducers/slices/dubPreviewSlice/dubPreviewSlice";
import { useNavigate } from "react-router";
import Actions from "./Actions";
import { STATUS } from "@/constants/status";
import { clearCurrentDubDataForIssues } from "@/reducers/slices/issueSlice/issueSlice";
import MarkAsDoneQcTaskDialog from "../dialogs/MarkAsDoneQcTaskDialog";

export function DubPreviewInternalTopBar() {
  const dispatch = useAppDispatch();

  const dubPreviewApiStatus = useTypedSelector(
    (state) => state.dubPreview.dubPreviewApiStatus
  );

  const navigate = useNavigate();

  const handleBackClick = () => {
    dispatch(clearDubDetails());
    dispatch(clearCurrentDubDataForIssues());
    navigate(-1);
  };

  return (
    <MurfAppBar>
      <Stack
        width={"100%"}
        direction={"row"}
        justifyContent={"space-between"}
        paddingX={0}
      >
        {dubPreviewApiStatus === STATUS.SUCCESS ? (
          <>
            <Stack direction={"row"} gap={2} alignItems={"center"}>
              <Button
                onClick={handleBackClick}
                variant="outlined"
                startIcon={<ChevronLeftRounded />}
                color="secondary"
                size="small"
              >
                Back
              </Button>
            </Stack>
            <Actions />{" "}
          </>
        ) : null}
      </Stack>
      <Divider orientation="vertical" sx={{ marginX: 1 }} />
      <MarkAsDoneQcTaskDialog />
    </MurfAppBar>
  );
}

export const MemoizedDubPreviewInternalTopBar = memo(DubPreviewInternalTopBar);
