import { useCallback } from "react";
import pollFactory from "@/utils/PollFactory";
import { handleRenderError, pollRenderStatus } from "../util";
import {
  store,
  useAppDispatch,
  useTypedSelector
} from "@/config/configureAppStore";
import {
  deleteRenderingBlockIds,
  upsertRenderedBlocksMap,
  checkBlockError,
  addRenderUpdatedBlocksQueue
} from "@/reducers/slices/projectUtilitySlice/projectUtilitySlice";
import { BlocksMap } from "@/reducers/slices/projectUtilitySlice/types";
import { RenderedBlockType, TextBlockStatus } from "@/features/blocks";
import { translationVoThunks } from "@/reducers/thunks/translationVo";
import { RenderPayload } from "..";
import { captureMessage } from "@sentry/react";
import { convertRichTextToHtmlText } from "@/features/editor";
import useTvoTrackingEvent from "@/features/scriptStudio/hooks/useTvoTrackingEvent";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";

export interface PollBlocksParams {
  blockIdList: string[];
  renderVersionMap: { [key: string]: number };
  payload: RenderPayload;
  onEnd?: (args: { totalSuccessfulBlocks?: any }) => any;
  onError?: (errMsg?: string) => any;
  pollerId?: string;
  startTimeStamp: number;
}

export interface ValidateBlockStatusParams {
  responseBlocks: BlocksMap;
  renderVersionMap: { [key: string]: number };
  payload: RenderPayload;
  onEnd?: (args: { totalSuccessfulBlocks?: any }) => any;
  onError?: (props?: any) => any;
  startTimeStamp: number;
}

export function usePollBlocks() {
  const workspaceId = useTypedSelector(
    (state) => state.currentScriptProject.workspaceId
  );
  const fileId = useTypedSelector((state) => state.currentScriptProject.fileId);
  const dubId = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.dubId
  );
  const dubVersion = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.dubVersion
  );
  const richTextEnabled = useTypedSelector(
    (state) => state.projectUtility.richTextEnabled
  );
  const pronunciations = useTypedSelector(
    (state) => state.currentScriptProject.pronunciations
  );

  const dispatch = useAppDispatch();
  const trackEvent = useTvoTrackingEvent();

  const validateBlockStatus = useCallback(
    ({
      responseBlocks,
      onEnd,
      onError,
      renderVersionMap,
      payload,
      startTimeStamp
    }: ValidateBlockStatusParams) => {
      let stopPolling = false;
      const failedBlocksMap: BlocksMap = {};
      const successfulBlocksMap: BlocksMap = {};
      const renderedBlocksMap: Record<string, Partial<RenderedBlockType>> = {};
      const nonExistentBlocks: any = [];
      let versionChangedBlocks = 0;

      const renderingBlocks =
        store.getState().projectUtility?.renderingBlocks || [];

      let responseBlockIds = Object.keys(responseBlocks);

      // Check if any of the blocks are already processed or locally updated
      if (responseBlockIds.length !== renderingBlocks.length) {
        responseBlockIds = responseBlockIds.filter((key) => {
          if (!renderingBlocks.includes(key)) {
            delete responseBlocks[key];
            return false;
          }
          return true;
        });
      }

      const totalBlocks = responseBlockIds.length;

      // Circuit breaker logic
      if (store.getState().projectUtility?.renderCircuitBreaker) {
        console.log(
          "RENDER STOPPED - Circuit breaker triggered",
          responseBlockIds
        );
        onError && onError("Block updated. Please render again.");
        handleRenderError(responseBlockIds, dispatch, {
          extra: "Block updated. Please render again."
        });
        return true;
      }

      // if responseBlockIds is empty, then it means that the render is stuck
      if (!responseBlockIds.length || !renderingBlocks.length) {
        captureMessage(
          `Render stuck (${
            !responseBlockIds.length
              ? "0 response blockIds"
              : "0 rendering blocks"
          })`,
          {
            level: "error",
            extra: {
              responseBlockIds: responseBlockIds,
              renderCircuitBreaker:
                store.getState().projectUtility?.renderCircuitBreaker,
              responseBlockIdsLength: responseBlockIds.length,
              renderingBlocksLength: renderingBlocks.length,
              renderingBlocks,
              responseBlocks
            }
          }
        );

        // stop polling if there are no blocks to render
        return true;
      }

      let anyBlockRenderComplete = false;

      responseBlockIds.forEach((blockId) => {
        const currentBlock = responseBlocks[blockId];

        // ignore the block if the renderVersion is not updated
        if (currentBlock.renderVersion >= renderVersionMap[blockId]) {
          anyBlockRenderComplete = true;
          versionChangedBlocks++;
          const speakerProperties = payload[blockId]?.speakerProperties;
          if (startTimeStamp) {
            const timeTaken = (Date.now() - startTimeStamp) / 1000;
            trackEvent(MIXPANEL_EVENTS.RENDER_TIME, {
              "Start time": new Date(startTimeStamp).toString(),
              "End time": new Date().toString(),
              "Time taken": timeTaken,
              "Audio duration": currentBlock.renderLength,
              RTF: timeTaken / currentBlock.renderLength
            });
          }

          switch (currentBlock.renderStatus) {
            case TextBlockStatus.FAILED:
              failedBlocksMap[blockId] = {
                ...currentBlock,
                needsRendering: true
              };
              break;
            case TextBlockStatus.DOES_NOT_EXIST:
              failedBlocksMap[blockId] = {
                ...currentBlock,
                needsRendering: true
              };
              nonExistentBlocks.push(blockId);
              break;
            case TextBlockStatus.DONE:
              // console.log("BLOCK DONE");
              successfulBlocksMap[blockId] = {
                ...currentBlock,
                needsRendering: false,
                affectedByBlockProperties: false,
                affectedBySpeakerProperties: false
              };
              renderedBlocksMap[blockId] = {
                blockId: currentBlock.blockId,
                speakerId: currentBlock.speakerId,
                renderVersion: currentBlock.renderVersion,
                renderStatus: currentBlock.renderStatus,
                // creating html text from rendered rich text and storing it
                htmlText: richTextEnabled
                  ? convertRichTextToHtmlText(
                      currentBlock.phrasePropertiesList,
                      pronunciations || []
                    )
                  : currentBlock.richText,
                renderedPitch: speakerProperties?.pitch || 0,
                renderedVoiceVariant:
                  speakerProperties?.selectedVoiceVariant || null,
                needsRendering: false
              };
              break;
            default:
              {
                // logging exception to sentry
                captureMessage("Render failed (default case)", {
                  level: "error",
                  extra: {
                    renderStatus: currentBlock.renderStatus,
                    requiredRenderV: renderVersionMap[blockId],
                    renderV: currentBlock.renderVersion,
                    renderingBlocksLength: renderingBlocks.length,
                    receivedBlocksLength: totalBlocks,
                    versionChangedBlocks
                  }
                });
                failedBlocksMap[blockId] = {
                  ...currentBlock,
                  renderStatus: TextBlockStatus.FAILED,
                  needsRendering: true
                };
              }
              break;
          }
        }
      });
      // console.log("SUCCESS", successfulBlocksMap);

      const processedBlocks = {
        ...failedBlocksMap,
        ...successfulBlocksMap
      };

      // console.log("PROCESSED", processedBlocks);

      const totalSuccessfulBlocks = Object.keys(successfulBlocksMap).length;

      // TODO: Add sync call for non-existent blocks

      // when all the blocks have been processed
      if (anyBlockRenderComplete) {
        // update block errors
        dispatch(checkBlockError({ blocksMap: processedBlocks }));
        dispatch(
          addRenderUpdatedBlocksQueue({
            blockIds: Object.keys(successfulBlocksMap)
          })
        );
        // adding blocks to rendered blocks
        dispatch(upsertRenderedBlocksMap({ renderedBlocksMap }));

        // delete all successful and failed blocks from renderingBlocks array
        dispatch(
          deleteRenderingBlockIds({
            blocksMap: processedBlocks
          })
        );

        // update blocksMap
        dispatch(
          translationVoThunks.updateBlocks({
            blocks: Object.values(processedBlocks)
          })
        );

        if (versionChangedBlocks === totalBlocks) {
          stopPolling = true;
        }

        onEnd && onEnd({ totalSuccessfulBlocks });
      }

      return stopPolling;
    },
    [dispatch, pronunciations, richTextEnabled, trackEvent]
  );

  const pollBlocks = useCallback(
    ({
      blockIdList,
      renderVersionMap,
      onEnd,
      onError,
      pollerId,
      payload,
      startTimeStamp
    }: PollBlocksParams) => {
      pollFactory.getPoller({
        id: pollerId,
        fn: () =>
          pollRenderStatus(workspaceId, fileId, dubId, dubVersion, blockIdList),
        validate: (blocks: any) =>
          validateBlockStatus({
            responseBlocks: blocks,
            renderVersionMap,
            onEnd,
            onError,
            payload,
            startTimeStamp
          }),
        interval: 2000
      });
    },
    [dubId, dubVersion, fileId, validateBlockStatus, workspaceId]
  );

  return { pollBlocks };
}
