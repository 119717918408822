import { api } from "@/lib/axios";
import { APIResponse } from "@/types/apiResponse";
import { TuningFile } from "@/features/tuning/types";

export interface FetchDetailedTuningParams {
  sourceFileId: string;
  workspaceId: string;
}

export const tuningProjectApi = {
  fetchDetailedTuningData: ({
    sourceFileId,
    workspaceId
  }: FetchDetailedTuningParams) =>
    api.get<APIResponse<TuningFile>>(
      `/member/face-mark/${sourceFileId}/clip-list-mapper?workspaceId=${workspaceId}`
    )
};
