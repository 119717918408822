import { ScriptBlockType } from "@/features/blocks";
import { getTVoApiUrl } from "@/features/scriptStudio";
import { Speaker, TVoApiParams } from "@/features/scriptStudio/types";
import { api } from "@/lib/axios";
import { RenderedBlocksMap } from "@/reducers/slices/projectUtilitySlice/types";
import { APIResponse } from "@/types/apiResponse";

export type RenderPayload = {
  [key: string]: {
    blockProperties: Partial<ScriptBlockType>;
    speakerProperties: Partial<Speaker>;
  };
};

export interface RenderBlocksParams extends TVoApiParams {
  dubId: string;
  payload: RenderPayload;
}

export interface GetRenderBlockStatusParams extends TVoApiParams {
  dubId: string;
  blockIds: string[];
}

export const blockSynthesisApi = {
  renderBlocks: ({
    dubId,
    dubVersion,
    fileId,
    workspaceId,
    payload
  }: RenderBlocksParams) =>
    api.post<APIResponse<null>>(
      getTVoApiUrl("block-render", { workspaceId, fileId, dubId, dubVersion }),
      payload
    ),
  getRenderBlocksStatus: ({
    dubId,
    dubVersion,
    fileId,
    workspaceId,
    blockIds
  }: GetRenderBlockStatusParams) =>
    api.post<APIResponse<RenderedBlocksMap>>(
      getTVoApiUrl("block-render-status", {
        workspaceId,
        fileId,
        dubId,
        dubVersion
      }),
      blockIds
    )
};
