import {
  createContext,
  useContext,
  useMemo,
  useReducer,
  Dispatch,
  useRef
} from "react";
import {
  MediaPlayerActionType,
  MediaPlayerContextType,
  MediaPlayerContextListType,
  MediaPlayerStateType,
  MediaPlayerSeekToParams,
  MediaPlayerProviderProps,
  MediaPlayerCallbackFunctions
} from "../types";
import { generateId } from "@/utils/misc";
import FilePlayer from "react-player/file";

const MediaPlayerContexts: MediaPlayerContextListType = {};

function MediaPlayerProvider({
  id = generateId("MEDIA_PLAYER_"),
  children,
  initialMuted = false,
  ...props
}: MediaPlayerProviderProps) {
  const callbackRefs = useRef<MediaPlayerCallbackFunctions>(null);
  const mediaPlayerRef = useRef<FilePlayer>(null);
  const initialState: MediaPlayerStateType = {
    // mediaPlayerRef: useRef<FilePlayer>(null),
    mediaProgress: 0,
    mediaProgressFraction: 0,
    mediaUrl: "",
    buffering: false,
    volume: 1,
    isPlaying: false,
    muted: initialMuted,
    seeking: false
  };

  function mediaPlayerReducer(
    state: MediaPlayerStateType,
    action: MediaPlayerActionType
  ) {
    switch (action.type) {
      case "setProgress": {
        const currentTime = action.payload;
        const duration = mediaPlayerRef?.current?.getDuration() ?? 0;
        const fraction = isNaN(currentTime / duration)
          ? 0
          : currentTime / duration;

        return {
          ...state,
          mediaProgress: action.payload,
          mediaProgressFraction: fraction
        };
      }
      case "setUrl": {
        return {
          ...state,
          mediaUrl: action.payload
        };
      }
      case "playPause": {
        const playState = action.payload?.playState ?? !state.isPlaying;
        if (playState && callbackRefs.current?.onPlay) {
          callbackRefs.current.onPlay();
        }
        if (!playState && callbackRefs.current?.onPause) {
          callbackRefs.current.onPause();
        }
        return {
          ...state,
          isPlaying: playState,
          mediaUrl: action.payload?.mediaUrl ?? state.mediaUrl
        };
      }
      case "seekTo": {
        const vidRef = mediaPlayerRef;
        const payload = action.payload;
        const seekType = payload.type ?? "seconds";
        let newMediaProgress = state.mediaProgress;
        let newMediaProgressFraction = state.mediaProgressFraction;
        if (vidRef?.current) {
          let seekAmount = payload.amount;
          const duration = vidRef.current.getDuration();
          // if seek value is more than the total duration
          // we'll just seek to the last frame
          if (seekType === "seconds" && payload.amount >= duration) {
            seekAmount = vidRef.current.getDuration();
          }
          vidRef.current.seekTo(seekAmount, seekType);
          newMediaProgress =
            seekType === "seconds" ? seekAmount : seekAmount * duration;
          newMediaProgressFraction =
            seekType === "seconds"
              ? isNaN(seekAmount / duration)
                ? 0
                : seekAmount / duration
              : seekAmount;
        }

        if (callbackRefs.current?.onProgress) {
          callbackRefs.current.onProgress(newMediaProgress);
        }

        return {
          ...state,
          mediaProgress: newMediaProgress,
          mediaProgressFraction: newMediaProgressFraction
        };
      }
      case "requestFullScreen": {
        const vidRef = mediaPlayerRef;
        const player = vidRef?.current?.getInternalPlayer();
        if (player) {
          if (player.requestFullscreen) {
            player.requestFullscreen();
          } else if (player.mozRequestFullScreen) {
            player.mozRequestFullScreen();
          } else if (player.webkitRequestFullscreen) {
            player.webkitRequestFullscreen();
          } else if (player.msRequestFullscreen) {
            player.msRequestFullscreen();
          }
        }
        return state;
      }
      case "setBuffering": {
        if (state.buffering !== action.payload) {
          return {
            ...state,
            buffering: action.payload
          };
        }

        return state;
      }
      case "setVolume": {
        return {
          ...state,
          volume: action.payload
        };
      }
      case "toggleMute": {
        return {
          ...state,
          muted: !state.muted
        };
      }
      case "setMute": {
        return {
          ...state,
          muted: action.payload
        };
      }
      case "setSeeking": {
        return {
          ...state,
          seeking: action.payload
        };
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  }

  const [state, dispatch] = useReducer(mediaPlayerReducer, initialState);

  const value = useMemo(() => {
    return { state, dispatch, mediaPlayerRef, callbackFunctions: callbackRefs };
  }, [state, dispatch, mediaPlayerRef]);

  if (!MediaPlayerContexts[id]) {
    MediaPlayerContexts[id] = createContext<MediaPlayerContextType | null>(
      null
    );
  }

  const MediaPlayerProviderWithId = MediaPlayerContexts[id];

  return (
    <MediaPlayerProviderWithId.Provider value={value} {...props}>
      {children}
    </MediaPlayerProviderWithId.Provider>
  );
}

function useMediaPlayer(id: string) {
  if (!MediaPlayerContexts[id]) {
    throw new Error(
      `MediaPlayer context with id ${id} does not exist. Make sure the component is wrapped within a MediaPlayerProvider`
    );
  }
  const context = useContext(MediaPlayerContexts[id]);
  if (context === undefined) {
    throw new Error(`useMediaPlayer must be used within a MediaPlayerProvider`);
  }

  return context;
}

function playPause(
  dispatch: Dispatch<MediaPlayerActionType>,
  payload?: { mediaUrl?: string; playState?: boolean }
) {
  dispatch({ type: "playPause", payload });
}

function seekTo(
  dispatch: Dispatch<MediaPlayerActionType>,
  payload: MediaPlayerSeekToParams
) {
  dispatch({ type: "seekTo", payload });
}

function setProgress(
  dispatch: Dispatch<MediaPlayerActionType>,
  payload: number
) {
  dispatch({ type: "setProgress", payload });
}

function setMediaUrl(
  dispatch: Dispatch<MediaPlayerActionType>,
  payload: string
) {
  dispatch({ type: "setUrl", payload });
}

function requestFullScreen(dispatch: Dispatch<MediaPlayerActionType>) {
  dispatch({ type: "requestFullScreen" });
}

function setVolume(dispatch: Dispatch<MediaPlayerActionType>, payload: number) {
  dispatch({ type: "setVolume", payload });
}

function setBuffering(
  dispatch: Dispatch<MediaPlayerActionType>,
  payload: boolean
) {
  dispatch({ type: "setBuffering", payload });
}

function onMediaEnd(dispatch: Dispatch<MediaPlayerActionType>) {
  // dispatch({ type: "playPause" });
  dispatch({ type: "seekTo", payload: { amount: 0 } });
}

function deleteMediaPlayer(id: string) {
  if (MediaPlayerContexts[id]) {
    delete MediaPlayerContexts[id];
  }
}

function toggleMute(dispatch: Dispatch<MediaPlayerActionType>) {
  dispatch({ type: "toggleMute" });
}

function setMute(dispatch: Dispatch<MediaPlayerActionType>, payload: boolean) {
  dispatch({ type: "setMute", payload });
}

function setSeeking(
  dispatch: Dispatch<MediaPlayerActionType>,
  payload: boolean
) {
  dispatch({ type: "setSeeking", payload });
}

export {
  playPause,
  seekTo,
  setProgress,
  setMediaUrl,
  onMediaEnd,
  requestFullScreen,
  setVolume,
  setBuffering,
  MediaPlayerProvider,
  useMediaPlayer,
  deleteMediaPlayer,
  toggleMute,
  setMute,
  setSeeking
};

// Exported functions
//
// 1. seekTo: seek to a timestamp defined in seconds by default
// 2. setProgress: the state stores the progress of the media. This function updates that
// 3. setMediaUrl: set the url of the media to be played
// 4. playPause: play or pause the media depending on the current play/pause state of the media
// 5. deleteMediaPlayer: to delete the instance of media player with the provided id
// 6. onMediaEnd: to handle the end of the media
// 7. useMediaPlayer: to use the media player context
// 8. MediaPlayerProvider: to wrap the component that uses the media player context
// 9. requestFullScreen: to request full screen for the media player
// 10. setVolume: to set the volume of the media player
// 11. setBuffering: to set the buffering state of the media player
