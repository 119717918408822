import { RootState } from "@/config/configureAppStore";
import { LANGUAGES } from "@/constants/languages";
import { MURF_ROLES, TMember } from "@/features/user";
import { TASK_TYPE } from "@/types/project";
import { convertArrayToMap } from "@/utils/json";
import { createSelector } from "@reduxjs/toolkit";

export const activeMemberSelector = createSelector(
  (state: RootState) => state.userManagement.users,
  (activeUsers) => {
    const users = activeUsers.filter(
      (user) =>
        user.userId &&
        user.murfRole !== MURF_ROLES.USER &&
        !user.invitationPending
    ) as TMember[];
    const userMap = convertArrayToMap(users, "userId");
    return {
      users,
      userMap
    };
  }
);

type MemberSelectorParams = {
  roles: MURF_ROLES[];
  taskTypes?: TASK_TYPE[];
  languages?: LANGUAGES[];
};

export const getMembersBasedOnRole = createSelector(
  [
    activeMemberSelector,
    (_state: RootState, params: MemberSelectorParams) => params
  ],
  ({ users }, { roles, languages, taskTypes }) => {
    const filteredUsers = users.filter((user) => {
      if (user.murfRole === MURF_ROLES.CONTRIBUTOR) {
        const languageMatch = (user.languages ?? []).some((e) =>
          languages?.includes(e)
        );
        const taskMatch = (user.specialisation ?? []).some((e) =>
          taskTypes?.includes(e)
        );
        return languageMatch && taskMatch;
      }
      // ADMIN OR DM
      return roles.includes(user.murfRole);
    });

    return {
      users: filteredUsers,
      userMap: convertArrayToMap(filteredUsers, "userId")
    };
  }
);

type GetTaskUsersProps = {
  taskType: TASK_TYPE;
  language: LANGUAGES;
  workspaceId?: string;
  role: MURF_ROLES[];
};

export const getMembersForTaskAssignment = createSelector(
  [
    (state: RootState, params: GetTaskUsersProps) =>
      getMembersBasedOnRole(state, {
        roles: [
          MURF_ROLES.ADMIN,
          MURF_ROLES.CONTRIBUTOR,
          MURF_ROLES.DELIVERY_MANAGER
        ],
        languages: [params.language],
        taskTypes: [params.taskType]
      }),
    (_state: RootState, params: GetTaskUsersProps) => params.workspaceId
  ],
  ({ users }, workspaceId) => {
    const filteredUsers = users.filter((user) => {
      if (user.murfRole === MURF_ROLES.CONTRIBUTOR) {
        return true;
      }
      return workspaceId
        ? user.assignedWorkspaces?.includes(workspaceId)
        : true;
    });
    return {
      users: filteredUsers,
      userMap: convertArrayToMap(filteredUsers, "userId")
    };
  }
);
