const STORAGE_PREFIX = "murf_";
export const USER_INVITE_PENDING = "user__invite__pending";
export const START_FLOW_COMPLETED = "start__flow__completed";

const storagePrefix = (key: string) => `${STORAGE_PREFIX}${key}`;

const storageService = {
  getItem: (key: string) => localStorage.getItem(storagePrefix(key)),
  setItem: (key: string, data: any) => {
    localStorage.setItem(storagePrefix(key), data);
  },
  removeItem: (key: string) => {
    localStorage.removeItem(storagePrefix(key));
  },
  clear: () => {
    localStorage.clear();
  }
};

export { storageService };
