import { TASK_TYPE } from "@/types/project";
import { QC_ACCESS_ROLES } from "@/types/user";
import { BoxProps, CollapseProps, StackOwnProps } from "@mui/material";

export interface CommentEditorProps {
  htmlText: any;
  textAreaRef: any;
  actionHandler: () => void;
  defaultParentRef?: any;
  /**
   * @default: false
   */
  readOnly?: boolean;
  /**
   * @default: false
   */
  editMode?: boolean;
  /**
   * @default: false
   */
  isLoading?: boolean;
  onCancel?: () => void;
  placeholder?: string;
  editorStyles?: StackOwnProps["sx"];
  isExternal?: boolean;
}
export interface ICreatedBy {
  displayName: string;
  avatarUrl?: string | null;
  // userId
}

export enum IssueTags {
  LIP_SYNC = "FACE_MARKING",
  TRANSLATION = "TRANSLATION",
  VOICEOVER = "VOICEOVER",
  BACKGROUND_MUSIC = "BACKGROUND_MUSIC"
}

export const IssueTagsLabel = {
  [IssueTags.LIP_SYNC]: "Lip Sync",
  [IssueTags.TRANSLATION]: "Translation",
  [IssueTags.VOICEOVER]: "Voiceover",
  [IssueTags.BACKGROUND_MUSIC]: "Background Music"
};

export enum ISSUE_TAG_RESOLVE_STATUS {
  UNRESOLVED = "UNRESOLVED",
  RESOLVED = "RESOLVED",
  CANNOT_BE_RESOLVED = "CANNOT_BE_RESOLVED"
}

export interface IIssueTag {
  name: IssueTags;
  status: ISSUE_TAG_RESOLVE_STATUS;
  resolvedBy: string | null;
  resolvedAt: number;
}

export const IssueTagsToTaskType = {
  [IssueTags.LIP_SYNC]: TASK_TYPE.FACE_MARKING,
  [IssueTags.TRANSLATION]: TASK_TYPE.TRANSLATION,
  [IssueTags.VOICEOVER]: TASK_TYPE.VOICE_OVER,
  [IssueTags.BACKGROUND_MUSIC]: TASK_TYPE.VOICE_OVER
};

export enum ICommentTextType {
  TEXT = "TEXT",
  MENTION = "MENTION",
  MENTION_WITH_INVITE = "MENTION_WITH_INVITE"
}

export interface IUserForMentions {
  name: string;
  userId: string;
  profileImageUrl?: string;
  email: string;
  shouldSendInvite?: boolean;
}

export interface ICommentText {
  type: ICommentTextType;
  text: string;
  userId?: string;
  shouldSendInvite?: boolean;
  email?: string;
  properties?: {
    [key: string]: string;
  };
}

export interface ICreateComment {
  textDescriptionList: ICommentText[];
  issueId: string;
  fileId: string;
  dubId: string;
  workspaceId: string;
  externalComment: boolean;
  projectId: string;
  dubVersion: string;
}

export interface IEditComment extends ICreateComment {
  commentId: string;
}

export interface IDeleteComment {
  commentId: string;
  issueId: string;
  workspaceId: string;
}

export interface IComment extends ICreateComment {
  commentId: string;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ICreateIssue {
  fileId: string;
  dubId: string;
  internalIssue: boolean;
  tags: IIssueTag[];
  startTimeStamp: number;
  description: {
    textDescriptionList: ICommentText[];
  };
  projectId: string;
  externalIssue?: boolean;
}

export interface CreateIssuePayload extends ICreateIssue {
  dubVersion: string;
  workspaceId: string;
}

export interface IIssue extends ICreateIssue {
  issueId: string;
  changeRequestSubmitted: boolean;
  clientComments: number;
  membersComment: number;
  resolved: boolean;
  createdAt: Date;
  updatedAt: Date;
  lastSeen: number;
  internalUpdatedAt: Date;
  clientUpdatedAt: Date;
  createdBy: string;
  description: {
    textDescriptionList: ICommentText[];
  };
  workspaceId: string;
  dubVersion: string;
  status: ISSUE_TAG_RESOLVE_STATUS;
}

type IIssueCardSize = "small" | "medium";
export interface IIssueCard {
  title: ICommentText[];
  createdBy: string;
  startTimeStamp: string | number;
  createdAt: Date;
  tags: IIssueTag[];
  /**
   * @default small
   */
  size?: IIssueCardSize;
  /**
   * @default false
   */
  truncateText?: boolean;
  workspaceId?: string;
  isUnread?: boolean;
  /**
   * @default true
   */
  showIssueStatus?: boolean;
}

export interface GeneralIssueAPIPayload {
  issueId: string;
  fileId: string;
  dubId: string;
  workspaceId?: string;
  dubVersion: string;
}

export interface GetAllIssuePayload {
  fileId: string;
  dubId: string;
  dubVersion: string;
  workspaceId: string;
}

export interface CommentCardProps extends IComment {
  isUnread?: boolean;
  disabled?: boolean;
  isExternal?: boolean;
}

export interface TagsResolvePayload {
  name: IssueTags;
  resolvedBy: string | null;
  status: ISSUE_TAG_RESOLVE_STATUS;
}

export interface UpdateResolveStatusPayload {
  dubId: string;
  dubVersion: string;
  workspaceId: string;
  issueId: string;
  data: TagsResolvePayload[];
}

export interface IssueCardProps extends IIssueCard {
  issueId: string;
  dubId: string;
  fileId: string;
  boxProps?: BoxProps;
  /**
   * @default false
   */
  expandComments?: boolean;
  /**
   * @default false
   */
  expandReplyBox?: boolean;
  /**
   * @default true
   */
  showControls?: boolean;
  /**
   * @default false
   */
  editMode?: boolean;
  setEditMode?: (_: boolean) => void;
  onSuccessEdit?: (_: any) => void;
  commentsWrapperSX?: CollapseProps["sx"];
  lastSeen: number;
  /**
   * @default false
   */
  isInternal?: boolean;
}

export interface InternalIssueCardProps extends IssueCardProps {
  teamComments?: IComment[];
  isCreatedInternally?: boolean;
  workspaceId: string;
}

export interface IIssueObj {
  [issueId: string]: IIssue;
}

export interface ICurrentDubData {
  workspaceId: string;
  projectId: string;
  fileId?: string;
  dubId: string;
  dubVersion: string;
  accessLevel?: QC_ACCESS_ROLES;
}
export interface IIssueSlice {
  allIssues: {
    [dubId: string]: IIssueObj;
  };
  isFetchingAllIssues: boolean;
  isIssuesPopOverOpen: boolean;
  currentDubData?: ICurrentDubData;
  currentlyOpenedIssueInTvoPage?: string;
}

export interface ICommentObj {
  [commentId: string]: IComment;
}

export interface ICommentsSlice {
  fetchState: {
    hasLoadedOnce: string | null; // issue Id for which the comments are being fetched,
    isLoading: string | null; // issue Id for which the comments are being fetched,
    error?: string; // any error message returned from backend
  };
  allComments: {
    [issueId: string]: ICommentObj;
  };
}

export interface CommentTextAreaRef {
  childNodes: HTMLElement[];
  innerHTML: string;
  focus: HTMLElement["focus"];
  scrollIntoViewIfNeeded: any;
}

export interface IRequestChanges {
  fileId: string;
  dubId: string;
  dubVersion: string;
  workspaceId: string;
  projectId: string;
  isInternal: boolean;
}
