import {
  Avatar,
  AvatarProps,
  Typography,
  TypographyOwnProps
} from "@mui/material";
import {
  stringToColorFromPalette,
  PROFILE_ICON_PALETTE,
  invertColor
} from "@/utils/colors";

interface UserAvatarImageProps {
  displayName: string;
  avatarUrl?: string;
  fontSize?: number;
  typographyProps?: TypographyOwnProps;
}

type UserAvatarProps = UserAvatarImageProps & AvatarProps;

export function UserAvatar({
  displayName,
  avatarUrl = undefined,
  fontSize,
  typographyProps = undefined,
  ...props
}: UserAvatarProps) {
  const userInitials: string | null = displayName
    ? displayName
        .split(" ")
        ?.reduce((acc, initial) => acc + initial.charAt(0), "")
        ?.slice(0, 2)
        ?.toUpperCase()
    : null;

  const userIconColor: string | null = displayName
    ? stringToColorFromPalette(displayName, PROFILE_ICON_PALETTE)
    : null;

  return (
    <>
      <Avatar
        alt={displayName}
        src={avatarUrl}
        {...props}
        sx={{
          backgroundColor: userIconColor, // in case the image fails to load, we can show the initials
          ...props.sx
        }}
      >
        {!avatarUrl && userInitials ? (
          <Typography
            fontWeight={600}
            fontSize={fontSize || "auto"}
            {...typographyProps}
            sx={{
              color: invertColor(userIconColor ?? "#FFFFFF", true),
              ...typographyProps?.["sx"]
            }}
          >
            {userInitials}
          </Typography>
        ) : null}
      </Avatar>
    </>
  );
}
