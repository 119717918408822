import { ReactNode } from "react";
import { Stack, Typography } from "@mui/material";

interface Props {
  icon: ReactNode;
  message: string;
  backgroundColor?: string;
  description?: string;
}

const DialogMessage = ({
  icon,
  message,
  backgroundColor,
  description
}: Props) => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      textAlign="center"
      gap={2}
      padding={3}
      bgcolor={backgroundColor || "background.default"}
      borderRadius={2}
    >
      {icon}
      <Typography variant="h6">{message}</Typography>
      {description ? (
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      ) : null}
    </Stack>
  );
};

export default DialogMessage;
