import { store, useTypedSelector } from "@/config/configureAppStore";
import { TranslationPromptTypes } from "@/features/blocks/components/TextBlock/constants";
import { useCallback } from "react";
import { translationVoApi } from "../api";
import { LocalScriptBlockType } from "@/features/blocks";
import {
  convertRichTextToPlainText,
  getContentEditableEl,
  simulateEditableChangeEvent
} from "@/features/editor";
import { UNKNOWN_ERROR_MESSAGE } from "@/constants/errors";
import { getBlockSurroundingText } from "../utils";
import { LANGUAGES } from "@/constants/languages";

export interface GenerateTranslationParams {
  prompt: string;
  prompts: TranslationPromptTypes[];
  block: LocalScriptBlockType;
  useLocalText: boolean;
  speakerId?: string;
  abortController?: AbortController;
  useEnglishText?: boolean;
}

interface ApplyTranslationParams {
  text: string;
  addToList: boolean;
  type?: string;
  prompt?: string;
  promptSource?: string;
  blockId: string;
}

export const useTranslation = () => {
  const workspaceId = useTypedSelector(
    (state) => state.currentScriptProject.workspaceId
  );
  const fileId = useTypedSelector((state) => state.currentScriptProject.fileId);
  const dubId = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.dubId
  );
  const dubVersion = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.dubVersion
  );
  const sourceLanguage = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.sourceLanguage
  );

  const targetLanguage = useTypedSelector(
    (state) => state.currentScriptProject.currentProject.targetLanguage
  );

  const newTranslationApiEnabled = useTypedSelector(
    (state) =>
      state.currentScriptProject.currentProject.newTranslationApiEnabled
  );

  const richTextEnabled = useTypedSelector(
    (state) => state.projectUtility.richTextEnabled
  );

  const updateTranslation = useCallback(
    ({ text, blockId }: ApplyTranslationParams) => {
      if (blockId && text) {
        // updating text and simulating change event for block (helps in sync and block height adjustments)
        const editable = getContentEditableEl(blockId);
        if (editable) {
          editable.innerHTML = text;
          simulateEditableChangeEvent(editable);
        }
      }
    },
    []
  );

  const generateTranslation = useCallback(
    ({
      useLocalText,
      useEnglishText = false,
      prompts,
      prompt,
      block,
      abortController
    }: GenerateTranslationParams) => {
      let text = block.original.text;
      let fromLanguage = sourceLanguage,
        toLanguage = targetLanguage;
      if (useLocalText) {
        // if we are using local text (translated text) then from and to language are same
        fromLanguage = targetLanguage;
        toLanguage = targetLanguage;
      }

      if (useEnglishText && block.original.englishText) {
        fromLanguage = LANGUAGES.ENGLISH;
        text = block.original.englishText;
      }

      const translation = richTextEnabled
        ? convertRichTextToPlainText(block.phrasePropertiesList)
        : block.richText;

      let surroundingText = "";

      if (
        newTranslationApiEnabled &&
        prompts.includes(TranslationPromptTypes.BASE_TRANSLATION)
      ) {
        const blocksOrder =
          store.getState().currentScriptProject.currentProject.blocksOrder;
        const blocks = store.getState().projectUtility.blocksMap;
        if (blocksOrder && blocks) {
          surroundingText = getBlockSurroundingText(
            block.blockNumber - 1,
            blocksOrder,
            blocks,
            useEnglishText
          );
        }
      }

      const signal = abortController?.signal;
      return (
        newTranslationApiEnabled
          ? translationVoApi.reTranslate({
              fileId,
              dubId,
              dubVersion,
              workspaceId,
              blockId: block.blockId,
              speakerId: block.speakerId,
              payload: {
                prompts,
                sourceLanguage: fromLanguage,
                targetLanguage: toLanguage,
                translation,
                text,
                customPrompt: prompts.includes(
                  TranslationPromptTypes.CUSTOM_PROMPT
                )
                  ? prompt
                  : "",
                promptOnOriginal: !useLocalText,
                surroundingText
              },
              signal
            })
          : translationVoApi.generateTranslation({
              fileId,
              dubId,
              workspaceId,
              blockId: block.blockId,
              payload: {
                prompt: prompt,
                fromLanguage,
                toLanguage,
                textGiven: useLocalText,
                text: translation
              }
            })
      )
        .then((res) => {
          // remove empty string
          const suggestions: string[] = [];
          res.data.responseData?.forEach((text) => {
            // removing empty suggestions and converting pauses to seconds
            if (text.length) {
              suggestions.push(text);
            }
          });

          return suggestions;
        })
        .catch((err) => {
          console.log(err);
          const errorMessage = err?.extra || UNKNOWN_ERROR_MESSAGE;
          throw new Error(errorMessage);
        });
    },
    [
      dubId,
      fileId,
      newTranslationApiEnabled,
      richTextEnabled,
      sourceLanguage,
      targetLanguage,
      workspaceId,
      dubVersion
    ]
  );

  return { generateTranslation, updateTranslation };
};
