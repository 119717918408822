import { Avatar, Chip, ChipProps, chipClasses, styled } from "@mui/material";
import {
  ArchiveRounded,
  Check,
  CheckCircle,
  CloudUploadRounded,
  ErrorRounded,
  ReviewsRounded,
  WatchLaterRounded
} from "@mui/icons-material";
interface VariantProps {
  label: string;
  color: ChipProps["color"];
  icon: ChipProps["avatar"];
  props?: ChipProps;
}
// Update the Type Keys in Story as well
export interface VariantType {
  uploading: VariantProps;
  "upload-wait": VariantProps;
  uploaded: VariantProps;
  queued: VariantProps;
  processing: VariantProps;
  "wait-for-approval": VariantProps;
  finished: VariantProps;
  "upload-failed": VariantProps;
  "processing-failed": VariantProps;
  failed: VariantProps;
  preprocessing: VariantProps;
  "dub-failed": VariantProps;
  "revision-in-process": VariantProps;
  "revision-failed": VariantProps;
  "file-finished": VariantProps;
  archived: VariantProps;
}

const iconSizeStyle = { height: 16, width: 16 };

export const ProjectUploadVariants: VariantType = {
  "upload-wait": {
    label: "Queued for upload",
    color: "default",
    icon: <WatchLaterRounded sx={iconSizeStyle} />
  },
  uploading: {
    label: "Uploading",
    color: "info",
    icon: <CloudUploadRounded color="info" sx={iconSizeStyle} />
  },
  uploaded: {
    label: "Uploaded",
    color: "default",
    icon: <Check sx={iconSizeStyle} />
  },
  queued: {
    label: "Queued for processing",
    color: "default",
    icon: <WatchLaterRounded sx={iconSizeStyle} />
  },
  preprocessing: {
    label: "Processing the file",
    color: "info",
    icon: undefined,
    props: {
      minimal: false
    }
  },
  processing: {
    label: "Processing...",
    color: "info",
    icon: undefined,
    props: {
      minimal: false,
      variant: "outlined",
      sx: {
        border: 0,
        height: "auto"
      }
    }
  },
  "wait-for-approval": {
    label: "Waiting for approval",
    color: "warning",
    icon: <ReviewsRounded color="warning" />
  },
  finished: {
    label: "Finished",
    color: "success",
    icon: <CheckCircle sx={iconSizeStyle} color="success" />,
    props: {
      minimal: false,
      variant: "outlined",
      sx: {
        border: 0
      }
    }
  },
  "file-finished": {
    label: "Dubbing Complete",
    color: "success",
    icon: <CheckCircle sx={iconSizeStyle} color="success" />,
    props: {
      minimal: false,
      variant: "outlined"
    }
  },
  "upload-failed": {
    label: "Uploading failed",
    color: "error",
    icon: <ErrorRounded color="error" />
  },
  failed: {
    label: "Uploading failed",
    color: "error",
    icon: <ErrorRounded color="error" />
  },
  "processing-failed": {
    label: "Processing failed",
    color: "error",
    icon: <ErrorRounded color="error" />
  },
  "dub-failed": {
    label: "Processing failed",
    color: "error",
    icon: <ErrorRounded color="error" sx={iconSizeStyle} />,
    props: {
      variant: "outlined",
      minimal: false,
      size: "small"
    }
  },
  "revision-in-process": {
    label: "Revision in process...",
    color: "info",
    icon: undefined,
    props: {
      minimal: false,
      variant: "outlined",
      sx: {
        border: 0,
        height: "auto"
      }
    }
  },
  "revision-failed": {
    label: "Revision Failed",
    color: "error",
    icon: <ErrorRounded color="error" sx={iconSizeStyle} />,
    props: {
      variant: "outlined",
      minimal: false,
      size: "small"
    }
  },
  archived: {
    label: "Archived",
    color: "default",
    icon: (
      <ArchiveRounded
        sx={{
          ...iconSizeStyle,
          color: "text.secondary"
        }}
      />
    ),
    props: {
      minimal: false,
      sx: {
        color: "text.secondary"
      }
    }
  }
};

export const ProjectUploadStyledChip = styled(Chip)({
  padding: 0,
  backgroundColor: "transparent",
  [`& .${chipClasses.avatar}`]: iconSizeStyle,
  [`& .${chipClasses.label}`]: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
    paddingRight: 0,
    marginLeft: 4,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "143%",
    letterSpacing: "0.17px"
  }
});

interface ProjectUploadStatusProps extends Partial<ChipProps> {
  status: keyof VariantType;
}

export function ProjectUploadStatus({
  status,
  ...props
}: ProjectUploadStatusProps) {
  let variantValues: VariantProps = ProjectUploadVariants[status];

  if (!variantValues) {
    variantValues = ProjectUploadVariants["upload-wait"];
  }

  const avatarColor =
    variantValues.color === "default" ? "text.secondary" : variantValues.color;
  const labelColor =
    variantValues.color === "default" ? "text.secondary" : variantValues.color;

  return (
    <ProjectUploadStyledChip
      label={variantValues.label}
      color={variantValues.color}
      minimal={true}
      avatar={
        variantValues.icon ? (
          <Avatar
            sx={{
              bgcolor: "transparent",
              color: avatarColor
            }}
          >
            {variantValues.icon}
          </Avatar>
        ) : undefined
      }
      {...props}
      sx={{
        color: labelColor || "text.selected",
        border: 0,
        ...props.sx
      }}
      {...variantValues.props}
    />
  );
}
