import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { ISSUE_TAG_RESOLVE_STATUS } from "@/features/comments";
import { useIssuesComments } from "@/features/comments/hooks";
import { setMarkAsDoneDubPreviewDialog } from "@/reducers/slices/dialogSlice/dialogSlice";
import { Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { DUB_STATUS } from "@/types/project";
import { ReOpenDubPopup } from "../ReOpenDubPopup";
import { DubDialog } from "@/components/elements/DubDialog";
import { trackUserFlowEvent } from "@/utils/userflow";
import { USER_FLOW_EVENTS } from "@/constants/userflow";
import { dubPreviewInternalApi } from "../../api";
import {
  qcMarkAsDone,
  qcSendToCustomer
} from "@/reducers/slices/dubPreviewSlice/dubPreviewSlice";
import { UNKNOWN_ERROR_MESSAGE } from "@/constants/errors";
import { useSnackbar } from "@/components/elements/MurfSnackbar";
import { InfoRounded } from "@mui/icons-material";
import { dubThunks } from "@/reducers/thunks/dub";
import { useParams } from "react-router-dom";
import { batch } from "react-redux";
import { issueCommentThunk } from "@/reducers/thunks/issueCommentThunk";

const PROCESSING_STATES = [
  //   DUB_STATUS.PROCESSING,         // For Internal re-open, dub will be in PROCESSING STATE
  DUB_STATUS.QC_IN_PROCESS
  // DUB_STATUS.REVISION_IN_PROCESS   // For Internal re-open, dub will be in PROCESSING STATE
];

const MarkAsDoneQcTaskDialog = (): React.ReactNode => {
  const { projectId } = useParams();
  const isOpen = useTypedSelector(
    (state) => state.dialog.markAsDoneDubPreviewDialog.open
  );
  const dispatch = useAppDispatch();
  const { showError } = useSnackbar();

  const { filteredIssues: allIssues } = useIssuesComments();

  const anyIssueIsUnresolved = useMemo(() => {
    return allIssues?.some(
      (issue) => issue.status !== ISSUE_TAG_RESOLVE_STATUS.RESOLVED
    );
  }, [allIssues]);

  const dubStatus = useTypedSelector(
    (state) => state.dubPreview.dubDetails.dubStatus
  );
  const { dubId, workspaceId, fileId, dubVersion } = useTypedSelector(
    (state) => state.dubPreview.dubDetails
  );

  const [isLoading, setIsLoading] = useState(false);

  const closeDialog = () => {
    dispatch(setMarkAsDoneDubPreviewDialog(false));
  };

  const handleMarkAsDone = () => {
    console.debug("Mark as done clicked with data", {
      dubId,
      workspaceId,
      fileId
    });
    if (workspaceId && dubId && fileId) {
      trackUserFlowEvent(USER_FLOW_EVENTS.MARK_AS_DONE_QA, {});
      setIsLoading(true);
      dubPreviewInternalApi
        .qcMarkAsDone({ dubId, workspaceId, fileId })
        .then(() => {
          dispatch(qcMarkAsDone());
          dubPreviewInternalApi
            .sendToCustomer({ dubId, workspaceId, fileId })
            .then((res) => {
              setIsLoading(false);
              dispatch(qcSendToCustomer());
              console.debug("Send to Customer", { res });
              closeDialog();
              batch(() => {
                dispatch(
                  dubThunks.fetchDubPreviewInternal({
                    fileId,
                    workspaceId,
                    projectId: projectId!,
                    dubId,
                    dubVersion
                  })
                );
                dispatch(
                  issueCommentThunk.getAllInternalQAPageIssues({
                    fileId,
                    workspaceId,
                    dubId,
                    dubVersion
                  })
                );
              });
            })
            .catch((err) => {
              setIsLoading(false);
              console.log({ err });
            });
        })
        .catch((err) => {
          setIsLoading(false);
          showError(err?.extra || UNKNOWN_ERROR_MESSAGE);
          console.log({ err });
        });
    }
  };

  if (anyIssueIsUnresolved && !PROCESSING_STATES.includes(dubStatus)) {
    return <ReOpenDubPopup dubId={dubId} />;
  }

  return isOpen ? (
    <DubDialog
      onClose={closeDialog}
      headerProps={{
        title: `Send to Customer`,
        showCloseIcon: true
      }}
      footerProps={{
        primaryAction: {
          label: "Send to Customer",
          onClick: handleMarkAsDone,
          isLoading: isLoading
        },
        secondaryActions: {
          label: "No, Cancel",
          onClick: () => {
            closeDialog();
          }
        }
      }}
      callOut={{
        title: `Please confirm before sending it to the customer`,
        icon: <InfoRounded sx={{ height: 64, width: 64 }} color="info" />
      }}
    >
      <Stack gap={0.5}>
        {[
          "Please review the dub and make sure all issues (if any) are resolved.",
          "Once submitted, you won't be able to re-open the dub."
        ].map((text) => (
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <InfoRounded
              sx={{ height: 20, width: 20, color: "text.secondary" }}
            ></InfoRounded>
            <Typography variant="body2" color={"text.secondary"}>
              {text}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </DubDialog>
  ) : null;
};

export default MarkAsDoneQcTaskDialog;
