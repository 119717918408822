import Uppy, { UppyFile } from "@uppy/core";
import { SelectedFilesWithMultiParts, uploadMultiPartFileApi } from "../api";
import AwsS3 from "@uppy/aws-s3";
import { FILE_TYPES } from "@/features/clientProjectDetails";
import { MEDIA_TYPE_EXTN } from "@/features/clientSearch/constant";
import { SnackBarState } from "@/reducers/slices/dialogSlice/types";
import { PROJECT_FILE_STATUS } from "@/types/project";
import { trackMixpanelEvent } from "@/utils/mixpanel";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { trackUserFlowEvent } from "@/utils/userflow";

let instanceExists: boolean = false;

export interface UppyCreateMultiPartUpload {
  fileId: string;
  fileData: any;
  replaceWith?: string;
}

export interface UppyUpdateMultiPartUpload {
  fileId: string;
  projectId: string;
}

export interface UppyCompleteMultiPartUpload {
  fileId: string;
  projectId: string;
  fileData?: any;
}

interface addPluginProps {
  plugin: any;
  pluginId: string;
  targetId: string;
}

class UppyFactory {
  projectId: string | undefined;
  entryPoint: string | undefined;
  uppyObj: Uppy<Record<string, unknown>, Record<string, unknown>> | undefined;

  _selectedFiles: {
    [key: string]: SelectedFilesWithMultiParts;
  } = {};

  _createMultipartUpload:
    | (({ fileId, fileData, replaceWith }: UppyCreateMultiPartUpload) => void)
    | undefined;
  _signPart: ((data: UppyUpdateMultiPartUpload) => void) | undefined;
  _completeMultipartUpload:
    | ((data: UppyCompleteMultiPartUpload) => void)
    | undefined;
  _abortMultipartUpload:
    | ((data: UppyUpdateMultiPartUpload) => void)
    | undefined;
  _removeFileWithToaster:
    | ((projectId: string, fileId: string, reason?: string) => void)
    | undefined;
  _showSnackBar:
    | ((message: string, type: SnackBarState["type"]) => void)
    | undefined;

  constructor() {
    if (instanceExists) {
      throw new Error("You can only create one instance!");
    } else {
      instanceExists = true;
    }
    this.initiateUppy();
  }

  // Setters
  setProjectId = (projectId: string) => {
    this.projectId = projectId;
  };
  setEntryPoint = (entryPoint: string) => {
    this.entryPoint = entryPoint;
  };
  setCreateMultipartUpload = (
    callback: (data: UppyCreateMultiPartUpload) => void
  ) => {
    this._createMultipartUpload = callback;
  };
  setSignPart = (callback: (data: UppyUpdateMultiPartUpload) => void) => {
    this._signPart = callback;
  };
  setCompleteMultipartUpload = (
    callback: (data: UppyCompleteMultiPartUpload) => void
  ) => {
    this._completeMultipartUpload = callback;
  };
  setAbortMultipartUpload = (
    callback: (data: UppyUpdateMultiPartUpload) => void
  ) => {
    this._abortMultipartUpload = callback;
  };
  setRemoveFileWithToaster = (
    callback: (projectId: string, fileId: string, reason?: string) => void
  ) => {
    this._removeFileWithToaster = callback;
  };
  setShowSnackBar = (
    callback: (message: string, type: SnackBarState["type"]) => void
  ) => {
    this._showSnackBar = callback;
  };

  // Methods
  abortFileUploadByUser = (fileKey: string) => {
    try {
      this.uppyObj?.removeFile(
        this._selectedFiles[fileKey].fileData.id,
        "removed-by-user"
      );
    } catch (err) {
      console.debug(err);
      // do nothing
    }
  };

  retryUpload = (fileKey: string): boolean => {
    if (fileKey) {
      if (this._selectedFiles[fileKey]?.fileData?.id) {
        this.uppyObj?.retryUpload(this._selectedFiles[fileKey].fileData.id);
        return true;
      }
    }
    return false;
  };

  uploadFile = (file: any, replaceWith: string) => {
    if (file) {
      if (replaceWith && !this._selectedFiles[replaceWith]) {
        this._selectedFiles[replaceWith] = {
          retryUpload: true,
          ...file,
          sourceFileId: replaceWith
        };
      }
      this.uppyObj?.addFile(file);
    }
  };

  // init Uppy
  initiateUppy = () => {
    this.uppyObj = new Uppy({
      autoProceed: true,
      debug: true,
      restrictions: {
        allowedFileTypes: [
          ".mp4",
          ".mov",
          ".avi",
          ".mkv",
          ".mp3",
          ".wav",
          ".aac"
        ]
      },
      onBeforeFileAdded: (currentFile) => {
        const modifiedFile = {
          ...currentFile,
          id: `${Date.now()}__${currentFile.name}`
        };
        return modifiedFile;
      }
    }).use(AwsS3, {
      id: "AWS_ID_TEMP",
      limit: 3,
      shouldUseMultipart: () => true,
      getChunkSize: () => 15000000, // 15 * MiB,
      createMultipartUpload: async (file) => {
        console.debug("createMultipartUpload");

        if (!file?.type || !this.projectId) {
          console.debug(file?.type, this.projectId);
          return await new Promise((_, reject) =>
            reject({
              uploadId: "No File Type or Project Id"
            })
          );
        }

        const [type] = file.type.split("/");

        const isRetry = Object.values(this._selectedFiles).filter(
          (_file) =>
            (_file?.fileData?.id &&
              file?.id &&
              _file.fileData.id === file.id) ||
            _file.retryUpload
        )?.[0];
        let res;
        const TEMP_FILE_ID = `TEMP_${file.id}`;

        const payload = {
          contentType: file.type,
          fileName: file.name,
          fileSize: file.size,
          projectId: this.projectId,
          workspaceId: window.activeWorkspaceId!,
          type:
            type === MEDIA_TYPE_EXTN.AUDIO
              ? FILE_TYPES.BG_AUDIO
              : type.toUpperCase()
        };

        if (isRetry) {
          res = await uploadMultiPartFileApi.replaceUpload({
            fileId: isRetry.sourceFileId,
            payload
          });
        } else {
          // To show a dummy upload before createMultiPart
          const fileData = {
            ...file,
            fileData: file,
            projectId: this.projectId,
            sourceFileId: file.id,
            fileSize: file.size,
            numberOfParts: 1,
            partSize: 1500000,
            signedUrls: [],
            status: PROJECT_FILE_STATUS.WAITING_FOR_UPLOAD,
            workspaceId: payload.workspaceId
          };
          if (typeof this._createMultipartUpload === "function") {
            this._selectedFiles[TEMP_FILE_ID] = fileData;
            this._createMultipartUpload({ fileId: TEMP_FILE_ID, fileData });
          }
          res = await uploadMultiPartFileApi.createParts(payload).catch(() => {
            if (this._selectedFiles[TEMP_FILE_ID]) {
              this._selectedFiles[TEMP_FILE_ID] = {
                ...this._selectedFiles[TEMP_FILE_ID],
                status: PROJECT_FILE_STATUS.FAILED
              };
            }
            if (typeof this._createMultipartUpload === "function") {
              this._createMultipartUpload({
                fileData: {
                  ...this._selectedFiles[TEMP_FILE_ID],
                  status: PROJECT_FILE_STATUS.FAILED
                },
                fileId: TEMP_FILE_ID
              });
            }
          });
        }
        if (!res?.data) {
          if (typeof this._removeFileWithToaster === "function") {
            this._removeFileWithToaster(payload.projectId, TEMP_FILE_ID);
          }
          if (this._selectedFiles[TEMP_FILE_ID]) {
            delete this._selectedFiles[TEMP_FILE_ID];
          }
          return await new Promise((_, reject) =>
            reject({
              uploadId: "Failed to create multi parts"
            })
          );
        }

        this._selectedFiles[res.data.responseData.sourceFileId] = {
          ...res.data.responseData,
          fileData: file,
          projectId: this.projectId,
          workspaceId: payload.workspaceId
        };
        if (this._selectedFiles[TEMP_FILE_ID]) {
          delete this._selectedFiles[TEMP_FILE_ID];
        }

        if (isRetry?.sourceFileId) {
          delete this._selectedFiles[isRetry.sourceFileId];
        }

        // Send Mixpanel Event

        if (!isRetry) {
          trackMixpanelEvent(MIXPANEL_EVENTS.FILE_UPLOAD_START, {
            "File Format": payload.contentType,
            "Upload Type": "Upload",
            "Entry Point": this.entryPoint,
            "Project ID": this.projectId
          });
        }

        if (typeof this._createMultipartUpload === "function") {
          const _sourceFileId = res.data.responseData.sourceFileId;
          this._selectedFiles[_sourceFileId].status =
            PROJECT_FILE_STATUS.WAITING_FOR_UPLOAD;
          this._createMultipartUpload({
            fileData: {
              ...this._selectedFiles[_sourceFileId],
              projectId: this._selectedFiles[_sourceFileId].projectId
            },
            fileId: _sourceFileId,
            replaceWith: TEMP_FILE_ID
          });
        }

        return {
          uploadId: res.data.responseData.sourceFileId,
          key: ""
        };
      },
      listParts: async (file: UppyFile) => {
        console.debug("listParts", file);
        // @TODO
        return await new Promise((resolve) => resolve([]));
      },
      signPart: async (_, opts) => {
        const { uploadId, partNumber, signal } = opts;
        signal?.throwIfAborted();
        if (
          typeof this._signPart === "function" &&
          this._selectedFiles?.[uploadId] &&
          this._selectedFiles?.[uploadId].status ===
            PROJECT_FILE_STATUS.WAITING_FOR_UPLOAD
        ) {
          this._selectedFiles = Object.assign({}, this._selectedFiles, {
            [uploadId]: {
              ...this._selectedFiles[uploadId],
              status: "UPLOADING"
            }
          });
          this._signPart({
            fileId: uploadId,
            projectId: this._selectedFiles[uploadId].projectId as string
          });
        }
        return {
          url: this._selectedFiles[uploadId].signedUrls[partNumber - 1]
        };
      },
      completeMultipartUpload: async (_, opts) => {
        // console.log("completeMultipartUpload", file, opts);
        const { uploadId, parts, signal } = opts;

        signal?.throwIfAborted();

        const eTagMap: { [key: string | number]: string } = {};
        parts.map((_) => {
          if (_.PartNumber && _.ETag) {
            eTagMap[_.PartNumber] = _.ETag.replaceAll('"', "");
          }
        });

        const res = await uploadMultiPartFileApi.finishParts({
          fileId: uploadId,
          payload: {
            sourceFileId: uploadId,
            eTagMap
          },
          workspaceId: this._selectedFiles[uploadId].workspaceId!
        });

        const eventPayload = {
          "File Format": this._selectedFiles[uploadId].fileData.type,
          "Upload Type": "Upload",
          "Project ID": this._selectedFiles[uploadId].projectId
        };
        // Send Mixpanel & userFlow Event
        trackMixpanelEvent(MIXPANEL_EVENTS.FILE_UPLOAD_SUCCESS, eventPayload);
        trackUserFlowEvent(MIXPANEL_EVENTS.FILE_UPLOAD_SUCCESS, eventPayload);

        if (typeof this._completeMultipartUpload === "function") {
          this._selectedFiles = Object.assign({}, this._selectedFiles, {
            [uploadId]: {
              ...this._selectedFiles[uploadId],
              status: PROJECT_FILE_STATUS.PREPROCESSING
            }
          });
          this._completeMultipartUpload({
            fileId: uploadId,
            projectId: this._selectedFiles[uploadId].projectId as string,
            fileData: {
              ...this._selectedFiles[uploadId],
              status: PROJECT_FILE_STATUS.PREPROCESSING
            }
          });
        }

        // console.log("completeMultipartUpload 2", res);
        return res.data;
      },
      abortMultipartUpload: async (file: UppyFile, { uploadId }) => {
        const _workspaceId =
          this._selectedFiles[uploadId]?.workspaceId ||
          window.activeWorkspaceId ||
          "";
        const res = await uploadMultiPartFileApi.abortParts(
          uploadId,
          _workspaceId
        );
        console.debug("abortMultipartUpload", res, file);
        if (typeof this._abortMultipartUpload === "function") {
          if (this._selectedFiles[uploadId]) {
            this._selectedFiles = Object.assign({}, this._selectedFiles, {
              [uploadId]: {
                ...this._selectedFiles[uploadId],
                status: "FAILED"
              }
            });
          }
          this._abortMultipartUpload({
            fileId: uploadId,
            projectId: this._selectedFiles[uploadId].projectId as string
          });
        }
      },
      getUploadParameters: async (file) => {
        console.debug("getUploadParameters", file);
        return {
          method: "POST",
          url: "",
          fields: {}, // For presigned PUT uploads, this should be left empty.
          // Provide content type header required by S3
          headers: {
            "Content-Type": "text"
          }
        };
      }
    });

    this.uppyObj?.on("file-removed", (file: any, reason) => {
      if (reason === "removed-by-user") {
        console.debug(file.s3Multipart.uploadId);
        delete this._selectedFiles[file.s3Multipart.uploadId];
      }
    });
    this.uppyObj.on("upload-retry", (fileId: string) => {
      console.debug("upload-retry", fileId);
    });
    this.uppyObj.on("restriction-failed", (_, error) => {
      if (typeof this._showSnackBar === "function") {
        this._showSnackBar(error.message, "error");
      }
    });
  };
  addPlugin = ({ plugin, pluginId, targetId }: addPluginProps) => {
    if (!this.uppyObj) {
      return;
    }
    const _currentEl = this.uppyObj.getPlugin(pluginId);
    if (_currentEl) {
      return;
    }
    this.uppyObj.use(plugin, {
      id: pluginId,
      target: targetId
    });
  };
  removePlugin = (pluginId: string) => {
    if (!this.uppyObj) {
      return;
    }
    const _cur = this.uppyObj.getPlugin(pluginId);
    if (_cur) {
      this.uppyObj?.removePlugin(_cur);
    }
  };
}

const uppyFactory = new UppyFactory();

export default uppyFactory;
export * from "./hooks";
