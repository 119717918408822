import { api } from "@/lib/axios";
import { PROJECT_FILE_STATUS } from "@/types/project";
import { UppyFile } from "@uppy/core";
import { AxiosResponse } from "axios";

interface CreateMultiParts {
  contentType: string | undefined;
  fileName: string;
  fileSize: number;
  projectId: string;
  type: string;
  duration?: number;
  workspaceId: string;
}

export interface CreateMultiPartsResponseData {
  sourceFileId: string;
  fileSize: number;
  partSize: number;
  numberOfParts: number;
  signedUrls: string[];
}

export const UPLOAD_COMPLETE_STATES = [
  PROJECT_FILE_STATUS.PREPROCESSING,
  PROJECT_FILE_STATUS.PROCESSING,
  PROJECT_FILE_STATUS.UPLOADED
];

export type FileStatusType = PROJECT_FILE_STATUS;

export interface SelectedFilesWithMultiParts
  extends CreateMultiPartsResponseData {
  fileData: UppyFile;
  status?: FileStatusType;
  isAborted?: boolean;
  retryUpload?: boolean;
  projectId?: string;
  workspaceId?: string;
}

interface CreateMultiPartsResponse {
  responseCode: string;
  extra: null;
  responseData: CreateMultiPartsResponseData;
  responseMessage: string;
}

interface FinishMultiParts {
  fileId: string;
  payload: {
    sourceFileId: string;
    eTagMap: {
      [key: string]: string;
    };
  };
  workspaceId: string;
}

export interface IUpdateFile {
  fileName?: string;
  archived?: boolean;
}

export const uploadMultiPartFileApi = {
  createParts: (
    payload: CreateMultiParts
  ): Promise<AxiosResponse<CreateMultiPartsResponse>> =>
    api.post(`user/source/NA/initiate-multi-part-upload`, payload, {
      headers: {
        workspaceId: payload.workspaceId
      }
    }),
  abortParts: (fileId: string, workspaceId: string) =>
    api.post(`user/source/${fileId}/abort-multi-part-upload`, undefined, {
      headers: {
        workspaceId
      }
    }),
  finishParts: ({ fileId, payload, workspaceId }: FinishMultiParts) =>
    api.post(`user/source/${fileId}/finish-multi-part-upload`, payload, {
      headers: {
        workspaceId
      }
    }),
  updateFile: (fileId: string, payload: IUpdateFile) =>
    api.put(`user/source/${fileId}/update-file`, payload),
  replaceUpload: ({
    fileId,
    payload
  }: {
    fileId: string;
    payload: CreateMultiParts;
  }) => api.put(`user/source/${fileId}/replace`, payload),
  removeFile: ({ fileId }: { fileId: string }) =>
    api.post(`user/source/${fileId}/remove`),
  copyFileToProject: ({
    fileId,
    toProjectId
  }: {
    fileId: string;
    toProjectId: string;
  }) => api.post(`user/source/${fileId}/copy/${toProjectId}`)
};
