import { memo, useMemo, useState } from "react";
import { IconButton, Paper, Popover, Stack, Typography } from "@mui/material";
import { getSeekerTime } from "@/utils/time";
import { DubPreviewBlockType } from "@/reducers/slices/dubPreviewSlice/types";
import { useTypedSelector } from "@/config/configureAppStore";
import { PreviewPlayer } from "@/reducers/slices/dubPreviewSlice/dubPreviewSlice";
import { BLOCK_LENGTH_VARIANCE_THRESHOLD } from "@/features/blocks/utils";
import { WarningAmberRounded } from "@mui/icons-material";

interface PreviewBlockProps {
  block: DubPreviewBlockType;
  isActive: boolean;
  handleBlockClick: (blockId: DubPreviewBlockType) => void;
  currentPlayer: PreviewPlayer;
  showReferenceLanguage?: boolean;
}

const PreviewBlockError = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <IconButton
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          p: 0.5
        }}
      >
        <WarningAmberRounded color="warning" sx={{ height: 20, width: 20 }} />
      </IconButton>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none"
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        slotProps={{
          paper: {
            sx: {
              width: 300,
              p: 1,
              borderRadius: 1
            }
          }
        }}
      >
        <Typography display={"inline-block"} variant="caption">
          Translated text is longer than the original resulting in cropping of
          the voiceover. We recommend reducing the number of characters to
          resolve the error by clicking on edit dub.
        </Typography>
      </Popover>
    </>
  );
};

export function PreviewBlock({
  block,
  isActive,
  handleBlockClick,
  currentPlayer,
  showReferenceLanguage
}: PreviewBlockProps) {
  const { qcEnabled, dubVersion, qcDubVersions } = useTypedSelector(
    (state) => state.dubPreview.dubDetails
  );

  const shouldShowError = useMemo(() => {
    if (
      !qcEnabled ||
      (qcEnabled && qcDubVersions && !qcDubVersions?.includes(dubVersion))
    ) {
      return (
        block.renderLength &&
        block.original?.duration &&
        block.renderLength - block.original.duration >
          BLOCK_LENGTH_VARIANCE_THRESHOLD
      );
    }
    return false;
  }, [
    block.renderLength,
    block.original?.duration,
    qcEnabled,
    qcDubVersions,
    dubVersion
  ]);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: 1,
        borderRadius: 2,
        border: isActive ? "2px solid" : "1px solid",
        borderColor: isActive ? "secondary.main" : "divider",
        position: "relative"
      }}
      data-dubpreview-blockid={block.blockId}
    >
      <button
        onClick={() => handleBlockClick(block)}
        style={{
          height: "100%",
          width: "100%",
          border: "none",
          background: "none",
          padding: 0,
          textAlign: "left",
          cursor: "pointer"
        }}
      >
        <Stack direction={"row"} gap={1}>
          <Stack gap={1}>
            <Typography variant="caption" color="text.secondary">
              {getSeekerTime({
                timeValue: block.original.startTime
              })}
            </Typography>
            <Typography
              variant="body2"
              className="font-script"
              color={isActive ? "text.primary" : "text.secondary"}
            >
              {currentPlayer === PreviewPlayer.Dubbed
                ? block.translatedText
                : showReferenceLanguage
                ? block.original.englishText
                : block.original.text}
            </Typography>
          </Stack>
        </Stack>
        {shouldShowError ? <PreviewBlockError /> : null}
      </button>
    </Paper>
  );
}

export const MemoizedPreviewBlock = memo(PreviewBlock);
