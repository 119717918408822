import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { useBlockSynthesis } from "@/features/synthesis";
import { setRenderCircuitBreaker } from "@/reducers/slices/projectUtilitySlice/projectUtilitySlice";
import { useEffect, useRef } from "react";

export const useBlockAutoRender = () => {
  const blockIdRef = useRef<string | null>(null);
  const forceRender = useRef(false);
  const saveCompleted = useRef(false);
  const saveInProgress = useTypedSelector(
    (state) => state.currentScriptProject.saveInProgress
  );

  const dispatch = useAppDispatch();
  const { chunkAndSynthesizeBlocks } = useBlockSynthesis();

  useEffect(() => {
    if (forceRender.current && blockIdRef.current) {
      if (saveInProgress) {
        saveCompleted.current = true;
      } else if (saveCompleted.current) {
        forceRender.current = false;
        saveCompleted.current = false;
        dispatch(setRenderCircuitBreaker({ renderCircuitBreaker: false }));
        chunkAndSynthesizeBlocks({ blockIdList: [blockIdRef.current] });
        blockIdRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveInProgress]);

  const renderBlock = (blockId: string) => {
    blockIdRef.current = blockId;
    forceRender.current = true;
  };

  return renderBlock;
};
