import {
  RenderPayload,
  blockSynthesisApi
} from "@/features/synthesis/api/index";
import { sanitizeContentEditable } from "@/features/editor";
import {
  BlockError,
  LocalScriptBlockType,
  RenderedBlockType
} from "@/features/blocks";
import { Speaker, VoiceVariant } from "@/features/scriptStudio/types";
import { AppDispatch } from "@/config/configureAppStore";
import {
  BLOCK_ERROR_MESSAGES,
  BLOCK_ERROR_TYPE
} from "@/features/blocks/constants";
import {
  deleteRenderingBlockIds,
  upsertBlockError
} from "@/reducers/slices/projectUtilitySlice/projectUtilitySlice";

export function pollRenderStatus(
  workspaceId: string,
  fileId: string,
  dubId: string,
  dubVersion: string,
  blockIds: string[]
): Promise<any> {
  return new Promise((resolve, reject) => {
    blockSynthesisApi
      .getRenderBlocksStatus({
        dubId,
        dubVersion,
        fileId,
        workspaceId,
        blockIds: blockIds
      })
      .then((res) => {
        resolve(res.data.responseData);
      })
      .catch((e) => {
        if (e?.code !== "ERR_NETWORK") {
          console.log("poll err", e);
          reject("Error");
        } else {
          if (e?.code === "ERR_NETWORK") {
            console.log("No Internet Connection !");
          }

          reject(e);
        }
      });
  });
}

export const generateRenderBlocksPayload = (
  allBlocks: any,
  speakers: { [key: string]: Speaker },
  selected: { blockId: string; renderVersion: number }[],
  audioRenderedBlocks: any,
  maxRenderVersion: number,
  richTextEnabled: boolean,
  isDataFromLocal: boolean = false
) => {
  const map: RenderPayload = {};
  selected.forEach((item) => {
    const block = allBlocks[item.blockId];
    const speaker = speakers[block.speakerId];
    const renderedBlock = audioRenderedBlocks[item.blockId];

    const newRenderVersion =
      Math.max(
        isDataFromLocal
          ? item.renderVersion || 0
          : renderedBlock?.renderVersion || 0,
        maxRenderVersion
      ) + 1;

    map[item.blockId] = {
      blockProperties: {
        speakerId: block.speakerId,
        volume: 0,
        richText: richTextEnabled ? undefined : block.richText,
        phrasePropertiesList: richTextEnabled
          ? block.phrasePropertiesList
          : undefined,
        renderVersion: newRenderVersion
      },
      speakerProperties: {
        pitch: speaker.pitch,
        selectedVoiceVariant: speaker.selectedVoiceVariant
      }
    };
  });

  return map;
};

/**
 * function used to check if voice variants are same or not
 * @param voice1
 * @param voice2
 * @returns
 */
export const areVoicesEqual = (
  voice1: VoiceVariant | null,
  voice2: VoiceVariant | null
) => {
  return (
    voice1 &&
    voice2 &&
    voice1.voiceId === voice2.voiceId &&
    voice1.name === voice2.name &&
    voice1.style === voice2.style
  );
};

export const areBlocksEqual = (
  block: LocalScriptBlockType,
  renderedBlock: RenderedBlockType,
  currentSpeaker: Speaker
) => {
  return (
    sanitizeContentEditable(block.htmlText) ===
      sanitizeContentEditable(renderedBlock.htmlText) &&
    !renderedBlock.needsRendering &&
    !block.needsRendering &&
    block.renderVersion === renderedBlock.renderVersion &&
    block.speakerId === renderedBlock.speakerId &&
    areVoicesEqual(
      currentSpeaker.selectedVoiceVariant,
      renderedBlock.renderedVoiceVariant
    ) &&
    currentSpeaker.pitch === renderedBlock.renderedPitch
  );
};

/**
 * function used to check if block needs rendering
 */
export const doesBlockNeedRender = (
  block: LocalScriptBlockType,
  renderedBlock: RenderedBlockType,
  speaker: Speaker,
  renderingBlocks: string[] = []
): boolean => {
  const blocksEqual = areBlocksEqual(block, renderedBlock, speaker);

  if (renderingBlocks.includes(block.blockId) && blocksEqual) return false;
  else {
    return !blocksEqual;
  }
};

/**
 * function to handle the render error
 * it removes blocks from rendering state and updates the block error
 * @param blockIds - array of block ids that are rendered
 * @param dispatch
 * @param res - error response
 */
export const handleRenderError = (
  blockIds: string[],
  dispatch: AppDispatch,
  res: any
) => {
  // remove blocks from rendering array and update block errors
  const blocksErrorMap = blockIds.reduce((prev, curr) => {
    prev[curr] = {
      type: BLOCK_ERROR_TYPE.RENDER_FAILED,
      message:
        res?.extra || BLOCK_ERROR_MESSAGES[BLOCK_ERROR_TYPE.RENDER_FAILED]
    };
    return prev;
  }, {} as Record<string, BlockError>);

  dispatch(
    upsertBlockError({
      blocksErrorMap,
      type: BLOCK_ERROR_TYPE.RENDER_FAILED
    })
  );
  dispatch(deleteRenderingBlockIds({ blockIds }));
};
