import { ReactNode, memo, useEffect } from "react";
import { initializeSentry, setSentryUser } from ".";
import { ErrorBoundary } from "@sentry/react";
import ErrorFallback from "@/components/elements/ErrorFallback/ErrorFallback";
import { useAuth } from "../firebase/firebaseHooks";

initializeSentry();

const SentryWrapper = ({ children }: { children: ReactNode }) => {
  const user = useAuth();

  useEffect(() => {
    if (user?.uid) {
      setSentryUser(user.uid);
    }
  }, [user?.uid]);

  return <ErrorBoundary fallback={<ErrorFallback />}>{children}</ErrorBoundary>;
};

export default memo(SentryWrapper);
