import { RichTextType } from "@/features/blocks";
import { DubPronunciation } from "@/features/scriptStudio/types/pronunciations";
import { unicodeAwareEqualsIgnoringCase } from "@/features/scriptStudio/utils/pronunciation";
import { generateId } from "@/utils/misc";
import {
  boundaryCharRegex,
  pauseRegex,
  pauseRegexWithoutBrackets
} from "@/utils/regex";

export const MTAG_TYPES = {
  PRONUNCIATION: "PRONUNCIATION",
  EMPHASIS: "EMPHASIS",
  PAUSE: "PAUSE"
} as const;

type MTagType = (typeof MTAG_TYPES)[keyof typeof MTAG_TYPES];

export const MTAG_CLASSES = {
  PRONUNCIATION: "mpro",
  EMPHASIS: "memp",
  PAUSE: "pause-non-editable"
};

export const MTAG_VALUES = {
  PRONUNCIATION: {
    alt: "alt",
    sayAs: "sayAs",
    projectPronunciation: "pro"
  },
  EMPHASIS: {
    emphasis: "e"
  },
  PAUSE: {
    isPause: "isPause",
    pause: "pause"
  }
};

export const MTAG_DATA_ATTRIBUTES = {
  PRONUNCIATION: {
    alt: `data-${MTAG_VALUES.PRONUNCIATION.alt}`,
    sayAs: `data-${MTAG_VALUES.PRONUNCIATION.sayAs}`,
    pro: `data-${MTAG_VALUES.PRONUNCIATION.projectPronunciation}`
  },
  EMPHASIS: {
    emphasis: `data-${MTAG_VALUES.EMPHASIS.emphasis}`
  },
  PAUSE: {
    pause: `data-${MTAG_VALUES.PAUSE.pause}`,
    isPause: `data-${MTAG_VALUES.PAUSE.isPause}`
  },
  MURF: {
    murf: "data-m"
  }
};

export const NodeTypeMap = {
  ELEMENT_NODE: 1,
  TEXT_NODE: 3
};

/**
 *
 * @typedef {Object.<string, string>} GenericToken
 *
 * @param {string} str String to be tokenized
 * @param {RegExp} pattern RegEx pattern to tokenize
 * @param {string} [prefix=POT] RegEx pattern to tokenize
 *
 *
 * @returns {{
 *  tokens: GenericToken,
 *  result: String
 * }}
 */
export const tokenizeHtmlAttrs = (
  str: string,
  pattern: RegExp,
  prefix = "POT",
  addSpace = false
) => {
  const tokens: { [key: string]: string } = {};
  const result = str.replace(pattern, (o) => {
    const id = generateId(prefix);
    tokens[id] = o;
    return addSpace ? ` ${id} ` : id;
  });

  return {
    tokens,
    result
  };
};

export const stripHtml = (str: string): string => {
  return str.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, "");
};

/**
 * @param str - string to be sanitized
 * @return string
 *
 * @description - Takes a string and performs following operations on it
 *                1. Normalize spaces characters
 *                2. replace "&amp" with &
 *                3. replace "&lt" with <
 *                4. replace "&gt" with >
 */
export const sanitizeContentEditable = (str: string): string => {
  return str
    .replace(/&nbsp;|\u202F|\u00A0/g, " ")
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">");
};

export const getCleanWordBoundaries = (str: string) => {
  const cleanText = stripHtml(sanitizeContentEditable(str));
  const { result, tokens } = tokenizeHtmlAttrs(cleanText, pauseRegex, "PAUS");
  return result
    .replace(boundaryCharRegex, " ")
    .split(" ")
    .map((k) => k?.trim())
    .map((k) => (tokens[k] ? tokens[k] : k))
    .filter((k) => !!k);
};

export const cleanText = (str: string) => {
  return sanitizeContentEditable(stripHtml(str));
};

/**
 * @param {string} inputString String to be tested
 * @returns {boolean} returns is current text contains non english characters
 *
 * @description Takes a string as input and returns whether it has non english character or not
 *
 */
export const containsNonEnglishCharacters = (inputString = "") => {
  const hasMultipleWords = inputString.split(" ").filter((k) => !!k).length > 1;
  //eslint-disable-next-line no-control-regex
  const pattern = /[^\x00-\x7F]+/;
  return pattern.test(inputString) && !hasMultipleWords;
};

export const isSelectionProperWord = (editableText = ""): boolean => {
  const selectedText = window.getSelection()?.toString()?.trim();
  if (selectedText?.length) {
    if (boundaryCharRegex.test(selectedText)) return false;

    //check if selection has non english letters
    if (containsNonEnglishCharacters(selectedText)) return true;

    const wordBoundaryArray = getCleanWordBoundaries(
      editableText.replace(pauseRegexWithoutBrackets, "")
    );

    if (wordBoundaryArray.includes(selectedText)) return true;
    else return false;
  }

  return false;
};

export const getSelectionDimensions = () => {
  let width = 0,
    height = 0,
    left = 0,
    top = 0;

  if (window.getSelection) {
    const sel = window.getSelection();
    if (sel?.rangeCount) {
      const range = sel.getRangeAt(0).cloneRange();
      if (range.getBoundingClientRect) {
        const rect = range.getBoundingClientRect();
        top = rect.top;
        left = rect.left;
        width = rect.right - rect.left;
        height = rect.bottom - rect.top;
      }
    }
  }
  return { width, height, left, top };
};

export const dispatchGlobalSelectEvent = (options = {}) => {
  const event = new CustomEvent("textselect", options);
  window.dispatchEvent(event);
};

export const isNodeSpan = (node: Node | null) => {
  if (!node) return false;
  return node.nodeType !== NodeTypeMap.TEXT_NODE && node.nodeName === "SPAN";
};

export interface RangeData {
  isSpan: boolean;
  text: string | undefined;
  startContainer: Node;
  endContainer: Node;
  rangeError: boolean;
  startOffset: number;
  endOffset: number;
}

/**
 * @param {Node} node
 *
 * @description checks whether the passed node is contenteditable or not, if yes it means it is the main editable of subblock
 */
export const isNodeDivEditable = (node: Node | Element) => {
  if (node.nodeType === NodeTypeMap.ELEMENT_NODE) {
    const isNodeDiv = node.nodeName === "DIV";
    const isNodeEditable =
      isNodeDiv && (node as Element)?.getAttribute("contenteditable");
    return isNodeDiv && isNodeEditable === "true";
  }

  return false;
};

export const getRangeDataForRedux = (range: Range): RangeData => {
  if (!(range instanceof Range))
    throw new Error(
      "getRangeDataForRedux::provided range is not an instance of Range"
    );
  let isRangeDataIncorrect = false;
  let span = null;
  let isOnlyNonEditable = false;

  /**
   * if commonAncestorContainer is a div editable,
   * this might mean that selection is done using keyboard
   * on a non-editable span and range api is not able to get correct data for it.
   * We then rely on the incorrect data and determine the pattern for spans actual position to feed data to redux.
   */
  if (isNodeDivEditable(range.commonAncestorContainer)) {
    isRangeDataIncorrect = true;

    const isSpanAtStart =
      isNodeDivEditable(range.startContainer) &&
      !isNodeDivEditable(range.endContainer);

    const isSpanAtEnd =
      !isNodeDivEditable(range.startContainer) &&
      isNodeDivEditable(range.endContainer);

    const isSpanInMiddle = !range.startContainer.isEqualNode(
      range.endContainer
    );

    // firefox: not getting correct start container and end container
    isOnlyNonEditable = !isSpanAtEnd && !isSpanInMiddle && !isSpanAtStart;
    if (isSpanAtStart && range.commonAncestorContainer.firstChild) {
      //possible that browser has selected a dummy text node which is not visible but
      //is always before our own first node
      if (
        range.commonAncestorContainer.firstChild.nodeType === Node.TEXT_NODE
      ) {
        span = range.commonAncestorContainer.firstChild.nextSibling;
      } else {
        span = range.commonAncestorContainer.firstChild;
      }
    } else if (isSpanAtEnd && range.commonAncestorContainer.lastChild) {
      if (range.commonAncestorContainer.lastChild.nodeType === Node.TEXT_NODE) {
        span = range.commonAncestorContainer.lastChild.previousSibling;
      } else {
        span = range.commonAncestorContainer.lastChild;
      }
    } else if (isSpanInMiddle) {
      //if start and end container are different that means span is between these two

      //if startContainer's next node and endContainer's previous node are same then that
      //is the span we're looking for
      if (
        range.startContainer?.nextSibling?.isEqualNode(
          range.endContainer?.previousSibling
        )
      ) {
        span = range.startContainer.nextSibling;
      }
    }
  }
  let startContainer =
    isRangeDataIncorrect && span ? span.firstChild : range.startContainer;
  const endContainer =
    isRangeDataIncorrect && span ? span.firstChild : range.endContainer;

  // if there is only one pronunciation applied and and no other text is there
  if (isOnlyNonEditable) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    startContainer = range?.startContainer?.firstElementChild?.firstChild;
  }

  const rangeError = !startContainer;

  return {
    isSpan: isRangeDataIncorrect
      ? true
      : isNodeSpan(range.startContainer) ||
        isNodeSpan(range.startContainer.parentNode),
    text: window.getSelection()?.toString()?.trim(),
    rangeError: rangeError,
    startContainer: startContainer || range.startContainer,
    endContainer: endContainer || range.endContainer,
    startOffset: range.startOffset,
    endOffset: range.endOffset
  };
};

export interface CursorPosition {
  startContainer: Node;
  startOffset: number;
  endContainer: Node;
  endOffset: number;
}

export const getCursorPosition = (): CursorPosition | null => {
  const selection = window.getSelection();
  if (selection?.rangeCount && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);

    let startContainer = range.startContainer;

    while (startContainer?.firstChild) {
      startContainer = startContainer.firstChild;
    }

    return {
      startContainer: startContainer,
      startOffset: range.startOffset,
      endContainer: range.endContainer,
      endOffset: range.endOffset
    };
  }

  return null;
};

export const setCursorPosRich = (
  node: Node,
  startOffset = 0,
  after = false
) => {
  if (window.getSelection) {
    try {
      const selection = window.getSelection();
      const range = document.createRange();
      if (after) {
        range.setStartAfter(node);
      } else {
        range.setStart(node, startOffset);
      }
      range.collapse(true);
      selection?.removeAllRanges();
      selection?.addRange(range);
    } catch (e) {
      console.log(e);
      console.log("::setCursorPosRich::", node, startOffset);
    }
  }
};

const removeSquareBracketsFromPause = (str = "") => {
  return str.replace(/[[\]]/g, "");
};

interface Option {
  /**Value after the string "pause", refer pauseRegex in MurfUtil. Eg: 0.1, 0.2, weak, x-weak */
  value: string;
  /**Exact pause value to be added, eg: [pause weak], [pause strong] refer pauseRegex */
  pv: string;
}

export const createPauseNode = (option: Option): HTMLElement => {
  const span = document.createElement("span");
  span.innerText = removeSquareBracketsFromPause(option.value);
  span.classList.add(MTAG_CLASSES.PAUSE);
  span.setAttribute("contenteditable", "false");
  span.setAttribute(MTAG_DATA_ATTRIBUTES.PAUSE.pause, option.value);
  span.setAttribute(MTAG_DATA_ATTRIBUTES.MURF.murf, "true");

  return span;
};

/**
 * function to create a text pause node used for plain editor
 * @param option pause values
 * @returns pause text node
 */
export const createTextPauseNode = (option: Option): Node => {
  return document.createTextNode(`[pause ${option.pv}] `);
};

export const getContentEditableEl = (blockId: string): HTMLDivElement | null =>
  document.querySelector(`[data-blockid="${blockId}"]`);

export const simulateEditableChangeEvent = (
  editable: HTMLDivElement | null
) => {
  if (!editable) return;
  const inputEvent = new InputEvent("input", {
    bubbles: true,
    cancelable: true
  });
  editable.dispatchEvent(inputEvent);
};

const getElementFromRange = (rangeData: RangeData) => {
  const startContainer = rangeData.startContainer;
  let element;

  if (!startContainer) return startContainer;

  if (startContainer.nodeType === NodeTypeMap.TEXT_NODE)
    element = startContainer.parentElement;

  return element;
};

export const wrapTag = ({
  tagName = "span",
  rangeData,
  type,
  attributes = {},
  removeAttributes = [],
  removeClasses = [],
  isEditable = false,
  element
}: {
  tagName?: keyof HTMLElementTagNameMap;
  rangeData?: RangeData;
  type: MTagType;
  attributes: { [key: string]: string };
  removeAttributes?: string[];
  removeClasses?: string[];
  isEditable: boolean;
  element?: HTMLElement | null;
}) => {
  if (!type) throw new Error("wrapTag::No type was provided to wrap tag");
  if (!Object.keys(attributes).length)
    throw new Error("wrapTag::No attributes were provided");

  try {
    let span = element || document.createElement(tagName);
    let alreadyPresent = false;
    if (rangeData?.isSpan && !element) {
      span = getElementFromRange(rangeData) || span;
      alreadyPresent = true;
    }
    //default necessary classes
    span.setAttribute(MTAG_DATA_ATTRIBUTES.MURF.murf, "true");
    span.classList.add(MTAG_CLASSES[type]);
    span.contentEditable = String(isEditable);
    //add attributes
    Object.keys(attributes).forEach((attributeKey) => {
      span.setAttribute(
        `data-${attributeKey.toLowerCase()}`,
        attributes[attributeKey]
      );
    });
    removeAttributes.forEach((attributeKey) => {
      span.removeAttribute(`data-${attributeKey.toLowerCase()}`);
    });
    removeClasses.forEach((c) => {
      span.classList.remove(c);
    });
    if (alreadyPresent || !rangeData) return;

    const selection = window.getSelection();
    const isSelectionAvailable = selection?.toString()?.length;

    if (isSelectionAvailable) {
      const range = selection.getRangeAt(0).cloneRange();
      range.surroundContents(span);
      selection.removeAllRanges();
      selection.addRange(range);
    } else if (selection) {
      const dummyRange = document.createRange();
      dummyRange.setStart(rangeData.startContainer, rangeData.startOffset);
      dummyRange.setEnd(rangeData.endContainer, rangeData.endOffset);

      dummyRange.surroundContents(span);
      selection.addRange(dummyRange);
      selection.removeAllRanges();
    }
  } catch (e) {
    console.log("wrapTag::error", e);
  }
};

const removeTag = (element: Element) => {
  const parent = element.parentElement;

  const textContent = document.createTextNode(element.textContent || "");

  parent?.replaceChild(textContent, element);

  return textContent;
};

export const removeMurfTag = ({
  removeData,
  rangeData,
  element,
  keepElement = false
}: {
  removeData: { [key: string]: boolean };
  rangeData?: RangeData;
  element?: Element | null | undefined;
  keepElement?: boolean;
}) => {
  element = element
    ? element
    : rangeData
    ? getElementFromRange(rangeData)
    : null;

  let node: any = element;

  if (element && element.nodeType === Node.ELEMENT_NODE) {
    if (removeData.pronunciation) {
      // if no emphasis then remove element and keep only text
      if (!element.getAttribute(MTAG_DATA_ATTRIBUTES.EMPHASIS.emphasis)) {
        node = removeTag(element);
      } else {
        Object.values(MTAG_DATA_ATTRIBUTES.PRONUNCIATION).forEach(
          (attribute) => {
            element?.removeAttribute(attribute);
          }
        );
        element.classList.remove(MTAG_CLASSES.PRONUNCIATION);
      }
    }

    if (removeData.emphasis) {
      // if no pronunciation then remove tag
      if (
        !element.getAttribute(MTAG_DATA_ATTRIBUTES.PRONUNCIATION.alt) &&
        !keepElement
      ) {
        removeTag(element);
      } else {
        Object.values(MTAG_DATA_ATTRIBUTES.EMPHASIS).forEach((attribute) => {
          element?.removeAttribute(attribute);
        });
        element.classList.remove(MTAG_CLASSES.EMPHASIS);

        if (!element.getAttribute(MTAG_DATA_ATTRIBUTES.PRONUNCIATION.alt)) {
          element.removeAttribute(MTAG_DATA_ATTRIBUTES.MURF.murf);
        }
      }
    }
  }

  return node;
};

export const parseHTML = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body;
};

/**
 * function used to get block text and murf's highlighted elements
 * it removes highlight for other words
 */
export const getMurfHtmlText = (str: string) => {
  const html = parseHTML(str);
  const nodes = html?.childNodes;
  let text = "";

  if (nodes) {
    for (const node of nodes) {
      if (node.nodeType === NodeTypeMap.ELEMENT_NODE) {
        const elment = node as Element;
        let word = "";
        if (elment.getAttribute(MTAG_DATA_ATTRIBUTES.MURF.murf)) {
          word = elment.outerHTML;
        } else if (node.textContent) {
          word = node.textContent;
        }

        text = word ? `${text} ${word}` : text;
      }
    }
  }

  return text;
};

/**
 * function to convert html text to rich text(json)
 * @param htmlString
 * @returns rich text
 */
export const getMurfTokensUsingDom = (htmlString: string): RichTextType[] => {
  let nodes: any = [];
  if (htmlString) {
    htmlString = sanitizeContentEditable(htmlString);
    const html = parseHTML(htmlString);
    nodes = html?.childNodes;
  }

  const tokens = [];

  if (!nodes || !nodes.length) {
    tokens.push({ text: "" });
  } else {
    for (const node of nodes) {
      const text = node.textContent?.trim?.();
      const token: RichTextType | null = text
        ? {
            text
          }
        : null;

      if (token && node.nodeType === NodeTypeMap.ELEMENT_NODE) {
        const pauseNodeValue =
          node.getAttribute(MTAG_DATA_ATTRIBUTES.PAUSE.pause) ?? undefined;

        if (pauseNodeValue) {
          token.isPause = true;
          token.text = pauseNodeValue;
        } else {
          token["emphasis"] =
            node.getAttribute(MTAG_DATA_ATTRIBUTES.EMPHASIS.emphasis) ||
            undefined;

          token["alt"] =
            node.getAttribute(MTAG_DATA_ATTRIBUTES.PRONUNCIATION.alt) ??
            undefined;

          token["sayAs"] =
            node.getAttribute(MTAG_DATA_ATTRIBUTES.PRONUNCIATION.sayAs) ??
            undefined;

          const isProjectPronun = node.getAttribute(
            MTAG_DATA_ATTRIBUTES.PRONUNCIATION.pro
          );
          // if project pronunciation then remove pronunciation data
          if (isProjectPronun) {
            delete token.alt;
            delete token.sayAs;
          }
        }
      }

      if (token) tokens.push(token);
    }
  }

  return tokens;
};

const removeNbsp = (str: string) => {
  if (str) {
    return str.replace(/&nbsp;|\u202F|\u00A0/g, "");
  } else return "";
};

export const highlightPronunciation = (
  htmlString: string,
  pronunciation: DubPronunciation
) => {
  if (!htmlString) return htmlString;
  // Word to search for and replace
  const wordToReplace = pronunciation.word;

  // Regular expression pattern to match the word
  // it ignores word if it contains ' , -
  const pattern = new RegExp(
    "(?<=\\s|^)" + wordToReplace + "(?=[\\s.,!?|।]|$)",
    "gi"
  );

  // Function to replace the matched word with a <span> tag
  /**
   * @todo make this readable
   */
  function replaceWordWithSpan(match: string) {
    return `<span class="${
      MTAG_CLASSES.PRONUNCIATION
    }" data-pro="true" data-m="true" contenteditable="false" data-${MTAG_VALUES.PRONUNCIATION.alt.toLowerCase()}="${
      pronunciation.alternate
    }" data-${MTAG_VALUES.PRONUNCIATION.sayAs.toLowerCase()}="${
      pronunciation.sayAs
    }">${match}</span>`.trim();
  }

  const outputString = htmlString.replace(pattern, replaceWordWithSpan);

  return outputString;
};

/**
 * function used to handle project pronunciation
 * @param {string} htmlString
 * @param {object} pronun - pronunciation object
 * @param {boolean} override wether to override currently applied pronunciation
 * @returns highlighted html string
 */
export const handleProjectPronunciation = (
  htmlString: string,
  pronun: DubPronunciation,
  override = false
) => {
  let oldPronunciationAffected = false;
  const html = parseHTML(htmlString);
  const nodes = html?.childNodes;

  const result = [];

  for (const node of nodes) {
    // if span element
    if (node.nodeType === NodeTypeMap.ELEMENT_NODE) {
      const element = node as Element;
      // if no pronunciation applied and word matches then add pronunciatino attributes
      if (
        (override ||
          typeof element.getAttribute(
            MTAG_DATA_ATTRIBUTES.PRONUNCIATION.alt
          ) !== "string" ||
          element.getAttribute(MTAG_DATA_ATTRIBUTES.PRONUNCIATION.pro)) &&
        unicodeAwareEqualsIgnoringCase(
          removeNbsp(element.textContent || ""),
          pronun.word
        )
      ) {
        // if pronunciation present and updating
        if (
          element.getAttribute(MTAG_DATA_ATTRIBUTES.PRONUNCIATION.alt) &&
          !element.getAttribute(MTAG_DATA_ATTRIBUTES.PRONUNCIATION.pro)
        ) {
          oldPronunciationAffected = true;
        }

        element.classList.add(MTAG_CLASSES.PRONUNCIATION);
        element.setAttribute(
          MTAG_DATA_ATTRIBUTES.PRONUNCIATION.alt,
          pronun.alternate
        );
        element.setAttribute(
          MTAG_DATA_ATTRIBUTES.PRONUNCIATION.sayAs,
          String(pronun.sayAs)
        );
        element.setAttribute(MTAG_DATA_ATTRIBUTES.PRONUNCIATION.pro, "true");
      }

      result.push(element.outerHTML);
    } else {
      // if text then highlight
      const updateNodeValue = highlightPronunciation(
        node.nodeValue || "",
        pronun
      );
      result.push(updateNodeValue);
    }
  }
  return {
    text: result.join(""),
    oldPronunciationAffected
  };
};

/**
 * @description function to remove project pronunciation from text
 * @param {*} htmlString
 * @param {*} pronunciation
 * @returns - text without project pronunciation
 */
export const removeDubPronunciation = (
  htmlString: string,
  pronunciation: DubPronunciation,
  override = false
) => {
  let oldPronunciationAffected = false;
  const html = parseHTML(htmlString);
  if (!html) return { text: htmlString, oldPronunciationAffected };
  const nodes = html.childNodes;

  const result = [];

  for (const node of nodes) {
    if (node.nodeType === NodeTypeMap.ELEMENT_NODE) {
      let element = node as Element;
      // if its project pronunciation and word matches
      if (
        (override ||
          element.getAttribute(MTAG_DATA_ATTRIBUTES.PRONUNCIATION.pro)) &&
        unicodeAwareEqualsIgnoringCase(
          removeNbsp(element.textContent || ""),
          pronunciation.word
        )
      ) {
        // if pronunciation present and removing
        if (
          element.getAttribute(MTAG_DATA_ATTRIBUTES.PRONUNCIATION.alt) &&
          !element.getAttribute(MTAG_DATA_ATTRIBUTES.PRONUNCIATION.pro)
        ) {
          oldPronunciationAffected = true;
        }

        element = removeMurfTag({
          element: element,
          removeData: { pronunciation: true }
        });

        if (element.nodeType === NodeTypeMap.ELEMENT_NODE)
          result.push(element.outerHTML);
        else result.push(element.textContent);
      } else result.push(element.outerHTML);
    } else result.push(node.textContent);
  }

  return {
    text: result.join(" "),
    oldPronunciationAffected
  };
};

export const getUnsafeCursorPosition = () => {
  const selection = window.getSelection();
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);

    const parent = range.startContainer.parentNode; //assumes startContainer is always a text node;
    let offset = 0,
      currentNode = parent?.firstChild;

    while (currentNode) {
      const innerText = currentNode.textContent || "";

      if (currentNode.isEqualNode(range.startContainer)) {
        offset += range.startOffset;
        currentNode = null;
      } else {
        currentNode = currentNode.nextSibling;
        offset += innerText.length;
      }
    }

    return offset;
  }

  return 0;
};

export const setUnsafeCursorPosition = (
  editable: Element,
  position: number
) => {
  let currentNode = editable.firstChild,
    currentNodeOffset = 0,
    lastNodeOffset = 0,
    pos: { startContainer: Node | null; startOffset: number } = {
      startContainer: null,
      startOffset: 0
    };

  if (position === 0) {
    const firstChild = editable.firstChild,
      isFirstChildSpan = isNodeSpan(firstChild);

    setCursorPosRich(
      isFirstChildSpan || !firstChild ? editable : firstChild,
      0
    );

    return;
  }

  while (currentNode) {
    const innerText = currentNode.textContent || "";

    currentNodeOffset += innerText.length;

    if (currentNodeOffset >= position) {
      pos = {
        startContainer: currentNode,
        startOffset: position - lastNodeOffset
      };
      currentNode = null;
    } else {
      currentNode = currentNode.nextSibling;
    }

    lastNodeOffset += innerText.length;
  }

  if (pos.startContainer) {
    /**
     * check if current startContainer is span,
     * if yes get the next sibling and focus its 0 pos to avoid invalid range problems
     */
    if (isNodeSpan(pos.startContainer) && pos.startContainer.nextSibling) {
      pos = {
        startContainer: pos.startContainer.nextSibling,
        startOffset: 0
      };
    }

    if (pos.startContainer)
      setCursorPosRich(
        pos.startContainer,
        pos?.startOffset ?? 0,
        isNodeSpan(pos.startContainer) ? true : false
      );
  } else {
    console.log("no pos");
  }
};

/**
 * Function to convert rich text (json) to html text
 * @param richText - rich text
 * @param pronunciations - dub pronunciations array
 * @returns html text
 */
export const convertRichTextToHtmlText = (
  richText: RichTextType[],
  pronunciations: DubPronunciation[]
): string => {
  if (!richText) return "";
  const htmlText: string[] = [];

  const pronunciationsObject = pronunciations.reduce((prev, curr) => {
    prev[curr.word] = curr;
    return prev;
  }, {} as { [key: string]: DubPronunciation });

  richText.forEach((richObj) => {
    let isNode = false,
      text = "",
      innerText = richObj.text;
    const attributes: { [key: string]: any } = {};
    const classes = [];

    const projectPronunciationForCurrentWord =
      pronunciationsObject[richObj.text] ||
      pronunciationsObject[richObj.text.toLocaleLowerCase()];
    // check pronunciation
    if (richObj.alt) {
      isNode = true;
      attributes[MTAG_VALUES.PRONUNCIATION.alt] = richObj.alt;
      attributes[MTAG_VALUES.PRONUNCIATION.sayAs] = richObj.sayAs;

      // don't highlight if default pronunciation is applied
      if (!unicodeAwareEqualsIgnoringCase(richObj.alt, richObj.text)) {
        classes.push(MTAG_CLASSES.PRONUNCIATION);
      }
    } else if (projectPronunciationForCurrentWord) {
      // if project pronunciation is present
      isNode = true;
      attributes[MTAG_VALUES.PRONUNCIATION.alt] =
        projectPronunciationForCurrentWord.alternate;
      attributes[MTAG_VALUES.PRONUNCIATION.sayAs] =
        projectPronunciationForCurrentWord.sayAs;
      attributes[MTAG_VALUES.PRONUNCIATION.projectPronunciation] = "true";

      classes.push(MTAG_CLASSES.PRONUNCIATION);
    }

    // check if emphasis is present
    if (richObj.emphasis) {
      isNode = true;
      attributes[MTAG_VALUES.EMPHASIS.emphasis] = richObj.emphasis;
      classes.push(MTAG_CLASSES.EMPHASIS);
    }

    // checking if pause is present
    if (richObj.isPause) {
      isNode = true;
      attributes[MTAG_VALUES.PAUSE.pause] = innerText;
      innerText = removeSquareBracketsFromPause(innerText);
      classes.push(MTAG_CLASSES.PAUSE);
    }

    const attributesString = Object.entries(attributes)
      .map((entry) => `data-${entry[0].toLowerCase()}="${entry[1]}"`)
      .join(" ");

    if (isNode)
      text = `<span data-m="true" class="${classes.join(
        " "
      )}" contenteditable="false" ${attributesString}>${innerText}</span>`;
    else {
      text = richObj.text;
      pronunciations.forEach((pronoun) => {
        text = highlightPronunciation(text, pronoun);
      });
    }

    htmlText.push(text);
  });

  return htmlText.join(" ");
};

/**
 * @description function to split text in to words by space
 * it removes html content and keeps only words
 * @param {string} text string that needs to
 * @returns array of words
 */
export const splitTextToWordsBySpace = (text = "") => {
  return sanitizeContentEditable(stripHtml(text))
    .replace(pauseRegex, " ")
    .replace(boundaryCharRegex, " ")
    .split(/[\s,]+/);
};

export const convertPausesToNodes = (text: string) => {
  const pauseRegEx = new RegExp(/\[pause\s*([\d.]+)s?\]/gi);
  const newText = text.replaceAll(pauseRegEx, (_, sec) => {
    return `<span class="${MTAG_CLASSES.PAUSE}" contenteditable="false" ${MTAG_DATA_ATTRIBUTES.MURF.murf}="true" ${MTAG_DATA_ATTRIBUTES.PAUSE.pause}="[pause ${sec}s]" ${MTAG_DATA_ATTRIBUTES.PAUSE.isPause}="true">pause ${sec}s</span>`;
  });

  return newText;
};

export const stripNbsp = (str: string) => {
  if (str) {
    return str.replace(/&nbsp;|\u202F|\u00A0/g, " ");
  } else return "";
};

/**
 * @param {string} [prefix=POT] Prefix to add before the pattern to recognize the token
 * @returns {RegExp}
 */
export const idMatcher = (prefix = "POT") =>
  new RegExp(`${prefix}[A-Za-z0-9]*`, "gm");

export const replaceLegacyPauseWithNodes = (inputString: string) => {
  inputString = stripNbsp(inputString);
  const pauseTokenPrefix = "PAUS";
  const { result, tokens } = tokenizeHtmlAttrs(
    inputString,
    new RegExp(`${pauseRegex.source}(?:^|\\s|\\[|$)`, "g"), // \\b not working because square brackets??
    pauseTokenPrefix,
    true
  );

  const withPauseNodes = result.replace(
    idMatcher(pauseTokenPrefix),
    (match) => {
      //template for word boundary spacings
      return ` ${
        createPauseNode({ value: tokens[match].trim(), pv: "" }).outerHTML
      } `;
    }
  );

  return withPauseNodes;
};

/**
 * function to convert rich text to plain text (only pauses are preserved)
 * @param richText
 * @returns plain text
 */
export const convertRichTextToPlainText = (richText: RichTextType[]) => {
  return richText.reduce((prev, richObj) => {
    if (richObj.text) return `${prev} ${richObj.text}`;
    return prev;
  }, "");
};

export const replaceNodeInHtmlString = (
  htmlString: string,
  elementReplacer: ((element: Element) => Node) | null,
  textReplacer: ((text: string) => string) | null
): string => {
  const html = parseHTML(htmlString);
  if (!html) return htmlString;
  const results = [];

  const nodes = html?.childNodes;

  for (const node of nodes) {
    if (node.nodeType === NodeTypeMap.ELEMENT_NODE) {
      const element = node as Element;
      if (elementReplacer) html.replaceChild(element, elementReplacer(element));
      results.push(element.outerHTML);
    } else {
      if (textReplacer) {
        node.nodeValue = textReplacer(node.nodeValue || "");
      }
      results.push(node.textContent);
    }
  }

  return results.join("");
};
