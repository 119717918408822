import { useTypedSelector } from "@/config/configureAppStore";
import {
  PolicyFunction,
  usePermissions
} from "@/features/permissions/hooks/usePermissions";
import { QC_ACCESS_ROLES } from "@/types/user";
import { useMemo } from "react";

const policy: PolicyFunction<{
  accessLevel: QC_ACCESS_ROLES;
  readOnlyMode: boolean;
}> = ({ policyArgs }) =>
  !policyArgs.readOnlyMode &&
  (policyArgs.accessLevel === QC_ACCESS_ROLES.VOICEOVER ||
    policyArgs.accessLevel === QC_ACCESS_ROLES.TRANSLATION_VOICEOVER);

const useTranslationVoPermissions = () => {
  const accessLevel = useTypedSelector(
    (state) => state.currentScriptProject.accessLevel
  );
  const readOnlyMode = useTypedSelector(
    (state) => state.currentScriptProject.readOnly
  );
  const { canAccess } = usePermissions();

  const hasAccessToSidePanel = useMemo(
    () =>
      accessLevel
        ? canAccess({
            policy: policy,
            policyArgs: { accessLevel, readOnlyMode }
          })
        : false,
    [canAccess, readOnlyMode, accessLevel]
  );

  return {
    hasAccessToSidePanel
  };
};

export default useTranslationVoPermissions;
