import { useState, useEffect, useRef } from "react";
import { AuthUserContext } from "./context";
import { FIREBASE } from "murflib";
import { useAppDispatch } from "@/config/configureAppStore";
import { STATUS } from "@/constants/status";
import { FirebaseInstance } from ".";
import { AuthUser } from "@/types/firebase";
import useAuthMethods from "@/hooks/useAuthMethods";
import { updateAppInitializationStatus } from "@/reducers/slices/globalSlice";

/**
 *
 * @function withAuthentication
 * @property {AuthUser} authUser - This contains firebase auth user better storing this in contextAPI as redux official docs says "Do Not Put Non-Serializable Values in State or Actions"
 * @see https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
 */
export const withAuthentication = (Component: any) => {
  function WithAuthentication(props: any) {
    const [authUser, setAuthUser] = useState<AuthUser | null>();
    const dispatch = useAppDispatch();
    const { updateUserDataAndRedirect } = useAuthMethods();
    const authStateUpdated = useRef(false);
    const initialLoad = useRef(true);

    useEffect(() => {
      const unsubscribe = FIREBASE.authStateChangedCallback(
        FirebaseInstance?.auth,
        (user: AuthUser | null) => {
          setAuthUser(user);
          authStateUpdated.current = true;
        }
      );
      return () => {
        unsubscribe();
      };
    }, []);

    useEffect(() => {
      if (authStateUpdated.current) {
        const redirectRequired = initialLoad.current;
        initialLoad.current = false;
        authStateUpdated.current = false;
        updateUserDataAndRedirect({ user: authUser, redirectRequired }).then(
          () => {
            setTimeout(() => {
              dispatch(updateAppInitializationStatus(STATUS.SUCCESS));
            }, 0);
          }
        );
      }
      return () => {};
    }, [authUser, dispatch, updateUserDataAndRedirect]);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  }

  return WithAuthentication;
};
