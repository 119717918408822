export const BLOCK_MAX_CHAR = 1500;

export enum BLOCK_ERROR_TYPE {
  TEXT_LIMIT_EXCEEDED_ERROR = "TEXT_LIMIT_EXCEEDED_ERROR",
  RENDER_FAILED = "RENDER_FAILED",
  RENDER_LENGTH_EXCEEDED = "RENDER_LENGTH_EXCEEDED",
  RENDER_LENGTH_INSUFFICIENT = "RENDER_LENGTH_INSUFFICIENT"
}

export const BLOCK_ERROR_MESSAGES = {
  [BLOCK_ERROR_TYPE.TEXT_LIMIT_EXCEEDED_ERROR]: `Block is to long make it smaller than ${BLOCK_MAX_CHAR} characters`,
  [BLOCK_ERROR_TYPE.RENDER_FAILED]: "Render failed. Please try again.",
  [BLOCK_ERROR_TYPE.RENDER_LENGTH_EXCEEDED]:
    "Audio duration is more than original block",
  [BLOCK_ERROR_TYPE.RENDER_LENGTH_INSUFFICIENT]:
    "Audio duration is less than original block"
};
