import { pluralize } from "./misc";

export function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export const getTwoDigitTime = (time: number) => {
  return time?.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    maximumFractionDigits: 0,
    useGrouping: false
  });
};

export const getMSUsingLocaleString = (
  seconds: number,
  msEnabled: boolean = false,
  isCyclic?: boolean
) => {
  const decimal = Math.round((seconds % 1) * 100);
  return `${getTwoDigitTime(
    Math.floor((isCyclic ? seconds % 3600 : seconds) / 60)
  )}:${getTwoDigitTime(Math.floor((seconds % 3600) % 60))}${
    msEnabled ? `:${decimal.toString().padStart(2, "0")}` : ""
  }`;
};

export const getMS = (
  seconds: number,
  msEnabled: boolean = false,
  isCyclic?: boolean
) => {
  const decimal = parseInt(((seconds - Math.floor(seconds)) * 10).toFixed(0));
  const hour = Math.floor(seconds / 3600);
  const minutes = isCyclic
    ? Math.floor((seconds % 3600) / 60)
    : Math.floor(seconds / 60);
  const _seconds = Math.floor((seconds % 3600) % 60);

  let temp = "";

  if (isCyclic) {
    temp += `${padTo2Digits(hour)}:`;
  }
  temp += `${padTo2Digits(minutes)}:${padTo2Digits(_seconds)}`;

  if (msEnabled) {
    temp += `:${padTo2Digits(decimal)}`;
  }

  return temp;
};

export const getMSWithFrame = (
  seconds: number,
  msEnabled: boolean = false,
  frameCount: number = 24,
  isCyclic?: boolean
) => {
  const decimalRaw = seconds - Math.floor(seconds);
  return `${getMS(seconds, msEnabled, isCyclic)}:${padTo2Digits(
    Math.round(decimalRaw * frameCount)
  )}`;
};

export const returnDateString = (
  initDate: number | string | Date,
  locale?: string,
  options?: Intl.DateTimeFormatOptions
) => {
  if (initDate === null || initDate === undefined) return "";
  const date = new Date(initDate);

  return date.toLocaleDateString(
    locale ?? "en-US",
    options ?? {
      month: "short",
      day: "numeric",
      year: "numeric"
    }
  );
};

export function getTimeDifference(timestamp: number): number {
  const now = new Date().getTime();
  const timeDifference = timestamp - now;
  return timeDifference;
}

export function getTimeDifferenceText(timestamp: number): string {
  const now = new Date().getTime();
  const timeDifference = now - timestamp;
  const absTimeDifference = Math.abs(timeDifference);

  // Define time units in milliseconds
  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30.44 * day;
  if (absTimeDifference < minute) {
    const seconds = Math.floor(absTimeDifference / 1000);
    const timeString = `second${seconds > 1 ? "s" : ""}`;
    return timeDifference > 0
      ? `${seconds} ${timeString} ago`
      : `${seconds} ${timeString} left`;
  } else if (absTimeDifference < hour) {
    const minutes = Math.floor(absTimeDifference / minute);
    const timeString = `minute${minutes > 1 ? "s" : ""}`;
    return timeDifference > 0
      ? `${minutes} ${timeString} ago`
      : `${minutes} ${timeString} left`;
  } else if (absTimeDifference < day) {
    const hours = Math.floor(absTimeDifference / hour);
    const timeString = `hour${hours > 1 ? "s" : ""}`;
    return timeDifference > 0
      ? `${hours} ${timeString} ago`
      : `${hours} ${timeString} left`;
  } else if (absTimeDifference < week) {
    const days = Math.floor(absTimeDifference / day);
    const timeString = `day${days > 1 ? "s" : ""}`;
    return timeDifference > 0
      ? `${days} ${timeString} ago`
      : `${days} ${timeString} left`;
  } else if (absTimeDifference < month) {
    const weeks = Math.floor(absTimeDifference / week);
    const timeString = `week${weeks > 1 ? "s" : ""}`;
    return timeDifference > 0
      ? `${weeks} ${timeString} ago`
      : `${weeks} ${timeString} left`;
  } else {
    const months = Math.floor(absTimeDifference / month);
    const timeString = `month${months > 1 ? "s" : ""}`;
    return timeDifference > 0
      ? `${months} ${timeString} ago`
      : `${months} ${timeString} left`;
  }
}
export function getSeekerTime({
  timeValue,
  msEnabled = false,
  isCyclic = false
}: {
  timeValue: number | undefined;
  msEnabled?: boolean;
  isCyclic?: boolean;
}) {
  if (timeValue && !isNaN(timeValue)) {
    return getMS(timeValue, msEnabled, isCyclic);
  }
  return isCyclic ? `00:00:00` : `00:00`;
}

export function getSeekerTimeWithFrame({
  timeValue,
  msEnabled = false,
  frameCount = 24,
  isCyclic = false
}: {
  timeValue: number | undefined;
  msEnabled?: boolean;
  isCyclic?: boolean;
  frameCount?: number;
}) {
  if (timeValue && !isNaN(timeValue)) {
    return getMSWithFrame(timeValue, msEnabled, frameCount, isCyclic);
  }
  return isCyclic ? "00:00:00:00" : "00:00:00";
}

export const formatTimeToMinSec = (totalSeconds: number): string => {
  let timeString = "";

  if (totalSeconds > 59) {
    const minutes = Math.floor(totalSeconds / 60);

    const seconds: number =
      ((totalSeconds % 60).toFixed(2) as unknown as number) * 1;

    timeString = `${padTo2Digits(minutes)}m ${padTo2Digits(seconds)}s`;
  } else {
    timeString = totalSeconds + "s";
  }

  return timeString;
};

export const generalDateStringForClientSide = (_date: Date | undefined) => {
  let dateString = "";
  const createdAtDate = _date ? new Date(_date) : new Date();
  dateString = createdAtDate.getDate().toString() + " ";
  dateString += createdAtDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short"
  });
  dateString += `, ${createdAtDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit"
  })}`;
  return dateString;
};

export const getRelativeTime = (_date: Date) => {
  const now = Date.now();
  const givenDate = new Date(_date).valueOf();
  const MINUTE = 1000 * 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  const WEEK = DAY * 7;
  const MONTH = WEEK * 4;
  const diff = Math.abs(givenDate - now);
  if (diff < HOUR) {
    const _temp = Math.ceil(diff / MINUTE);
    return `${pluralize(_temp, "min")}`;
  } else if (diff < DAY) {
    const _temp = Math.ceil(diff / HOUR);
    return `${pluralize(_temp, "hr")} `;
  } else if (diff < WEEK) {
    const _temp = Math.ceil(diff / DAY);
    return `${pluralize(_temp, "day")}`;
  } else if (diff < MONTH) {
    const _temp = Math.ceil(diff / WEEK);
    return `${pluralize(_temp, "week")}`;
  } else {
    const _temp = Math.ceil(diff / MONTH);
    return `${pluralize(_temp, "month")} `;
  }
};
