import { PaletteOptions } from "@mui/material";
import { themeVariables } from "./themeVariables";

export const darkThemePalette: PaletteOptions = {
  mode: "dark",
  primary: {
    main: "#FFD833",
    light: "#FFEB99",
    dark: "#E5B000",
    contrastText: "#191D25",
    hover: "#FFCE0014",
    selected: "#FFCE0040",
    focusVisible: "#FFCE004D",
    outlinedBorder: "#FFCE0080"
  },
  secondary: {
    main: "#DBDEE5",
    light: "#A9B0C0",
    dark: "#FFFFFF",
    contrastText: "#212632",
    hover: "#A8B2D414",
    selected: "#A8B2D429",
    focusVisible: "#A8B2D44D",
    outlinedBorder: "#A8B2D459"
  },
  info: {
    main: "#2B96F9",
    light: "#99CEFF",
    dark: "#005DB2",
    contrastText: "#191D25",
    hover: "#2B96F91A",
    selected: "#2B96F940",
    focusVisible: "#2B96F966",
    outlinedBorder: "#2B96F999"
  },
  success: {
    main: "#009961",
    light: "#3CDDA2",
    dark: "#006641",
    contrastText: "#FFFFFF",
    hover: "#0099611A",
    selected: "#00996140",
    focusVisible: "#00996166",
    outlinedBorder: "#00996199"
  },
  error: {
    main: "#EB4A47",
    light: "#EC9493",
    dark: "#AE3532",
    contrastText: "#FFFDE7",
    hover: "#EB4A471A",
    selected: "#EB4A4740",
    focusVisible: "#EB4A4766",
    outlinedBorder: "#EB4A4799"
  },
  warning: {
    main: "#FB9200",
    light: "#FFCA80",
    dark: "#AD6500",
    contrastText: "#FFFFFF",
    hover: "#FB92001A",
    selected: "#FB920040",
    focusVisible: "#FB920066",
    outlinedBorder: "#FB920099"
  },
  action: {
    active: "#FFFFFF",
    hover: "#A8B2D40F",
    selected: "#A8B2D41A",
    focus: "#A8B2D41F",
    disabledBackground: "#FFFFFF1F"
  },
  text: {
    primary: "#F0F1F5",
    secondary: "#FFFFFF99",
    disabled: "#FFFFFF4D"
  },
  divider: themeVariables.dark.dividerColor,
  background: {
    default: themeVariables.dark.backgroundDefault,
    paper: themeVariables.dark.backgroundPaper,
    elevation0: themeVariables.dark.elevation[0],
    elevation1: themeVariables.dark.elevation[1],
    elevation2: themeVariables.dark.elevation[2],
    elevation3: themeVariables.dark.elevation[3]
  },
  elevation: {
    outlinedBorder: themeVariables.dark.elevationOutline
  }
};
