import { areVoicesEqual } from "@/features/synthesis/util";
import { Speaker } from "../types";
import { sanitizeContentEditable } from "@/features/editor/util/richEditor";
import { BlocksMap } from "@/reducers/slices/projectUtilitySlice/types";

const RenderAffectingProps = ["speakerId", "renderVersion", "renderStatus"];
const TextContextBlocksLength = 2;

export const doesChangeAffectsRender = (block1: any, block2: any): boolean => {
  const htmlText1 = sanitizeContentEditable(block1.htmlText);
  const htmlText2 = sanitizeContentEditable(block2.htmlText);

  if (htmlText1 !== htmlText2) return true;

  return RenderAffectingProps.reduce(
    (prev, curr) => prev || block1[curr] !== block2[curr],
    false
  );
};

/**
 * function to update voice name with voice prefix and index
 * @param speakers - speakers array
 * @returns - updated speakers with voice name
 */

export const updateVoiceNames = (speakers: Speaker[]) => {
  return speakers.map((speaker) => {
    let count = 1;
    speaker.voiceVariantList = speaker.voiceVariantList.map((voice) => {
      const newVoiceName = `Voice ${count}`;
      count++;
      if (areVoicesEqual(speaker.selectedVoiceVariant, voice)) {
        speaker.selectedVoiceVariant.name = newVoiceName;
      }
      return {
        ...voice,
        name: newVoiceName
      };
    });

    return speaker;
  });
};

export const getBlockSurroundingText = (
  blockIndex: number,
  blocksOrder: string[],
  blocks: BlocksMap,
  useEnglishText = false
): string => {
  let start = blockIndex - TextContextBlocksLength;
  let end = blockIndex + TextContextBlocksLength;

  if (start < 0) {
    start = 0;
  }

  if (end > blocksOrder.length) {
    end = blocksOrder.length;
  }

  const surroundingText = [];

  for (let i = start; i < end; i++) {
    const block = blocks[blocksOrder[i]];

    if (block) {
      surroundingText.push(
        useEnglishText ? block.original.englishText : block.original.text
      );
    }
  }

  return surroundingText.join(" ");
};

/**
 * function to open find and replace popup
 */
export const openFindAndReplacePopup = () => {
  const popup = document.getElementById("find-and-replace-popup");
  if (popup) {
    popup.style.display = "flex";
    const searchInput = document.getElementById("find-and-replace-search");
    if (searchInput) searchInput?.focus();
  }
};
