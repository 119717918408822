import RequiresAuth from "@/components/auth/RequiresAuth";
import { RouteObject } from "react-router-dom";
import { dubPreviewRoutes } from "@/features/preview";
import { createProjectRoutes } from "@/features/createProject";
import { clientProjectDetailsRoute } from "@/features/clientProjectDetails";
import { clientHomeRoutes } from "@/features/clientHome";
import Base from "./Base";
import getIndexRedirect from "@/utils/getIndexRedirect";
import { profileRoute } from "@/features/profile";
import { searchPageRoutes } from "@/features/clientSearch/routes";

export const baseRoute: RouteObject = {
  path: `/`,
  element: (
    <RequiresAuth>
      <Base />
    </RequiresAuth>
  ),
  children: [
    getIndexRedirect("/home"),
    ...dubPreviewRoutes,
    ...createProjectRoutes,
    ...clientProjectDetailsRoute,
    ...clientHomeRoutes,
    ...searchPageRoutes,
    ...profileRoute
  ]
};
