import { CheckboxProps, Theme } from "@mui/material";

/**
 * function to get the colors based on block state
 * @param theme - theme
 * @param props - block properties
 * @returns - color , background color and borderColor
 */
export const getBlockColors = (
  theme: Theme,
  props: {
    isSelected: boolean;
    isActive: boolean;
    isBlockPlaying: boolean;
    hasError: boolean;
    completed: boolean;
  }
) => {
  let color: CheckboxProps["color"] = "default",
    borderColor: string = "transparent",
    bgColor =
      props.isActive || props.isSelected
        ? theme.palette.action.selected
        : "transparent";

  if (props.hasError) {
    color = "error";
    bgColor = props.isActive
      ? theme.palette.error.selected
      : theme.palette.error.hover;
  } else if (props.completed) {
    color = "success";
    bgColor = props.isActive
      ? theme.palette.success.selected
      : theme.palette.success.hover;
  }

  if (props.isSelected || props.isBlockPlaying) {
    if (props.hasError) {
      borderColor = theme.palette.error.outlinedBorder;
    } else if (props.completed)
      borderColor = theme.palette.success.outlinedBorder;
    else borderColor = theme.palette.secondary.outlinedBorder;
  }

  return {
    color,
    bgColor,
    borderColor
  };
};
