import LoaderWrapper from "@/components/layout/LoaderWrapper";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { STATUS } from "@/constants/status";
import { userThunks } from "@/reducers/thunks/user";
import { setWorkspaceProps } from "@/utils/mixpanel";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Base = () => {
  const { apiStatus } = useTypedSelector((state) => state.user);
  const workspaceApiStatus = useTypedSelector(
    (state) => state.user.workspaceApiStatus
  );

  const dispatch = useAppDispatch();

  /**
   * fetching workspace state for external user
   */
  useEffect(() => {
    if (workspaceApiStatus === STATUS.IDLE && apiStatus === STATUS.SUCCESS) {
      dispatch(userThunks.fetchWorkspaceState()).then((res) => {
        if (res.meta.requestStatus !== "rejected") {
          setWorkspaceProps(res.payload);
        }
      });
    }
  }, [apiStatus, dispatch, workspaceApiStatus]);

  return (
    <LoaderWrapper loading={apiStatus !== STATUS.SUCCESS}>
      <Outlet />
    </LoaderWrapper>
  );
};

export default Base;
