const commonVariables = {
  shadowColorLight: "rgba(7, 10, 15, 0.15)",
  shadowColorDark: "rgba(7, 10, 15, 0.75)",
  tooltipBackground: "#464C5C"
};

const themeVariables = {
  light: {
    backgroundDefault: "#EDF1F7",
    backgroundPaper: "#FFFFFF",
    shadows: [
      "none",
      `0px 4px 4px -4px ${commonVariables.shadowColorLight}, 0px 1px 8px 0px ${commonVariables.shadowColorLight}`,
      `0px 16px 16px -12px ${commonVariables.shadowColorLight}, 0px 2px 16px 0px ${commonVariables.shadowColorLight}`,
      `0px 32px 32px -16px ${commonVariables.shadowColorLight}, 0px 4px 32px 0px ${commonVariables.shadowColorLight}`
    ],
    elevation: ["#FFFFFF", "#FAFBFFCC", "#FFFFFFD9", "#FFFFFFD9"],
    backdropFilter: ["none", "blur(64px)", "blur(64px)", "blur(72px)"],
    backdropBackground: "#2126327f",
    dividerColor: "#1213141A",
    elevationOutline: "rgba(25, 29, 37, 0.19)"
  },
  dark: {
    backgroundDefault: "#11151E",
    backgroundPaper: "#202533",
    shadows: [
      "none",
      `0px 4px 4px -4px ${commonVariables.shadowColorDark}, 0px 1px 8px 0px ${commonVariables.shadowColorDark}`,
      `0px 16px 16px -12px ${commonVariables.shadowColorDark}, 0px 2px 16px 0px ${commonVariables.shadowColorDark}`,
      `0px 32px 32px -16px ${commonVariables.shadowColorDark}, 0px 4px 32px 0px ${commonVariables.shadowColorDark}`
    ],
    elevation: ["#202533", "#6777A13D", "#6777A13D", "#A8B2D43D"],
    backdropFilter: ["none", "blur(72px)", "blur(72px)", "blur(72px)"],
    backdropBackground: "#000000CC",
    dividerColor: "#EDF2FF1A",
    elevationOutline: "rgba(255, 255, 255, 0.15)"
  }
};

export { themeVariables, commonVariables };
