import { TimelineVariant } from "../types/variants";

export const MAX_SLICE_WIDTH_PX = 16_000;

export enum TIMELINE_VARIANT_NAMES {
  NORMAL = "NORMAL",
  DENSE = "DENSE",
  SLIM = "SLIM"
}

export const TIMELINE_VARIANTS: Record<
  TIMELINE_VARIANT_NAMES,
  TimelineVariant
> = {
  NORMAL: {
    name: "NORMAL",
    ticksHeight: 26,
    ticksMargin: 0,
    marginBottom: 0
  },
  DENSE: {
    name: "DENSE",
    ticksHeight: 26,
    ticksMargin: 0,
    marginBottom: 0
  },
  SLIM: {
    name: "SLIM",
    ticksHeight: 26,
    ticksMargin: 0,
    marginBottom: 4
  }
};
